import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BuyeractivityRoutingModule } from './buyeractivity-routing.module';
import { BuyerlistComponent } from './buyerlist/buyerlist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { BuyeractivitylistComponent } from './buyeractivitylist/buyeractivitylist.component';


@NgModule({
  declarations: [BuyerlistComponent, BuyeractivitylistComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    CoreModule,
    SharedModule,
    BuyeractivityRoutingModule
  ]
})
export class BuyeractivityModule { }
