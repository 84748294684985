<!-- <p *ngIf="response" style="margin-top:20%;text-align:center">emailconformation is succesfull. Please login
  <a href="" [routerLink]="['/home']">Login</a>
</p>
<P *ngIf="errormsg">Email confirmation is failed
  <a href="" [routerLink]="['/home']">Login</a>
</P> -->

<div class="row justify-content-center p-3 no-gutters">
  <div class="col-xs-12 col-lg-8 col-md-9">
    <div class="card">
      <div class="card-body">
        <h2 class="text-center">
          Email Confirmation
        </h2>
        <div class="py-5">
          <span *ngIf="result" style="color: green;"> You have successfully confirmed your email address.</span>
          <span *ngIf="!result" style="color: red;"> Error while confirming your email address. Please contact the mediafiche team.</span>
        </div>
        <div class="text-left">Signin to Mediafiche?
          <a [routerLink]="['/login']" id="register-form-link" style="font-weight:bold; text-decoration: underline">
            Login
          </a>
        </div>
      </div>
    </div>
  </div>
</div>