import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browse-page',
  templateUrl: './browse-page.component.html',
  styleUrls: ['./browse-page.component.scss']
})
export class BrowsePageComponent implements OnInit {
  Options
  number
  constructor() { }

  ngOnInit() {

  }
  goToTop(){
    window.scrollTo(0, 0);
  //  $('html, body').animate({scrollTop: 0}, 1000);
  }
}
