import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRole } from 'src/app/enums/UserRole';

import { OnboardingComponent } from './onboarding.component';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { SellerinfoComponent } from './sellerinfo/sellerinfo.component';
import { SelleraddressComponent } from './selleraddress/selleraddress.component';
import { SellercontactComponent } from './sellercontact/sellercontact.component';
import { AuthGuardService } from '../auth/service/auth-guard.service';

const appRoutes: Routes = [
    {
        path: 'seller-onboarding',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                component: OnboardingComponent,
                canActivate: [AuthGuardService],
                data: {
                    roles: [
                        UserRole.MFSystemAdmin
                    ]
                }
            }
        ]
    }, {
        path: 'seller-onboarding/:id',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                component: SellerinfoComponent,
                canActivate: [AuthGuardService],
                data: {
                    roles: [
                        UserRole.MFSystemAdmin
                    ]
                }
            }
        ]
    },
    {
        path: 'seller-onboarding/:id/seller-address',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                component: SelleraddressComponent,
                canActivate: [AuthGuardService],
                data: {
                    roles: [
                        UserRole.MFSystemAdmin
                    ]
                }
            }
        ]
    },
    {
        path: 'seller-onboarding/:id/seller-contact',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                component: SellercontactComponent,
                canActivate: [AuthGuardService],
                data: {
                    roles: [
                        UserRole.MFSystemAdmin
                    ]
                }
            }
        ]
    }

];


@NgModule({
    imports: [
        RouterModule.forChild(appRoutes)
    ],
    exports: [RouterModule]
})
export class OnboardingRoutingModule { }