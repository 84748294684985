import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-offer-page',
  templateUrl: './offer-page.component.html',
  styleUrls: ['./offer-page.component.scss']
})
export class OfferPageComponent implements OnInit {

  userName : string;

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(private authService: AuthService) { 
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if(this.isAuthenticated){
      this.userName = this.authService.name;
    }
  }
}