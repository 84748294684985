import { Component, OnInit, Renderer2 } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { AlertService } from '../../core/api/services/alertservice/alert.service';
import { ViewrateService } from '../../core/api/services/viewrate.service';
import { ActivatedRoute } from '@angular/router';
import { ViewRate, Offers, offerLine } from '../../core/api/model';
import { OfferService } from '../../core/api/services/offer.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { SubmitofferComponent } from '../submitoffer/submitoffer.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-createinitialoffer',
  templateUrl: './createinitialoffer.component.html'
})
export class CreateinitialofferComponent implements OnInit {
  magazineid: number;
  issuedata: any;
  Datepick: any;
  offerid: number;
  adRateid: any;
  wishlistLineId?: number
  page: any;
  action: Subject<any> = new Subject();

  date: Date = new Date();

  showDatesAvailable: boolean = false;

  selectedAdPrice: any;

  dropdownSettings: any;

  constructor(
    private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    private renderer: Renderer2,
    public route: ActivatedRoute,
    private _ViewrateService: ViewrateService,
    private alertService: AlertService,
    private offerService: OfferService,
    private fb: FormBuilder) {
  }

  createOfferForm = this.fb.group({
    magazineId: [''],
    offerNumber: [''],
    instructions: [''],
    adRateId: [''],
    offerId: [''],
    offeredRate: ['', Validators.required],
    isFinalOffer: [false],
    coverDatesVm: ['', [Validators.required]],
    clientName: ['']
  });

  get magazineId() { return this.createOfferForm.get('magazineId'); }
  get offerNumber() { return this.createOfferForm.get('offerNumber'); }
  get instructions() { return this.createOfferForm.get('instructions'); }
  get adRateId() { return this.createOfferForm.get('adRateId'); }
  get offerId() { return this.createOfferForm.get('offerId'); }
  get offeredRate() { return this.createOfferForm.get('offeredRate'); }
  get coverDatesVm() { return this.createOfferForm.get('coverDatesVm'); }
  get clientName() { return this.createOfferForm.get('clientName'); }
  get isFinalOffer() { return this.createOfferForm.get('isFinalOffer'); }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'coverDate',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.Datepick = null;

    if (this.magazineid) {
      this.getIssueDates();
    }

    this.magazineId.patchValue(this.magazineid)
    this.adRateId.patchValue(this.adRateid);
  }

  loadOfferData() {
    if (this.offerid) {
      this.offerService.getOfferDetails(this.offerid).subscribe((data: Offers) => {
        this.createOfferForm.patchValue(data);
        this.selectedAdPrice = data.rate;

        if (!this.showDatesAvailable) {
          this.Datepick = this.dateformatter(data.coverDatesVm);
          this.coverDatesVm.patchValue(this.Datepick);
        }
      }, (err) => {
        this.alertService.error(err);
      });
    }
  }

  dateformatter(dates) {
    let coverdate: any
    let coverDates = [];
    if (dates) {
      if (this.showDatesAvailable) {
        dates.forEach(elem => {
          coverDates.push({ id: elem.id, coverDate: elem.coverDate });
        });

        return coverDates;
      } else if (!this.showDatesAvailable) {
        dates.forEach(elem => {
          coverdate = elem.coverDate;
        });

        return coverdate;
      }
    }
  }

  getIssueDates() {
    this._ViewrateService.getIssueDates(this.magazineid).subscribe((data: any) => {

      this.issuedata = data;
      if (this.issuedata.length > 0) {
        this.showDatesAvailable = true;
      }
      this.loadOfferData();
    });
  }

  validateForm() {
    Object.keys(this.createOfferForm.controls).forEach(field => {
      const control = this.createOfferForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    let date = this.createOfferForm.getRawValue();
    if(date.coverDatesVm && date.coverDatesVm.length === undefined){
      this.coverDatesVm.setErrors({ required: true });
    }
  }

  createOfferFormOnSubmit() {
    this.validateForm();
    if (this.createOfferForm.valid) {
      this.createInitialOffer();
    }
  }

  get f() { return this.createOfferForm.controls; }
  createInitialOffer() {
    if (this.offerid) {
     
      this.offerService.put(this.createOfferForm.getRawValue()).subscribe((data: number) => {
        this.modalRef.hide();
        this.callToSubmitOffferPopUp(data);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
    }
    else {

      this.offerService.create((this.createOfferForm.getRawValue())).subscribe((data: any) => {
        this.modalRef.hide();
        this.callToSubmitOffferPopUp(data);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
    }
  }

  onInputFieldChange($event) {
    if ($event) {
      if ($event.value == "") {
        this.coverDatesVm.patchValue([{}]);
      }
      else {
        this.coverDatesVm.patchValue([{ id: 0, coverDate: $event.value }]);
      }
    }
  }

  callToSubmitOffferPopUp(offerId: number) {
    this.modalRef = this.modalService.show(SubmitofferComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      data: {
        offerId: offerId,
        coverDate: this.Datepick,
        page: this.page,
        wishlistLineId: this.wishlistLineId
      }
    });
    setTimeout(() => {
      //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
      this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
    }, 500);


    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
      
      this.action.next(result);
    });
  }

  public myDatePickerOptions: IMyOptions = {

    // Buttons
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",
    // Format
    dateFormat: 'mm-dd-yyyy',
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() },
    //markDates: [{dates: [{year: 2020, month: 8, day: 20}], color: '#303030'}],
    showTodayBtn: true,
    // Show Clear date button
    showClearDateBtn: true,
    closeAfterSelect: true,

  };
}
