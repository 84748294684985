<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="breadCrumps">
        <mdb-breadcrumb customClass="blue-text lighten-4 tittle3">
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/seller-onboarding/']">
                <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
            </mdb-breadcrumb-item>
        </mdb-breadcrumb>
        </div>
        <div class="tab-pane fade show active">
            <div class="container-fluid">
                <div class="tab-pane fade in show active " id="panelSummary" role="tabpanel">
                    <div class="my-2 mt-3 mfl-o text-center mf-tittle">
                        <span class="ml-2">Seller Search</span>
                    </div>
                    <div class="jumbotron py-3">
                        <div *ngIf="canShowSellerData else noDataBlock">
                            <div class="row">
                                <div class="col-sm-3 my-2">
                                    <div class="card" style="width: 16rem;">
                                        <div class="card-body">
                                            <h6 class="card-subtitle mb-2 text-muted">
                                                <div>
                                                    Name:{{sellerDetails?.sellerName}}
                                                    <br /> Is Active: {{sellerDetails?.isActive}}
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #noDataBlock>
                            <div>
                                No Buyer Data found
                            </div>
                        </ng-template>
                        <div class="justify-content-center pt-3">
                            <button type="button" mdbBtn size="md" rounded="true"
                                class="btn btn-secondary1 secondary relative waves-light" (click)="sellerAddress()"
                                mdbWavesEffect>
                                Seller Address
                            </button>
                            <button type="button" mdbBtn size="md" rounded="true"
                                class="btn btn-secondary1 secondary relative waves-light" (click)="sellerContact()"
                                mdbWavesEffect>
                                Seller Contact
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>