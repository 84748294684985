<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="breadCrumps tittle3">
            <mdb-breadcrumb customClass="blue-text lighten-4 tittle3">
                <mdb-breadcrumb-item class="tittle3" [routerLink]="['/seller-onboarding/']">
                    <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
                </mdb-breadcrumb-item>
                <mdb-breadcrumb-item class="tittle3" [routerLink]="['/seller-onboarding/', sellerid]">
                    <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Info
                </mdb-breadcrumb-item>
            </mdb-breadcrumb>
        </div>
        <div class="tab-pane fade in show active" id="sellerAddressTabPane" role="tabpanel">
            <div class="my-2 mfl-o text-center mf-tittle">
                <span class="ml-2">Seller Addresses</span>
            </div>
            <div class="jumbotron py-3">
                <div *ngIf="canShowSellerAddressCard else noAddressBlock">
                    <div class="row">
                        <div *ngFor="let sa of sellerAddresses; let i = index">
                            <div class="col-sm-3 my-2">
                                <div class="card" style="width: 16rem;">
                                    <div class="card-body">
                                        <div class="text-right">
                                            <a class="tittle1" (click)="editAddressOnClick(sa)">Edit</a>
                                        </div>
                                        <h6 class="card-subtitle mb-2 text-muted">
                                            <div>
                                                {{sa.addressLine1}}
                                                <br />
                                                City: {{sa.addressCity}}
                                                <br />
                                                State: {{sa.addressStateCode}}
                                                <br />
                                                Zip: {{sa.addressZip}}
                                            </div>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #noAddressBlock>
                    <div>
                        No address found
                    </div>
                </ng-template>
                <div class="text-left pt-3">
                    <button type="button" mdbBtn size="md" rounded="true"
                        class="btn btn-secondary1 secondary relative waves-light" (click)="addAddressOnClick()"
                        mdbWavesEffect>
                        Add Address
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>