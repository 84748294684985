<button type="button" [data]="magazineId" mdbBtn class="btn-secondary1 border-radius btn-md waves-light"
  (click)="showModelwithRates(frame)" mdbWavesEffect>
  View Rate Card <i class="fas fa-eye ml-2"></i></button>
<div mdbModal #frame="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto !important;">

  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row" *ngIf="viewrateData">
          <div class="col-lg-5">
            <span class="black-text" *ngIf="viewrateData.magazineDisplayName">CPMs:
              {{ viewrateData.magazineDisplayName }}</span>
            <!--Carousel Wrapper-->


            <!--[animation]="'slide'"-->
            <!--First slide-->

            <img class="d-block w-100" src="{{coverUrl}}" alt="First slide">
            <div *ngIf="hasRates()">
              <p *ngFor="let data of results">
                <a *ngIf="data.assetTypeId == 4 || data.assetTypeId == 6" class="fa fa-file-pdf-o"
                  rel="noopener noreferrer" (click)="onMedialinkClick(data.url)" style="display:block"> <span
                    class="tittle1">{{data.assetName}}.pdf</span></a>
              </p>
            </div>
            <div style="color:#17a2b8" *ngIf="!hasRates()"> No Rates</div>

            <!--/.Carousel Wrapper-->

          </div>
          <div class="col-lg-7">
            <h2 class="h2-responsive product-name mainTittle">
              <strong>{{magazineName}}</strong>
            </h2>


            <!--Choose Ad Size-->
            <div>
              <mdb-card class="accordions" id="adsize">
                <div class="card z-depth-0 bordered">
                  <button mdbBtn class="waves-light btn-link cartfont" type="button" (click)="adsize.toggle()"
                    mdbWavesEffect>
                    <span class="tittle3">Choose Ad Size</span>
                  </button>
                  <div mdbCollapse #adsize="bs-collapse">
                    <mdb-accordion [multiple]="false" aria-multiselectable="true"
                      *ngFor="let adType of viewrateData.adOptionViewModels; let $index = index">
                      <mdb-accordion-item [collapsed]="true">
                        <mdb-accordion-item-head><span class="tittle1 font-bold-tittle">{{ adType.adOptionName |
                            uppercase }}</span></mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th class="text-left tittle3">Ad Size</th>
                                <th class="tittle3">Price</th>
                                <th *ngIf="IsBuyer tittle3">Add to cart</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let ad of adType.adRateOptionViewModel;  let $index = index">
                                <td style="text-align: left">
                                  <span *ngIf="ad.adSize">{{ad.adSize}}&nbsp;</span>
                                </td>
                                <td> {{ '$' + ad.adPrice}}</td>
                                <td>
                                  <div style="display:inline-block" *ngIf="IsBuyer" (click)="addToCart(ad)">
                                    <button type="button" class="cart"
                                      style="background-color: transparent; border:none; outline: none" mdbWavesEffect>
                                      <a>
                                        <i class="fa fa-cart-plus"></i>
                                      </a>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>
                    </mdb-accordion>

                  </div>
                </div>
              </mdb-card>
            </div>
            <!--/.Choose Ad Size-->

            <div class="text-center mt-5" *ngIf="IsBuyer">
              <button type="button" mdbBtn color="" class="btn-secondary1" mdbWavesEffect (click)="frame.hide()"
                data-dismiss="modal">Keep Browsing</button>
              <button mdbBtn class="btn-secondary1 waves-light" mdbWavesEffect [routerLink]="['/shopping-cart']">
                View Cart
                <i class="fa fa-cart-plus ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- /.Add to Cart -->

    </div>
  </div>
</div>