import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AddcontactService } from 'src/app/core/api/services/addcontact.service';
import { TelephoneType, MdbSelectOptions } from 'src/app/core/api/model';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { RegisterService } from 'src/app/core/api/services/register.service';
import { RegistrationLiteModel } from 'src/app/core/api/defs/RegistrationLiteModel';
import { Router } from '@angular/router';
import { AddaddressService } from 'src/app/core/api/services/addaddress.service';
import { AddessType } from 'src/app/core/api/defs/AddressType';
import { ViewChild } from '@angular/core';
import { States } from '../../core/api/defs/States';

@Component({
  selector: 'app-registration-lite',
  templateUrl: './registration-lite.component.html',
  styleUrls: ['./registration-lite.component.scss']
})
export class RegistrationLiteComponent implements OnInit {
  registrationLiteForm: FormGroup;
  telephoneTypeOptions: Array<any>;
  addressTypeOptions: Array<any>;
  agreementText: any;
  viewContent: string = "";
  stateTypeOptions: Array<any>;

  constructor(private formBuilder: FormBuilder,
    private addContactService: AddcontactService,
    private addaddressService:AddaddressService,
    private alertService: AlertService,
    private registerService: RegisterService,
    private router: Router) { }

  ngOnInit() {
    this.createForm();

    // TO DO: Need to uncomment when we want to include address. (UI & API)

    // this.getTelephoneTypes();

    // this.callAgreement();

    // this.getStates();

    // this.getAddressTypes();
  }

  createForm() {
    this.registrationLiteForm = this.formBuilder.group({

      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      buyerName: ['', Validators.required],
      buyerCompanyDescription: [''],
      //phone:['',[Validators.pattern(/^\(\d{3}\)-\d{3}-\d{4}$/),Validators.required]],
      //areaCode: ['', [Validators.minLength(3), Validators.maxLength(3), Validators.required, Validators.pattern('[0-9]*')]],
      //prefix: ['', [Validators.minLength(3), Validators.maxLength(3), Validators.required]],
      //lineNumber: ['', [Validators.minLength(4), Validators.maxLength(4), Validators.required]],
      //extension: ['', Validators.pattern('[0-9]*')],
      //telephoneTypeId: ['', Validators.required],
      isAgreementAccepted: [true, Validators.requiredTrue],
      // addressTypeId: ['', Validators.required],
      // addressLine1:['', Validators.required],
      // addressLine2:[''],
      // addressLine3:[''],
      // city:['', Validators.required],
      // state:['', Validators.required],
      // zipCode:['', Validators.required],
      // country:[{ value: 'US', disabled: true }, Validators.required],
    })
  }

  get firstName() { return this.registrationLiteForm.get('firstName'); }
  get lastName() { return this.registrationLiteForm.get('lastName'); }
  get email() { return this.registrationLiteForm.get('email'); }
  get password() { return this.registrationLiteForm.get('password'); }
  get confirmPassword() { return this.registrationLiteForm.get('confirmPassword'); }
  get buyerName() { return this.registrationLiteForm.get('buyerName'); }
  get buyerCompanyDescription() { return this.registrationLiteForm.get('buyerCompanyDescription'); }
  
  // get phone() { return this.registrationLiteForm.get('phone'); }

  // get areaCode() { return this.registrationLiteForm.get('areaCode'); }

  // get prefix() { return this.registrationLiteForm.get('prefix'); }

  // get lineNumber() { return this.registrationLiteForm.get('lineNumber'); }

  // get telephoneTypeId() { return this.registrationLiteForm.get('telephoneTypeId'); }

  // get extension() { return this.registrationLiteForm.get('extension'); }

  // get isAgreementAccepted() { return this.registrationLiteForm.get('isAgreementAccepted'); }

  // get addressTypeId() { return this.registrationLiteForm.get('addressTypeId'); }

  // get addressLine1() { return this.registrationLiteForm.get('addressLine1'); }
  
  // get addressLine2() { return this.registrationLiteForm.get('addressLine2'); }

  // get addressLine3() { return this.registrationLiteForm.get('addressLine3'); }
  
  // get city() { return this.registrationLiteForm.get('city'); }
  
  // get state() { return this.registrationLiteForm.get('state'); }
  
  // get zipCode() { return this.registrationLiteForm.get('zipCode'); }
  
  // get country() { return this.registrationLiteForm.get('country'); }


  getTelephoneTypes() {

    this.addContactService.getTelephoneTypes().subscribe((data: Array<TelephoneType>) => {
      this.telephoneTypeOptions = data.map((telephoneType) => {
        let option = new MdbSelectOptions();
        option.value = telephoneType.telephoneTypeId;
        option.label = telephoneType.telephoneTypeName;
        return option;
      })
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  getAddressTypes(){
    this.addaddressService.getAddressTypes().subscribe((data: Array<AddessType>) => {
      this.addressTypeOptions = data.map((addressType) => {
        let option = new MdbSelectOptions();
        option.value = addressType.addressTypeId;
        option.label = addressType.addressTypeName;
        return option;
      })
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  getConfirmPasswordValidationErrorMessage(): string {
    let err = this.registrationLiteForm.controls.confirmPassword.errors;

    if (err != null) {
      if (err.required) {
        return "Required";
      }

      if (err.validateEqual == false) {
        return "Confirm Password doesn't match with Password";
      }
    }

    return null;
  }

  validateForm() {
    Object.keys(this.registrationLiteForm.controls).forEach(field => {
      const control = this.registrationLiteForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  onSubmit() {
    // TO DO: Need to uncomment when we want to include address. (UI & API)
    
    // let number = this.phone.value;
    // this.areaCode.patchValue(number.substring(1,4));
    // this.prefix.patchValue(number.substring(6,9));
    // this.lineNumber.patchValue(number.substring(10,14));

    this.validateForm();
    if (this.registrationLiteForm.invalid) {
      return;
    }
    let registrationLiteModel: RegistrationLiteModel = this.registrationLiteForm.value;

    this.registerService.registrationLite(registrationLiteModel).subscribe((data: any) => {
      this.alertService.success(data.message);
      this.router.navigateByUrl('home');
    }, (err) => {
      this.alertService.error(err);
    });
  }

  getStates() {
    this.addaddressService.getStates('US').subscribe((data: Array<States>) => {
      if (data != null) {

        this.stateTypeOptions = data.map((state: States) => {
          let option = {
            label: state.stateName,
            value: state.stateCode
          };

          return option;
        });
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  callAgreement() {
    this.registerService.Agreements("Buyer").subscribe((response: string) => {
      this.agreementText = response;
      if (this.agreementText) {
        this.registerService.calltoAgreement(this.agreementText).subscribe((response: any) => {
          this.viewContent = response;
        })
      }
    });
  }
}