import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { Magazine } from '../defs/Magazine';
import { environment } from '../../../../environments/environment';

// interface browseSearchInputs {
//   value: string;
// }

@Injectable({
  providedIn: 'root'
})
export class BrowseSearchService {
  //inputs: browseSearchInputs = { value: '' };
  //selectedMagazine: any;


  //Seller tittle search
  params = new HttpParams().set("api-version", environment.azureSearchApiVersion);
  headers = new HttpHeaders()
    .append("Content-Type", "application/json")
    .append("Access-Control-Allow-Credentials", "true")
    .append("Access-Control-Allow-Origin", "*")
    .append("Access-Control-Allow-Methods", "*")
    .append("api-key", environment.azureSearchApiKey);

  constructor(private http: HttpClient) { }
  
  // search(term: string) {
  //   if (term === '') {
  //     return of([]);
  //   }
  //   this.inputs.value = term;
  //   return this.http.post<Magazine>(`${environment.mfServerUrl}api/Magazines/TitleLookup/`, this.inputs);
  // }

  azureSearchMagazineWithIcons(searchText: string) {
    return this.http.get<any>(`${environment.azureMagazineWithIconSearchUrl}` + '?search=' + searchText + "*",
      {
        params: this.params,
        headers: this.headers
      });
  }
}
