<mdb-navbar SideClass="navbar sticky-top navbar-expand-lg navbar-dark" [containerInside]="false">

  <!-- Navbar brand -->
  <mdb-navbar-brand>

    <a id="header-marketplace" class="navbar-brand" [routerLink]="['/home']">
      <img src="../../assets/images/MF_full_web.png" [routerLink]="['/home']" height="38" alt="MediaFiche logo" />
    </a>
  </mdb-navbar-brand>
</mdb-navbar>
<!--/.Navbar-->


<div class="row justify-content-center no-gutters">
  <div class="col-sm-10">
    <div class="pb-3">
      <router-outlet></router-outlet>
    </div>
  </div>


</div>