import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ViewrateService } from 'src/app/core/api/services/viewrate.service';
@Component({
  selector: 'app-demographic',
  templateUrl: './demographic.component.html',
  styleUrls: ['./demographic.component.scss']
})
export class DemographicComponent implements OnInit {
  @Input()
  results: any[];
  assetTypeId: number[];

  constructor(private _ViewrateService: ViewrateService) {
  }

  ngOnInit() {
    this.assetTypeId = [3];
    this._ViewrateService.assetsList.subscribe((response: any) => {
      this.results = response;
    });
  }

  hasDemographics() {
    if (this.results) {
      return this.results.find((result) => (this.assetTypeId.indexOf(result.assetTypeId) != -1) ? true : false);
    }
  }
  onMedialinkClick(MediaKitURL) {
    window.close();
    let w = window.open(MediaKitURL, "MediaKitPopup", "height=600,width=800");
    w.focus();
  }

}
