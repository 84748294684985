<div class="row">
  <div class="col-sm-10 mx-auto">
    <div class="breadCrumps">
      <mdb-breadcrumb customClass="blue-text lighten-4">
        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/seller-onboarding/']">
          <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/seller-onboarding/', sellerid]">
          <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Info
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
    <div *ngIf="sellerDetails != null " class="tab-pane fade in show active" id="sellerContactTabPane" role="tabpanel">
      <div class="my-2 mfl-o text-center mf-tittle">
        <span class="ml-2">Seller Contacts</span>
      </div>

      <div class="jumbotron py-3">

        <div *ngIf="canShowSellerContactCard else noContactBlock">

          <div *ngIf="hasRegisteredPrimaryContact" class="alert alert-warning" role="alert">
            <mdb-icon fas icon="info-circle"></mdb-icon>
            Primary contact has already been registered for this seller.
          </div>

          <div class="row">
            <div *ngFor="let sc of sellerContacts; let j = index">
              <div class="col-sm-3 my-2">
                <div class="card" style="width: 16rem;">
                  <div class="card-body">
                    <div class="text-right">
                      <a class="tittle1" (click)="editContactOnClick(sc)">Edit</a>
                    </div>
                    <h6 class="card-subtitle mb-2 text-muted">
                      {{sc.contactDescription}}
                    </h6>
                    <div class="card-text">
                      {{sc.contactFirstName}} {{sc.contactLastName}}
                      <br />

                      <div *ngFor="let e of sc.email">
                        <div>
                          {{e.emailDescription}} : {{e.email1}}
                        </div>
                      </div>

                      <div *ngFor="let c of sc.telephone">
                        <div>
                          +{{c.countryCode}}
                          ({{c.areaCode}})-{{c.prefix}}-{{c.lineNumber}}
                        </div>
                      </div>

                    </div>

                    <div class="card-text mt-1">
                      <div class="row no-gutters">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                          <label>
                            <input type="radio" name="contactTypRadio_{{j}}"
                              style="position: relative; opacity: 1; pointer-events: all;"
                              [checked]="sc.contactTypeId === 3" [value]="sc.contactId"
                              (change)="sellerPrimaryContactOnChange(sc.contactId, sellerDetails.sellerId)" />
                            Primary
                          </label>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                          <label>
                            <input type="radio" name="contactTypRadio_{{j}}"
                              style="position: relative; opacity: 1; pointer-events: all;"
                              [checked]="sc.contactTypeId === 4" [value]="sc.contactId"
                              (change)="sellerSecondaryContactOnChange(sc.contactId, sellerDetails.sellerId)" />
                            Secondary
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-template #noContactBlock>
          <div>
            No contacts found
          </div>
        </ng-template>

        <div class="text-left pt-3">
          <button type="button" mdbBtn size="md" rounded="true"
            class="btn btn-secondary1 secondary relative waves-light" (click)="addContactOnClick()" mdbWavesEffect>
            Add Contact
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="canShowSellerContactCard" class="text-center pb-3">
      <div *ngIf="!hasRegisteredPrimaryContact; else disabledButton">
        <button type="button" mdbBtn size="md" rounded="true"
          class="btn btn-secondary1 secondary relative waves-light" (click)="generateCode()" mdbWavesEffect>Generate
          Code
        </button>
      </div>

      <ng-template #disabledButton>
        <button disabled type="button" mdbBtn size="md" rounded="true"
          class="btn btn-secondary1 secondary relative waves-light" mdbWavesEffect>Generate Code
        </button>
      </ng-template>
    </div>
    <!-- Modal -->
    <div mdbModal class="modal fade modal-title w-100" #generatedCodeModal="mdb-modal" tabindex="-1" role="dialog"
      aria-labelledby="generatedCodeModalLabel" aria-hidden="true">

      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">On-boarding Detail</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              (click)="generatedCodeModal.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div *ngIf="generatedTempAuthResponse; else noDataBlock">
              <div style="padding: 1rem;">
                <div>
                  Please hand over the below authorization detail with seller's primary contact
                </div>
                <br />
                <div>
                  Temporary Authorization Code : {{generatedTempAuthResponse.tempAuthToken}}
                </div>
                <div>
                  Expiration Date Time : {{generatedTempAuthResponse.tempAuthExpirationDateTime | date: 'short'}}
                </div>
              </div>
            </div>
            <ng-template #noDataBlock>
              <div style="padding: 1rem;">
                There is some problem while generating the token. Please contact the administrator.
              </div>
            </ng-template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary tc-1 blueborder" data-dismiss="modal"
              (click)="generatedCodeModal.hide()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>