import * as __model from '../model';

export class Offers {
    magazineId?: number;
    offerId?: number;
    offerNumber?: number;
    adRateId?: number;
    instructions?: string;
    offeredRate?: number;
    rate?: number;
    magazineName?:string;
    adType?: string;
    adColor?: string;
    adSize?: string;
    adPrice?: number;
    offerredRate?:number;
    coverDatesVm?: coverDates[];
    clientName ?: string;
    isBuyerPaymentAccountSetup?:boolean;
}

export interface coverDates {
    id: number;
    coverDate: string;
}
