import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { ViewrateService } from '../../../core/api/services/viewrate.service';
import { ActivatedRoute } from '@angular/router';
import { ViewRate, CreateOffer } from '../../../core/api/model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreateinitialofferComponent } from '../../../shared/createinitialoffer/createinitialoffer.component';
import { UserRole } from '../../../enums/UserRole';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Subscription } from 'rxjs/Rx';


@Component({
  selector: 'app-createoffer',
  templateUrl: './createoffer.component.html',
  styleUrls: ['./createoffer.component.scss']
})
export class CreateofferComponent implements OnInit {
  @Input()
  magazineId: any;

  @Input()
  magazineName: string;

  adTypes: Array<any>;

  adColors: Array<any>;

  adSizes: Array<any>;

  viewrateData: ViewRate;
  
  showSelectionMessage: boolean;
  
  editoffer: CreateOffer;

  selectedAdPrice: number = 0;

  adForm: FormGroup;

  adTypeId: number;

  adColorId: number;
  
  adSizeId: number;

  selectDate: any;

  modalRef: MDBModalRef;

  isAuthenticated: boolean;

  subscription: Subscription;


  constructor(public route: ActivatedRoute,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private _ViewrateService: ViewrateService,
    private fb: FormBuilder, 
    private authService: AuthService) {

    route.params.subscribe(params => {
      this.getOfferData(params.id);

      this.adForm = this.fb.group({
        adType: ['', Validators.required],
        adColor: ['', Validators.required],
        adSize: ['', Validators.required],
      });
    });
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);
  }

  get adType() { return this.adForm.get('adType'); }

  get adColor() { return this.adForm.get('adColor'); }

  get adSize() { return this.adForm.get('adSize'); }

  get isAdFormHasAllValues() {
    return this.adTypeId && this.adColorId && this.adSizeId;
  }

  validateForm() {
    Object.keys(this.adForm.controls).forEach(field => {
      const control = this.adForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  getKeys(object) {
    return Object.keys(object);
  }

  onMedialinkClick(MediaKitURL) {
    let url = MediaKitURL;
    let w = window.open(url, "MediaKitPopup", "height=600,width=800");
    w.focus();
  }

  // adding selected item to cart
  addToCart() {
    if (this.isAdFormHasAllValues) {
      this._ViewrateService.addToCart(this.adSizeId);
    } else {
      this.validateForm();
    }
  }

  getOfferData(magazineId) {
    this.adTypes = [];
    this.adColors = [];
    this.adSizes = [];
    this.selectedAdPrice = 0;

    setTimeout(() => {
      this._ViewrateService.getAdtype(magazineId).subscribe((response: any) => {
        this.adTypes = response;
  
        setTimeout(() => {
          this.invokeAdTypeAndAdColorAndAdSizeOnInit();
        });
  
      })
    }, 500);
    
  }

  getAdcolors() {
    this.adColor.patchValue('');
    this.adSize.patchValue('');
    this.adColors = [];
    this.adSizes = [];
    this.adTypeId = this.adType.value;
    if (this.adTypeId) {
      this._ViewrateService.getAdColor(this.magazineId, this.adTypeId).subscribe((response: any) => {
        this.adColors = response;
      })
    }
  }

  getAdSizes() {
    this.adSizes  = [];
    this.adSize.patchValue('');
    this.adColorId = this.adColor.value;
    if (this.adColorId) {
      this._ViewrateService.getAdSize(this.magazineId, this.adTypeId, this.adColorId).subscribe((response: any) => {
        this.adSizes = response;
      })
    }
  }

  getAdPrices() {
    this.adSizeId = this.adSize.value;
    this._ViewrateService.getAdPrice(this.magazineId, this.adSizeId).subscribe((response: any) => {
      this.selectedAdPrice = response;
    });
  }

  showMakeOfferModal() {
    this.validateForm();

    if (this.adForm.valid)
      this.createInitialOffer();
  }

  get IsBuyer() {
    if (this.isAuthenticated) {
      return this.authService.getUserRoles().indexOf(UserRole.Buyer) != -1;
    }

    return false;
  }

  createInitialOffer() {
    this.modalRef = this.modalService.show(CreateinitialofferComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      data: {
        magazineid: this.magazineId,
        magazineName: this.magazineName,
        adRateid: this.adSizeId,
        selectedAdPrice: this.selectedAdPrice

      }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
    });
  }

  invokeAdTypeAndAdColorAndAdSizeOnInit() {
    var foundAdType = this.adTypes.find((x: { adTypeId: number; }) => x.adTypeId == 2);

    if (foundAdType != null && foundAdType != typeof (undefined)) {
      this.adTypeId = foundAdType.adTypeId;
      this.adType.patchValue(foundAdType.adTypeId);

      // Invoke AdColor API
      this._ViewrateService.getAdColor(this.magazineId, foundAdType.adTypeId).subscribe((adColorData: any) => {
        setTimeout(() => {
          this.adColors = adColorData;
          var foundAdColor = this.adColors.find(x => x.adColorId == 2);

          if (foundAdColor != null && foundAdColor != typeof (undefined)) {
            this.adColorId = 2;
            this.adColor.patchValue(2);

            // Invoke AdType API
            setTimeout(() => {
              this._ViewrateService.getAdSize(this.magazineId, this.adTypeId, this.adColorId).subscribe((response: any) => {
                this.adSizes = response;
              });
            }, 500);

          }
        }, 500);

      })

    }
  }
}