import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html'
})
export class DeleteConfirmComponent implements OnInit {
  deleteName:any;
  action: Subject<any> = new Subject();
  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  delete() {
    this.modalRef.hide();
    let json = {
      status: 'delete',
    };
    this.action.next(json);
  }

  cancel() {
    this.modalRef.hide();
  }
}
