import {CHANGE_MARKET_CATEGORY,
    CHANGE_MARKET_CATEGORY_ALL,
    CHANGE_MARKET_CATEGORY_DESELECT,
    CHANGE_MARKET_CATEGORY_DESELECT_ALL} from '../actions'


const marketCategoryReducer = (state = [],action) => {
    switch(action.type){

        case CHANGE_MARKET_CATEGORY: {
            let result =  [...state,action.payload]
            return result;
        }
        case  CHANGE_MARKET_CATEGORY_ALL : {
            let result = action.payload
            return result;
        }
        
        case  CHANGE_MARKET_CATEGORY_DESELECT_ALL: {
            return []
        }
        default: return state;
    }

}
export default marketCategoryReducer;