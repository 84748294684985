<div>
  <form [formGroup]="adForm">
    <div class="row">
      <div class="col">
        <span class="tittle3 font-weight-bold"> Ad Type</span>
        <select formControlName="adType" class="browser-default custom-select" (change)="getAdcolors()">
          <option value='' selected>- Select Type-</option>
          <option [value]='adtype.adTypeId' *ngFor="let adtype of adTypes">
            {{ adtype.adTypeName | uppercase }}
          </option>
        </select>

        <div *ngIf="adType.invalid && (adType.dirty || adType.touched)" class="text-danger">
          <div *ngIf="adType.errors.required">
            Required
          </div>
        </div>

      </div>
      <div class="col">
        <span class="tittle3 font-weight-bold"> Ad Color</span>
        <select formControlName="adColor" class="browser-default custom-select" (change)="getAdSizes()">
          <option value='' selected>- Select Color-</option>
          <option [value]='adColor.adColorId' *ngFor="let adColor of adColors">
            <span>{{ adColor.adColorName | uppercase }}</span>
          </option>
        </select>
        <div *ngIf="adColor.invalid && (adColor.dirty || adColor.touched)" class="text-danger">
          <div *ngIf="adColor.errors.required">
            Required
          </div>
        </div>
      </div>
      <div class="col">
        <span class="tittle3 font-weight-bold"> Ad Size</span>
        <select formControlName="adSize" class="browser-default custom-select" (change)="getAdPrices()">
          <option value='' selected>- Select Size-</option>
          <option [value]='adSize.adRateId' *ngFor="let adSize of adSizes">
            {{adSize.adSizeName | uppercase}}
          </option>
        </select>

        <div *ngIf="adSize.invalid && (adSize.dirty || adSize.touched)" class="text-danger">
          <div *ngIf="adSize.errors.required">
            Required
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3"></div>
    <div class="row bg-c1">
      <div class="col mt-3">
        <span class="font-weight-bold o-bold tittle3 o-display-inline ml-2 mr-3"> Price:</span>&nbsp;
        <span class="font-weight-bold text-left tittle1"> US ${{selectedAdPrice | number: '1.2-2'}}</span>
      </div>
      <div class="col text-left mt-3 mb-3">
        <div *ngIf="IsBuyer">
          <button mdbBtn class="btn-secondary1 border-radius btn-md waves-light" (click)="addToCart()" mdbWavesEffect>
            Add to cart <i class="fa fa-cart-plus ml-2" aria-hidden="true"></i>
          </button>
          <button mdbBtn class="btn-secondary1 border-radius btn-md waves-light" mdbWavesEffect
            (click)="showMakeOfferModal()">Make Offer <i class="fas fa-file-signature ml-2"></i></button>
        </div>
      </div>
    </div>
  </form>
</div>