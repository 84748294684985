import { Component, OnInit, Renderer2 } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { AddDemographicComponent } from '../../../shared/add-demographic/add-demographic.component';
import { DemographicsService } from 'src/app/core/api/services/demographics.service';
import { Demographic } from '../../../core/api/defs/Demographic';
import { DeleteConfirmComponent } from 'src/app/shared/delete-confirm/delete-confirm.component';


@Component({
  selector: 'app-demographic',
  templateUrl: './demographic.component.html',
  styleUrls: ['./demographic.component.scss']
})
export class DemographicComponent implements OnInit {
  selectedMediakitId: any;

  modalRef: MDBModalRef;

  demographic: Demographic;

  showDemographic: boolean = false;

  constructor(private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private demographicsService: DemographicsService) { }
  optionsSelect: Array<any>;

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.selectedMediakitId = params.mediaKitId;
    });
    this.getDemographic(this.selectedMediakitId);
  }

  getDemographic(mediakitId) {
    this.demographicsService.read(mediakitId).subscribe((data: Demographic) => {
      this.demographic = data;
      this.showDemographic = true;
    })
  }

  addDemoClick() {
    this.modalRef = this.modalService.show(AddDemographicComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.selectedMediakitId,
        isEdit: false
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {

      // The values are sent in json format
      if (result != null) {
        this.getDemographic(this.selectedMediakitId);
      }
    });
  }

  editDemoClick(demo) {
    this.modalRef = this.modalService.show(AddDemographicComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.selectedMediakitId,
        demographic: demo,
        isEdit: true
      }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {

      // The values are sent in json format
      if (result != null) {
        this.getDemographic(this.selectedMediakitId);
      }
    });
  }
  deleteDemo(demoId) {
    this.modalRef = this.modalService.show(DeleteConfirmComponent, {
      class: 'modal-sm modal-notify modal-warning',
      ignoreBackdropClick: true,
      data: {
        deleteName : 'Demographic'
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result.status == 'delete') {
        this.demographicsService.delete(demoId).subscribe((data: Demographic) => {
          this.alertService.success("Demographic Item is Deleted Successfully")
          this.getDemographic(this.selectedMediakitId);
        }, (errMsg) => {
          this.alertService.error(errMsg);
        });
      }
    });
  }

}
