import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { BuyerAddressData } from 'src/app/core/api/defs/Buyer';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';
import { AddAddressComponent } from 'src/app/shared/add-address/add-address.component';

@Component({
  selector: 'app-buyeraddress',
  templateUrl: './buyeraddress.component.html'
})
export class BuyeraddressComponent implements OnInit {
  canShowBuyerAddressCard: boolean = false;
  Buyeraddressdata: BuyerAddressData;
  buyerid:any;
  modalRef: MDBModalRef;
  constructor(private buyersearchService: BuyersearchService,
    private alertService: AlertService,
    private modalService: MDBModalService,
    private router: Router,
    public route: ActivatedRoute,
    private renderer: Renderer2) {
    route.params.subscribe(params => {
this.buyerid = params.id

    })
  }


  ngOnInit(): void {
    this.getBuyerAddress(this.buyerid);
  }
  getBuyerAddress(buyerId) {
    this.buyersearchService.getBuyerAddress(buyerId).subscribe((data: BuyerAddressData) => {
      this.canShowBuyerAddressCard = (data) ? true : false;

      this.Buyeraddressdata = data;

    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }
  addAddressOnClick() {
    this.modalRef = this.modalService.show(AddAddressComponent, {
        class: 'modal-lg',
        data: {
            id: this.buyerid,
            type: "buyer",
            isEdit: false
        }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {

        // The values are sent in json format
        if (result != null) {

            if (result.type == "buyer") {
                // Invoke API to get the data
                this.getBuyerAddress(result.id);
            }
        }
    });
}

editAddressOnClick(buyer) {
    this.modalRef = this.modalService.show(AddAddressComponent, {
        class: 'modal-lg',
        data: {
            id: this.buyerid,
            type: "buyer",
            data: buyer,
            isEdit: true
        }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {

        // The values are sent in json format
        if (result != null) {

            if (result.type == "buyer") {
                // Invoke API to get the data
                this.getBuyerAddress(result.id);
            }
        }
    });
}
}
