export namespace MagazineSearch {

    export interface Config {
        index: string,
        queryKey: string,
        service: string
    };

    //export type SearchApiVersion = "2019-05-06" | "2015-02-28-Preview";
    export type SearchMode = "any" | "all";
    export type QueryType = "simple" | "full";

    export type SearchParameters = {
        count: boolean,
        top: number,
        skip: number,
        orderby: string,
        searchMode: SearchMode,
        scoringProfile: string,
        select: string,
        searchFields: string,
        minimumCoverage?: string,
        apiVersion: string,
        queryType: QueryType,
        scoringParameters: string[],
        highlight: string,
        highlightPreTag: string,
        highlightPostTag: string
    };

    export type SearchParametersUpdate = {
        count?: boolean,
        top?: number,
        skip?: number,
        orderby?: string,
        searchMode?: SearchMode,
        scoringProfile?: string,
        select?: string,
        searchFields?: string,
        minimumCoverage?: string,
        apiVersion?: string,
        queryType?: QueryType,
        scoringParameters?: string[],
        highlight?: string,
        highlightPreTag?: string,
        highlightPostTag?: string
    };

    export type SuggestionsParameters = {
        top: number,
        filter: string,
        orderby: string,
        fuzzy: boolean,
        highlightPreTag: string,
        highlightPostTag: string
        select: string,
        searchFields: string,
        minimumCoverage?: string,
        apiVersion: string,
        suggesterName: string
    };

    export type Parameters = {
        searchParameters: SearchParameters,
        suggestionsParameters: SuggestionsParameters,
        input: string
    };

    export type SearchResults = {
        count: number,
        isFetching: boolean,
        lastUpdated: number,
        results: {}[]
    };

    export type CheckboxFacetItem = {
        value: string | number,
        count: number
        selected: boolean
    };

    export type FacetSortingMode = "count" | "count-" | "value" | "value-";

    export type RangeDataType = "number" | "date";

    export type RangeFacet = {
        type: "RangeFacet"
        dataType: RangeDataType,
        key: string,
        min: number | Date,
        max: number | Date,
        filterLowerBound: number | Date,
        filterUpperBound: number | Date,
        lowerBucketCount: number,
        middleBucketCount: number,
        upperBucketCount: number,
        facetClause: string,
        filterClause: string,
    };

    export type CheckboxDataType = "number" | "string" | "collection" | "boolean" | "array" | "education";

    export type CheckboxFacet = {
        type: "CheckboxFacet"
        dataType: CheckboxDataType
        key: string,
        values: { [key: string]: CheckboxFacetItem }
        count: number,
        sort: FacetSortingMode
        facetClause: string,
        filterClause: string,
    };

    export type FacetResult = {
        count: number,
        // for bucketed facets
        value?: string | number,
        // for range facets
        from?: number,
        to?: number
    };

    export type FacetMode = "simple" | "advanced";

    export type Facets = {
        facetMode: FacetMode,
        globalFilters: { [key: string]: string },
        facets: { [key: string]: Facet }
    };

    export type Facet = RangeFacet | CheckboxFacet;

    export interface SearchState {
        config: Config,
        results: SearchResults,
        // suggestions: Suggestions,
        facets: Facets
        parameters: Parameters
    };
    //export type MagazineDetailsApiKey = 'A42CE719EEEAAC76CAF7281ACA0B5F71';

    //export type MagazineDetailsApiVersion = "2017-11-11";

    // export type MagazineDetailsResult = {
    //     Magazineid: any;
    //     MagazineName : any;
    //     MarketCategoryName : any;
    //     PublicationFrequency : any;
    //     magazineName : any;
    //     Readership : any;
    //     PublisherStatement : any;
    //     type: any;
    // };
    export interface MagazineDetailsResultUpdate {
        MagazineId: any;
        MagazineName: any;
        MarketCategories: any;
        CoverUrl: any;
        PublicationFrequency: any;
        Circulation: any;
        PublisherName: any;
        PublisherStatement: any;
        MediaKitURL: string;
        results: any[];
        EnteredBy: string;
        AssetFolder: string;
        Readership: string;
        AuditedAnnual: string;
        ReportedAnnual: string;
        RateBase: string;
        AuditedPerIssue: string;
        ReportedPerIssue: string;
        Subscribers: string;
        NewsStandSales: string;
        Average_HHI: string;
        Median_HHI: string;
        Median_Age: string;
        Average_Age: string;
        Average_HH_Net_Worth: string;
        Median_HH_Net_Worth: string;
        Average_Income: string;
        Median_Income: string;

    };

    export interface MagazineDetailsResultTabUpdate {
        results: any;
        // MarketCategoryName : any;
        // PublicationFrequency : any;
        // magazineName : any;
        // Readership : any;
        // PublisherStatement : any;
        // type: any;
    };

    export type Asset = {
        "AssetName": string;
        "AssetUri": any;
        "AssetTypeId": string
        // MarketCategoryName : any;
        // PublicationFrequency : any;
        // magazineName : any;
        // Readership : any;
        // PublisherStatement : any;
        // type: any;
    };
    export class ageNumericSlider {
        labelName: string
        name: string
        minValue: number = 0;
        maxValue: number = 65;
        options: ageRangeSliderOptions = new ageRangeSliderOptions();

    }
    export class ageRangeSliderOptions {
        floor: number = 0;
        ticksArray = [18, 25, 35, 45, 55, 65];
        translate: (value: string) => string = (value) => {
            if(value == '65'){
                return value + "+";
            }
           else{
            return value;
           }
    }
}
    export class numericRangeSlider {
        labelName: string
        name: string
        minValue: number = 0;
        maxValue: number = 65;
        options: numericRangeSliderOptions = new numericRangeSliderOptions();
    }
    export class numericRangeSliderOptions {
        floor: number = 0;
        ceil: number = 65;
        step: number = 1;
    }
    export class percentRangeSlider extends numericRangeSlider {
        options: percentRangeSliderOptions = new percentRangeSliderOptions();
    }
    export class currencyRangeSlider extends numericRangeSlider {
        options: currencyRangeSliderOptions = new currencyRangeSliderOptions();
    }

    export class numberslider extends numericRangeSlider {
        options: numberRangeSliderOptions = new numberRangeSliderOptions();
    }

    export class numberRangeSliderOptions extends numericRangeSliderOptions {
        translate: (value: number) => string = (value) => {
            let formatter = new Intl.NumberFormat('en-US', {
                currency: 'USD',
                minimumFractionDigits: 0
            })
            return formatter.format(value);
        }
    }

    export class percentRangeSliderOptions extends numericRangeSliderOptions {
        translate: (value: string) => string = (value) => value + "%";
    }
    export class currencyRangeSliderOptions extends numericRangeSliderOptions {
        translate: (value: number) => string = (value) => {
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            return formatter.format(value);
        }
    }


}