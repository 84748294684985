import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { TimerInfo, OfferData, MfTimeSpan } from 'src/app/core/api/model';
import { Observable, interval, Subscription, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OfferState } from 'src/app/enums/OfferState';
import { TimerState } from 'src/app/enums/TimerState';

@Component({
  selector: 'app-offertimer',
  templateUrl: './offertimer.component.html',
})
export class OffertimerComponent implements AfterViewInit, OnDestroy {

  @Input()
  timerInfo?: TimerInfo;

  @Input()
  offerState: string;

  private unsubscribe: Subject<void> = new Subject();

  displayText: Observable<string>;

  displayTimerText: string;

  isTimerExpired: boolean = true;

  isTimerStopped: boolean = false;

  isNewOffer: boolean = false;

  constructor() { }

  ngAfterViewInit() {

    // we can use delay(0) from rxjs as well
    setTimeout(() => {

      if (this.offerState == OfferState.NewOffer) {
        this.isNewOffer = true;
      }
      else if(this.offerState == OfferState.Expired){
        this.isTimerExpired = true;
      }
      else {
        if (this.timerInfo) {
          if (this.timerInfo.timerStateId == TimerState.Stop) {
            this.isTimerStopped = true;
          }
          else {
            this.isTimerExpired = false;

            this.displayText = new Observable<string>(observer => {

              const interval = setInterval(() => {
                let val = this.getTimer(this.timerInfo.remainingTime);
                observer.next(val)
              }, 1000);

              // teardown
              return () => {
                clearInterval(interval);
              }
            });

            this.displayText
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(
                (value) => this.displayTimerText = value,
                (error) => console.error(error),
                () => { });
          }
        }
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getTimer(offerTimeSpan: MfTimeSpan): string {

    if (offerTimeSpan.days <= 0 && offerTimeSpan.hours <= 0 && offerTimeSpan.minutes <= 0 && offerTimeSpan.seconds <= 0) {
      this.isTimerExpired = true;
      return "";
    }

    offerTimeSpan.seconds--;

    if (offerTimeSpan.seconds == 0) {
      offerTimeSpan.seconds = 59;
      offerTimeSpan.minutes--;
    }

    if (offerTimeSpan.minutes == 0) {
      offerTimeSpan.minutes = 59;
      offerTimeSpan.hours--;
    }

    if (offerTimeSpan.hours == 0) {
      offerTimeSpan.hours = 23;
      offerTimeSpan.days--;
    }

    if (offerTimeSpan.days > 0) {
      return offerTimeSpan.days + "d " + offerTimeSpan.hours + "h " + offerTimeSpan.minutes + "m " + offerTimeSpan.seconds + "s "
    }
    else if (offerTimeSpan.hours > 0) {
      return offerTimeSpan.hours + "h " + offerTimeSpan.minutes + "m " + offerTimeSpan.seconds + "s ";
    }
    else if (offerTimeSpan.minutes > 0) {
      return offerTimeSpan.minutes + "m " + offerTimeSpan.seconds + "s ";
    }
    else if (offerTimeSpan.seconds > 0) {
      return offerTimeSpan.seconds + "s "
    }

    return "0d 0h 0m 0s";
  }
}

class TimeConstants {
  public static Seconds: number = 1000;
  public static Minutes: number = (1000 * 60);
  public static Hours: number = (1000 * 60 * 60);
  public static Days: number = (1000 * 60 * 60 * 24);
}

