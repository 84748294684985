import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { MediakituploadService } from 'src/app/core/api/services/mediakitupload.service';
import { MediakitAssets } from 'src/app/core/api/defs/MediakitAssets';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { AddAssetsComponent } from '../../../../shared/add-assets/add-assets.component';
import { AlertService } from '../../../../core/api/services/alertservice/alert.service';
import { Router } from '@angular/router';
import { DeleteConfirmComponent } from 'src/app/shared/delete-confirm/delete-confirm.component';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Input() message: string;
  @Input() assetsTypeId: number;
  @Input() mediakitId: number;
  @Input() magazineId: number;
  @Input() sellerId: number;
  modalRef: MDBModalRef;


  assetsData: any;
  constructor(
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private mediakituploadService: MediakituploadService,
    private router: Router,
    private alertService: AlertService) { }

  ngOnInit() {
    this.getAssets();
  }

  getAssets() {
    this.mediakituploadService.read(this.mediakitId, this.assetsTypeId).subscribe((data: MediakitAssets) => {
      this.assetsData = data;
    })
  }
  deleteAsset(assetId) {
    this.modalRef = this.modalService.show(DeleteConfirmComponent, {
      class: 'modal-sm modal-notify modal-warning',
      ignoreBackdropClick: true,
      data: {
        deleteName : 'Upload'
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result.status == 'delete') {
        this.mediakituploadService.delete(assetId).subscribe((response: any) => {
          this.alertService.success("Assets Deleted Successfully");
          this.getAssets();
        }, (errMsg) => {
          this.alertService.error(errMsg);
        });
      }
    });
  }

  continuechanges() {
    this.router.navigateByUrl('maintenance/' + this.sellerId + '/magazine/' + this.magazineId + '/mediakit/' + this.mediakitId + '/demo-circular-rates');
    //Maintenance/:id/Mediakit/DemoCircularRates
  }
  addClick() {
    this.modalRef = this.modalService.show(AddAssetsComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        mediakitid: this.mediakitId,
        assetsTypeid: this.assetsTypeId,
        magazineid: this.magazineId
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result) {
        this.getAssets();
      }
    });
  }


}
