<div class="row">
  <div class="col-sm-12 mx-auto">
    <div class="tab-pane fade show active">
      <div class="container-fluid">
        <div class="tab-pane fade in show active" id="MagazineTabPane" role="tabpanel">
          <div class="my-2 mt-5 mfl-o mf3-center mf-tittle">
            <span class="ml-2">Circulation List</span>
          </div>
          <div class="jumbotron py-3">
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th>MediaKit Id</th>
                  <th>Circulation Per Issue</th>
                  <th>Readership</th>
                  <th>News Stand Sales</th>
                  <th>Subscribers</th>
                  <th>Rate Base</th>
                  <th>Reach</th>
                  <th>Audited Annual</th>
                  <th>Audited Per Issue</th>
                  <th>Reported Annual</th>
                  <th>Reported Per Issue</th>
                  <th>Audited Circulation INT</th>
                  <th>Audited Circulation Total</th>
                  <th>Audited Circulation US</th>
                  <th>Reported Circulation INT</th>
                  <th>Is Circulation Per Issue Audited</th>
                  <th>Is Active</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cir of circulations; let i = index">
                  <td>{{cir.mediaKitId}}</td>
                  <td>{{cir.circulationPerIssue}}</td>
                  <td>{{cir.readership}}</td>
                  <td>{{cir.newsStandSales}}</td>
                  <td>{{cir.subscribers}}</td>
                  <td>{{cir.rateBase}}</td>
                  <td>{{cir.reach}}</td>
                  <td>{{cir.auditedAnnual}}</td>
                  <td>{{cir.auditedPerIssue}}</td>
                  <td>{{cir.reportedAnnual}}</td>
                  <td>{{cir.reportedPerIssue}}</td>
                  <td>{{cir.auditedCirculationInt}}</td>
                  <td>{{cir.auditedCirculationTotal}}</td>
                  <td>{{cir.auditedCirculationUs}}</td>
                  <td>{{cir.reportedCirculationInt}}</td>
                  <td>{{cir.isCirculationPerIssueAudited}}</td>
                  <td>{{cir.isActive}}</td>

                  <td class="tittle1">
                    <a (click)="editCirculationClick(cir)">
                      <mdb-icon fas icon="edit"></mdb-icon>
                    </a>
                  </td>
                  <td class="tittle1">
                    <a (click)="delteCirculationClick(cir.circulationId)">
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row pb-3">
            <div class="col-sm-12 text-center">
              <button type="button" mdbBtn size="md" class="btn btn-secondary1 secondary relative waves-light mr-4"
                (click)="addCirculationClick()" mdbWavesEffect>Add Circulation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>