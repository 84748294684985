import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../auth/service/auth-guard.service';
import { UserRole } from '../enums/UserRole';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { TermsandconditionComponent } from './pages/termsandcondition/termsandcondition.component';


const routes: Routes = [
  {
    path: 'terms-and-conditions',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: TermsandconditionComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.Buyer,
            UserRole.Seller
          ]
        }
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TermsandconditionsRoutingModule { }
