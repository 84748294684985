import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService, ModalDirective } from 'ng-uikit-pro-standard';
import { SellerSearch } from 'src/app/core/api/defs/SearchSeller';
import { TempAuthRequest } from 'src/app/core/api/model';
import { Publisher } from 'src/app/core/api/defs/Seller'
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { SellersearchService } from 'src/app/core/api/services/sellersearch.service';
import { AddContactComponent } from 'src/app/shared/add-contact/add-contact.component';

@Component({
    selector: 'app-sellercontact',
    templateUrl: './sellercontact.component.html'
})
export class SellercontactComponent implements OnInit {
    @ViewChild('generatedCodeModal', /* TODO: add static flag */ { static: true })
    public generatedCodeModal: ModalDirective;
    sellerDetails: Publisher;
    sellerContacts: Array<any>;

    selectedSellerSearch: SellerSearch = new SellerSearch();
    canShowSellerContactCard: boolean = false;
    sellerPrimaryContactAny: boolean = false;

    sellerid: any;
    modalRef: MDBModalRef;
    hasRegisteredPrimaryContact: boolean = false;
    generatedTempAuthResponse: TempAuthRequest;

    constructor(private sellerSearchService: SellersearchService,
        private alertService: AlertService,
        private modalService: MDBModalService,
        private router: Router,
        private renderer: Renderer2,
        public route: ActivatedRoute) {
        route.params.subscribe(params => {
            this.sellerid = params.id;
            this.getSellerContact(this.sellerid);
        })
    }

    ngOnInit(): void {
    }

    getSellerContact(sellerId) {
        this.sellerSearchService.getSellerDetails(sellerId).subscribe((data: Publisher) => {
            this.sellerDetails = data;
            this.hasRegisteredPrimaryContact = data.hasRegisteredPrimaryContact
        });
        this.sellerSearchService.getSellerContacts(sellerId).subscribe((data: Array<any>) => {

            this.sellerContacts = data;
            this.canShowSellerContactCard = data.length > 0;
            this.sellerPrimaryContactAny = data.some((c) => c.contactTypeId === 3);
        });
    }

    generateCode() {
        if (this.sellerDetails) {
            this.selectedSellerSearch.SellerName = this.sellerDetails.sellerName;
            this.selectedSellerSearch.PartyId = this.sellerDetails.partyId;
        }

        if (this.sellerPrimaryContactAny) {
            this.sellerSearchService.generateCode(this.selectedSellerSearch).subscribe((data: TempAuthRequest) => {

                this.generatedTempAuthResponse = data;
                this.generatedCodeModal.show();
            }, (errMsg) => {
                this.alertService.error(errMsg);
            });
        }
        else {
            this.alertService.error("Please select primary contact before generating code");
        }
    }

    editContactOnClick(contact) {
        this.modalRef = this.modalService.show(AddContactComponent, {
            class: 'modal-lg',
            data: {
                id: this.sellerid,
                type: "seller",
                data: contact,
                isEdit: true
            }
        });
        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {

            // The values are sent in json format
            if (result != null) {

                if (result.type == "seller") {
                    // Invoke API to get the data
                    this.getSellerContact(result.id);
                }
            }
        });
    }

    addContactOnClick() {
        this.modalRef = this.modalService.show(AddContactComponent, {
            class: 'modal-lg',
            data: {
                id: this.sellerid,
                type: "seller",
                isEdit: false
            }
        });

        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {

            // The values are sent in json format
            if (result != null) {

                if (result.type == "seller") {
                    // Invoke API to get the data
                    this.getSellerContact(result.id);
                }
            }
        });
    }

    sellerPrimaryContactOnChange(contactId: number, sellerId: number) {
        this.sellerSearchService.anyRegisteredPrimaryContact(sellerId).subscribe((data: boolean) => {
            if (!data) {
                let sc = {
                    sellerId: sellerId,
                    contactId: contactId
                };

                this.sellerSearchService.updateSellerPrimaryContact(sc).subscribe((data: any) => {
                    this.alertService.success(data.message);
                    this.getSellerContact(sellerId);
                }, (errMsg) => {
                    this.alertService.error(errMsg);
                });
            }
            else {
                this.getSellerContact(sellerId);
            }
        }, (error) => {
            console.log(error);
        })
    }

    sellerSecondaryContactOnChange(contactId: number, sellerId: number) {
        let sc = {
            sellerId: sellerId,
            contactId: contactId
        };
        this.sellerSearchService.updateSellerSecondaryContact(sc).subscribe((data: any) => {
            this.alertService.success(data.message);
            this.getSellerContact(sellerId);
        }, (errMsg) => {
            this.alertService.error(errMsg);
        })
    }
}
