import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/service/auth.service';

@Component({
  selector: 'app-signout-callback',
  templateUrl: './signout-callback.component.html',
  styleUrls: ['./signout-callback.component.css']
})
export class SignoutCallbackComponent implements OnInit {

  constructor(private authService: AuthService,
    private router: Router) {
  }

  ngOnInit() {
    this.authService.signOut().then(_ => {
      this.router.navigate(['/'], { replaceUrl: true })
    });
  }
}
