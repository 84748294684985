import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MDBModalService, MDBModalRef, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs/Rx';
import { Seller } from 'src/app/core/api/defs/Seller';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { MagazineService } from 'src/app/core/api/services/magazine.service';
import { SellersearchService } from '../../core/api/services/sellersearch.service'
import { SellerSearchResult, SellerSearch } from '../../core/api/defs/SearchSeller';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-change-seller',
  templateUrl: './change-seller.component.html'
})
export class ChangeSellerComponent implements OnInit {
  
  @ViewChild('auto', /* TODO: add static flag */ { static: true })
  public completer: MdbAutoCompleterComponent;
  searchText: string;
  magazineid: number;
  currentDate: Date
  action: Subject<any> = new Subject();
  sellerSearchResultData: Array<SellerSearch>;
  selectedSellerSearch: any;
  userName: any;
  isAuthenticated: boolean;
  subscription: Subscription;

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private sellersearchService: SellersearchService,
    private alertService: AlertService,
    private magazineService: MagazineService,
    private authService: AuthService) {

  }

  changeSellerDetails = this.fb.group({
    newSellerId: [''],
    magazineId: [''],
    modifiedOn: [''],
    modifiedBy: ['']
  });

  // Properties to use in html
  get newSellerId() { return this.changeSellerDetails.get('newSellerId'); }
  get magazineId() { return this.changeSellerDetails.get('magazineId'); }
  get modifiedOn() { return this.changeSellerDetails.get('modifiedOn'); }
  get modifiedBy() { return this.changeSellerDetails.get('modifiedBy'); }

  validateForm() {
    Object.keys(this.changeSellerDetails.controls).forEach(field => {
      const control = this.changeSellerDetails.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  ngOnInit() {
    this.searchText = '';
    this.currentDate = new Date();

    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.loadData();
  }

  ngAfterViewInit() {
    this.completer.selectedItemChanged().subscribe((data: any) => {
      this.searchText = data.text;
      this.selectedSellerSearch = this.sellerSearchResultData.find(x => x.SellerName == data.text);
      this.newSellerId.patchValue(this.selectedSellerSearch.SellerId);
    });
  }

  onChange() {
    
    if (this.searchText != "") {
      this.sellersearchService.searchSeller(this.searchText).subscribe((data: SellerSearchResult) => {
        this.sellerSearchResultData = data.value;
      }, (error) => {
        console.log(error);
      });
    }
  }

  loadData() {
    this.magazineId.patchValue(this.magazineid);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  changedSellerDetail() {
    this.magazineService.changeSeller(this.changeSellerDetails.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Seller updated successfully');

        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          type: 'success'
        };
        this.action.next(json);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

}