import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../core/core.module';
import { OnboardingbuyerRoutingModule } from './onboardingbuyer-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OnboardingbuyerComponent } from './onboardingbuyer.component';

import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';
import { BuyeraddressComponent } from './buyeraddress/buyeraddress.component';
import { BuyerinfoComponent } from './buyerinfo/buyerinfo.component';
import { BuyercontactComponent } from './buyercontact/buyercontact.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    OnboardingbuyerRoutingModule,
    FormsModule,
    CoreModule,
    SharedModule
  ],
  declarations: [OnboardingbuyerComponent, BuyeraddressComponent, BuyerinfoComponent, BuyercontactComponent]
})
export class OnboardingbuyerModule { }
