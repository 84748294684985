<footer class="page-footer font-small pt-4 bottom">
  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">
    <div class="row">
      <div class="col-xs-12 col-sm-12">
        <h5 class="text-uppercase font-weight-bold text-center">Need Help?</h5>
        <p class="text-center">Contact Our Customer Support Team</p>
          <p class="text-center"><a href="https://www.mediafiche.com/faq" target="_blank">FAQ</a>  |  <a href="https://www.mediafiche.com" target="_blank">Toll-Free Line: 866.514.3342</a> |  Email: support@mediafiche.com  |  <a href="https://www.mediafiche.com/privacy-policy" target="_blank">Privacy Policy </a></p>
      </div>
    </div>
  </div>

  <div class="footer-copyright text-center py-3">
    <div class="">
      © 2020 Copyright:
      <a target="_blank" href="https://mediafiche.com/"> Mediafiche.com</a>
    </div>

    <div class=" text-center" style="font-size: .75rem !important; color: #fff">

      Version: <span *ngIf="buildDetails?.environmentName !=''">{{buildDetails?.environmentName}}</span>
      {{buildDetails?.buildNumber}}

    </div>
  </div>
</footer>