import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowseRoutingModule } from './browse-routing.module';
import { BrowsePageComponent } from './pages/browse-page.component';
import { DisplayresultsComponent } from './pages/displayresults/displayresults.component';
import { SidenavComponent } from './pages/sidenav/sidenav.component';
import { MultiselectComponent } from './pages/shared/multiselect/multiselect.component';
import { NgReduxModule } from '@angular-redux/store';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { SingleselectComponent } from './pages/shared/singleselect/singleselect.component';
import { MagazinedetailspageComponent } from './pages/magazinedetails/magazinedetailspage.component';
import { AudienceComponent } from './pages/magazinedetails/audience/audience.component';
import { CirculationComponent } from './pages/magazinedetails/circulation/circulation.component';
import { DatesComponent } from './pages/magazinedetails/dates/dates.component';
import { RatesComponent } from './pages/magazinedetails/rates/rates.component';
import { DemographicComponent } from './pages/magazinedetails/demographics/demographic.component';
import { CollapseComponent } from './pages/collapse/collapse.component'
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { CreateofferComponent } from './pages/createoffer/createoffer.component';
import { SharedModule } from '../shared/shared.module';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { SingleselectstaticComponent } from './pages/shared/singleselectstatic/singleselectstatic.component';

@NgModule({
  imports: [
    BrowseRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    MDBBootstrapModulesPro.forRoot(),
    NgReduxModule,
    CommonModule,
    CoreModule,
    SharedModule
    // SharedModule
  ],
  declarations: [
    CollapseComponent,
    BrowsePageComponent,
    DisplayresultsComponent,
    SidenavComponent,
    MultiselectComponent,
    SingleselectComponent,
    MagazinedetailspageComponent,
    AudienceComponent,
    CirculationComponent,
    DatesComponent,
    RatesComponent,
    DemographicComponent,
    CreateofferComponent,
    SingleselectstaticComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})

export class BrowseModule {

}
