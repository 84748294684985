import * as MagazineSearchActions from '../actions/magazine-search.action'

export interface State {
  input: string,
}

export const InitialState: State = {
  input: ""
}

export function reducer(state = InitialState, action: MagazineSearchActions.Actions) {

  switch (action.type) {
    case MagazineSearchActions.SEARCH_INPUT:
      return { ...state, input: action.payload.input}
      case MagazineSearchActions.SEARCH_RESET:
       state = InitialState;
      return state;
    default:
      return state;
  }
}
