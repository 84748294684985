<form [formGroup]="addAddressForm" (ngSubmit)="addAddressOnSubmit()">
  <div class="modal-header">
    <div *ngIf="isEdit; else addContactTemplate">
      <h5 class="modal-title tittle3" id="exampleModalLabel">Edit Address</h5>
    </div>
    <ng-template #addContactTemplate>
      <h5 class="modal-title tittle3" id="exampleModalLabel">Add Address</h5>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form  mt-0">
          <mdb-select label="Address Type" mdbValidate formControlName="addressTypeId" [options]="addressTypeOptions">
          </mdb-select>
          <mdb-error *ngIf="addressTypeId.invalid && (addressTypeId.dirty || addressTypeId.touched)">
            Required
          </mdb-error>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form  mt-0">
          <input mdbInput mdbValidate type="text" formControlName="addressDescription" class="form-control"
            id="addressDescription">
          <label for="addressDescription">Address Description</label>
          <mdb-error *ngIf="addressDescription.invalid && (addressDescription.dirty || addressDescription.touched)">
            Required
          </mdb-error>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="addressLine1" class="form-control"
            id="addressLine1" />
          <label for="addressLine1">Address Line 1</label>
          <mdb-error *ngIf="addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)">
            Required
          </mdb-error>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="addressLine2" class="form-control"
            id="addressLine2" />
          <label for="addressLine2">Address Line 2</label>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="addressLine3" class="form-control"
            id="addressLine3" />
          <label for="addressLine3">Address Line 3</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="addressCity" class="form-control" id="addressCity" />
          <label for="addressCity">City</label>
          <mdb-error *ngIf="addressCity.invalid && (addressCity.dirty || addressCity.touched)">Required
          </mdb-error>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form  mt-0">
          <mdb-select label="State" mdbValidate formControlName="addressStateCode" [options]="stateTypeOptions">
          </mdb-select>
          <mdb-error *ngIf="addressStateCode.invalid && (addressStateCode.dirty || addressStateCode.touched)">Required
          </mdb-error>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="addressZip" class="form-control" id="addressZip" />
          <label for="addressZip">Zip Code</label>
          <mdb-error *ngIf="addressZip.invalid && (addressZip.dirty || addressZip.touched)">Required
          </mdb-error>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="addressCountryCode" class="form-control"
            id="addressCountryCode" />
          <label for="addressCountryCode">Country Code</label>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form ml-2 mt-0">
          <mdb-checkbox mdbValidate formControlName="isActive">
            Is Active
          </mdb-checkbox>
          <mdb-error *ngIf="isActive.invalid && (isActive.dirty || isActive.touched)">Required</mdb-error>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()"
      mdbWavesEffect>Close</button>
  </div>
</form>