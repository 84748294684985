import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import * as MagazineSearchActions from '../../../../core/search/actions/magazine-search.action';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../core/search/reducers/searchstate.reducer';

@Component({
  selector: 'app-singleselectstatic',
  templateUrl: './singleselectstatic.component.html',
  styleUrls: ['./singleselectstatic.component.scss']
})
export class SingleselectstaticComponent implements OnInit {

  dropdownSettings: any;
  @Input() selectedItem: string[];
  @Input() items: string[];
  @Input() type: string;
  @Input() maxvalue: number;
  @Output() select: EventEmitter<string> = new EventEmitter();
  @Output() deSelect: EventEmitter<string> = new EventEmitter();
  @Output() selectAllItems: EventEmitter<Object> = new EventEmitter();
  @Output() deSelectAllItems: EventEmitter<Object> = new EventEmitter();

  selectedvalue: string;
  constructor(private store: Store<fromRoot.SearchState>) { }

  onSelect(item) {
    this.select.emit(item);
  }

  onDeSelect(item) {
    this.deSelect.emit(item);
  }
  selectAllStaticItems(items){
    this.selectAllItems.emit(items);
   }
 
   selectAllDeStaticItems(items){
    this.deSelectAllItems.emit(this.items);
   }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

}
