import * as __model from '../model';

export class offerLine {
    offerLineId?: number;
    offerId?: number;
    offerLineNumber?: number;
    coverDate?: string;
    rate?: number;
    adRateId?:number;
    issueId?:number;
}
export interface AdRates {
    adRateId?: number;
    qty?: number;
    rate?: number;
    adTypeName?: String;
    adPlacement?: String;
    adSizeDescription?: String;
    adColorName?: String;
    adOrientation?: String;
    adUnit?: String;
    adUnitType?: String;
    adTypeAndColor?: String;
    pageFractionName?: String;
    description?: String;
    adSize?: String;
}
