<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="tab-pane fade show active">
            <div class="container-fluid">
                <div class="breadCrumps">
                    <mdb-breadcrumb customClass="blue-text lighten-4">
                        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance']">
                            <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId]">
                            <mdb-icon fas class="mr-1"></mdb-icon> {{sellerName}}
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="tittle3" class="active">
                            <mdb-icon fas class="mr-1"></mdb-icon> Magazines
                        </mdb-breadcrumb-item>
                    </mdb-breadcrumb>
                </div>

                <div *ngIf="showMagazineList" class="tab-pane fade in show active" id="MagazineTabPane" role="tabpanel">
                    <div class="my-2 mfl-o mf3-center mf-tittle">
                        <span class="ml-2">Seller Magazines</span>
                    </div>
                    <div class="jumbotron py-3">
                        <table class="table table-hover magazinetable">
                            <thead>
                                <tr>
                                    <th style="width:8%">Magazine Name</th>
                                    <th style="width:8%">Publication Fequency</th>
                                    <th style="width:8%">Fequency Type</th>
                                    <th style="width:8%">Active</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="cursor: pointer;" *ngFor="let magazine of magazines; let i = index">
                                    <td (click)="goToMagazine(magazine.magazineId)" style="width:8%">
                                        {{magazine.magazineName}}</td>
                                    <td (click)="goToMagazine(magazine.magazineId)" style="width:8%">
                                        {{magazine.publicationFrequency}}</td>
                                    <td (click)="goToMagazine(magazine.magazineId)" style="width:8%">
                                        {{magazine.frequencyTypeName}}</td>
                                    <td (click)="goToMagazine(magazine.magazineId)" style="width:8%"
                                        *ngIf="magazine.isActive">Y</td>
                                    <td (click)="goToMagazine(magazine.magazineId)" style="width:8%"
                                        *ngIf="!magazine.isActive">N</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row pb-3" *ngIf="showMagazineList">
                    <div class="col-sm-12 text-center">
                        <button type="button" mdbBtn size="md"
                            class="btn btn-secondary1 secondary relative waves-light mr-4" (click)="addMagazineClick()"
                            mdbWavesEffect>Add Magazine
                        </button>

                        <button type="button" mdbBtn size="md"
                            class="btn btn-secondary1 secondary relative waves-light mr-4" (click)="addTagsClick()"
                            mdbWavesEffect>Add Tags
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>