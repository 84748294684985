<form [formGroup]="addMediakitForm" (ngSubmit)="addMediakitSubmit()">
  <div class="modal-header">
    <div *ngIf="isEdit; else addMagazineTemplate">
      <h5 class="modal-title tittle3" id="exampleModalLabel">Edit MediaKit</h5>
    </div>
    <ng-template #addMagazineTemplate>
      <h5 class="modal-title tittle3" id="exampleModalLabel">Add MediaKit</h5>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="md-form mt-0 col-sm-12 col-md-6 col-lg-6">
      <div *ngIf="!isEdit">
        <app-year-picker [(ngModel)]="chosenYearDate" [ngModelOptions]="{standalone: true}" [disabled]="false"
          [jpCustomFormFieldClass]="jpCustomFormFieldClass" [label]="label" [max]="max" [min]="min" [touchUi]="touchUi">
        </app-year-picker>
      </div>
      <select *ngIf="isEdit" class="browser-default custom-select" disabled>
        <option selected>{{mediakit.mediaKitYear }}</option>
      </select>
    </div>

    <div class="md-form mt-0 col-sm-6 col-md-6">
      <mdb-checkbox mdbValidate formControlName="isActive">
        Is Active
      </mdb-checkbox>
      <mdb-error *ngIf="isActive.invalid && (isActive.dirty || isActive.touched)">Required</mdb-error>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()"
    mdbWavesEffect>Close</button>
  </div>
</form>