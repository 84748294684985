import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Circulation } from '../model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CirculationService {

  constructor(private http: HttpClient) { }

  read(mediaKitId: number): Observable<Circulation> {
    return this.http
      .get<Circulation>(`${environment.mfServerUrl}/api/Circulation/${mediaKitId}`);
  }

  create(circulation: Circulation): Observable<Circulation> {
    return this.http.post<Circulation>(`${environment.mfServerUrl}/api/Circulation`, circulation);
  }

  update(circulation: Circulation) {
    return this.http.put<Circulation>(`${environment.mfServerUrl}/api/Circulation`, circulation);
  }

  delete(circulationId: number) {
    return this.http.delete<any>(`${environment.mfServerUrl}/api/Circulation?circulationId=${circulationId}`);
  }

}
