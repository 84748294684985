import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject, Subscription } from 'rxjs';
import { RatesService } from '../../core/api/services/rates.service'
import { MdbSelectOptions, AdRates, AdPlacement, AdOrientation, AdUnit, AdPrintSource } from 'src/app/core/api/model';
import { AdType, AdColor } from '../../core/api/model'
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-add-rates',
  templateUrl: './add-rates.component.html'
})
export class AddRatesComponent implements OnInit {
  id: number;
  type: string;
  isEdit: boolean;
  adrate: AdRates;
  optionsSelect: Array<any>;
  action: Subject<any> = new Subject();
  adTypeOptions: Array<any>;
  adColorOptions: Array<any>;
  adPlacementOptions: Array<any>;
  AdOrientationOptions: Array<any>;
  AdUnitOptions: Array<any>;
  AdPrintSourceOptions: Array<any>;
  quantityOptions: Array<any>;
  data: any;
  userName: any;
  currentDate: Date;
  rateDecimal: any;
  qtyDecimal: any;

  isAuthenticated: boolean;

  subscription: Subscription;
  
  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private ratesService: RatesService,
    private alertService: AlertService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();
   
    this.loadRatesOnInit();
    this.getAdTypes();
    this.getAdColor();
    this.getAdPlacement();
    this.getAdOrientation();
    this.getAdUnit();
    this.getAdPrintSource();
    this.getQuantity();
  }

  getAdTypes() {
    let adTypes = [];
    this.ratesService.getAdType().subscribe((data: Array<AdType>) => {
      adTypes = data;
      this.adTypeOptions = data.map((adTypeOptions) => {

        let option = new MdbSelectOptions();
        option.value = adTypeOptions.adTypeId;
        option.label = adTypeOptions.adTypeName;
        return option;
      })
      if (!this.isEdit) {
        let AdType = adTypes.find((x: { adTypeId: number; }) => x.adTypeId == 2);
        if (AdType != null && AdType != typeof (undefined)) {
          this.adTypeId.patchValue(AdType.adTypeId);
        }
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  setTwoNumberDecimal(event) {
    let ele = "" + event.currentTarget.value;
    ele = ele.trim();
    if (event.currentTarget.name == 'qty') {
      ele = parseFloat(ele).toFixed(4);
      this.qtyDecimal = ele;
    } else if (event.currentTarget.name == 'rate') {
      ele = parseFloat(ele).toFixed(2);
      this.rateDecimal = ele;
    }
  }

  getAdColor() {
    let adColor = [];
    this.ratesService.getAdColor().subscribe((data: Array<AdColor>) => {
      adColor = data
      this.adColorOptions = data.map((adColorOptions) => {
        let option = new MdbSelectOptions();
        option.value = adColorOptions.adColorId;
        option.label = adColorOptions.adColorName;
        return option;
      })
      if (!this.isEdit) {
        let adColorSelected = adColor.find((x: { adColorId: number; }) => x.adColorId == 2);
        if (adColorSelected != null && adColorSelected != typeof (undefined)) {
          this.adColorId.patchValue(adColorSelected.adColorId);
        }
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  getAdPlacement() {
    let adPlacement = [];
    this.ratesService.getAdPlacement().subscribe((data: Array<AdPlacement>) => {
      adPlacement = data
      this.adPlacementOptions = data.map((adPlacementOptions) => {
        let option = new MdbSelectOptions();
        option.value = adPlacementOptions.adPlacementId;
        option.label = adPlacementOptions.adPlacementName;
        return option;
      })
      if (!this.isEdit) {
        let selectedAdPlacement = adPlacement.find((x: { adPlacementId: number; }) => x.adPlacementId == 1);
        if (selectedAdPlacement != null && selectedAdPlacement != typeof (undefined)) {
          this.adPlacementId.patchValue(selectedAdPlacement.adPlacementId);
        }
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  getAdOrientation() {
    let adOrientation = [];
    this.ratesService.getAdOrientation().subscribe((data: Array<AdOrientation>) => {
      adOrientation = data;
      this.AdOrientationOptions = data.map((AdOrientationOptions) => {
        let option = new MdbSelectOptions();
        option.value = AdOrientationOptions.adOrientationId;
        option.label = AdOrientationOptions.adOrientationName;
        return option;
      })
      if (!this.isEdit) {
        let selectedAdOrientation = adOrientation.find((x: { adOrientationId: number; }) => x.adOrientationId == 1);
        if (selectedAdOrientation != null && selectedAdOrientation != typeof (undefined)) {
          this.adOrientationId.patchValue(selectedAdOrientation.adOrientationId);
        }
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }
  getAdUnit() {
    let adUnit = [];
    this.ratesService.getAdUnit().subscribe((data: Array<AdUnit>) => {
      adUnit = data;
      this.AdUnitOptions = data.map((AdUnitOptions) => {
        let option = new MdbSelectOptions();
        option.value = AdUnitOptions.adUnitId;
        option.label = AdUnitOptions.adUnitName;
        return option;
      })
      if (!this.isEdit) {
        let selectedAdUnit = adUnit.find((x: { adUnitId: number; }) => x.adUnitId == 1);
        if (selectedAdUnit != null && selectedAdUnit != typeof (undefined)) {
          this.adUnitId.patchValue(selectedAdUnit.adUnitId);
        }
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  getAdPrintSource() {
    let adPrintSource = [];
    this.ratesService.getAdPrintSource().subscribe((data: Array<AdPrintSource>) => {
      adPrintSource = data;
      this.AdPrintSourceOptions = data.map((AdPrintSourceOptions) => {
        let option = new MdbSelectOptions();
        option.value = AdPrintSourceOptions.adPrintSourceId;
        option.label = AdPrintSourceOptions.adPrintSourceName;
        return option;
      })
      if (!this.isEdit) {
        let selectedAdPrintSource = adPrintSource.find((x: { adPrintSourceId: number; }) => x.adPrintSourceId == 1);
        if (selectedAdPrintSource != null && selectedAdPrintSource != typeof (undefined)) {
          this.adPrintSourceId.patchValue(selectedAdPrintSource.adPrintSourceId);
        }
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  getQuantity() {
    let adQuantity = [];
    this.ratesService.getQuantity().subscribe((data: Array<any>) => {
      adQuantity = data;
      this.quantityOptions = data.map(x => {
        let option = new MdbSelectOptions();
        option.value = x.value;
        option.label = x.displayText;
        return option;
      })
      if (!this.isEdit) {
        let selectedAdQuantity = adQuantity.find((x: { value: number; }) => x.value == 1);
        if (selectedAdQuantity != null && selectedAdQuantity != typeof (undefined)) {
          this.qty.patchValue(selectedAdQuantity.value);
        }
      }
    }, (err) => {
      this.alertService.error(err);
    })
  }

 

  addRatesForm = this.fb.group({
    mediaKitId: [''],
    qty: ['', Validators.required],
    rate: ['', Validators.required],
    isBleed: [false],
    adTypeId: ['', Validators.required],
    adPlacementId: ['', Validators.required],
    adColorId: ['', Validators.required],
    adOrientationId: ['', Validators.required],
    adUnitId: ['', Validators.required],
    adPrintSourceId: ['', Validators.required],
    adRateId: [''],
    adSizeDescription: ['', Validators.required],
    isActive: [true, Validators.required],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
    rowVersion: ['']
  });
  // Properties to use in html

  get mediaKitId() { return this.addRatesForm.get('mediaKitId'); }
  get qty() { return this.addRatesForm.get('qty'); }
  get isActive() { return this.addRatesForm.get('isActive'); }
  get rate() { return this.addRatesForm.get('rate'); }
  get isBleed() { return this.addRatesForm.get('isBleed'); }
  get adTypeId() { return this.addRatesForm.get('adTypeId'); }
  get adPlacementId() { return this.addRatesForm.get('adPlacementId'); }
  get adColorId() { return this.addRatesForm.get('adColorId'); }
  get adOrientationId() { return this.addRatesForm.get('adOrientationId'); }
  get adUnitId() { return this.addRatesForm.get('adUnitId'); }
  get adPrintSourceId() { return this.addRatesForm.get('adPrintSourceId'); }
  get rowVersion() { return this.addRatesForm.get('rowVersion'); }
  get adRateId() { return this.addRatesForm.get('adRateId'); }
  get adSizeDescription() { return this.addRatesForm.get('adSizeDescription'); }
  get createdOn() { return this.addRatesForm.get('createdOn'); }
  get createdBy() { return this.addRatesForm.get('createdBy'); }
  get modifiedOn() { return this.addRatesForm.get('modifiedOn'); }
  get modifiedBy() { return this.addRatesForm.get('modifiedBy'); }
  close(modalRef) {
    modalRef.hide();
  }

  validateForm() {
    Object.keys(this.addRatesForm.controls).forEach(field => {
      const control = this.addRatesForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }
  addMagazinesOnSubmit() {

    this.validateForm();

    if (this.addRatesForm.valid) {
      if (this.isEdit) {
        this.updateUserDetails();
        if (this.rateDecimal) {
          this.rate.patchValue(this.rateDecimal);
        }
        if (this.qtyDecimal) {
          this.qty.patchValue(this.qtyDecimal);
        }
        this.editRate();
      }
      else {
        this.addUserDetails();
        if (this.rateDecimal) {
          this.rate.patchValue(this.rateDecimal);
        }
        if (this.qtyDecimal) {
          this.qty.patchValue(this.qtyDecimal);
        }
        this.addRate();
      }
      this.rateDecimal = '';
      this.qtyDecimal = '';
    }

  }
  loadRatesOnInit() {
    setTimeout(() => {
      if (this.adrate != null || this.adrate != undefined) {
        this.addRatesForm.patchValue(this.adrate);
      }
    });
    this.mediaKitId.patchValue(this.id);
  }

  addRate() {
    this.ratesService.create(this.addRatesForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Rates added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };
        this.action.next(json);

      }, (errMsg) => {
        this.alertService.error(errMsg);
      });

  }

  editRate() {
    this.ratesService.update(this.addRatesForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Rates updated successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

}

