import { Injectable, ErrorHandler } from '@angular/core';
import { AppInsightsService } from '@markpieszak/ng-application-insights';

@Injectable({
    providedIn: 'root'
})
export class MyErrorHandler implements ErrorHandler {
    constructor(private appInsightsService: AppInsightsService) {}
    handleError(exception: Error) {
        // TO DO: Remove this while moving to production. We will have this code for now to log the errors.
        console.log(exception);
        this.appInsightsService.trackException(exception);
    }
}
