import { Injectable } from '@angular/core';
import { StateStore, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { BehaviorSubject, Subject } from 'rxjs/Rx';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _userManager: UserManager;
  private _user: any;

  private _loginChangedSubject = new BehaviorSubject<boolean>(false);
  public loginChanged = this._loginChangedSubject.asObservable();


  localStorageConfig = {
    userStore: new WebStorageStateStore({ store: localStorage }),
    stateStore: new WebStorageStateStore({ store: localStorage })
  };

  managerConfig = {
    ...this.idpSettings,
    ...this.localStorageConfig
  };

  private get idpSettings(): UserManagerSettings {
    return {
      authority: environment.identityProviderRootUrl,
      client_id: environment.clientId,
      redirect_uri: `${environment.clientRootUrl}/signin-callback`,
      scope: `${environment.scopes}`,
      response_type: "code",
      post_logout_redirect_uri: `${environment.clientRootUrl}/signout-callback`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${environment.clientRootUrl}/silent-refresh`,
      loadUserInfo: true,
    }
  }

  constructor() {
    this._userManager = new UserManager(this.managerConfig);

    let path = location.pathname;

    if (path.indexOf("signin-callback") == -1
      && path.indexOf('login') == -1
      && path.indexOf('silent-refresh') == -1
      && path.indexOf('signout-callback') == -1) {
      this.localStorageConfig.stateStore.set('returnUrl', location.pathname)
    }


    this._userManager.getUser().then(user => {
      this._user = user;
      this._loginChangedSubject.next(this.checkUser(user));
    })

    this._userManager.events.addSilentRenewError(() => {
      console.error("error while renewing the access token using silent renewal method");
      this.logout();
    });

    this._userManager.events.addUserSignedOut(() => {
      console.log('addUserSignedOut is called');
      this.logout();
    });

    // this._userManager.events.addAccessTokenExpiring(() => {

    //   console.log("token is about to expire");
    //   this._userManager.signinSilent({ scope: "openid profile MagazineApi", response_type: "code" }).then(user => {
    //     this._user = user
    //     this._authNavStatusSource.next(this.isAuthenticated());
    //     console.log("silent refresh is called");

    //   })
    //   .catch((error: Error)=>{

    //   })

    // })


    this._userManager.events.addUserLoaded(() => {
      console.log("add user loaded is called");

      this._userManager.getUser().then(user => {
        this._user = user;

        this._loginChangedSubject.next(this.checkUser(user));

        console.log("add user loaded is called - getUser" + user != null);
      })

    });

  }

  public login = () => {
    return this._userManager.signinRedirect();
  }

  public logout = () => {

    // Clear the stale state after log out.
    this._userManager.clearStaleState().then(() => {
      console.log('cleared');
    });

    return this._userManager.signoutRedirect();
  }

  isLoggedIn(): boolean {
    return this._user != null && !this._user.expired;
  }

  completeAuthentication(): Promise<void> {

    return this._userManager.signinRedirectCallback()
      .then(user => {
        this._user = user;

        this._loginChangedSubject.next(this.checkUser(user));
      });
  }

  public signOut = () => {

    // Clear the stale state after log out.
    this._userManager.clearStaleState().then(() => {
      console.log('cleared');
    });

    return this._userManager.signoutRedirectCallback().then(x => {
      this._user = null;
      this._loginChangedSubject.next(this.checkUser(this._user));
    });

  }

  silentRefresh(): Promise<void> {

    console.log("silentRefresh is called");

    return this._userManager.signinSilentCallback().then(user => {
      this._user = user;

      this._loginChangedSubject.next(this.checkUser(user));
    });
  }

  getAuthorizationHeaderValue(): string {
    return `${this._user.token_type} ${this._user.access_token}`;
  }

  getUserRoles(): any {
    return this._user.profile.role;
  }

  get name(): string {
    return this._user != null ? this._user.profile.unique_name : '';
  }

  public isAuthenticated(): Promise<boolean> {

    return this._userManager.getUser()
      .then(user => {

        if (this._user !== user) {
          this._loginChangedSubject.next(this.checkUser(user));
        }

        this._user = user;

        return this.checkUser(user);
      });
  }

  private checkUser = (user: any): boolean => {
    return !!user && !user.expired;
  }

}