import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Issue } from '../defs/Issue';

@Injectable({
  providedIn: 'root'
})
export class IssueService {

  constructor(private http: HttpClient) { }

  read(mediaKitId: number) {
    return this.http.get<Array<Issue>>(`${environment.mfServerUrl}/api/Issue/${mediaKitId}`);
  }

  update(issue: Issue){
    return this.http.put<Issue>(`${environment.mfServerUrl}/api/Issue`, issue);
  }

  create(issue: Issue) {
    return this.http.post<Issue>(`${environment.mfServerUrl}/api/Issue`, issue);
  }

  delete(issueId){
    return this.http.delete<any>(`${environment.mfServerUrl}/api/Issue?issueId=${issueId}`);
  }
}
