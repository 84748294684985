import { Component, OnInit } from '@angular/core';
import { BuildNumberService } from 'src/app/core/api/services/build-number.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { BuildDetails } from 'src/app/core/api/defs/BuildDetails';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  buildDetails: BuildDetails;

  constructor(private buildNumberService: BuildNumberService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.getBuildNumber();
  }

  getBuildNumber() {
    this.buildNumberService.getBuildDetails().subscribe((data: BuildDetails) => {
      this.buildDetails = data;
    }, (err) => {
      this.alertService.error(err);
    });
  }

}
