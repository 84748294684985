import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject, Subscription } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { TelephoneType, MdbSelectOptions, ContactType } from 'src/app/core/api/model';
import { Buyer } from '../../core/api/defs/Buyer';
import { AuthService } from 'src/app/auth/service/auth.service';



@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html'
})
export class EditDetailsComponent implements OnInit {

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private buyersearchService: BuyersearchService,
    private alertService: AlertService,
    private authService: AuthService) {

  }

  id: number;
  type: string;
  data: Buyer;
  isEdit: boolean;

  userName: any;
  currentDate: Date;
  action: Subject<any> = new Subject();

  paymentOptions: Array<any>;

  isAuthenticated: boolean;

  subscription: Subscription;


  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();

    this.getPaymentOptions();

    this.loadBuyerData();
  }


  editBuyerDetails = this.fb.group({
    buyerId: [''],
    buyerName: ['', Validators.required],
    buyerCompanyDescription: [''],
    buyerDBA: [''],
    buyerFEID: [''],
    buyerDnB: [''],
    paymentOptionId: [''],
    creditLimit: [''],
    isCreditApproved: [true],
    createdDate: [''],
    createdBy: [''],
    partyId: [''],
    rowVersion: [''],
    createdOn: [''],
    modifiedOn: [''],
    modifiedBy: ['']
  });

  // Properties to use in html
  get buyerId() { return this.editBuyerDetails.get('buyerId'); }
  get buyerName() { return this.editBuyerDetails.get('buyerName'); }
  get buyerCompanyDescription() { return this.editBuyerDetails.get('buyerCompanyDescription'); }
  get buyerDBA() { return this.editBuyerDetails.get('buyerDBA'); }
  get buyerFEID() { return this.editBuyerDetails.get('buyerFEID'); }
  get buyerDnB() { return this.editBuyerDetails.get('buyerDnB'); }
  get creditLimit() { return this.editBuyerDetails.get('creditLimit'); }
  get paymentOptionId() { return this.editBuyerDetails.get('paymentOptionId'); }
  get isCreditApproved() { return this.editBuyerDetails.get('isCreditApproved'); }
  get createdDate() { return this.editBuyerDetails.get('createdDate'); }
  get createdOn() { return this.editBuyerDetails.get('createdOn'); }
  get createdBy() { return this.editBuyerDetails.get('createdBy'); }
  get modifiedOn() { return this.editBuyerDetails.get('modifiedOn'); }
  get modifiedBy() { return this.editBuyerDetails.get('modifiedBy'); }
  get partyId() { return this.editBuyerDetails.get('partyId'); }
  get rowVersion() { return this.editBuyerDetails.get('rowVersion'); }

  validateForm() {
    Object.keys(this.editBuyerDetails.controls).forEach(field => {
      const control = this.editBuyerDetails.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }
  buyerDetailEdit() {
    this.validateForm();
    if (this.editBuyerDetails.valid) {
      if (this.isEdit) {
        this.updateUserDetails();
        this.editBuyer();
      }
      else {
        this.addUserDetails();
        this.addBuyer();
      }
    }
  }

  getPaymentOptions() {
    this.buyersearchService.getBuyerPaymentOptions().subscribe((data: any) => {
      if (data != null) {
        this.paymentOptions = data.map((po) => {

          let option = new MdbSelectOptions();
          option.label = po.paymentOptionDescription;
          option.value = po.paymentOptionId;

          return option;
        })
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  loadBuyerData() {
    setTimeout(() => {
      if (this.data != null && this.data != undefined) {
        this.editBuyerDetails.patchValue(this.data);
      }
    });
  }

  editBuyer() {
    this.buyersearchService.editBuyer(this.editBuyerDetails.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Buyer updated successfully');

        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };
        this.action.next(json);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  addBuyer() {
    this.buyersearchService.addBuyer(this.editBuyerDetails.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Buyer added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: data.buyerId,
          type: this.type
        };
        this.action.next(json);

      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

}
