import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { DemographicComponent } from './demographics/demographic.component';
import { ViewRate, AdMediaKit, shoppingCart, AdRate, MagazineDetails, Assets } from 'src/app/core/api/model';
import { ViewrateService } from '../../../core/api/services/viewrate.service';
import { CreateofferComponent } from '../createoffer/createoffer.component';


@Component({
  selector: 'app-magazinedetailspage',
  templateUrl: './magazinedetailspage.component.html',
  styleUrls: ['./magazinedetailspage.component.scss']
})
export class MagazinedetailspageComponent implements OnInit {
  @ViewChild(DemographicComponent, /* TODO: add static flag */ {static:true}) child_demographics;
  @ViewChild(CreateofferComponent, /* TODO: add static flag */ {static:true}) child_createoffer;
  hasAdRates: boolean = false;
  selectedMagazineId: string;
  Magazineid: number;
  MagazineId: string;
  results: any;
  selectedAd: AdRate;
  magazineDetails: MagazineDetails;

  constructor(private route: ActivatedRoute, private router: Router, private _ViewrateService: ViewrateService) {
  }

  onMedialinkClick(mediaKitUrl) {
    let url = mediaKitUrl;
    let w = window.open(url, "MediaKitPopup", "height=600,width=800");
    w.focus();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
}
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.selectedMagazineId = params.id;
      this.MagazineId = params.id;

      this._ViewrateService.getMagazineAssets(params.id);
      this._ViewrateService.assetsList.subscribe((response:Assets ) => {
        this.results = response;
      });
      this._ViewrateService.getMagazineDetail(params.id).subscribe((data: MagazineDetails) => {
        this.magazineDetails = data;
      });
      this._ViewrateService.getAdRatesofMagazine(params.id).subscribe((data: any) => {
        this.hasAdRates = data;
      });
    });

  }
}