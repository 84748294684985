import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRole } from 'src/app/enums/UserRole';

import { MaintenanceComponent } from './pages/maintenance.component';
import { MediakitComponent } from './pages/mediakit/mediakit.component';
import { MediakituploadsComponent } from './pages/mediakituploads/mediakituploads.component'
import { DemographicComponent } from './pages/demographic/demographic.component';
import { CirculationComponent } from './pages/circulation/circulation.component';
import { AudienceComponent } from './pages/audience/audience.component';
import { DemocricularratesComponent } from './pages/RateOptions/democricularrates.component';
import { RatesComponent } from './pages/rates/rates.component';
import { MagazinesComponent } from './pages/magazines/magazines.component';
import { SellerInfoComponent } from './pages/sellerInfo/sellerInfo.component';
import { MagazineInfoComponent } from './pages/magazineInfo/magazineInfo.component';
import { MediakitInfoComponent } from './pages/mediakitInfo/mediakitInfo.component';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { IssueComponent } from './pages/issue/issue.component';
import { AuthGuardService } from '../auth/service/auth-guard.service';
import { AssignSellerToMagazineComponent } from './pages/assign-seller-to-magazine/assign-seller-to-magazine.component';


const appRoutes: Routes = [
  {
    path: 'maintenance',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: MaintenanceComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFSystemAdmin
          ]
        }
      }
    ]
  },
  {
    path: 'maintenance/seller/:sellerId',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: SellerInfoComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFSystemAdmin
          ]
        }
      }
    ]
  },
  {
    path: 'maintenance/seller/:sellerId/magazine/:magazineId',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: MagazineInfoComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFSystemAdmin
          ]
        }
      }
    ]
  },
  {
    path: 'maintenance/seller/:sellerId/magazines',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: MagazinesComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFSystemAdmin
          ]
        }
      }
    ]
  },
  {
    path: 'maintenance/seller/:sellerId/magazine/:magazineId/mediakits',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: MediakitComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFSystemAdmin
          ]
        }
      }
    ]
  },
  {
    path: 'maintenance/seller/:sellerId/magazine/:magazineId/mediakit/:mediaKitId',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: MediakitInfoComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFSystemAdmin
          ]
        }
      }
    ]
  },
  {
    path: 'maintenance/seller/:sellerId/magazine/:magazineId/mediakit/:mediaKitId/mediakit-upload',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: MediakituploadsComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFSystemAdmin
          ]
        }
      }
    ]
  },
  {
    path: 'maintenance/seller/:sellerId/magazine/:magazineId/mediakit/:mediaKitId/demo-circular-rates',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: DemocricularratesComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFSystemAdmin
          ]
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'demographics'
          },
          {
            path: 'demographics',
            component: DemographicComponent
          },
          {
            path: 'circulation',
            component: CirculationComponent,
          },
          {
            path: 'audience',
            component: AudienceComponent,
          },
          {
            path: 'rates',
            component: RatesComponent,
          },
          {
            path: 'issue',
            component: IssueComponent
          }
        ]
      }
    ]
  },
  {
    path: 'maintenance/no-seller-magazines',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: AssignSellerToMagazineComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFSystemAdmin
          ]
        }
      }
    ]
  }



];


@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [RouterModule]
})
export class MaintenancRoutingModule { }