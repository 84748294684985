import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';

@Component({
  selector: 'app-edit-buyerpaymentaccount',
  templateUrl: './edit-buyerpaymentaccount.component.html'
})
export class EditBuyerpaymentaccountComponent implements OnInit {

  userName: any;
  currentDate: Date;
  data: any;
  buyerid: number;
  action: Subject<any> = new Subject();
  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private buyersearchService: BuyersearchService,
    private alertService: AlertService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();

    this.loadAddressDataOnInit();
  }

  buyerPaymentAccountForm = this.fb.group({
    buyerId: [0],
    isPaymentAccountSetup: [false, Validators.requiredTrue],
    isActive: [true],
    createdBy: [''],
    createdOn: [''],
    modifiedBy: [''],
    modifiedOn: ['']
  });

  // Properties to use in html
  get buyerId() { return this.buyerPaymentAccountForm.get('buyerId'); }
  get isPaymentAccountSetup() { return this.buyerPaymentAccountForm.get('isPaymentAccountSetup'); }
  get isActive() { return this.buyerPaymentAccountForm.get('isActive'); }
  get createdBy() { return this.buyerPaymentAccountForm.get('createdBy'); }
  get createdOn() { return this.buyerPaymentAccountForm.get('createdOn'); }
  get modifiedBy() { return this.buyerPaymentAccountForm.get('modifiedBy'); }
  get modifiedOn() { return this.buyerPaymentAccountForm.get('modifiedOn'); }


  close(modalRef) {
    modalRef.hide();
  }


  validateForm() {
    Object.keys(this.buyerPaymentAccountForm.controls).forEach(field => {
      const control = this.buyerPaymentAccountForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  loadAddressDataOnInit() {
    setTimeout(() => {
      if (this.data != null || this.data != undefined) {
        this.buyerPaymentAccountForm.patchValue(this.data);
      }
    });
  }

  buyerPaymentAccountOnSubmit() {
    this.editBuyerAccount();
  }

  editBuyerAccount() {

    this.validateForm();

    if (this.buyerPaymentAccountForm.valid) {
      this.updateUserDetails();
      this.buyersearchService.buyerPaymentAccountSetup(this.buyerPaymentAccountForm.getRawValue(), this.buyerid).subscribe(
        (data: any) => {
          this.modalRef.hide();
          this.alertService.success('Buyer Account updated successfully');
          // Set the json in Subscribe so that once the modal is closed 
          // API will be invoked to get the new results
          let json = {
            id: this.buyerid,
            type: "buyer"
          };

          this.action.next(json);
        },
        (errMsg) => {
          this.alertService.error(errMsg);
        });
    }
  }
}
