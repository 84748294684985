<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="tab-pane fade show active">
            <div class="container-fluid">
                <div class="breadCrumps">
                    <mdb-breadcrumb customClass="blue-text lighten-4">
                        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance']">
                            <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId]">
                            <mdb-icon fas class="mr-1"></mdb-icon> {{sellerName}}
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="tittle3"
                            [routerLink]="['/maintenance/seller', sellerId,'magazines']">
                            <mdb-icon fas class="mr-1"></mdb-icon> Magazines
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="tittle3" class="active">
                            <mdb-icon fas class="mr-1"></mdb-icon>{{magazineName}}
                        </mdb-breadcrumb-item>
                    </mdb-breadcrumb>
                </div>
                <div class="tab-pane fade in show active" id="magazineInfo" role="tabpanel">
                    <div class="my-2 mfl-o mf3-center mf-tittle">
                        <span class="ml-2 ">Magazine Information</span>
                    </div>
                    <div class="jumbotron py-3">
                        <div *ngIf="canShowMagazineDataCard else noDataBlock">
                            <div class="row">
                                <div class="col-sm-10 my-2">
                                    <h6>
                                        <span class="tittle1">Magazine Name: </span>{{magazineData?.magazineName}}
                                    </h6>
                                    <h6>
                                        <span class="tittle1">Seller Name: </span>{{magazineData?.sellerName}} <a
                                            class="tittle1 pl-4" (click)="changeSeller()"><b>Change Seller</b></a>
                                    </h6>
                                    <h6>
                                        <span class="tittle1">Publisher Statement:
                                        </span>{{magazineData?.publisherStatement}}
                                    </h6>
                                    <h6>
                                        <span class="tittle1">Frequency : </span>{{magazineData?.frequencyTypeName}}
                                    </h6>
                                    <h6>
                                        <span class="tittle1">Market Categories : </span>{{marketCategories}}
                                    </h6>
                                    <h6>
                                        <span class="tittle1">Media Classification: </span>{{mediaClassification}}
                                    </h6>
                                    <h6>
                                        <span class="tittle1">
                                            Magazine Website Url:
                                        </span>{{magazineData?.magazineWebsiteUrl}}
                                    </h6>
                                    <h6>
                                        <span class="tittle1">US National : </span>{{magazineData?.isUsNational}}
                                    </h6>
                                    <h6>
                                        <span class="tittle1"> Seasonal : </span>{{magazineData?.isSeasonal}}
                                    </h6>
                                    <h6>
                                        <span class="tittle1"> Active : </span>{{magazineData?.isActive}}
                                    </h6>
                                    <h6>
                                        <span class="tittle1"> Tags : </span>{{tags}}
                                    </h6>

                                </div>
                                <div class="col-sm-2 my-2">
                                    <div class="">
                                        <a (click)="editMagazineClick(magazineData)" title="Edit" class="px-1 tittle1">
                                            <mdb-icon fas icon="edit"></mdb-icon>
                                        </a>
                                        <a class="px-1 tittle1" title="Mediakits"
                                            [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineData?.magazineId, 'mediakits' ]">
                                            <mdb-icon fas icon="file"></mdb-icon>
                                        </a>

                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #noDataBlock>
                            <div>
                                No Magazine Data found
                            </div>

                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>