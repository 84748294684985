<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="font-weight-bolder py-3">
            <div class="font-bold py-3 tittle3">
                <i class="fas fa-arrow-left pr-2 tc-2" aria-hidden="true"></i>
                <a class="tc-2" [routerLink]="['/offers']">Back to offers</a>
            </div>
        </div>
    </div>
    <div class="col-sm-10 mx-auto">
        <div class="tab-pane fade show active">
            <div class="container-fluid">
                <div class="tab-pane fade in show active" id="MagazineTabPane" role="tabpanel">
                    <div class="my-2 mfl-o mf3-center mf-tittle">
                        <span class="ml-2">Terms And Conditions</span>
                    </div>
                    <div class="jumbotron py-3">
                        <div style="color:black" [innerHTML]="'<span>'+viewContent+'</span>'"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>