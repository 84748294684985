<form [formGroup]="addTagDetails" (ngSubmit)="tagSubmit()">
    <div class="modal-header">
        <h5 class="modal-title tittle3" id="exampleModalLabel">Add Tag</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <div class="md-form mt-0">
                    <input mdbInput mdbValidate type="text" id="tagName" formControlName="tagName"
                        class="form-control" />
                    <label for="tagName">Name</label>
                    <mdb-error *ngIf="tagName.invalid && (tagName.dirty || tagName.touched)">
                        Required
                    </mdb-error>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
        <button type="button" class="btn btn-secondary btn-outline-primary tc-1 blueborder" data-dismiss="modal"
            (click)="modalRef.hide()" mdbWavesEffect>Close</button>
    </div>
</form>