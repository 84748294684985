import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first ,  map } from 'rxjs/operators';
import { AlertService } from '../../core/api/services/alertservice/alert.service'
import { RegisterService } from '../../core/api/services/register.service'
import { User } from '../../core/api/defs/User'
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  agreementText: any;
  viewContent: any;
  isShow: boolean;
  registerForm: FormGroup;

  createForm(): void {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      confirmpassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      tempAuthToken: ['', Validators.required],
      agreementAccepted: [false, Validators.requiredTrue]
    });
    this.isShow = false;
  }

  get firstName() { return this.registerForm.get('firstName'); }
  get lastName() { return this.registerForm.get('lastName'); }
  get email() { return this.registerForm.get('email'); }
  get password() { return this.registerForm.get('password'); }
  get confirmpassword() { return this.registerForm.get('confirmpassword'); }
  get agreementAccepted() { return this.registerForm.get('agreementAccepted'); }
  get tempAuthToken() { return this.registerForm.get('tempAuthToken'); }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private registerService: RegisterService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.createForm();
    this.callAgreement();
  }

  callAgreement() {
    this.registerService.Agreements("Seller").subscribe((response: string) => {
      this.agreementText = response;
      if (this.agreementText) {
        this.registerService.calltoAgreement(this.agreementText).subscribe((response: any) => {
          this.viewContent = response;
        })
      }
    });
  }

  validateForm() {
    Object.keys(this.registerForm.controls).forEach(field => {
      const control = this.registerForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  getPasswordValidationErrorMessage(): string {
    let err = this.registerForm.controls.password.errors;

    if (err != null) {
      if (err.required) {
        return "Required";
      }

      if (err.pattern != null) {
        return "At least 8 characters, including one uppercase, one lowercase, one number and must have at least one special character";
      }
    }

    return null;
  }

  getConfirmPasswordValidationErrorMessage(): string {
    let err = this.registerForm.controls.confirmpassword.errors;

    if (err != null) {
      if (err.required) {
        return "Required";
      }

      if (err.validateEqual == false) {
        return "Confirm Password doesn't match with Password";
      }
    }

    return null;
  }

  onSubmit() {

    this.validateForm();

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    let user: User = this.registerForm.value;
    user.accountType = "Seller";

    this.registerService.registerUser(user).subscribe((data: any) => {
      this.alertService.success(data.message);
      this.router.navigate(['/home']);
    }, (err) => {
      this.alertService.error(err);
    });
  }
}