import { Component, OnInit } from '@angular/core';
import { OfferService } from 'src/app/core/api/services/offer.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { MdbSelectOptions } from 'src/app/core/api/model';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-decline-offer',
  templateUrl: './decline-offer.component.html'
})
export class DeclineOfferComponent implements OnInit {

  offerId: number;
  action: Subject<any> = new Subject();
  reasonOptions: Array<any>;

  constructor(private offerService: OfferService,
    public modalRef: MDBModalRef,
    private alertService: AlertService,
    private fb: FormBuilder) { }

  declineOfferForm = this.fb.group({
    offerId: [''],
    reasonId: ['', Validators.required],
    instructions: [''],
  });

  get reasonId() { return this.declineOfferForm.get('reasonId'); }

  get instructions() { return this.declineOfferForm.get('instructions'); }

  ngOnInit() {
    this.declineOfferForm.get('offerId').patchValue(this.offerId);

    this.getReasons();
  }

  getReasons() {
    this.offerService.getReason().subscribe((response: any) => {
      this.reasonOptions = response.map((reasonType) => {
        let option = new MdbSelectOptions();
        option.label = reasonType.reasonName;
        option.value = reasonType.reasonId;

        return option;
      });
    }, (err) => {
      this.alertService.error(err);
    });
  }

  declineOfferFormSubmit() {

    this.validateForm();

    if (this.declineOfferForm.valid) {
      this.offerService.decline(this.declineOfferForm.getRawValue()).subscribe((data) => {
        this.alertService.success('Offer Declined successfully');
        this.offerService.emitOfferConfig("offer");
        this.modalRef.hide();
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
    }
  }

  validateForm() {
    Object.keys(this.declineOfferForm.controls).forEach(field => {
      const control = this.declineOfferForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}