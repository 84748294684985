import * as __model from '../model';

export class AcceptDeclineOffer {
  offerId?: number;
  /**
   * The date this resource was created.
   * format: date
   */
  comment?: string;
  /**
   * The date this resource was modified.
   * format: date
   */
  rowversion?: string;
}

export class DeclineOffer {
  offerId?: number;
  comment?: string;
  rowversion?: string;
  reasonId?: number;
}

export class AcceptOffer {
  offerId?: number;
  rowversion?: string;
}

