import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Buyer } from 'src/app/core/api/defs/Buyer';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';
import { SellersearchService } from 'src/app/core/api/services/sellersearch.service';
import { EditBuyerpaymentaccountComponent } from 'src/app/shared/edit-buyerpaymentaccount/edit-buyerpaymentaccount.component';

@Component({
  selector: 'app-buyerdetail',
  templateUrl: './buyerdetail.component.html',
  styleUrls: ['./buyerdetail.component.css']
})
export class BuyerdetailComponent implements OnInit {

  modalRef: MDBModalRef;
  canShowBuyerDataCard: boolean = false;
  buyerId: number;
  buyerData: Buyer;

  constructor(private router: ActivatedRoute,
    private buyersearchService: BuyersearchService,
    private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2) {

    router.params.subscribe((params) => {
      this.buyerId = params.buyerId;
    })
  }

  ngOnInit(): void {
    this.getBuyerData(this.buyerId);
  }

  getBuyerData(buyerId: number) {
    this.buyersearchService.getBuyerDetails(buyerId).subscribe((data: Buyer) => {
      if (data != null) {
        this.canShowBuyerDataCard = true;
        this.buyerId = data.buyerId
        this.buyerData = data;
      }
      else {
        this.canShowBuyerDataCard = false;
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  editBuyerClick(buyer) {
    this.modalRef = this.modalService.show(EditBuyerpaymentaccountComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        data: buyer,
        buyerid: buyer.buyerId
      }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      if (result != null) {
        if (result.type == "buyer") {
          // Invoke API to get the data
          this.getBuyerData(result.id);
        }
      }
    });
  }


}
