<div class="row">
  <div class="col-sm-12 mx-auto">
    <div class="tab-pane fade show active">
      <div class="container-fluid">
        <div class="tab-pane fade in show active" id="MagazineTabPane" role="tabpanel">
          <div class="my-2 mt-5 mfl-o mf3-center mf-tittle mf-tittle">
            <span class="ml-2">Demograhics List</span>
          </div>
          <div class="jumbotron py-3" *ngIf="showDemographic">
            <table class="table table-hover magazinetable table-responsive">
              <thead>
                <tr>
                  <th style="width:8%">Media Kit Id</th>
                  <th style="width:8%">Statistics</th>
                  <th style="width:8%">Value</th>
                  <th style="width:8%">Is Active</th>
                  <th style="width:8%">Edit</th>
                  <th style="width:8%">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let demo of demographic; let i = index">
                  <td style="width:8%">{{demo.mediaKitId}}</td>
                  <td style="width:8%">{{demo.demoStatisticName}}</td>
                  <td style="width:8%">{{demo.value}} </td>
                  <th style="width:8%">{{demo.isActive}}</th>
                  <td style="width:8%;" class="tittle1"><a (click)="editDemoClick(demo)"><mdb-icon fas icon="edit"></mdb-icon></a></td>
                  <td style="width:8%;" class="tittle1"><a><i class="far fa-trash-alt" (click)="deleteDemo(demo.demoId)"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row pb-3">
            <div class="col-sm-12 text-center">
              <button type="button" mdbBtn size="md" class="btn btn-secondary1 secondary relative waves-light"
                (click)="addDemoClick()" mdbWavesEffect>Add Demographics
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>