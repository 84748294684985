
import * as __model from '../model';

export class MagazineDetails{
    sellerName :string;
    magazineId : number;
    magazineName: string;
    category : string;
    mediaKitUrl : string;
    circulation : number;
    coverPageUrl:string;
    coverThumbnailUrl:string;
    coverIconUrl:string;
    publicationFrequency: number;
    publisherStatement: string;
}