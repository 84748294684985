import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowsePageComponent } from './pages/browse-page.component';
import { MagazinedetailspageComponent } from './pages/magazinedetails/magazinedetailspage.component';
import { DemographicComponent } from './pages/magazinedetails/demographics/demographic.component';
import { CirculationComponent } from './pages/magazinedetails/circulation/circulation.component';
import { AudienceComponent } from './pages/magazinedetails/audience/audience.component';
import { DatesComponent } from './pages/magazinedetails/dates/dates.component';
import { RatesComponent } from './pages/magazinedetails/rates/rates.component';
import { FullPageMainLayoutComponent } from '../layout/fullPageMainLayout/full-page-main-layout/full-page-main-layout.component';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { UserRole } from '../enums/UserRole';
import { AuthGuardService } from '../auth/service/auth-guard.service';

const routes: Routes = [
  {
    path: 'browse',
    component: FullPageMainLayoutComponent,
    children: [
      {
        path: '',
        component: BrowsePageComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.Buyer
          ]
        }
      },
    ]
  },
  {
    path: 'browse/magazine-details/:id',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: MagazinedetailspageComponent,
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'demographics',
            component: DemographicComponent,
            canActivate: [AuthGuardService],
            data: {
              roles: [
                UserRole.Buyer
              ]
            }
          },
          {
            path: 'audience',
            component: AudienceComponent,
            canActivate: [AuthGuardService],
            data: {
              roles: [
                UserRole.Buyer
              ]
            }
          },
          {
            path: 'circulation',
            component: CirculationComponent,
            canActivate: [AuthGuardService],
            data: {
              roles: [
                UserRole.Buyer
              ]
            }
          },
          {
            path: 'dates',
            component: DatesComponent,
            canActivate: [AuthGuardService],
            data: {
              roles: [
                UserRole.Buyer
              ]
            }
          },
          {
            path: 'rates',
            component: RatesComponent,
            canActivate: [AuthGuardService],
            data: {
              roles: [
                UserRole.Buyer
              ]
            }
          },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class BrowseRoutingModule { }
