import { Component, Input, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { MediakitService } from 'src/app/core/api/services/mediakit.service';
import { Magazines } from '../../core/api/defs/Magazines'
import { Subject, Subscription } from 'rxjs';
import { AdMediaKit } from 'src/app/core/api/model';
import { formatDate } from '@angular/common';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { MatDatepicker } from '@angular/material/datepicker';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-add-mediakit',
  templateUrl: './add-mediakit.component.html'
})
export class AddMediakitComponent implements OnInit {
  @Input() jpCustomFormFieldClass = '';

  @Input() label = 'Choose Year';

  @Input() max: any;

  @Input() min: any;

  chosenYearDate: Date;

  @Input() touchUi = false;
  
  @Input()
  id: number;
  type: string;
  mediakit: AdMediaKit;
  isEdit: boolean;
  sellerId: number;
  userName: any;
  currentDate: Date;
  optionsSelect: Array<any>;
  action: Subject<any> = new Subject();
  
  isAuthenticated: boolean;

  subscription: Subscription;


  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private mediakitService: MediakitService,
    private alertService: AlertService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();
   
    this.loadAddressDataOnInit();
    let year = new Date().getFullYear();
    this.optionsSelect = [year, year + 1]
  }

  _takeFocusAway($datepicker: MatDatepicker<any>) {
    $datepicker.disabled = true;
    setTimeout(() => {
      $datepicker._datepickerInput['_elementRef'].nativeElement.blur();
      $datepicker.disabled = false;
    }, 600);
  }
  addMediakitForm = this.fb.group({
    mediaKitId: [0],
    magazineId: [0],
    mediaKitYear: ['', Validators.required],
    effectiveStartDate: ['', Validators.required],
    effectiveEndDate: [''],
    //mediaKitUrl: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
    createdBy: [''],
    createdOn: [''],
    modifiedBy: [''],
    modifiedOn: [''],
    rowVersion: [''],
    isActive: [false]
  });

  // Properties to use in html
  get mediaKitId() { return this.addMediakitForm.get('mediaKitId'); }
  get magazineId() { return this.addMediakitForm.get('magazineId'); }
  get mediaKitYear() { return this.addMediakitForm.get('mediaKitYear'); }
  get effectiveStartDate() { return this.addMediakitForm.get('effectiveStartDate'); }
  get effectiveEndDate() { return this.addMediakitForm.get('effectiveEndDate'); }
  get mediaKitUrl() { return this.addMediakitForm.get('mediaKitUrl'); }
  get rowVersion() { return this.addMediakitForm.get('rowVersion'); }
  get isActive() { return this.addMediakitForm.get('isActive'); }
  get createdOn() { return this.addMediakitForm.get('createdOn'); }
  get createdBy() { return this.addMediakitForm.get('createdBy'); }
  get modifiedOn() { return this.addMediakitForm.get('modifiedOn'); }
  get modifiedBy() { return this.addMediakitForm.get('modifiedBy'); }

  close(modalRef) {
    modalRef.hide();
  }

  validateForm() {
    Object.keys(this.addMediakitForm.controls).forEach(field => {
      const control = this.addMediakitForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  changeYear(seectedvalue) {
    let startdate = new Date(seectedvalue + "-" + 1 + "-" + 1).toLocaleDateString();
    let enddate = new Date(seectedvalue + "-" + 12 + "-" + 31).toLocaleDateString();
    this.effectiveStartDate.patchValue(startdate);
    this.effectiveEndDate.patchValue(enddate);
  }

  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  getYear() {
    if (!this.isEdit) {
      let year = new Date(this.chosenYearDate).getFullYear();
      this.changeYear(year);
      this.mediaKitYear.patchValue(year);
    }
  }

  addMediakitSubmit() {
    this.getYear();
    this.validateForm();

    if (this.addMediakitForm.valid) {
      if (this.isEdit) {
        this.updateUserDetails();
        this.editMediaKit();
      }
      else {
        this.addUserDetails();
        this.addMediaKit();
      }
    }
  }

  loadAddressDataOnInit() {
    setTimeout(() => {
      if (this.mediakit != null || this.mediakit != undefined) {
        this.addMediakitForm.patchValue(this.mediakit);
      } else if (this.id != null || this.id != undefined) {
        this.magazineId.patchValue(this.id);
      }
    });
  }

  addMediaKit() {
    this.mediakitService.create(this.addMediakitForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Mediakit added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: "add"
        };
        this.action.next(json);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  editMediaKit() {
    this.mediakitService.update(this.addMediakitForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Mediakit updated successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: "edit"
        };

        this.action.next(json);
      },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }
}