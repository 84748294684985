import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { SellersearchService } from 'src/app/core/api/services/sellersearch.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject, Subscription } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { MdbSelectOptions } from 'src/app/core/api/model';
import { Seller } from 'src/app/core/api/defs/Seller';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-add-seller',
  templateUrl: './add-seller.component.html'
})
export class AddSellerComponent implements OnInit {
  
  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private sellersearchService: SellersearchService,
    private alertService: AlertService,
     private authService: AuthService) {

  }

  id: number;
  type: string;
  data: Seller;
  isEdit: boolean;
  userName: any;
  currentDate: Date;
  action: Subject<any> = new Subject();

  paymentOptions: Array<any>;
  
  isAuthenticated: boolean;

  subscription: Subscription;


  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();

    this.loadsellerData();
  }


  addSellerDetails = this.fb.group({
    sellerId: [''],
    sellerName: ['', Validators.required],
    sellerCompanyDescription: [''],
    sellerDba: [''],
    sellerFeid: [''],
    sellerDnB: [''],
    commissionPercent: [''],
    creditLimit: [''],
    isCreditApproved: [true],
    hasRegisteredPrimaryContact: [''],
    isActive: [true],
    partyId: [''],
    rowVersion: [''],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: ['']
  });

  // Properties to use in html
  get sellerId() { return this.addSellerDetails.get('sellerId'); }
  get sellerName() { return this.addSellerDetails.get('sellerName'); }
  get sellerCompanyDescription() { return this.addSellerDetails.get('sellerCompanyDescription'); }
  get sellerDba() { return this.addSellerDetails.get('sellerDba'); }
  get sellerFeid() { return this.addSellerDetails.get('sellerFeid'); }
  get sellerDnB() { return this.addSellerDetails.get('sellerDnB'); }
  get commissionPercent() { return this.addSellerDetails.get('commissionPercent'); }
  get hasRegisteredPrimaryContact() { return this.addSellerDetails.get('hasRegisteredPrimaryContact'); }
  get partyId() { return this.addSellerDetails.get('partyId'); }
  get rowVersion() { return this.addSellerDetails.get('rowVersion'); }
  get isActive() { return this.addSellerDetails.get('isActive'); }
  get createdOn() { return this.addSellerDetails.get('createdOn'); }
  get createdBy() { return this.addSellerDetails.get('createdBy'); }
  get modifiedOn() { return this.addSellerDetails.get('modifiedOn'); }
  get modifiedBy() { return this.addSellerDetails.get('modifiedBy'); }

  validateForm() {
    Object.keys(this.addSellerDetails.controls).forEach(field => {
      const control = this.addSellerDetails.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  sellerDetailEdit() {
    this.validateForm();
    if (this.addSellerDetails.valid) {
      if (this.isEdit) {
        this.updateUserDetails();
        this.editSeller();
      }
      else {
        this.addUserDetails();
        this.addSeller();
      }
    }
  }

  loadsellerData() {
    setTimeout(() => {
      if (this.data != null && this.data != undefined) {
        this.addSellerDetails.patchValue(this.data);
      }
    });
  }

  editSeller() {
    this.sellersearchService.editSeller(this.addSellerDetails.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Seller updated successfully');

        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };
        this.action.next(json);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  addSeller() {
    this.sellersearchService.addSeller(this.addSellerDetails.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Seller added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: data.sellerId,
          type: this.type
        };
        this.action.next(json);

      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }
}