import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DownloadRoutingModule } from './download-routing.module';
import { DownloadbatchComponent } from './pages/downloadbatch/downloadbatch.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DownloadofferComponent } from './pages/downloadoffer/downloadoffer.component';


@NgModule({
  declarations: [DownloadbatchComponent, DownloadofferComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    CoreModule,
    SharedModule,
    DownloadRoutingModule
  ]
})
export class DownloadModule { }
