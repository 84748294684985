<div id="top-section1">
  <app-headercomponent></app-headercomponent>

  <div class="row justify-content-center no-gutters" >
    <div class="col-sm-12">
      <div class="mf3-content container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
    
    <div class="col-sm-12">
      <app-copyright></app-copyright>
    </div>
  </div>
</div>