import * as __model from '../model';


export class AdMediaKit {
    effectiveStartDate: any;
    effectiveEndDate: string;
    isActive: boolean;
    isMigrated?: boolean;
    magazineId: number;
    magazineName: string;
    mediaKitId: number;
    mediaKitUrl: string;
    rowVersion: string;
    mediaKitYear: number;
    createdBy: string;
    createdOn: Date;
    modifiedBy: string;
    modifiedOn: Date;
}
