import { Injectable, Output, Directive } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { shoppingCart } from '../defs/ShoppingCart';
import { wishlistLine } from '../defs/wishListLine'
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter } from 'selenium-webdriver';
import { AlertService } from './alertservice/alert.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class CartService {
  constructor(private http: HttpClient,
    private alertService: AlertService) { }

  totalItems: any;

  shoppingTotalItems: Subject<any> = new Subject<any>();

  shoppingCart: shoppingCart;

  @Output()
  shoppingCartUpdates: Subject<shoppingCart> = new Subject<shoppingCart>();

  create(adrateId) {
    this.http.put<any>(`${environment.mfServerUrl}/api/WishList/${adrateId}`, adrateId).subscribe((response: any) => {
      this.shoppingCart = response;
      this.shopingCartTotalItems();
      this.alertService.success('Item is added to Cart Successfully');
    }, err => {
      this.alertService.error(err);
    });
  }

  shopingCartTotalItems() {
    this.http.get<shoppingCart>(`${environment.mfServerUrl}/api/Wishlist/Count`).subscribe((response) => {
      this.totalItems = response;
      this.shoppingTotalItems.next(this.totalItems);
    });
  }

  getAllWishlist(pageIndex: number, pageSize: number) {
    return this.http.get<any>(`${environment.mfServerUrl}/api/Wishlist?pageIndex=${pageIndex}&pageSize=${pageSize}`);
  }

  delete(wishlistLineId: number) {
    return this.http.delete(`${environment.mfServerUrl}/api/Wishlist?wishlistLineId=${wishlistLineId}`);
  }

}
