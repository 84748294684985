import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Publisher } from 'src/app/core/api/model';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { SellersearchService } from 'src/app/core/api/services/sellersearch.service';

@Component({
  selector: 'app-sellerinfo',
  templateUrl: './sellerinfo.component.html' 
})
export class SellerinfoComponent implements OnInit {
  sellerid:any;
  sellerDetails: Publisher;
  hasRegisteredPrimaryContact: boolean = false;
  canShowSellerData: boolean = false;
  constructor(private sellerSearchService: SellersearchService,
    private alertService: AlertService,
    private modalService: MDBModalService,
    private router: Router,
    private renderer: Renderer2,
    public route: ActivatedRoute) {
      route.params.subscribe(params => {
        this.sellerid = params.id;
        this.getSellerDetails(this.sellerid);
      })
    }

  ngOnInit(): void {
  }
  getSellerDetails(sellerId){
    this.sellerSearchService.getSellerDetails(sellerId).subscribe((data: any) => {
      if(data){
        this.canShowSellerData = true;
      }
      this.sellerDetails = data;
      this.hasRegisteredPrimaryContact = data.hasRegisteredPrimaryContact;
      
  });
  }
  sellerAddress(){
    this.router.navigateByUrl("seller-onboarding/" + this.sellerid + '/seller-address');
  }

  sellerContact(){
    this.router.navigateByUrl("seller-onboarding/" + this.sellerid + '/seller-contact');
  }
}
