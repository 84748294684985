import * as __model from '../model';

export class AdRate {
    adColor: string;
    adColorId: number;
    adOrientation: string;
    adPlacement: string;
    adPrintSource: string;
    adRateId: number;
    adSize: string;
    adType: string;
    pageFractionName: string;
    adTypeDescription:string
    adUnit: string;
    mediaKitId: number;
    description:string;
    qty: number;
    rate: number;
    isActive: boolean;
    createdBy: string;
    createdOn: Date;
    modifiedBy: string;
    modifiedOn: Date;
    rowVersion: string;
}
