import * as __model from '../model';

export class Circulation {
    circulationId?: number;
    mediaKitId?: number;
    circulationPerIssue?: number;
    isCirculationPerIssueAudited?: number;
    readership?: number;
    subscribers?: number;
    rateBase?: number;
    reach?: number;
    newsStandSales?: number;
    auditedAnnual?: number;
    reportedAnnual?:number
    reportedPerIssue?: number;
    auditedCirculationInt?: number;
    auditedCirculationTotal?:number
    auditedCirculationUs?: number;
    reportedCirculationInt?: number;
    reportedCirculationNa?:number
    reportedCirculationTotal?: number;
    reportedCirculationUs?: number;
    isActive: boolean;
    createdBy: string;
    createdOn: Date;
    modifiedBy: string;
    modifiedOn: Date;
    rowVersion: string;
}

