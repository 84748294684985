<div class="row justify-content-center p-3 no-gutters">
  <div class="col-sm-12 col-lg-8 col-md-9">
    <div class="card">
      <div class="card-body">
        <form id="login-form" [formGroup]="resetpasswordForm" action="" (ngSubmit)="onSubmit()" method="post"
          role="form">
          
          <h2 class="text-center">
            Reset Password
          </h2>

          <div class="md-form">
            <input type="password" formControlName="password" id="materialRegisterFormPassword" class="form-control"
              aria-describedby="materialRegisterFormPasswordHelpBlock" mdbInput mdbValidate tabindex="1" required />
            <label for="materialRegisterFormPassword">New Password</label>
            <mdb-error *ngIf="password.invalid && (password.dirty || password.touched)">
              Required
            </mdb-error>
            <small id="materialRegisterFormPasswordHelpBlock" class="form-text text-muted mb-4 pt-2">
              At least 8 characters, including 1 uppercase, 1 lowercase, 1 digit and 1 special character
            </small>
          </div>

          <div class="md-form">
            <input mdbInput mdbValidate type="password" formControlName="confirmPassword" class="form-control" required
              validateequal="password" id="confirmPassword" name="confirmPassword" tabindex="2" />
            <label for="confirmPassword">Confirm Password</label>
            <mdb-error *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
              {{getConfirmPasswordValidationErrorMessage()}}
            </mdb-error>
          </div>

          <div class="md-form">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-lg-6 col-md-6">
                <input type="submit" name="login-submit" id="login-submit" class="form-control btn-register btn"
                  value="Save Changes" tabindex="3" />
              </div>
            </div>
          </div>
          
          <div class="text-left">Return to
            <a [routerLink]="['/home']" id="register-form-link" style="font-weight:bold; text-decoration: underline">
              Homepage</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>