<div class="row justify-content-center p-3 no-gutters">
    <div class="col-xs-12 col-lg-8 col-md-9">
        <div class="card">
            <div class="card-body">
                <form id="login-form" action="" [formGroup]="loginForm" (ngSubmit)="onSubmit()" method="post"
                    role="form">
                    <h2 class="text-center">
                        Login Now
                    </h2>

                    <div class="md-form">
                        <input mdbInput mdbValidate type="email" formControlName="userName" class="form-control"
                            required id="userName" tabindex="1" />
                        <label for="userName">Email</label>
                        <mdb-error *ngIf="userName.invalid && (userName.dirty || userName.touched)">
                            <span *ngIf="userName.errors.required">Required</span>
                            <span *ngIf="userName.errors.email">Enter Valid Email Address</span>
                        </mdb-error>
                    </div>

                    <div class="md-form">
                        <input mdbInput mdbValidate type="password" formControlName="password" class="form-control"
                            required validateequal="confirmpassword" reverse="true" id="password" tabindex="2" />
                        <label for="password">Password</label>
                        <mdb-error *ngIf="password.invalid && (password.dirty || password.touched)">
                            Required
                        </mdb-error>
                    </div>

                    <div class="md-form">
                        <div class="text-right">
                            <a [routerLink]="['/forgot-password']" tabindex="5" class="forgot-password ">
                                Forgot Password?
                            </a>
                        </div>
                    </div>
                    <div>
                        <mdb-checkbox [inline]="true" formControlName="rememberMe">Remember Me</mdb-checkbox>
                    </div>
                    <div class="md-form">
                        <div class="row justify-content-center">
                            <div class="col-sm-12 col-lg-6 col-md-6">
                                <input type="submit" name="login-submit" id="login-submit" tabindex="4"
                                    class="form-control btn2 btn-register btn" value="Log In" />
                            </div>
                        </div>
                    </div>
                    <div class="text-left">New to Mediafiche?
                        <a [routerLink]="['/registration-lite']" id="register-form-link"
                            style="font-weight:bold; text-decoration: underline">
                            Register
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>