
<div class="modal-header">
  <div>
    <strong class="modal-title white-text">Delete {{deleteName}}</strong>
  </div>

  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide();">
    <span aria-hidden="true" class="white-text">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="text-center">
    <i class="fas fa-times fa-4x mb-3 animated rotateIn tittle1"></i>
    <p></p>
    <p>
      <strong>Do you wish to continue deleting?</strong>
    </p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary1" data-dismiss="modal" (click)="delete()" mdbWavesEffect>Yes</button>
  <button type="button" mdbBtn color="primary" outline="true"
      class="relative waves-light btn-outline-primary tc-1 blueborder" 
      mdbWavesEffect (click)="modalRef.hide();">No</button>