import { Action } from '@ngrx/store'
import { MagazineSearch } from '../models/magazine-search.model'

//configuration
export const SET_CONFIGURATION = '[MAGAZINE SEARCH] Set Configuration'

//searching
export const SEARCH_BEGIN = '[MAGAZINE SEARCH] Search Begin'
export const SEARCH_COMPLETE = '[MAGAZINE SEARCH] Search Complete'
export const SEARCH_RESULTS = '[MAGAZINE SEARCH] Search Results'
export const SEARCH_ERROR = '[MAGAZINE SEARCH] Search Error'
export const SEARCH_RESET = '[MAGAZINE SEARCH] Search Reset'
// export const SEARCH_FACET_RESULTS = '[MAGAZINE SEARCH] Search Facet Results'
export const SEARCH_INPUT = '[MAGAZINE SEARCH] Search Input'

//parameters
export const SEARCH_PARAMETERS_UPDATE = '[MAGAZINE SEARCH] Update Parameters'

//pagingation
export const SEARCH_PAGENATION_INCREMENT_SKIP = '[MAGAZINE SEARCH] [PAGINATION] Increment Skip'
export const SEARCH_PAGENATION_DECREMENT_SKIP = '[MAGAZINE SEARCH] [PAGINATION] Decrement Skip'
export const SEARCH_PAGINATION_SET_PAGE = '[MAGAZINE SEARCH] [PAGINATION] Set Page'

//facets
export const SEARCH_FACETS_ADD_CHECKBOX = '[MAGAZINE SEARCH] [FACETS] Add Checkbox'
export const SEARCH_FACETS_ADD_RANGE = '[MAGAZINE SEARCH] [FACETS] Add Range'
export const SEARCH_FACETS_TOGGLE_CHECKBOX = '[MAGAZINE SEARCH] [FACETS] Toggle Checkbox'
export const SEARCH_FACETS_SINGLE_TOGGLE_CHECKBOX = '[MAGAZINE SEARCH] [FACETS] Toggle Single Checkbox'
export const SEARCH_FACETS_TOGGLE_ALL_CHECKBOX = '[MAGAZINE SEARCH] [FACETS] Toggle All Checkbox'
export const SEARCH_FACETS_SET_RANGE = '[MAGAZINE SEARCH] [FACETS] Set Range'
export const SEARCH_FACETS_CLEAR = '[MAGAZINE SEARCH] [FACETS] Clear'
export const SEARCH_FACETS_SET_VALUES = '[MAGAZINE SEARCH] [FACETS] Set Values'
export const SEARCH_FACETS_UPDATE_VALUES = '[MAGAZINE SEARCH] [FACETS] Update Values'
export const MAGAZINE_DETAILS_RESULTS_VALUES = '[MAGAZINE] [DETAILS] Result Values'
export const MAGAZINE_DETAILS_RESULTS_VALUES_UPDATE = '[MAGAZINE] [DETAILS] Result Values Update'
export const MAGAZINE_DETAILS_TAB_RESULTS_VALUES = '[MAGAZINE] [DETAILS] Result Tab Values'
export const MAGAZINE_DETAILS_TAB_RESULTS_VALUES_UPDATE = '[MAGAZINE] [DETAILS] Result Values Tab Update'

export class SetConfiguration implements Action {
    readonly type = SET_CONFIGURATION

    constructor(public payload: MagazineSearch.Config) { }
}

export class SearchBegin implements Action {
    readonly type = SEARCH_BEGIN

    constructor() { }
}

export class SearchComplete implements Action {
    readonly type = SEARCH_COMPLETE

    constructor() { }
}

export class SearchResults implements Action {
    readonly type = SEARCH_RESULTS

    constructor(public payload: { results: {}[], date: number, count: number }) { }
}

export class SearchReset implements Action {
    readonly type = SEARCH_RESET

    constructor() { }
}

// export class SearchFacetResults implements Action {
//     readonly type = SEARCH_FACET_RESULTS

//     constructor(public payload: { results: { [key: string]: MagazineSearch.FacetResult[] } }) { }
// }

export class SearchFacetSetValues implements Action {
    readonly type = SEARCH_FACETS_SET_VALUES

    constructor(public payload: { facets: { [key: string]: MagazineSearch.FacetResult[] } }) { }
}

export class SearchFacetUpdateValues implements Action {
    readonly type = SEARCH_FACETS_UPDATE_VALUES

    constructor(public payload: { facets: { [key: string]: MagazineSearch.FacetResult[] } }) { }
}

export class SearchError implements Action {
    readonly type = SEARCH_ERROR

    constructor(public payload: { message: string }) { }
}

export class SearchInput implements Action {
    readonly type = SEARCH_INPUT

    constructor(public payload: { input: string }) { }
}

export class SearchPaginationIncrementSkip implements Action {
    readonly type = SEARCH_PAGENATION_INCREMENT_SKIP

    constructor() { }
}

export class SearchPaginationDecrementSkip implements Action {
    readonly type = SEARCH_PAGENATION_DECREMENT_SKIP

    constructor() { }
}

export class SearchPaginationSetPage implements Action {
    readonly type = SEARCH_PAGINATION_SET_PAGE

    constructor(public payload: { page: number }) { }
}

export class SearchParametersUpdate implements Action {
    readonly type = SEARCH_PARAMETERS_UPDATE

    constructor(public payload: MagazineSearch.SearchParametersUpdate) { }
}

export class SearchFacetsAddCheckbox implements Action {
    readonly type = SEARCH_FACETS_ADD_CHECKBOX

    constructor(public payload:
        {
            key: string,
            dataType: MagazineSearch.CheckboxDataType,
            count: number,
            sort: MagazineSearch.FacetSortingMode
        }) { }
}

export class SearchFacetsSetRange implements Action {
    readonly type = SEARCH_FACETS_SET_RANGE

    constructor(public payload:
        {
            key: string,
            lowerBound: number | Date,
            upperBound: number | Date
        }) { }
}

export class SearchFacetsAddRange implements Action {
    readonly type = SEARCH_FACETS_ADD_RANGE

    constructor(public payload:
        {
            key: string,
            dataType: MagazineSearch.RangeDataType
            min: number | Date,
            max: number | Date
        }) { }
}

export class SearchFacetsToggleCheckbox implements Action {
    readonly type = SEARCH_FACETS_TOGGLE_CHECKBOX

    constructor(public payload:
        {
            key: string,
            value: string | number 
        }) { }
}

export class SearchFacetsSingleToggleCheckbox implements Action {
    readonly type = SEARCH_FACETS_SINGLE_TOGGLE_CHECKBOX

    constructor(public payload:
        {
            key: string,
            value: string | number | any
        }) { }
}

export class SearchFacetsToggleAllCheckbox implements Action {
    readonly type = SEARCH_FACETS_TOGGLE_ALL_CHECKBOX

    constructor(public payload:
        {
            key: string,
            values: string[] | number[],
            boolType: boolean
        }) { }
}

export class SearchFacetsClear implements Action {
    readonly type = SEARCH_FACETS_CLEAR

    constructor() { }
}

export class MagazineDetailsResult implements Action {
    readonly type = MAGAZINE_DETAILS_RESULTS_VALUES
    constructor(public payload : 
        {
             id: string
            }
        ) { }
}

export class MagazineDetailsResultUpdate implements Action {
    readonly type = MAGAZINE_DETAILS_RESULTS_VALUES_UPDATE

    constructor(public payload: MagazineSearch.MagazineDetailsResultUpdate) { }
}

export class MagazineDetailsTabResult implements Action {
    readonly type = MAGAZINE_DETAILS_TAB_RESULTS_VALUES
    constructor(public payload : 
        {
             id: string
            }
        ) { }
} 

export class MagazineDetailsResultTabUpdate implements Action {
    readonly type = MAGAZINE_DETAILS_TAB_RESULTS_VALUES_UPDATE

    constructor(public payload: MagazineSearch.MagazineDetailsResultTabUpdate) { }
}
export type Actions = SetConfiguration | SearchBegin | SearchComplete | SearchResults | SearchError | SearchInput
    | SearchPaginationIncrementSkip | SearchPaginationDecrementSkip | SearchPaginationSetPage | SearchParametersUpdate | SearchFacetsAddCheckbox | SearchFacetsToggleCheckbox
    |SearchFacetsToggleAllCheckbox| SearchFacetsSingleToggleCheckbox |SearchFacetsClear | SearchFacetsAddRange | SearchFacetsSetRange | SearchFacetSetValues | SearchFacetUpdateValues | MagazineDetailsResult
    | MagazineDetailsResultUpdate | MagazineDetailsTabResult | MagazineDetailsResultTabUpdate | SearchReset