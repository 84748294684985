<form [formGroup]="addMigrateMediaKitForm" (ngSubmit)="migrateMediaKitSubmit()">
  <div class="modal-header">
    <div>
      <h5 class="modal-title tittle3" id="exampleModalLabel">Migrate MediaKit</h5>
    </div>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-sm-12 col-lg-6 col-md-6">
        <div class="md-form mt-0">
          <mdb-select label="From MediaKit" mdbValidate formControlName="fromMediaKitId" [options]="activeMediaKitOptions">
          </mdb-select>
          <mdb-error *ngIf="fromMediaKitId.invalid && (fromMediaKitId.dirty || fromMediaKitId.touched)">
            Required
          </mdb-error>

          <!-- <select formControlName="fromMediaKitId" class="browser-default custom-select">
            <option selected>Select Year</option>
            <option *ngFor="let year of optionsSelect">{{year}}</option>
          </select>
          <mdb-error *ngIf="fromMediaKitId.invalid && (fromMediaKitId.dirty || fromMediaKitId.touched)">
            <span>Please Select Year </span>
          </mdb-error> -->
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 col-md-6">
        <div class="md-form mt-0">
          <mdb-select label="To MediaKit" mdbValidate formControlName="toMediaKitId" [options]="inActiveMediaKitOptions">
          </mdb-select>
          <mdb-error *ngIf="toMediaKitId.invalid && (toMediaKitId.dirty || toMediaKitId.touched)">
            Required
          </mdb-error>

        
        </div>
      </div>
      <div >
        <div class="modal-footer">
          Note: This migrates only the Demographic Data such as Demographics, Audience, Circulations, Rates and Issues.  Assets needs to be migrated manually.
        </div>
        <div>
         
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()"
    mdbWavesEffect>Close</button>
  </div>
</form>