import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { DownloadService } from 'src/app/core/api/services/download.service';
import { saveAs as importedSaveAs } from "file-saver";
import { IMyOptions } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-downloadoffer',
  templateUrl: './downloadoffer.component.html'
})
export class DownloadofferComponent implements OnInit {

  partnerkey: any;

  partialInvoicePaymentEligiblityIndicatorOptions = [
    { value: null, label: '-Select-' },
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];


  constructor(private fb: FormBuilder,
    private alertService: AlertService,
    private downloadService: DownloadService) { }

  addDownloadOfferBatchForm = this.fb.group({
    mainPartnerKey: ['', [Validators.required, Validators.maxLength(50)]],
    offerNumber: ['', [Validators.required]],
    billingDate: ['', [Validators.required]],
    paymentDueDate: [''],
    invoiceNumber: ['', [Validators.required]],
    invoiceDescription: ['', [Validators.maxLength(120)]],
    partialInvoicePaymentEligiblityIndicator: ['']
  });

  get mainPartnerKey() { return this.addDownloadOfferBatchForm.get('mainPartnerKey'); }

  get offerNumber() { return this.addDownloadOfferBatchForm.get('offerNumber'); }

  get billingDate() { return this.addDownloadOfferBatchForm.get('billingDate'); }

  get paymentDueDate() { return this.addDownloadOfferBatchForm.get('paymentDueDate'); }

  get invoiceNumber() { return this.addDownloadOfferBatchForm.get('invoiceNumber'); }

  get invoiceDescription() { return this.addDownloadOfferBatchForm.get('invoiceDescription'); }

  get partialInvoicePaymentEligiblityIndicator() { return this.addDownloadOfferBatchForm.get('partialInvoicePaymentEligiblityIndicator'); }

  ngOnInit(): void {
  }

  validateForm() {
    Object.keys(this.addDownloadOfferBatchForm.controls).forEach(field => {
      const control = this.addDownloadOfferBatchForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  addDownloadOfferBatchFormOnSubmit() {
    this.downloadFiles();
  }

  downloadFiles() {
    this.validateForm();

    if (this.addDownloadOfferBatchForm.valid) {
      this.downloadService.downloadOffers(this.addDownloadOfferBatchForm.getRawValue())
        .subscribe((res: any) => {
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          importedSaveAs(res.body, filename);
        },
          err => {
            this.alertService.error(err);
          }
        );
    }

  }

  public myDatePickerOptions: IMyOptions = {

    // Buttons
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",
    // Format
    dateFormat: 'mm-dd-yyyy',
    //disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() },
    //markDates: [{dates: [{year: 2020, month: 8, day: 20}], color: '#303030'}],
    showTodayBtn: true,
    // Show Clear date button
    showClearDateBtn: true,
    closeAfterSelect: true,
  };
}