import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {MediakitAssets} from '../defs/MediakitAssets'

@Injectable({
  providedIn: 'root'
})
export class MediakituploadService {

  constructor(private http: HttpClient ) { }

  read(mediakitId: number,assetsTypeId: number): Observable<MediakitAssets> {
    return this.http
        .get<MediakitAssets>(`${environment.mfServerUrl}/api/MediakitAssets/${mediakitId}/Assets/${assetsTypeId}`);
  }

  create(asstes:any): Observable<any> {
    return this.http.post<any>(`${environment.mfServerUrl}/api/MediakitAssets/Upload`, asstes); 
  }

  update(rates:any): Observable<any> {
    return this.http.put<any>(`${environment.mfServerUrl}/api/AdRate`, rates);
  }

  delete(assetId:any): Observable<any> {
    return this.http.delete<any>(`${environment.mfServerUrl}/api/MediakitAssets/${assetId}`);
  }
  
}
