export class User {
    id: number;
    password: string;
    conformPassword: string;
    firstName: string;
    lastName: string;
    email: string;
    tempAuthToken: string;
    accountType: string;
    userId : number;
    agreementAccepted:boolean;
}