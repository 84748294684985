import {
    CHANGE_OCCUPATION,
    CHANGE_OCCUPATION_ALL,
    CHANGE_OCCUPATION_DESELECT,
    CHANGE_OCCUPATION_DESELECT_ALL
} from '../actions'

const occupationReducer = (state = [], action) => {
    switch (action.type) {

        case CHANGE_OCCUPATION: {
            let result = [...state, action.payload]
            return result;
        }
        case CHANGE_OCCUPATION_ALL: {
            let result = action.payload
            return result;
        }
      
        case CHANGE_OCCUPATION_DESELECT_ALL: {
            return []
        }
        default: return state;
    }
}

export default occupationReducer;