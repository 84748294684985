import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../auth/service/auth-guard.service';
import { UserRole } from '../enums/UserRole';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { DownloadbatchComponent } from './pages/downloadbatch/downloadbatch.component';
import { DownloadofferComponent } from './pages/downloadoffer/downloadoffer.component';


const routes: Routes = [
  {
    path: 'download-amf-batch-file',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: DownloadbatchComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFAccountingAdmin
          ]
        }
      }
    ]
  }, {
    path: 'download-offers-batch-file',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: DownloadofferComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFAccountingAdmin
          ]
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DownloadRoutingModule { }
