<form [formGroup]="addAudienceForm" (ngSubmit)="addAudienceOnSubmit()">
  <div class="modal-header">
    <div *ngIf="isEdit; else addMagazineTemplate">
      <h5 class="modal-title tittle3" id="exampleModalLabel">Edit Audience</h5>
    </div>
    <ng-template #addMagazineTemplate>
      <h5 class="modal-title tittle3" id="exampleModalLabel">Add Audience</h5>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="">
          <ng-multiselect-dropdown #multiSelect [placeholder]="'Select Audience'"
            [settings]="settings" [data]="audiencegrouplist" [(ngModel)]="selectedElement" [ngModelOptions]="{standalone: true}" [disabled]="false"
            (onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="value" class="form-control" id="value" />
          <label for="value">Value</label>
          <mdb-error *ngIf="value.invalid && (value.dirty || value.touched)">
            <span *ngIf="value.errors.min">Please Enter Valid Value </span>
          </mdb-error>
        </div>
      </div>
    </div>
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="md-form mt-0">
            <div class="md-form  mt-0">
              <mdb-checkbox mdbValidate formControlName="isActive">
                Is Active
                <mdb-error *ngIf="isActive.invalid && (isActive.dirty || isActive.touched)">
                  Required
                </mdb-error>
              </mdb-checkbox>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">   
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn waves-light btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()"
    mdbWavesEffect>Close</button>
  </div>
</form>