import { Component, OnInit } from '@angular/core';
import { Issue } from 'src/app/core/api/model';
import { Subject, Subscription } from 'rxjs';
import { MDBModalRef, IMyOptions } from 'ng-uikit-pro-standard';
import { FormBuilder, Validators } from '@angular/forms';
import { IssueService } from 'src/app/core/api/services/issue.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-add-issue',
  templateUrl: './add-issue.component.html'
})
export class AddIssueComponent implements OnInit {

  id: number;
  isEdit: boolean;
  issue: Issue;
  type: string;
  userName: any;
  currentDate: Date;
  action: Subject<any> = new Subject();
  date: Date = new Date();

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private issueService: IssueService,
    private alertService: AlertService,
    private authService: AuthService) { }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();

    this.loadDataOnInit();
  }

  loadDataOnInit() {
    setTimeout(() => {
      if (this.issue != null || this.issue != undefined) {
        this.addIssueForm.patchValue(this.issue);
      }
    });

    this.mediaKitId.patchValue(this.id);
  }

  addIssueForm = this.fb.group({
    issueId: [''],
    issueName: ['', Validators.required],
    issueDescription: [''],
    coverDate: [''],
    spaceCloseDate: [''],
    inHomeDate: [''],
    insertsDueDate: [''],
    onSaleDate: [''],
    specsDueDate: [''],
    materialsDueDate: [''],
    mediaKitId: [''],
    isActive: [true, Validators.required],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
    rowVersion: ['']
  });

  get issueId() { return this.addIssueForm.get('issueId'); }
  get issueName() { return this.addIssueForm.get('issueName'); }
  get issueDescription() { return this.addIssueForm.get('issueDescription'); }
  get coverDate() { return this.addIssueForm.get('coverDate'); }
  get spaceCloseDate() { return this.addIssueForm.get('spaceCloseDate'); }
  get inHomeDate() { return this.addIssueForm.get('inHomeDate'); }
  get insertsDueDate() { return this.addIssueForm.get('insertsDueDate'); }
  get onSaleDate() { return this.addIssueForm.get('onSaleDate'); }
  get specsDueDate() { return this.addIssueForm.get('specsDueDate'); }
  get materialsDueDate() { return this.addIssueForm.get('materialsDueDate'); }
  get mediaKitId() { return this.addIssueForm.get('mediaKitId'); }
  get isActive() { return this.addIssueForm.get('isActive'); }
  get createdOn() { return this.addIssueForm.get('createdOn'); }
  get createdBy() { return this.addIssueForm.get('createdBy'); }
  get modifiedOn() { return this.addIssueForm.get('modifiedOn'); }
  get modifiedBy() { return this.addIssueForm.get('modifiedBy'); }


  validateForm() {
    Object.keys(this.addIssueForm.controls).forEach(field => {
      const control = this.addIssueForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  
  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  addIssueOnSubmit() {
    this.validateForm();
    if (this.addIssueForm.valid) {
      if (this.issue) {
        this.updateUserDetails();
        this.issueService.update(this.addIssueForm.getRawValue()).subscribe((data: Issue) => {
          this.modalRef.hide();
          this.alertService.success('Issue Updated Successfully');

          // Set the json in Subscribe so that once the modal is closed 
          // API will be invoked to get the new results
          let json = {
            id: this.id,
            type: this.type
          };

          this.action.next(json);

        })
      }
      else {
        this.addUserDetails();
        this.issueService.create(this.addIssueForm.getRawValue()).subscribe((data: Issue) => {
          this.modalRef.hide();
          this.alertService.success('Issue Added Successfully');

          // Set the json in Subscribe so that once the modal is closed 
          // API will be invoked to get the new results
          let json = {
            id: this.id,
            type: this.type
          };

          this.action.next(json);
        })
      }
    }
  }

  public myDatePickerOptions: IMyOptions = {

    // Buttons
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",
    // Format
    dateFormat: 'mm-dd-yyyy',
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() },
    //markDates: [{dates: [{year: 2020, month: 8, day: 20}], color: '#303030'}],
    showTodayBtn: true,
    // Show Clear date button
    showClearDateBtn: true,
    closeAfterSelect: true,

  };
}