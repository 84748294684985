import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { OfferService } from 'src/app/core/api/services/offer.service';

@Component({
  selector: 'app-edit-offerack',
  templateUrl: './edit-offerack.component.html'
})
export class EditOfferackComponent implements OnInit {

  userName: any;

  currentDate: Date;

  data: any;

  offerid: number;

  offernumber: string;

  action: Subject<any> = new Subject();

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private offerService: OfferService,
    private alertService: AlertService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();

    this.offerId.patchValue(this.offerid);

    this.offerNumber.patchValue(this.offernumber);
  }

  offerPaymentForm = this.fb.group({
    offerId: [''],
    offerNumber: [''],
    isPaymentMade: [false, Validators.requiredTrue],
    paidAmount: ['', Validators.required],
    instructions: [''],
    modifiedBy: [''],
    modifiedOn: [''],
    confirmationNumber: ['', Validators.required]
  });

  // Properties to use in html
  get offerId() { return this.offerPaymentForm.get('offerId'); }
  get offerNumber() { return this.offerPaymentForm.get('offerNumber'); }
  get isPaymentMade() { return this.offerPaymentForm.get('isPaymentMade'); }
  get instructions() { return this.offerPaymentForm.get('instructions'); }
  get paidAmount() { return this.offerPaymentForm.get('paidAmount'); }
  get modifiedBy() { return this.offerPaymentForm.get('modifiedBy'); }
  get modifiedOn() { return this.offerPaymentForm.get('modifiedOn'); }
  get confirmationNumber() { return this.offerPaymentForm.get('confirmationNumber'); }


  close(modalRef) {
    modalRef.hide();
  }


  validateForm() {
    Object.keys(this.offerPaymentForm.controls).forEach(field => {
      const control = this.offerPaymentForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }


  offerPaymentFormOnSubmit() {
    this.editOffer();
  }

  editOffer() {

    this.validateForm();

    if (this.offerPaymentForm.valid) {
      this.updateUserDetails();
      this.offerService.paymentAcknowledgment(this.offerPaymentForm.getRawValue()).subscribe(
        (data: any) => {
          this.modalRef.hide();
          this.alertService.success('Offer Information Updated Successfully');
          // Set the json in Subscribe so that once the modal is closed 
          // API will be invoked to get the new results
          let json = {
            id: this.offerid,
            type: "offer"
          };

          this.action.next(json);
        },
        (errMsg) => {
          this.alertService.error(errMsg);
        });
    }

  }
}
