import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Buyer } from 'src/app/core/api/defs/Buyer';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';
import { EditDetailsComponent } from 'src/app/shared/edit-details/edit-details.component';

@Component({
  selector: 'app-buyerinfo',
  templateUrl: './buyerinfo.component.html'
})
export class BuyerinfoComponent implements OnInit {
  canShowBuyerDataCard: boolean = false;
  datacard: any;
  modalRef: MDBModalRef;
  constructor(private buyersearchService: BuyersearchService,
    private alertService: AlertService,
    private modalService: MDBModalService,
    private router: Router,
    private renderer: Renderer2,
    public route: ActivatedRoute) {
    route.params.subscribe(params => {
      this.getBuyerData(params.id);
   })
   }

  ngOnInit(): void {

  }
  getBuyerData(buyerId: number) {
    this.buyersearchService.getBuyerDetails(buyerId).subscribe((data: Buyer) => {
        if (data != null) {
            this.canShowBuyerDataCard = true;
            this.datacard = data;
           // this.hasRegisteredPrimaryContact = data.hasRegisteredPrimaryContact
        }
        else {
            this.canShowBuyerDataCard = false;
        }
    }, (errMsg)=>{
        this.alertService.error(errMsg);
    });

}

buyerAddress(){
  this.router.navigateByUrl("buyer-onboarding/" + this.datacard.buyerId + '/buyer-address');
}

buyerContact(){
  this.router.navigateByUrl("buyer-onboarding/" + this.datacard.buyerId + '/buyer-contact');
}
addBuyerClick() {
  this.modalRef = this.modalService.show(EditDetailsComponent, {
      class: 'modal-lg',
      data: {
          type: "buyer",
          isEdit: false
      }
  });
  //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
  this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
  // Once the modal is closed trigger the API to get the fresh results
  this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
          if (result.type == "buyer") {
              // Invoke API to get the data
              this.getBuyerData(result.id);
          }
      }
  });
}

editBuyerClick(datacard) {
  this.modalRef = this.modalService.show(EditDetailsComponent, {
      class: 'modal-lg',
      data: {
          id: this.datacard.buyerId,
          type: "buyer",
          data: datacard,
          isEdit: true

      }
  });
  //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
  this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
  // Once the modal is closed trigger the API to get the fresh results
  this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
          if (result.type == "buyer") {
              // Invoke API to get the data
              this.getBuyerData(result.id);
          }
      }
  });
}
}