import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { User } from '../defs/User';
import { environment } from '../../../../environments/environment'
import { Observable, Subject, BehaviorSubject } from '../../../../../node_modules/rxjs/Rx';
import { ViewRate, AdRate, shoppingCart, wishlistLine, Assets} from '../model';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { OutletContext } from '@angular/router';
import { CartService } from './cart.service';
import { AlertService } from './alertservice/alert.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ViewrateService {
  assetsList: BehaviorSubject<Assets>;  
  addToCart(adRateId) {
    this._cartService.create(adRateId);
  }
  magazineId: any; 
  assetsData: BehaviorSubject<ViewRate>;  
  @Output()
  dataLoaded: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private _cartService: CartService, private alertService: AlertService) {
    this.assetsList = new BehaviorSubject(null);
  }

  getAdRatesofMagazine(magazineId: number){
    return this.http.get(`${environment.mfServerUrl}/api/Magazines/${magazineId}/Any/AdRate`);
  }

  getIssueDates(magazineId: number){
    return this.http.get(`${environment.mfServerUrl}/api/Magazines/${magazineId}/CoverDates`);
  }

  getAdRates(magazineId: number) {
    return this.http.get(`${environment.mfServerUrl}/api/Magazines/${magazineId}/AdOptions`);
  }

  getAdtype(magazineId){
    return this.http.get(`${environment.mfServerUrl}/api/Magazines/${magazineId}/AdType`);
   // /api/Magazines/{magazineId}/AdType
  }

  getAdColor(magazineId,adTypeId){
    return this.http.get(`${environment.mfServerUrl}/api/Magazines/${magazineId}/AdColor/${adTypeId}`);
   // /api/Magazines/{magazineId}/AdType
  }

  getAdSize(magazineId,adTypeId,adColorId){
    return this.http.get(`${environment.mfServerUrl}/api/Magazines/${magazineId}/AdSize/${adTypeId}/${adColorId}`);
   // /api/Magazines/{magazineId}/AdType
  }

  getAdPrice(magazineId,adRateId){
    return this.http.get(`${environment.mfServerUrl}/api/Magazines/${magazineId}/AdRate/${adRateId}`);
  }

  getMagazineDetail(magazineId: number){
    return this.http.get(`${environment.mfServerUrl}/api/Magazines/${magazineId}/Details`);
  }

  getMagazineAssets(magazineId){
      this.http.get<Assets>(`${environment.mfServerUrl}/api/Magazines/${magazineId}/Assets`).subscribe((response:Assets) =>{
      
      this.assetsList.next(response);
      this.dataLoaded.next(true);
    });
  }
}
