<div class="row justify-content-center p-3">
  <div class="col-xs-12 col-lg-8 col-md-9">
      <div class="card">
          <div class="card-body">
              
                  
                <h2 class="text-center">
                  404
                </h2>

                  <div class="md-form">
                     <div class="text-center">
                       Unfortunately the page you are looking has moved or deleted.
                     </div>
                  </div>

                  <!-- <div class="md-form">
                      <div class="row justify-content-center">
                          <div class="col-sm-12 col-lg-6 col-md-6">
                              <input type="submit" name="login-submit" id="login-submit" tabindex="4"
                                  class="form-control btn2 btn-register btn" value="Log In" />
                          </div>
                      </div>
                  </div> -->

                  <div class="text-left">Return to 
                      <a [routerLink]="['/home']" id="register-form-link"
                          style="font-weight:bold; text-decoration: underline">
                          Homepage
                      </a>
                  </div>
              
          </div>
      </div>
  </div>
</div>
