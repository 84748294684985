<form [formGroup]="editBuyerDetails" (ngSubmit)="buyerDetailEdit()">
  <div class="modal-header">
    <h5 *ngIf="isEdit" class="modal-title" id="exampleModalLabel">Edit Details</h5>
    <h5 *ngIf="!isEdit" class="modal-title" id="exampleModalLabel">Add Details</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 col-md-5 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" id="buyerName" formControlName="buyerName" class="form-control" />
          <label for="buyerName">Name</label>
          <mdb-error *ngIf="buyerName.invalid && (buyerName.dirty || buyerName.touched)">
            Required
          </mdb-error>
        </div>
      </div>
      <div class="col-sm-12 col-md-7 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" id="buyerCompanyDescription" formControlName="buyerCompanyDescription" class="form-control" />
          <label for="buyerCompanyDescription">Company Description</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" id="buyerDBA" formControlName="buyerDBA" class="form-control" />
          <label for="buyerDBA">Buyer DBA</label>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" id="buyerFEID" formControlName="buyerFEID" class="form-control" />
          <label for="buyerFEID">Buyer FEID</label>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" id="buyerDnB" formControlName="buyerDnB" class="form-control" />
          <label for="buyerDnB">Buyer DNB</label>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form  mt-0">
          <mdb-select label="Payment Options" mdbValidate formControlName="paymentOptionId" [options]="paymentOptions">
          </mdb-select>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form  mt-0">
          <input mdbInput mdbValidate type="number" class="form-control" formControlName="creditLimit"
                 id="creditLimit">
          <label for="creditLimit">Credit Limit</label>
        </div>
      </div>

      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form  mt-0">
          <mdb-checkbox mdbValidate formControlName="isCreditApproved">
            Is Credit Approved
          </mdb-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn btn-secondary btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()" mdbWavesEffect>Close</button>
  </div>
</form>
