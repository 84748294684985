<div class="row">
  <div class="col-sm-10 mx-auto">
    <div>
      <div class="container-fluid">
        <div class="breadCrumps">
          <mdb-breadcrumb customClass="blue-text lighten-4">
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance']">
              <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId]">
              <mdb-icon fas class="mr-1"></mdb-icon> {{sellerName}}
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId,'magazines']">
              <mdb-icon fas class="mr-1"></mdb-icon> Magazines
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineId]">
              <mdb-icon fas class="mr-1"></mdb-icon> {{magazineName}}
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineId, 'mediakits']">
              <mdb-icon fas class="mr-1"></mdb-icon> Mediakits
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="tittle3"
              [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineId, 'mediakit', mediakitId]">
              <mdb-icon fas class="mr-1"></mdb-icon> {{mediaKitName}}
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="active">
              <mdb-icon fas class="mr-1"></mdb-icon>Mediakit Uploads
            </mdb-breadcrumb-item>
          </mdb-breadcrumb>
        </div>

        <div class="tab-pane fade in show active" id="MagazineTabPane" role="tabpanel">
          <div class="row">
            <!-- Nav tabs -->
            <div class="col-xs-4 col-sm-3">
              <ul class="nav list flex-column nav-pills nav-fill mf-tittle">
                <li class="nav-item listitem">
                  <a class="nav-link" name="Cover Page" id="1" (onload)="createComponent($event)"
                    (click)="createComponent($event)">Cover Page</a>
                </li>
                <li class="nav-item listitem">
                  <a class="nav-link" name="Thumbnail Image" id="14" (click)="createComponent($event)">Cover Thumbnail
                    Image</a>
                </li>
                <li class="nav-item listitem">
                  <a class="nav-link" name="Audience" id="2" (click)="createComponent($event)">Audience</a>
                </li>
                <li class="nav-item listitem">
                  <a class="nav-link" name="Circulation" id="5" (click)="createComponent($event)">Circulation</a>
                </li>
                <li class="nav-item listitem">
                  <a class="nav-link" name="Demographics" id="3" (click)="createComponent($event)">Demographics</a>
                </li>
                <li class="nav-item listitem">
                  <a class="nav-link" name="Display Rates" id="4" (click)="createComponent($event)">Display Rates</a>
                </li>
                <li class="nav-item listitem">
                  <a class="nav-link" name="Editorial Calendar" id="8" (click)="createComponent($event)">Editorial
                    Calendar</a>
                </li>
                <li class="nav-item listitem">
                  <a class="nav-link" name="Mechanical Requirements" id="6" (click)="createComponent($event)">Mechanical
                    Requirements</a>
                </li>
                <li class="nav-item listitem">
                  <a class="nav-link" name="Media Kit" id="10" (click)="createComponent($event)">
                    Media Kit</a>
                </li>
                <li class="nav-item listitem">
                  <a class="nav-link" name="Dates" id="9" (click)="createComponent($event)">
                    Dates</a>
                </li>
              </ul>
            </div>
            <!-- Tab panels -->
            <div class="col-xs-8 col-sm-9">
              <div class="tab-content card">
                <div class="mf3-center tittle1 mt-2">
                  <h4>Media Kit Uploads</h4>
                </div>
                <template #messagecontainer>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>