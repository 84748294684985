import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../core/core.module';
import { OnboardingRoutingModule } from './onboarding-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OnboardingComponent } from './onboarding.component';

import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';
import { SelleraddressComponent } from './selleraddress/selleraddress.component';
import { SellercontactComponent } from './sellercontact/sellercontact.component';
import { SellerinfoComponent } from './sellerinfo/sellerinfo.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    OnboardingRoutingModule,
    FormsModule,
    CoreModule,
    SharedModule
  ],
  declarations: [OnboardingComponent, SelleraddressComponent, SellercontactComponent, SellerinfoComponent]
})
export class OnboardingModule { }
