import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadCrumbs } from 'src/app/core/api/defs/BreadCrumbs';
import { BreadCrumbsService } from 'src/app/core/api/services/breadCrumbs.service';

@Component({
  selector: 'app-democricularrates',
  templateUrl: './democricularrates.component.html',
  styleUrls: ['./democricularrates.component.scss']
})
export class DemocricularratesComponent implements OnInit {
  magazineId: number;
  mediakitId: number;
  sellerId: number

  sellerName: string;
  magazineName: string;
  mediaKitName: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private breadCrumbsService: BreadCrumbsService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.sellerId = params.sellerId;
      this.magazineId = params.magazineId;
      this.mediakitId = params.mediaKitId;
    });

    this.breadCrumbsService.getBreadCrumbs(this.sellerId, this.magazineId, this.mediakitId).subscribe((data: BreadCrumbs) => {
      if (data != null) {
        this.sellerName = data.sellerName;
        this.magazineName = data.magazineName;
        this.mediaKitName = data.mediaKitName;
      }
    });

  }

}
