import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Publisher } from '../defs/Seller';
import { SellerAddContact } from '../model';
import { SellerSearchResult, SellerSearch } from '../defs/SearchSeller';
import { TempAuthRequest } from '../defs/TempAuthRequest';
import { SellerContact } from '../defs/SellerContact';
import { Buyer, Address } from '../defs/Buyer';
import { BuyerSearchResult, BuyerSearch } from '../defs/SearchBuyer';
import { BuyerPaymentAccount } from '../defs/BuyerPaymentAccount';

interface browseSearchInputs {
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class BuyersearchService {
  baseUrl: string = `${environment.identityProviderRootUrl}`;

  //Buyer search
  params = new HttpParams().set("api-version", environment.azureSearchApiVersion);
  headers = new HttpHeaders()
    .append("Content-Type", "application/json")
    .append("Access-Control-Allow-Credentials", "true")
    .append("Access-Control-Allow-Origin", "*")
    .append("Access-Control-Allow-Methods", "*")
    .append("api-key", environment.azureSearchApiKey);

  constructor(private http: HttpClient) { }

  searchBuyer(val: string): Observable<BuyerSearchResult> {

    return this.http.get<BuyerSearchResult>(`${environment.azureBuyerSearchUrl}` + '?search=' + val + "*",
      {
        params: this.params,
        headers: this.headers
      });
  }

  generateCode(buyer: Buyer): Observable<TempAuthRequest> {

    let tempAuthRequest = new TempAuthRequest();
    tempAuthRequest.partyName = buyer.buyerName;
    tempAuthRequest.partyType = "Buyer";

    let url = this.baseUrl + "/api/Account/CreateTempAuth";

    return this.http.post<TempAuthRequest>(url, tempAuthRequest);
  }

  getBuyerDetails(buyerId: number) {
    return this.http.get<Buyer>(`${this.baseUrl}` + '/api/Buyer/' + buyerId);
  }
 
  getBuyerAddress(buyerid) {
    let url = this.baseUrl + `/api/Buyer/${buyerid}/Addresses`;
    return this.http.get(url);
  }

  getBuyerContact(buyerid) {
    let url = this.baseUrl + `/api/Buyer/${buyerid}/Contacts`;
    return this.http.get(url);
  }

  readByPage(page: Number): Observable<Buyer> {
    return this.http.get<Buyer>(`${this.baseUrl}/api/Buyer?pageIndex=${page}&pageSize=10`);
  }

  addBuyer(buyer: Buyer): Observable<Buyer> {
    let url = this.baseUrl + `/api/Buyer`;
    return this.http.post<any>(url, buyer);
  }

  editBuyer(buyer: Buyer) {
    let url = this.baseUrl + `/api/Buyer`;
    return this.http.put<any>(url, buyer);
  }

  updateBuyerPrimaryContact(buyerContact: any,buyerid) {
    let url = this.baseUrl + `/api/Buyer/${buyerid}/PrimaryContact`;
    return this.http.put<any>(url, buyerContact);
  }

  updateBuyerSecondaryContact(buyerContact: any,buyerid) {

    let url = this.baseUrl + `/api/Buyer/${buyerid}/SecondaryContact`;
    return this.http.put<any>(url, buyerContact);
  }

  anyRegisteredPrimaryContact(buyerid: number) {
    let url = this.baseUrl + `/api/Buyer/${buyerid}/AnyRegisteredPrimaryContact`;
    return this.http.get(url);
  }

  getBuyerPaymentOptions() {
    let url = this.baseUrl + '/api/Buyer/PaymentOption';
    return this.http.get(url);
  }
 
  buyerPaymentAccountSetup(buyerPaymentAccount:BuyerPaymentAccount,buyerid){
    let url = this.baseUrl + `/api/Buyer/${buyerid}/PaymentAccountSetupAcknowledgement`;
    return this.http.put<any>(url, buyerPaymentAccount);
  }

  getBuyerActivity(buyerId){
    return this.http.get<any>(`${this.baseUrl}/api/Activity/${buyerId}`);
  }
  
  getBuyerActivityLine(page: Number,buyerId:Number){
    return this.http.get<any>(`${this.baseUrl}/api/Activity/ActivityLines/${buyerId}?pageIndex=${page}&pageSize=10`);
  }

}
