
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { AuthService } from 'src/app/auth/service/auth.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { RegisterService } from 'src/app/core/api/services/register.service';
import { UserRole } from 'src/app/enums/UserRole';

@Component({
  selector: 'app-termsandcondition',
  templateUrl: './termsandcondition.component.html'
})
export class TermsandconditionComponent implements OnInit {
  agreementText: any;

  viewContent: string = "";

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(private alertService: AlertService,
    private registerService: RegisterService,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    window.scroll(0, 0);

    this.callAgreement();
  }

  get IsBuyer() {
    if (this.isAuthenticated) {
      return this.authService.getUserRoles().indexOf(UserRole.Buyer) != -1;
    }

    return false;
  }

  callAgreement() {
    //   if (this.IsBuyer) {

    //     this.registerService.Agreements("Buyer").subscribe((response: string) => {
    //       this.agreementText = response;
    //       if (this.agreementText) {
    //         this.registerService.calltoAgreement(this.agreementText).subscribe((response: any) => {
    //           this.viewContent = response;
    //         })
    //       }
    //     });
    //   } else {
    //     this.registerService.Agreements("Seller").subscribe((response: string) => {
    //       this.agreementText = response;
    //       this.viewContent = response;

    //       // if (this.agreementText) {
    //       //   this.registerService.calltoAgreement(this.agreementText).subscribe((response: any) => {
    //       //     this.viewContent = response;
    //       //   })
    //       // }
    //     });
    //   }

  }
}