import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BuildDetails } from '../defs/BuildDetails';

@Injectable({
  providedIn: 'root'
})
export class BuildNumberService {

  baseUrl: string = `${environment.mfServerUrl}`;
  
  constructor(private http: HttpClient) { }

  getBuildDetails(){
    return this.http.get<BuildDetails>(`${this.baseUrl}` + '/api/Home/BuildDetails');
  }

}
