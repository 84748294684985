import { Injectable, Output } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Publisher } from '../defs/Seller';
import { TempAuthRequest } from '../defs/TempAuthRequest';
import { Buyer, Address } from '../defs/Buyer';
import { BuyerSearchResult } from '../defs/SearchBuyer';

@Injectable({
  providedIn: 'root'
})
export class AddaddressService {

  baseUrl : string =  `${environment.identityProviderRootUrl}`;

  constructor(private http: HttpClient) { 
  }
 
  getAddressTypes() {
    let url = `${this.baseUrl}` + "/api/Contact/AddressType";
    return this.http.get(url);
  }

  getStates(countryCode) {
    let url = `${this.baseUrl}` + `/api/Geo/States/${countryCode}`;
    return this.http.get(url);
  }

  getBuyerAddress(buyerid) {
    let url = `${this.baseUrl}` + `/api/Buyer/${buyerid}/Addresses`;
    return this.http.get(url);
  }
  
  addBuyer(buyer: Buyer): Observable<Buyer> {
    let url = `${this.baseUrl}` + `/api/Buyer`;
    return this.http.post<any>(url, buyer);

  }

  addBuyerAddress(buyerid, addressInfo: Address) {
    let url = `${this.baseUrl}` + `/api/Buyer/${buyerid}/Address`;
    return this.http.post<any>(url, addressInfo);

  }

  editBuyerAddress(buyerid, addressInfo: Address) {
    let url = `${this.baseUrl}` + `/api/Address`;
    return this.http.put<any>(url, addressInfo);
  }

  addSellerAddress(sellerid, addressInfo: Address) {
    let url = `${this.baseUrl}` + `/api/seller/${sellerid}/Address`;
    return this.http.post<any>(url, addressInfo);

  }

  editSellerAddress(sellerid, addressInfo: Address) {
    let url = `${this.baseUrl}` + `/api/Address`;
    return this.http.put<any>(url, addressInfo);
  }

}
