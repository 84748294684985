import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BofABatchOfferDetails } from '../defs/BofABatchOfferDetails';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  idSrvBaseUrl = `${environment.identityProviderRootUrl}`;
  magApiBaseUrl = `${environment.mfServerUrl}`

  constructor(private http: HttpClient) { }

  downloadBatch(partnerkey, buyerid: number): Observable<HttpResponse<Blob>> {

    let url = `${this.idSrvBaseUrl}/api/BofABatch/DownloadAccountMasterFile?mainPartnerKey=${partnerkey}`;

    if (buyerid > 0) {
      url = url + `&buyerId=${buyerid}`;
    }

    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' });
  }


  downloadOffers(bofABatchOfferDetails: BofABatchOfferDetails): Observable<HttpResponse<Blob>> {

    let url = `${this.magApiBaseUrl}/api/BofABatch/DownloadOfferDetailsFile`;

    return this.http.post<Blob>(url, bofABatchOfferDetails, { observe: 'response', responseType: 'blob' as 'json' });
  }
}