
import {timer as observableTimer,  Subscription, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    timer: any = observableTimer(3000);

    // values are assigned from modal (AlertService)
    type: string;
    message: string;

  constructor(public modalRef: MDBModalRef) { }

    ngOnInit() {
        setTimeout(() => {
            this.modalRef.hide();
          }, 8000);
    
    }


    get isSuccessMessage(){
        return this.type.toLowerCase() === "success";
    }
}
