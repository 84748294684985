import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Magazine } from '../defs/Magazine';
import { environment } from '../../../../environments/environment';
import { AdMediaKit } from '../model';
import { MigrateMediaKit } from '../defs/MigrateMediaKit';

@Injectable({
  providedIn: 'root'
})
export class MediakitService {

  constructor(private http: HttpClient) { }

  read(magazineId: number): Observable<AdMediaKit> {
    return this.http
      .get<AdMediaKit>(`${environment.mfServerUrl}/api/MediaKit/${magazineId}/MediaKits`);
  }

  create(adMediaKit: AdMediaKit): Observable<AdMediaKit> {
    return this.http.post<AdMediaKit>(`${environment.mfServerUrl}/api/MediaKit`, adMediaKit);
  }

  update(adMediaKit: AdMediaKit) {
    return this.http.put(`${environment.mfServerUrl}/api/MediaKit`, adMediaKit);
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.mfServerUrl}/api/MediaKit/${id}`);
  }
  getMediakit(magazineId: number, mediaKitId: number)  {
    return this.http.get<AdMediaKit>(`${environment.mfServerUrl}/api/MediaKit/${magazineId}/MediaKit/${mediaKitId}`);
  }

  migrate(migrateMediaKit: MigrateMediaKit){
    return this.http.post<MigrateMediaKit>(`${environment.mfServerUrl}/api/MediaKit/Migrate`, migrateMediaKit);
  }

  getActiveMediakits(magazineId: number){
    return this.http.get<Array<AdMediaKit>>(`${environment.mfServerUrl}/api/MediaKit/Migrate/Active/MediaKits/${magazineId}`)
  }

  getInActiveMediakits(magazineId: number){
    return this.http.get<Array<AdMediaKit>>(`${environment.mfServerUrl}/api/MediaKit/Migrate/InActive/MediaKits/${magazineId}`)
  }
}
