import { CHANGE_CLASS_MEDIA } from '../actions';
const INITIAL_iSTATE = 'all';

const mediaClassReducer = (state = INITIAL_iSTATE,action) => {
    switch(action.type){

        case CHANGE_CLASS_MEDIA : {
            let resultObj = action.payload;
            return resultObj;
        }
        default: return state
    }
}

export default mediaClassReducer;