<div class="row">
  <div class="col-12">

    <div class="tab-pane fade in show active" id="MagazineTabPane" role="tabpanel">
      <div class="my-2 mfl-o mf3-center mf-tittle">
        <span class="ml-2">{{message}}</span>
      </div>
      <div class="jumbotron py-3">
        <table class="table table-hover magazinetable">
          <thead>
            <tr>
              <th style="width:8%">Asset Id</th>
              <th style="width:8%">Asset Name</th>
              <th style="width:8%">Asset Uri</th>
              <th style="width:8%">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let asset of assetsData; let i = index" vbn njmhbgvf>
              <td style="width:8%">{{asset.assetId}}</td>
              <td style="width:8%">{{asset.assetName}}</td>
              <td style="width:8%">{{asset.assetUri}} </td>
              <td style="width:8%" class="tittle1"><a (click)="deleteAsset(asset.assetId)"><i
                    class="far fa-trash-alt"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row pb-3">
        <div class="col-sm-12 text-center">
          <button type="button" mdbBtn size="md" class="btn btn-secondary1 secondary relative waves-light"
            (click)="addClick()" mdbWavesEffect>Add {{message}}
          </button>
        </div>
        <!-- <div class=" text-right">
          <button type="submit" (click)="continuechanges()" class="btn mf3-right"
            class="mt-3 btn-secondary secondary relative waves-light btn btn-md" mdbWavesEffect>Add Data</button>
        </div> -->
      </div>
    </div>
  </div>
</div>