import { Component, OnInit, Renderer2, EventEmitter, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ComponentFactory } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { AddMediakitComponent } from '../../../shared/add-mediakit/add-mediakit.component';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';
import { FormBuilder } from '@angular/forms';
import { UploadComponent } from './upload/upload.component';
import { BreadCrumbs } from 'src/app/core/api/defs/BreadCrumbs';
import { BreadCrumbsService } from 'src/app/core/api/services/breadCrumbs.service';

@Component({
  selector: 'app-mediakituploads',
  templateUrl: './mediakituploads.component.html',
  styleUrls: ['./mediakituploads.component.scss']
})
export class MediakituploadsComponent implements OnInit {
  sellerId: number;
  @ViewChild('messagecontainer', /* TODO: add static flag */ {static:true, read: ViewContainerRef }) entry: ViewContainerRef;
  modalRef: MDBModalRef;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  magazineId: number;
  mediakitId: number;
  startUpload: any;
  onUploadOutput: any;
  showFiles: any;
  sellerName: string;
  magazineName: string;
  mediaKitName: string;

  componentRef: any;

  constructor(private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    private breadCrumbsService: BreadCrumbsService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.sellerId = params.sellerId;
      this.magazineId = params.magazineId;
      this.mediakitId = params.mediaKitId;
    });
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(UploadComponent);
    this.componentRef = this.entry.createComponent(factory);
    this.componentRef.instance.message = 'Cover Page';
    this.componentRef.instance.assetsTypeId = 1;
    this.componentRef.instance.mediakitId = this.mediakitId;
    this.componentRef.instance.magazineId = this.magazineId;
    this.componentRef.instance.sellerId = this.sellerId;

    this.breadCrumbsService.getBreadCrumbs(this.sellerId, this.magazineId, this.mediakitId).subscribe((data: BreadCrumbs) => {
      if (data != null) {
        this.sellerName = data.sellerName;
        this.magazineName = data.magazineName;
        this.mediaKitName = data.mediaKitName;
      }
    });
  }

  destroyComponent() {
    this.componentRef.destroy();
  }

  gotomediakit() {
    this.router.navigateByUrl('maintenance/' + this.sellerId + "/magazine/" + this.magazineId + '/Mediakit');
  }

  createComponent(event) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(UploadComponent);
    this.componentRef = this.entry.createComponent(factory);
    this.componentRef.instance.message = event.currentTarget.name;
    this.componentRef.instance.assetsTypeId = event.currentTarget.id;
    this.componentRef.instance.mediakitId = this.mediakitId;
    this.componentRef.instance.magazineId = this.magazineId;
    this.componentRef.instance.sellerId = this.sellerId;
  }

}
