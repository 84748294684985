<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="tab-pane fade show active">
            <div class="container-fluid">
                <div class="breadCrumps">
                    <mdb-breadcrumb customClass="blue-text lighten-4">
                        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance']">
                            <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId]">
                            <mdb-icon fas class="mr-1"></mdb-icon> {{sellerName}}
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId,'magazines']">
                            <mdb-icon fas class="mr-1"></mdb-icon> Magazines
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineId]">
                            <mdb-icon fas class="mr-1"></mdb-icon> {{magazineName}}
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="tittle3"
                            [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineId, 'mediakits']">
                            <mdb-icon fas class="mr-1"></mdb-icon> Mediakits
                        </mdb-breadcrumb-item>
                        <mdb-breadcrumb-item class="active">
                            <mdb-icon fas class="mr-1"></mdb-icon> Mediakit Year - {{mediaKitData?.mediaKitYear}}
                        </mdb-breadcrumb-item>
                    </mdb-breadcrumb>
                </div>

                <div class="tab-pane fade in show active" id="mediaKitInfo" role="tabpanel">
                    <div class="my-2 mfl-o mf3-center mf-tittle">
                        <span class="ml-2">Mediakit Information</span>
                    </div>
                    <div class="jumbotron py-3">
                        <div *ngIf="canShowMediaKitDataCard else noDataBlock">
                            <div class="row">
                                <div class="col-sm-10 my-2">
                                    <!-- <h6>
                                        Mediakit Url:{{mediaKitData?.mediaKitUrl}}
                                    </h6> -->
                                    <h6>
                                        Mediakit Year: {{mediaKitData?.mediaKitYear}}
                                    </h6>
                                </div>
                                <div class="col-sm-2 my-2">
                                    <div class="">
                                        <a (click)="editMediaKitClick(mediaKitData)" title="Edit Mediakit" class="px-1 tittle1">
                                            <mdb-icon fas icon="edit"></mdb-icon>
                                        </a>
                                        <a class="px-1 tittle1" title="File Uploads"
                                            [routerLink]="['/maintenance/seller', sellerId,'magazine', magazineId, 'mediakit', mediaKitData.mediaKitId, 'mediakit-upload']">
                                            <mdb-icon far icon="file"></mdb-icon>
                                        </a>
                                        <a class="px-1 tittle1" title="Data"
                                            [routerLink]="['/maintenance/seller', sellerId,'magazine', magazineId, 'mediakit', mediaKitData.mediaKitId, 'demo-circular-rates']">
                                            <mdb-icon fas icon="table"></mdb-icon>
                                        </a>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #noDataBlock>
                            <div>
                                No Magazine Data found
                            </div>

                        </ng-template>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>