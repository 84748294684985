<div class="modal-content">

  <div class="modal-header">
    <p class="heading lead tittle3 font-weight-bold">
      Decline Offer
    </p>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true tittle3">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="text-center">
      <i class="fas fa-times fa-4x mb-3 animated rotateIn tittle1"></i>
      <p>
        <strong> You are about to decline entire offer. Do you wish to continue declining the entire offer?</strong>
      </p>
    </div>
  </div>

  <div class="modal-footer justify-content-center">
    <a type="button" mdbBtn class="btn-secondary1 secondary relative waves-light mr-4" data-toggle="modal"
      data-target="#basicExample" (click)="showDeclineModal()" mdbWavesEffect>
      Yes, Continue
      <i class="fas fa-times ml-1"></i>
    </a>
    <button type="button" mdbBtn color="primary" outline="true"
      class="relative waves-light btn-outline-primary tc-1 blueborder" mdbWavesEffect (click)="modalRef.hide()">
      Close
    </button>
  </div>

</div>