import * as __model from '../model';


export class shoppingCart {
  pageIndex?: number;
  pageSize?: number;
  count?: number;
  data: data[];
}

export class data {
  sellerName: string;
  magazines:magazines[];
}

export class magazines {
  magazineName?: string;
  wishlistLine?: __model.wishlistLine[];
}

