<div class="mt-4 mb-3">
  <div *ngIf="hasDemographics()">
    <p *ngFor="let data of results">
      <a *ngIf="data.assetTypeId == 3" class="tittle1 font-weight-bold" rel="noopener noreferrer tittle1" (click)="onMedialinkClick(data.url)" style="display:block"> {{data.assetName}}.pdf</a>
    </p>
  </div>
  <div class="tittle1 font-weight-bold" *ngIf="!hasDemographics()">No demographics</div>
</div>


<!--
<div style="margin-top: 30px">
    <a *ngFor="let data of service.demographics" href="{{data.AssetUri}}?sv=2017-11-09&ss=bfqt&srt=sco&sp=rwdlacup&se=2018-11-03T02:18:35Z&st=2018-10-02T18:18:35Z&spr=https&sig=DS7LMPRwJ6KdtJUlbYVQ2RvkET0QA9ZHN69bUI%2FWk68%3D" rel="noopener noreferrer"
    target="_blank" (click)="onMedialinkClick(MediaKitURL)" style="color: #17a2b8; font-size: 12px;display:block">Media Kit</a>
  </div>-->