<div class="modal-header">
  <h5 class="modal-title tittle3" id="exampleModalLabel">Change Seller</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="md-form">
        <input type="text" class="completer-input form-control mdb-autocomplete mb-0"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="searchText" (ngModelChange)="onChange()"
          [mdbAutoCompleter]="auto" placeholder="Enter Seller Name" />
        <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="No results found">
          <mdb-option *ngFor="let option of sellerSearchResultData" [value]="option.SellerName">
            <div class="d-flex flex-column">
              <strong>{{ option.SellerName }}</strong>
            </div>
          </mdb-option>
        </mdb-auto-completer>
        <p></p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="submit" class="btn btn-secondary1" (click)="changedSellerDetail()" mdbWavesEffect>Save changes</button>
  <button type="button" class="btn btn-secondary btn-outline-primary tc-1 blueborder" data-dismiss="modal"
    (click)="modalRef.hide()" mdbWavesEffect>Close</button>
</div>