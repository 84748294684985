<!--Header-->
<div class="modal-header">
  <h4 class="modal-title" id="reviewofferModalLabel">
    <strong class="font-weight-bold tittle3">Review and Confirm Offer</strong>
  </h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-12 px-4">
      <div>
        <strong class="tittle3">Rate Card:&nbsp;</strong>
        <span>
          <strong class="font-weight-bold"> ${{offer?.rate | number: '1.2-2'}}</strong>
        </span>
      </div>

      <div class="mt-3">
        <strong class="tittle3">Ad Type:&nbsp;</strong>
        <span>
          {{offer?.adType}}
        </span>&nbsp;
        <strong class="tittle3">Ad Color:&nbsp;</strong>
        <span>
          {{offer?.adColor}}
        </span>&nbsp;
        <strong class="tittle3">Ad Size:&nbsp;</strong>
        <span>
          {{offer?.adSize}}
        </span>
      </div>

      <div class="mt-3">
        <strong>Your Offer:</strong>&nbsp;
        <span>
          <strong class="font-weight-bold">${{offer?.offeredRate | number: '1.2-2' }}</strong>
        </span>
      </div>

      <div class="mt-3">
        <span *ngIf="offer?.coverDatesVm">
          <strong>Cover Date: &nbsp;</strong>
          <div *ngFor="let dates of offer?.coverDatesVm">{{dates.coverDate}}</div>
        </span>
      </div>

      <div class="mt-3">
        <span>
          <strong>Client/Advertiser Name: &nbsp;</strong>
          <div>{{offer?.clientName}}</div>
        </span>
      </div>

      <div class="mt-3" *ngIf="offer?.instructions">
        <span>
          <strong>Comments: &nbsp;</strong>
          <div>{{offer?.instructions}}</div>
        </span>
      </div>

    </div>
  </div>
</div>
<div class="modal-footer">
  <div>
    By clicking <b>Submit Offer</b>, you are agreeing to and entering into a legally binding contract to
    purchase the item from the seller.
  </div>
</div>
<div class="modal-footer justify-content-center">

  <button type="submit" mdbBtn mdbWavesEffect data-dismiss="modal"
    class="btn-secondary1 secondary relative waves-light mr-4" (click)="submitclick();">
    Submit Offer
  </button>

  <button type="button" mdbBtn color="primary" outline="true"
  class="relative waves-light btn-outline-primary tc-1 blueborder" mdbWavesEffect
    data-dismiss="#modal" (click)="editOffer()">Edit Offer
  </button>
</div>