<form [formGroup]="addContactForm" (ngSubmit)="addContactOnSubmit()">
  <div class="modal-header">
    <div *ngIf="isEdit; else addContactTemplate">
      <h5 class="modal-title tittle3" id="exampleModalLabel">Edit Contact</h5>
    </div>
    <ng-template #addContactTemplate>
      <h5 class="modal-title tittle3" id="exampleModalLabel">Add Contact</h5>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form  mt-0">
          <mdb-select label="Contact Type" mdbValidate formControlName="contactTypeId" [options]="contactTypeOptions">
          </mdb-select>
          <mdb-error *ngIf="contactTypeId.invalid && (contactTypeId.dirty || contactTypeId.touched)">Required
          </mdb-error>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form  mt-0">
          <input mdbInput mdbValidate type="text" formControlName="contactDescription" class="form-control"
            id="contactDescription">
          <label for="contactDescription">Contact Description</label>
          <mdb-error *ngIf="contactDescription.invalid && (contactDescription.dirty || contactDescription.touched)">
            Required
          </mdb-error>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="contactFirstName" class="form-control"
            id="contactFirstName" />
          <label for="contactFirstName">First Name</label>
          <mdb-error *ngIf="contactFirstName.invalid && (contactFirstName.dirty || contactFirstName.touched)">Required
          </mdb-error>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="contactMiddleName" class="form-control"
            id="contactMiddleName">
          <label for="contactMiddleName">Middle Name</label>
          <mdb-error *ngIf="contactMiddleName.invalid && (contactMiddleName.dirty || contactMiddleName.touched)">
            Required
          </mdb-error>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="contactLastName" class="form-control"
            id="contactLastName" />
          <label for="contactLastName">Last Name</label>
          <mdb-error *ngIf="contactLastName.invalid && (contactLastName.dirty || contactLastName.touched)">Required
          </mdb-error>
        </div>
      </div>
    </div>


    <div class="md-form mt-0">
      <mdb-checkbox mdbValidate formControlName="isActive">
        Is Active
      </mdb-checkbox>
      <mdb-error *ngIf="isActive.invalid && (isActive.dirty || isActive.touched)">Required</mdb-error>
    </div>


    <!-- <mdb-accordion [multiple]="false">
      <mdb-accordion-item [collapsed]="true">
        <mdb-accordion-item-head>Email</mdb-accordion-item-head>
        <mdb-accordion-item-body> -->

    <div class="md-form">
      <button type="button" mdbBtn size="md" rounded="true" class="btn-savesubmit btn-secondary1 relative waves-light" mdbWavesEffect
        (click)="addContactEmail()" [disabled]="contactEmail.invalid">Add Email
      </button>
    </div>


    <div formArrayName="email">
      <div *ngFor="let item of contactEmail.controls; let pointIndex=index" [formGroupName]="pointIndex">
        <div class="jumbotron p-3">

          <div>
            <div *ngIf=" item.get('emailId').value > 0 else newEmailBlock">
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                  Email: <b>{{ item.get('email1').value }}</b>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                  Email Description: <b>{{ item.get('emailDescription').value }}</b>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                  IsActive: <b>{{ item.get('isActive').value }}</b>
                </div>
              </div>
            </div>

            <ng-template #newEmailBlock>
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <div class="md-form mt-0">
                    <input mdbInput mdbValidate type="email" formControlName="email1" class="form-control"
                      id="'email1'+ {{pointIndex}}" />
                    <label for="'email1'+ {{pointIndex}}">Email</label>
                    <mdb-error
                      *ngIf="item.get('email1').invalid && (item.get('email1').dirty || item.get('email1').touched)">
                      <span *ngIf="item.get('email1').errors.required">Required</span>
                      <span *ngIf="item.get('email1').errors.email">Invalid Email</span>
                    </mdb-error>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <div class="md-form mt-0">
                    <input mdbInput mdbValidate type="text" formControlName="emailDescription" class="form-control"
                      id="'emailDescription'+ {{pointIndex}}" />
                    <label for="'emailDescription'+ {{pointIndex}}">Email Description</label>
                    <mdb-error
                      *ngIf="item.get('emailDescription').invalid && (item.get('emailDescription').dirty || item.get('emailDescription').touched)">
                      Required</mdb-error>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                  <div class="md-form mt-0">
                    <mdb-checkbox formControlName="isActive">
                      Is Active
                    </mdb-checkbox>
                    <mdb-error
                      *ngIf="item.get('isActive').invalid && (item.get('isActive').dirty || item.get('isActive').touched)">
                      Required</mdb-error>
                  </div>
                </div>
                <div class="col-sm-12 col-md-1 col-lg-1">
                  <div class="md-form mt-2">
                    <i class="fas fa-trash-alt fa-lg red-text" (click)="deleteContactEmail(pointIndex)"></i>
                  </div>
                </div>
              </div>
            </ng-template>
            <!-- {{addContactForm.controls['contactEmail'].controls[pointIndex].invalid}} -->
          </div>
        </div>
      </div>
    </div>

    <div class="md-form">
      <button type="button" mdbBtn size="md" rounded="true" class="btn-savesubmit btn-secondary1 relative waves-light" mdbWavesEffect
        (click)="addContactTelephone()" [disabled]="contactTelephone.invalid">Add Telephone
      </button>
    </div>

    <div formArrayName="telephone">
      <div *ngFor="let item of contactTelephone.controls; let i=index" [formGroupName]="i">
        <div class="jumbotron p-3">

          <div>
            <div *ngIf=" item.get('telephoneId').value > 0 else newTelephoneBlock">
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                  Telephone Type: <b>{{ getTelephoneTypeName( item.get('telephoneTypeId').value) }}</b>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                  Telephone Description: <b>{{ item.get('telephoneDescription').value }}</b>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                  Telephone:
                  <b>({{ item.get('areaCode').value }})-{{item.get('prefix').value}}-{{item.get('lineNumber').value}}
                  </b>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                  Extension: <b>{{ item.get('extension').value }}</b>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                  IsActive: <b>{{ item.get('isActive').value }}</b>
                </div>
              </div>
            </div>

            <ng-template #newTelephoneBlock>
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="md-form mt-0">
                    <mdb-select label="Telephone Type" mdbValidate formControlName="telephoneTypeId"
                      [options]="telephoneTypeOptions">
                    </mdb-select>
                    <mdb-error
                      *ngIf="item.get('telephoneTypeId').invalid && (item.get('telephoneTypeId').dirty || item.get('telephoneTypeId').touched)">
                      Required</mdb-error>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="md-form mt-0">
                    <input mdbInput mdbValidate type="text" formControlName="telephoneDescription" class="form-control"
                      id="'telephoneDescription' + {{i}}">
                    <label for="'telephoneDescription' + {{i}}">Telephone Description</label>
                    <mdb-error
                      *ngIf="item.get('telephoneDescription').invalid && (item.get('telephoneDescription').dirty || item.get('telephoneDescription').touched)">
                      Required</mdb-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <div class="md-form mt-0">
                    <input mdbInput mdbValidate type="text" appPhoneMask formControlName="phone" class="form-control"
                      id="'phone' + {{i}}">
                    <label for="'phone' + {{i}}">Phone</label>
                    <mdb-error
                      *ngIf="item.get('phone').invalid && (item.get('phone').dirty || item.get('phone').touched)">
                      Required</mdb-error>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4">
                  <div class="md-form mt-0">
                    <input mdbInput mdbValidate type="text" formControlName="extension" class="form-control"
                      id="'extension' + {{i}}" pattern="[0-9]*">
                    <label for="'extension' + {{i}}">Extension</label>
                    <mdb-error
                      *ngIf="item.get('extension').invalid && (item.get('extension').dirty || item.get('extension').touched)">
                      Only Numbers</mdb-error>
                  </div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                  <div class="md-form mt-0">
                    <mdb-checkbox mdbValidate formControlName="isActive">
                      Is Active
                      <mdb-error
                        *ngIf="item.get('isActive').invalid && (item.get('isActive').dirty || item.get('isActive').touched)">
                        Required</mdb-error>
                    </mdb-checkbox>
                  </div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                  <div class="md-form mt-0">
                    <div class="md-form mt-2">
                      <i class="fas fa-trash-alt fa-lg red-text" (click)="deleteContactTelephone(i)"></i>
                    </div>
                  </div>
                </div>
              </div>

            </ng-template>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn btn-secondary btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()"
      mdbWavesEffect>Close</button>
  </div>
</form>