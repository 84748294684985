<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="tab-pane fade show active">
            <div class="container-fluid">
                <div class="tab-pane fade in show active " id="panelSummary" role="tabpanel">
                    <div class="my-2 mt-3 mfl-o mf3-center mf-tittle">
                        <span class="ml-2">Download Offers Details Batch File</span>
                    </div>
                    <div class="jumbotron" style="padding:1.5rem">
                        <form [formGroup]="addDownloadOfferBatchForm" (ngSubmit)="addDownloadOfferBatchFormOnSubmit()">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 mt-1">
                                    <div class="md-form mt-0">
                                        <input mdbInput mdbValidate type="text" formControlName="mainPartnerKey"
                                            class="form-control" id="mainPartnerKey" />
                                        <label for="mainPartnerKey">Main Partner Key</label>
                                        <mdb-error
                                            *ngIf="mainPartnerKey.invalid && (mainPartnerKey.dirty || mainPartnerKey.touched)">
                                            <span *ngIf="mainPartnerKey.errors.required">Required</span>
                                            <span *ngIf="mainPartnerKey.errors.maxlength">Exceeds maximum length</span>
                                        </mdb-error>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 mt-1">
                                    <div class="md-form mt-0">
                                        <input mdbInput mdbValidate type="text" formControlName="offerNumber"
                                            class="form-control" id="offerNumber" />
                                        <label for="offerNumber">Offer Number</label>
                                        <mdb-error
                                            *ngIf="offerNumber.invalid && (offerNumber.dirty || offerNumber.touched)">
                                            <span *ngIf="offerNumber.errors.required">Required</span>
                                        </mdb-error>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-6 mt-1">
                                    <div class="md-form mt-0">
                                        <input mdbInput mdbValidate type="text" formControlName="invoiceNumber"
                                            class="form-control" id="invoiceNumber" />
                                        <label for="invoiceNumber">Invoice Number</label>
                                        <mdb-error
                                            *ngIf="invoiceNumber.invalid && (invoiceNumber.dirty || invoiceNumber.touched)">
                                            <span *ngIf="invoiceNumber.errors.required">Required</span>
                                        </mdb-error>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 mt-1">
                                    <div class="md-form mt-0">
                                        <input mdbInput mdbValidate type="text" formControlName="invoiceDescription"
                                            class="form-control" id="invoiceDescription" />
                                        <label for="invoiceDescription">Invoice Description</label>
                                        <mdb-error
                                            *ngIf="invoiceDescription.invalid && (invoiceDescription.dirty || invoiceDescription.touched)">
                                            <span *ngIf="invoiceDescription.errors.maxlength">Excees maximum
                                                length</span>
                                        </mdb-error>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-4 mt-1">
                                    <div class="md-form mt-0">
                                        <mdb-date-picker mdbValidate name="billingDate" formControlName="billingDate"
                                            [options]="myDatePickerOptions" label="Billing Date" required>
                                        </mdb-date-picker>
                                        <mdb-error
                                            *ngIf="billingDate.invalid && (billingDate.dirty || billingDate.touched)">
                                            <span *ngIf="billingDate.errors.required">Required</span>
                                        </mdb-error>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4 mt-1">
                                    <div class="md-form mt-0">
                                        <mdb-date-picker name="paymentDueDate" formControlName="paymentDueDate"
                                            [options]="myDatePickerOptions" label="Payment Due Date"></mdb-date-picker>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4 mt-1">
                                    <div class="md-form mt-0">
                                        <mdb-select label="Is Partial Invoice Payment Eligible?" mdbValidate
                                            formControlName="partialInvoicePaymentEligiblityIndicator"
                                            [options]="partialInvoicePaymentEligiblityIndicatorOptions">
                                        </mdb-select>
                                    </div>
                                </div>

                            </div>

                            <div class="row col-sm-12 justify-content-center">
                                <button class="btn-secondary1 border-radius btn-md waves-light btn" type="submit"
                                    mdbWavesEffect>Download</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>