import * as MagazineSearchActions from '../actions/magazine-search.action'

export interface State {
  count: number;
  isFetching: boolean;
  lastUpdated: number;
  results: {}[];
}

export const InitialState: State = {
  count: -1,
  isFetching: false,
  lastUpdated: 0,
  results: []
};

export function reducer(state = InitialState, action: MagazineSearchActions.Actions) {

  switch (action.type) {
    case MagazineSearchActions.SEARCH_RESULTS:
      return { ...state, count: action.payload.count, results: action.payload.results, lastUpdated: action.payload.date }
      case MagazineSearchActions.SEARCH_RESET:
       state = InitialState;
      return state;
    default:
      return state;
  }
}