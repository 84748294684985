import {CHANGE_EDUCATION_LEVEL,
    CHANGE_EDUCATION_LEVEL_ALL,
    CHANGE_EDUCATION_LEVEL_DESELECT,
    CHANGE_EDUCATION_LEVEL_DESELECT_ALL} from '../actions'

const educationLevelReducer = (state = [],action) => {
    switch(action.type){

        case CHANGE_EDUCATION_LEVEL: {
            let result =  [...state,action.payload]
            return result;
        }
        case CHANGE_EDUCATION_LEVEL_ALL : {
            let result = action.payload
            return result;
        }
      
        case CHANGE_EDUCATION_LEVEL_DESELECT_ALL: {
            return []
        }
        default: return state;
    }
}

export default educationLevelReducer;