import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { AddcontactService } from 'src/app/core/api/services/addcontact.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { TelephoneType, MdbSelectOptions, ContactType, Contacts } from 'src/app/core/api/model';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html'
})
export class AddContactComponent implements OnInit {

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private addContactService: AddcontactService,
    private alertService: AlertService, 
    private authService: AuthService) {

  }

  telephoneTypeOptions: Array<any>;
  contactTypeOptions: Array<any>;

  // values are assiged from 'data' property in modal service.
  id: number;
  
  type: string;
  
  data: Contacts;
  
  isEdit: boolean;
  
  userName: any;
  
  currentDate: Date;
  
  action: Subject<any> = new Subject();

  isAuthenticated: boolean;

  subscription: Subscription;

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if(this.isAuthenticated){
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();

    // API to fetch the telephone types
    this.getTelephoneTypes();

    // API to fetch the contact types
    this.getContactTypes();

    this.loadContactData();
  }

  addContactForm = this.fb.group({
    contactId: [''],
    contactTypeId: ['', Validators.required],
    contactDescription: [''],
    contactTitle: [''],
    contactFirstName: ['', Validators.required],
    contactMiddleName: [''],
    contactLastName: ['', Validators.required],
    isActive: [true, Validators.required],
    rowVersion: [''],
    email: this.fb.array([
      this.addEmailFormGroup()
    ]),
    telephone: this.fb.array([
      this.addTelephoneFormGroup()
    ]),
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
  });

  // Properties to use in html
  get contactId() { return this.addContactForm.get('contactId'); }
  get contactTypeId() { return this.addContactForm.get('contactTypeId'); }
  get contactDescription() { return this.addContactForm.get('contactDescription'); }
  get contactTitle() { return this.addContactForm.get('contactTitle'); }
  get contactFirstName() { return this.addContactForm.get('contactFirstName'); }
  get contactMiddleName() { return this.addContactForm.get('contactMiddleName'); }
  get contactLastName() { return this.addContactForm.get('contactLastName'); }
  get isActive() { return this.addContactForm.get('isActive'); }
  get rowVersion() { return this.addContactForm.get('rowVersion'); }
  get createdOn() { return this.addContactForm.get('createdOn'); }
  get createdBy() { return this.addContactForm.get('createdBy'); }
  get modifiedOn() { return this.addContactForm.get('modifiedOn'); }
  get modifiedBy() { return this.addContactForm.get('modifiedBy'); }

  addEmailFormGroup(): FormGroup {
    return this.fb.group({
      emailId: [''],
      emailDescription: [''],
      email1: ['', [Validators.email, Validators.required]],
      isActive: [true, Validators.required],
      rowVersion: [''],
      createdOn: [''],
      createdBy: [''],
      modifiedOn: [''],
      modifiedBy: [''],
    });
  }

  addTelephoneFormGroup(): FormGroup {
    return this.fb.group({
      telephoneId: [''],
      countryCode: [''],
      phone:['',[Validators.pattern(/^\(\d{3}\)-\d{3}-\d{4}$/),Validators.required]],
      areaCode: [''],
      prefix: [''],
      lineNumber: [''],
      extension: ['', Validators.pattern('[0-9]*')],
      telephoneDescription: [''],
      telephoneTypeId: ['', Validators.required],
      isActive: [true, Validators.required],
      rowVersion: [''],
      createdOn: [''],
      createdBy: [''],
      modifiedOn: [''],
      modifiedBy: [''],
    });
  }

   get contactEmail() {
    return this.addContactForm.get('email') as FormArray;
  }

  addContactEmail() {
    this.contactEmail.push(this.addEmailFormGroup());
  }

  deleteContactEmail(index) {
    if (this.contactEmail.length > 1)
      this.contactEmail.removeAt(index);
  }

  get contactTelephone() {
    return this.addContactForm.get('telephone') as FormArray;
  }

  addContactTelephone() {
    this.contactTelephone.push(this.addTelephoneFormGroup());
  }

  deleteContactTelephone(index) {
    if (this.contactTelephone.length > 1)
      this.contactTelephone.removeAt(index);
  }
  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
      else if (control instanceof FormArray) {
        for (const control1 of control.controls) {
          if (control1 instanceof FormControl) {
            control1.markAsTouched({
              onlySelf: true
            });
          }
          if (control1 instanceof FormGroup) {
            this.validateAllFormFields(control1);
          }
        }
      }
    });
  }

  setphonevalues(){
    let arr = this.addContactForm.get('telephone').value;
    arr.forEach(element => {
      let ele = element.phone;
      let number = ele.toString();
      element.areaCode = number.substring(1,4);
      element.prefix = number.substring(6,9);
      element.lineNumber = number.substring(10,14);
      this.addContactForm.get('telephone').patchValue(arr);
    });
  }

  setuserDetailsForTelephone() {
    let arr = this.addContactForm.get('telephone').value;
    arr.forEach(element => {
      element.createdBy = this.userName;
      element.modifiedBy = this.userName;
      element.createdOn = this.currentDate;
      element.modifiedOn = this.currentDate;
      this.addContactForm.get('telephone').patchValue(arr);
    });
  }

  setuserDetailsForEmail() {
    let arr = this.addContactForm.get('email').value;
    arr.forEach(element => {
      element.createdBy = this.userName;
      element.modifiedBy = this.userName;
      element.createdOn = this.currentDate;
      element.modifiedOn = this.currentDate;
      this.addContactForm.get('email').patchValue(arr);
    });
  }
  addContactOnSubmit() {
    this.validateAllFormFields(this.addContactForm);

    if (this.addContactForm.valid) {

      if (this.type == "seller") {
        if (this.isEdit) {
          this.updateUserDetails();
          this.editSellerContact();
        }
        else {
          this.addUserDetails();
          this.setuserDetailsForEmail();
          this.setphonevalues();
          this.setuserDetailsForTelephone();
          this.addSellerContact();
        }
      }
      else if (this.type == "buyer") {
        if (this.isEdit) {
          this.updateUserDetails();
          this.editBuyerContact();
        }
        else {
          this.addUserDetails();
          this.setuserDetailsForEmail();
          this.setphonevalues();
          this.setuserDetailsForTelephone();
          this.addBuyerContact();
        }
      }
    }
  }

  getTelephoneTypes() {
    this.addContactService.getTelephoneTypes().subscribe((data: Array<TelephoneType>) => {
      this.telephoneTypeOptions = data.map((telephoneType) => {
        let option = new MdbSelectOptions();
        option.value = telephoneType.telephoneTypeId;
        option.label = telephoneType.telephoneTypeName;
        return option;
      })
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  getTelephoneTypeName(telephoneTypeId: number) {
    if (this.telephoneTypeOptions != null) {
      let option = this.telephoneTypeOptions.find((x: MdbSelectOptions) => {
        return x.value == telephoneTypeId;
      });

      return option.label;
    }

    return null;
  }

  getContactTypes() {
    this.addContactService.getContactTypes().subscribe((data: Array<ContactType>) => {
      if (data != null) {
        this.contactTypeOptions = data.map((contactType) => {

          let option = new MdbSelectOptions();
          option.label = contactType.contactTypeName;
          option.value = contactType.contactTypeId;

          return option;
        });
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  loadContactData() {
    if (this.data != null && this.data != undefined) {
      if (this.data.email.length > 1) {
        for (let index = 1; index < this.data.email.length; index++) {
          this.addContactEmail();
        }
      }

      if (this.data.telephone.length > 1) {
        for (let index = 1; index < this.data.telephone.length; index++) {
          this.addContactTelephone();
        }
      }

      this.addContactForm.patchValue(this.data);
    }
  }

  addSellerContact() {
    this.addContactService.addSellerContact(this.id, this.addContactForm.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Contact added successfully');

        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  addBuyerContact() {
    this.addContactService.addBuyerContact(this.id, this.addContactForm.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Contact added successfully');

        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  editSellerContact() {
    this.addContactService.editSellerContact(this.id, this.addContactForm.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Contact updated successfully');

        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  editBuyerContact() {
    this.addContactService.editBuyerContact(this.id, this.addContactForm.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Contact updated successfully');

        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }
}
