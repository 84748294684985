

<div>
  
  <div class="row m-2 py-2" *ngIf="displayPagination()">
    {{ skip$ | async}} - {{ take$ | async}} of {{ resultsCount$ | async}}
  </div>

  <div class="row justify-content-center" *ngIf="displayPagination()">
    <app-mf-pagination [TotalCount]="count" [PageSize]="50" [CurrentPageIndex]="currentPageIndex"
    (OnPageChanged)="mfPaginationOnPageChanged($event)"></app-mf-pagination>
  </div>
  
  <div class="row p-2" *ngFor="let magazine of displayResults">

    <div class="col-xs-12 col-sm-12 col-md-2 text-center py-2" [routerLink]="['/browse/magazine-details', magazine.MagazineId, 'demographics']" >
      <img class="img-responsive img-fluid" src="{{magazine.CoverThumbnailUrl}}" alt="image not found"
        style="width:75%!important">
    </div>

    <div class="col-xs-12 col-sm-12 col-md-10 py-2">
      <h4>
        <span href="" style="color: #111">
          <span [routerLink]="['/browse/magazine-details', magazine.MagazineId, 'demographics']" class="mainTittle"
            style="display:inline-block;margin-right:1%; cursor: pointer;">{{magazine.MagazineName}}</span>
          <a *ngIf="magazine.MediaKitURL" rel="noopener noreferrer" (click)="onMedialinkClick(magazine.MediaKitURL)"
            style="color: #17a2b8; font-size: 12px">Media Kit</a>
        </span>
      </h4>

      <div class="row">
        <div class="col-sm-12">
          <span class="tittle1">Publisher Name: </span>
          <span>{{magazine.PublisherName}}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <span class="tittle1">Category(s): </span>
          <span>{{magazine.MarketCategories}}</span>
        </div>
      </div>

      <div *ngIf="magazine.Circulation">
        <span class="tittle1">Circulation: </span>
        <span>{{magazine.Circulation | number}}</span>
        <div class="fa fa-info-circle px-1" aria-hidden="true">
          <span class="info bordered"> Circulation shown may be either Reported Per Issue or Audited.</span>
        </div>
      </div>

      <div id="summary">
        <span class="collapse" id="collapseSummary">
          <span class=" tittle1">Description: </span> {{magazine.PublisherStatement}}</span>
      </div>

    </div>
  </div>

  <div class="row justify-content-center pb-4" *ngIf="displayPagination()">
    <app-mf-pagination [TotalCount]="count" [PageSize]="50" [CurrentPageIndex]="currentPageIndex"
    (OnPageChanged)="mfPaginationOnPageChanged($event)"></app-mf-pagination>
  </div>

  <div class="row p-2 my-5" *ngIf="showNoResultSection()">
    <div class="col-sm-12 text-center my-5">
      No Results Found. Please try again with different search criteria. 
    </div>
  </div>

</div>