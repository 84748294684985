import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { MagazineSearch } from '../../../core/search/models/magazine-search.model';
import * as MagazineSearchActions from '../../../core/search/actions/magazine-search.action';
import * as fromRoot from '../../../core/search/reducers/searchstate.reducer';
import { ViewrateService } from '../../../core/api/services/viewrate.service'

@Component({
  selector: 'app-displayresults',
  templateUrl: './displayresults.component.html',
  styleUrls: ['./displayresults.component.scss']
})
export class DisplayresultsComponent implements OnInit {
  resultsCount$: Observable<number>;
  skip$: Observable<number>;
  take$: Observable<number>;
  count: number;
  currentPageIndex: number = 1;
  index$: Observable<string>;
  results$: Observable<MagazineSearch.SearchResults>;

  displayResults: {}[];
  //adTypesValues$: Observable<MagazineSearch.Facet[]>;
  constructor(private store: Store<fromRoot.State>) {

    this.index$ = store.pipe(select(fromRoot.getIndex));
    this.results$ = store.pipe(select(fromRoot.getResultsState));
    //this.adTypesValues$ = store.pipe(select(fromRoot.getMultiSelectValues, { name: "AdTypes" }));
    this.resultsCount$ = store.pipe(select(fromRoot.getResultsCount));
    this.skip$ = store.pipe(select(fromRoot.getSkip));
    this.take$ = store.pipe(select(fromRoot.getTake));

    this.skip$.subscribe(x => {
      if (x == 1)
        this.currentPageIndex = 1;
    })

    this.results$.subscribe((data) => {
      this.displayResults = data.results;
    });

    this.resultsCount$.subscribe((countVal) => {
      this.count = countVal;
    });
  }

  ngOnInit() {
  }

  mfPaginationOnPageChanged(currentPageNumber: any) {
    this.currentPageIndex = currentPageNumber;
    this.gotoPage(currentPageNumber);
  }

  gotoPage(page: number) {
    this.store.dispatch(new MagazineSearchActions.SearchPaginationSetPage({ page: page }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop();
  }

  displayPagination() {
    return this.count > 0;
  }

  showNoResultSection() {
    return this.displayResults.length == 0;
  }

  goToTop() {
    window.scrollTo(0, 0);
  }

  onMedialinkClick(MediaKitURL) {
    let url = MediaKitURL
    let w = window.open(url, "MediaKitPopup", "height=600,width=800");
    w.focus();
  }
}
