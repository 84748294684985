<form [formGroup]="addRatesForm" (ngSubmit)="addMagazinesOnSubmit()">
  <div class="modal-header">
    <div *ngIf="isEdit; else addMagazineTemplate">
      <h5 class="modal-title tittle3" id="exampleModalLabel">Edit Rates</h5>
    </div>
    <ng-template #addMagazineTemplate>
      <h5 class="modal-title tittle3" id="exampleModalLabel">Add Rates</h5>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-xs-12 col-sm-6 ">
        <div class="md-form mt-0">
          <mdb-select [options]="adTypeOptions" mdbValidate formControlName="adTypeId" label="Ad Type"></mdb-select>
          <mdb-error *ngIf="adTypeId.invalid && (adTypeId.dirty || adTypeId.touched)">
            <span *ngIf="adTypeId.errors.required">Required</span>
          </mdb-error>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="md-form mt-0">
          <mdb-select [options]="adColorOptions" mdbValidate formControlName="adColorId" label="Ad Color"></mdb-select>
          <mdb-error *ngIf="adColorId.invalid && (adColorId.dirty || adColorId.touched)">
            <span *ngIf="adColorId.errors.required">Required</span>
          </mdb-error>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="md-form mt-0">
          <mdb-select [options]="adPlacementOptions" mdbValidate formControlName="adPlacementId" label="Ad Placement">
          </mdb-select>
          <mdb-error *ngIf="adPlacementId.invalid && (adPlacementId.dirty || adPlacementId.touched)">
            <span *ngIf="adPlacementId.errors.required">Required</span>
          </mdb-error>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="md-form mt-0">
          <mdb-select [options]="AdOrientationOptions" mdbValidate [multiple]="false" formControlName="adOrientationId"
            label="Ad Orientation"></mdb-select>
          <mdb-error *ngIf="adOrientationId.invalid && (adOrientationId.dirty || adOrientationId.touched)">
            <span *ngIf="adOrientationId.errors.required">Required</span>
          </mdb-error>
        </div>

      </div>
    </div>
   
    <div class="row">
      <div class="col-xs-12 col-sm-6 ">
        <div class="md-form mt-0">
          <mdb-select [options]="AdUnitOptions" mdbValidate [multiple]="false" formControlName="adUnitId"
            label="Ad Unit">
          </mdb-select>
          <mdb-error *ngIf="adUnitId.invalid && (adUnitId.dirty || adUnitId.touched)">
            <span *ngIf="adUnitId.errors.required">Required</span>
          </mdb-error>
        </div>
      </div>
      
      <div class="col-xs-12 col-sm-6">
        <div class="md-form mt-0">
          <mdb-select [options]="AdPrintSourceOptions" mdbValidate [multiple]="false" formControlName="adPrintSourceId"
            label="Ad Print Source"></mdb-select>
          <mdb-error *ngIf="adPrintSourceId.invalid && (adPrintSourceId.dirty || adPrintSourceId.touched)">
            <span *ngIf="adPrintSourceId.errors.required">Required</span>
          </mdb-error>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-sm-12 col-md-8 col-lg-6">
        <div class="md-form mt-0">
          <mdb-select [options]="quantityOptions" mdbValidate [multiple]="false" formControlName="qty" label="Quantity">
          </mdb-select>
          <mdb-error *ngIf="qty.invalid && (qty.dirty || qty.touched)">
            <span *ngIf="qty.errors.required">Required </span>
          </mdb-error>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form ml-2 mt-0">
          <mdb-checkbox mdbValidate formControlName="isBleed">
            Is Bleed
          </mdb-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" name="rate" formControlName="rate" class="form-control"
            (focusout)="setTwoNumberDecimal($event)" />
          <label for="rate">Rate</label>
          <mdb-error *ngIf="rate.invalid && (rate.dirty || rate.touched)">
            <span *ngIf="rate.errors.pattern">Please Enter Valid Rate </span>
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" name="adSizeDescription" formControlName="adSizeDescription"
            class="form-control" id="adSizeDescription" />
          <label for="rate">AdSize Description</label>
          <mdb-error *ngIf="adSizeDescription.invalid && (adSizeDescription.dirty || adSizeDescription.touched)">
            <span *ngIf="adSizeDescription.errors.pattern">Please Enter Valid AdSize Description </span>
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="md-form mt-0">
          <div class="md-form  mt-0">
            <mdb-checkbox mdbValidate formControlName="isActive">
              Is Active
              <mdb-error *ngIf="isActive.invalid && (isActive.dirty || isActive.touched)">
                Required
              </mdb-error>
            </mdb-checkbox>
          </div>
        </div>
    </div>
  </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn waves-light btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()"
    mdbWavesEffect>Close</button>
  </div>
</form>