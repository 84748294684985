<nav aria-label="MediaFiche Page Navigation" *ngIf="isPaginationVisible">
    <ul class="pagination pg-teal justify-content-center">

        <li class="page-item" [ngClass]="isPreviousDisabled ? 'disabled' : ''" >
            <a class="page-link" (click)="previousOnClick()" mdbWavesEffect>Previous</a>
        </li>

        <li class="page-item" *ngFor="let p of getPageNumbers(); let i= index"
            [ngClass]="CurrentPageIndex == p ? 'active': ''">
            <div *ngIf="p == '...'; else linkBlock" >{{p}}</div>
            <ng-template #linkBlock>
                <a class="page-link" (click)="pageChangeOnClick(p)" mdbWavesEffect>{{p}}</a>
            </ng-template>
            
        </li>

        <li class="page-item" [ngClass]="isNextDisabled ? 'disabled' : ''" >
            <a class="page-link" (click)="nextOnClick()" mdbWavesEffect>Next</a>
        </li>
    </ul>
</nav>