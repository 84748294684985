
import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/catch";
import { AuthService } from '../auth/service/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(public authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.includes("mfassets.blob.core.windows.net") || request.url.includes("BuildDetails")) {
            return next.handle(request);
        }
        else {
            if(this.authService.isLoggedIn() && this.authService.isAuthenticated()){
                request = request.clone({
                    setHeaders:{
                        Authorization: this.authService.getAuthorizationHeaderValue()
                    }
                });           
                return next.handle(request);      
            }
            

            //return next.handle(
                // this.addAuthenticationToken(request)).pipe(catchError(error => {
                //     // We don't want to refresh token for some requests like login or refresh token itself
                //     // So we verify url and we throw an error if it's the case
                //     if (
                //         request.url.includes(environment.loginUrl) ||
                //         request.url.includes(environment.refreshTokenUrl)
                //     ) {
                //         // We do another check to see if refresh token failed
                //         // In this case we want to logout user and to redirect it to login page

                //         if (request.url.includes("refreshtoken")) {
                //             this.auth.logout();
                //         }

                //         return throwError(error);
                //     }

                //     // If error status is different than 401 we want to skip refresh token
                //     // So we check that and throw the error if it's the case
                //     if (error !== "Unauthorized") {
                //         return throwError(error);
                //     }

                //     if (this.refreshTokenInProgress) {
                //         // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                //         // – which means the new token is ready and we can retry the request again
                //         return this.refreshTokenSubject.pipe(
                //             filter(result => result !== null),
                //             take(1),
                //             switchMap(() => next.handle(this.addAuthenticationToken(request))));
                //     } else {
                //         this.refreshTokenInProgress = true;

                //         // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                //         this.refreshTokenSubject.next(null);

                //         // Call auth.refreshAccessToken(this is an Observable that will be returned)
                //         return this.auth
                //             .refresh().pipe(
                //                 switchMap((response: any) => {
                //                     //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                //                     // for the next time the token needs to be refreshed
                //                     this.refreshTokenInProgress = false;
                //                     this.refreshTokenSubject.next(response);
                //                     if (response && response.refreshToken) {
                //                         // store user details and jwt token in local storage to keep user logged in between page refreshes
                //                         localStorage.setItem('currentUser', JSON.stringify(response));
                //                     }
                //                     return next.handle(this.addAuthenticationToken(request));
                //                 })).pipe(catchError((err: any) => {
                //                     this.refreshTokenInProgress = false;
                //                     //this.auth.logout();
                //                     return throwError(error);
                //                 }));
                //     }
                 //})
                 //);
        }
    }

    // addAuthenticationToken(request) {
    //     // Get access token from Local Storage
    //     const accessToken = this.auth.getAccessToken()

    //     // If access token is null this means that user is not logged in
    //     // And we return the original request
    //     if (!accessToken) {
    //         return request;
    //     }
    //     // We clone the request, because the original request is immutable
    //     return request.clone({
    //         setHeaders: {
    //             Authorization: `Bearer ${this.auth.getAccessToken()}`
    //         }
    //     });
    // }

    // addBlobRequest(request) {
    //     return request;
    // }
}
