import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { PasswordresetService } from 'src/app/core/api/services/passwordreset.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  constructor(private formBuilder: FormBuilder, 
    private passwordResetService: PasswordresetService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get email() { return this.forgotPasswordForm.get('email'); }

  validateForm() {
    Object.keys(this.forgotPasswordForm.controls).forEach(field => {
      const control = this.forgotPasswordForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  onSubmit() {
    this.validateForm();

    if (this.forgotPasswordForm.invalid) {
      return;
    }

    let email =  this.forgotPasswordForm.value.email;

    this.passwordResetService.read(email).subscribe(data => {
      this.alertService.success(data.message);
    }, err => {
      this.alertService.error(err);
    });
  }

}
