import { Component, OnInit, Renderer2 } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { AddMediakitComponent } from '../../../shared/add-mediakit/add-mediakit.component';
import { MediakitService } from '../../../core/api/services/mediakit.service';
import { AdMediaKit } from '../../../core/api/model'
import { BreadCrumbs } from 'src/app/core/api/defs/BreadCrumbs';
import { BreadCrumbsService } from 'src/app/core/api/services/breadCrumbs.service';
import { DeleteConfirmComponent } from 'src/app/shared/delete-confirm/delete-confirm.component';
import { MigrateMediaKitComponent } from '../migrate-media-kit/migrate-media-kit.component';

@Component({
  selector: 'app-mediakit',
  templateUrl: './mediakit.component.html',
  styleUrls: ['./mediakit.component.scss']
})
export class MediakitComponent implements OnInit {
  sellerId: number;
  sellerName: string;
  magazineId: number;
  magazineName: string;
  modalRef: MDBModalRef;
  mediakits: AdMediaKit;
  showMediaList: boolean = false;

  constructor(private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private mediakitService: MediakitService,
    private breadCrumbsService: BreadCrumbsService) {
  }
  optionsSelect: Array<any>;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.sellerId = params.sellerId;
      this.magazineId = params.magazineId;
    });

    this.getMediaKit(this.magazineId);

    this.breadCrumbsService.getBreadCrumbs(this.sellerId, this.magazineId).subscribe((data: BreadCrumbs) => {
      if (data != null) {
        this.sellerName = data.sellerName;
        this.magazineName = data.magazineName;
      }
    });
  }

  mediakitAssestsClick(mediakitId) {
    //this.router.navigateByUrl('browse/magazinedetails/' + selectedmagazine);
    this.router.navigateByUrl('maintenance/' + this.sellerId + '/magazine/' + this.magazineId + '/mediakit/' + mediakitId + '/mediakit-upload');
  }

  getMediaKit(magazineId: number) {
    this.mediakitService.read(magazineId).subscribe((data: AdMediaKit) => {
      this.mediakits = data;
      this.showMediaList = true;
    })
  }

  addMediakitClick() {
    this.modalRef = this.modalService.show(AddMediakitComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.magazineId,
        isEdit: false
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {

      // The values are sent in json format
      if (result.type) {
        this.getMediaKit(this.magazineId);

      }
    });
  }

  deleteMediakit(mediakitid) {
    this.modalRef = this.modalService.show(DeleteConfirmComponent, {
      class: 'modal-sm modal-notify modal-warning',
      ignoreBackdropClick: true,
      data: {
        deleteName: 'Media Kit'
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result.status == 'delete') {
        this.mediakitService.delete(mediakitid).subscribe((response: any) => {
          this.alertService.success("Mediakit Deleted Successfully");
          this.getMediaKit(this.magazineId);
        }, (errMsg) => {
          this.alertService.error(errMsg);
        });
      }
    });
  }

  migrateMediaKitClick() {
    this.modalRef = this.modalService.show(MigrateMediaKitComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        magazineId: this.magazineId
      }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.getMediaKit(this.magazineId);
    });
  }

  goToMediaKit(mediaKitId) {
    this.router.navigateByUrl("/maintenance/seller/" + this.sellerId + "/magazine/" + this.magazineId + "/mediakit/" + mediaKitId);
  }
}