<!--Header-->
<form [formGroup]="createOfferForm" (ngSubmit)="createOfferFormOnSubmit()">
  <div class="modal-header">
    <h4 class="modal-title" id="makeofferModalLabel">
      <strong class="font-weight-bold tittle3">Make Offer</strong>
    </h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 px-4">

        <div>
          <strong class="tittle3">Rate Card:&nbsp;</strong>
          <span>
            <strong class="font-weight-bold">${{selectedAdPrice | number: '1.2-2'}}</strong>
          </span>
        </div>

        <div class="mt-3">
          <div class="md-form input-group mb-3">
            <strong style="margin-top: 5px;">Your Rate</strong>
            <div class="input-group-prepend">
              <span class="input-group-text md-addon">$</span>
            </div>
            <input tabindex="1" mdbInput mdbValidate formControlName="offeredRate" required type="number"
              class="form-control pl-0 rounded-0" id="offeredRate" name="offeredRate" required />

            <mdb-error style="margin-left: 100px;"
              *ngIf="offeredRate.invalid && (offeredRate.dirty || offeredRate.touched)">
              Required
            </mdb-error>
            <strong style="margin-top: 5px;"> per insertions </strong>
          </div>
        </div>

        <div class="mt-4">
          <span *ngIf="showDatesAvailable">
            <label class="tittle3">Cover Date</label>
            <ng-multiselect-dropdown  #multiSelect [placeholder]="'Select'" required [settings]="dropdownSettings"
              [data]="issuedata" [(ngModel)]="coverDatesVm" formControlName="coverDatesVm"
              [disabled]="false" required>
            </ng-multiselect-dropdown>
          </span>
          <span *ngIf="!showDatesAvailable">
            Cover Dates are not Available for this Magazine. Please enter date you want to run Your Ad.
            <mdb-date-picker (inputFieldChanged)="onInputFieldChange($event)" tabindex="2" name="adRunDate"
              [options]="myDatePickerOptions" [placeholder]="'Select date'" [(ngModel)]="Datepick"
             formControlName="coverDatesVm" required></mdb-date-picker>
          </span>
          <div style="color: #f44336" *ngIf=" f.coverDatesVm.invalid && ( f.coverDatesVm.dirty ||  f.coverDatesVm.touched)">
                Select Cover Dates
            </div>
        </div>

        <div class="mt-4">
          <div class="md-form">
            <input tabindex="3" type="text" formControlName="clientName" name="clientName" id="clientName"
              class="form-control" />
            <label for="clientName">Client/Advertiser Name</label>
          </div>
        </div>
        <div class="mt-3">
          <mdb-checkbox mdbValidate formControlName="isFinalOffer">
            Is Best and Final
          </mdb-checkbox>
        </div>
        <div class="">
          <div class="md-form">
            <textarea tabindex="4" formControlName="instructions" type="text" name="instructions" id="instructions"
              class="md-textarea form-control" mdbInput></textarea>
            <label for="instructions">Add Comments</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-center">
    <button type="submit" mdbBtn mdbWavesEffect data-dismiss="modal"
      class="btn-secondary1 secondary relative waves-light mr-4">
      Review Offer <i class="far fa-file-pdf ml-2"></i>
    </button>
    <!-- <button mdbBtn type="submit" rounded="true" color="indigo" size="md" class="waves-light" mdbWavesEffect>
      Review Offer
    </button> -->
    <button type="button" mdbBtn color="primary" outline="true"
      class="relative waves-light btn-outline-primary tc-1 blueborder" mdbWavesEffect
      (click)="modalRef.hide()">Close</button>
  </div>
</form>