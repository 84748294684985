import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { Circulation } from 'src/app/core/api/model';
import { CirculationService } from 'src/app/core/api/services/circulation.service';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-add-circulation',
  templateUrl: './add-circulation.component.html',
  styleUrls: ['./add-circulation.component.scss']
})
export class AddCirculationComponent implements OnInit {

  id: number;
  isEdit: boolean;
  type: string;
  circulation: Circulation;
  userName: string;
  currentDate: Date;

  action: Subject<any> = new Subject();

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(private fb: FormBuilder,
    private alertService: AlertService,
    public modalRef: MDBModalRef,
    private circulationService: CirculationService, 
    private authService: AuthService) { }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if(this.isAuthenticated){
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();
    
    this.loadDataOnInit();
  }

  loadDataOnInit() {

    setTimeout(() => {
      if (this.circulation != null || this.circulation != undefined) {
        this.addCirculationForm.patchValue(this.circulation);
      }
    });

    this.mediaKitId.patchValue(this.id);
  }


  addCirculationForm = this.fb.group({
    circulationId: [''],
    mediaKitId: [''],
    circulationPerIssue: [''],
    isCirculationPerIssueAudited: [false],
    readership: [''],
    subscribers: [''],
    rateBase: [''],
    reach: [''],
    newsStandSales: [''],
    auditedAnnual: [''],
    auditedPerIssue: [''],
    reportedAnnual: [''],
    reportedPerIssue: [''],
    auditedCirculationTotal: [''],
    auditedCirculationUs: [''],
    reportedCirculationInt: [''],
    auditedCirculationInt: [''],
    reportedCirculationNa: [''],
    reportedCirculationTotal: [''],
    reportedCirculationUs: [''],
    isActive: [true],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
    rowVersion: ['']
  });

  // Properties to use in html
  get mediaKitId() { return this.addCirculationForm.get('mediaKitId'); }
  get circulationId() { return this.addCirculationForm.get('circulationId'); }
  get circulationPerIssue() { return this.addCirculationForm.get('circulationPerIssue'); }
  get isCirculationPerIssueAudited() { return this.addCirculationForm.get('isCirculationPerIssueAudited'); }
  get subscribers() { return this.addCirculationForm.get('subscribers'); }
  get readership() { return this.addCirculationForm.get('readership'); }
  get reach() { return this.addCirculationForm.get('reach'); }
  get rateBase() { return this.addCirculationForm.get('rateBase'); }
  get newsStandSales() { return this.addCirculationForm.get('newsStandSales'); }
  get auditedAnnual() { return this.addCirculationForm.get('auditedAnnual'); }
  get auditedPerIssue() { return this.addCirculationForm.get('auditedPerIssue'); }
  get reportedAnnual() { return this.addCirculationForm.get('reportedAnnual'); }
  get reportedPerIssue() { return this.addCirculationForm.get('reportedPerIssue'); }
  get auditedCirculationTotal() { return this.addCirculationForm.get('auditedCirculationTotal'); }
  get auditedCirculationUs() { return this.addCirculationForm.get('auditedCirculationUs'); }
  get reportedCirculationInt() { return this.addCirculationForm.get('reportedCirculationInt'); }
  get auditedCirculationInt() { return this.addCirculationForm.get('auditedCirculationInt'); }
  get reportedCirculationNa() { return this.addCirculationForm.get('reportedCirculationNa'); }
  get reportedCirculationTotal() { return this.addCirculationForm.get('reportedCirculationTotal'); }
  get reportedCirculationUs() { return this.addCirculationForm.get('reportedCirculationUs'); }
  get createdOn() { return this.addCirculationForm.get('createdOn'); }
  get createdBy() { return this.addCirculationForm.get('createdBy'); }
  get modifiedOn() { return this.addCirculationForm.get('modifiedOn'); }
  get modifiedBy() { return this.addCirculationForm.get('modifiedBy'); }

  validateForm() {
    Object.keys(this.addCirculationForm.controls).forEach(field => {
      const control = this.addCirculationForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }
  addCirculationOnSubmit() {
    this.validateForm();
    if (this.addCirculationForm.valid) {
      if (this.circulation) {
        this.updateUserDetails();
        this.circulationService.update(this.addCirculationForm.getRawValue()).subscribe((data: Circulation) => {
          this.modalRef.hide();
          this.alertService.success('Circulation updated successfully');

          // Set the json in Subscribe so that once the modal is closed 
          // API will be invoked to get the new results
          let json = {
            id: this.id,
            type: this.type
          };

          this.action.next(json);

        })
      }
      else {
        this.addUserDetails();
        this.circulationService.create(this.addCirculationForm.getRawValue()).subscribe((data: Circulation) => {
          this.modalRef.hide();
          this.alertService.success('Circulation Added Successfully');

          // Set the json in Subscribe so that once the modal is closed 
          // API will be invoked to get the new results
          let json = {
            id: this.id,
            type: this.type
          };

          this.action.next(json);
        })
      }
    }

  }

}
