import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MdbAutoCompleterComponent, MDBModalService } from 'ng-uikit-pro-standard';
import { Buyer } from 'src/app/core/api/defs/Buyer';
import { BuyerSearch, BuyerSearchResult } from 'src/app/core/api/defs/SearchBuyer';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';

@Component({
  selector: 'app-buyerlist',
  templateUrl: './buyerlist.component.html'
})
export class BuyerlistComponent implements OnInit {
  
  @ViewChild('auto', /* TODO: add static flag */ { static: true })
  public completer: MdbAutoCompleterComponent;
  searchText: string = '';
  totalCount:any;
  currentPageIndex:any;
  buyer:Buyer;
  canShowPagination: boolean = false;
  buyerSearchResultData: Array<BuyerSearch>;
  selectedBuyerSearch: BuyerSearch = new BuyerSearch();
  constructor(private buyersearchService:BuyersearchService,
    private alertService: AlertService, 
    private router: Router,
    private modalService: MDBModalService,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    this.invokeBuyerService(1)
  }
  ngAfterViewInit() {
    this.completer.selectedItemChanged().subscribe((data: any) => {
        this.searchText = data.text;
        this.selectedBuyerSearch = this.buyerSearchResultData.find(x => x.BuyerName == data.text);
    this.router.navigate(['buyer-activity/' + this.selectedBuyerSearch.BuyerId]);
    });
}
  onChange() {
    if (this.searchText == "") {
    }
    else {
        this.buyersearchService.searchBuyer(this.searchText).subscribe((data: BuyerSearchResult) => {
            this.buyerSearchResultData = data.value;
        }, (error) => {
            console.log(error);
        });
    }
}

goToTop() {
  window.scrollTo(0, 0);
}

  mfPaginationOnPageChanged(currentPageNumber: any) {
    this.invokeBuyerService(currentPageNumber);
  }
  //readByPage
  invokeBuyerService(currentPageNumber: number) {
    this.buyersearchService.readByPage(currentPageNumber - 1).subscribe((response: any) => {
     this.totalCount = response.count;
      this.currentPageIndex = currentPageNumber;
      this.buyer = response.data;
      this.goToTop();
     setTimeout(() => {
       this.canShowPagination = true;
      });

    });
  }
                           
}
