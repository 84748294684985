<form [formGroup]="declineOfferForm" (submit)="declineOfferFormSubmit()">

  <div class="modal-content">
    <div class="modal-header">
      <p class="heading lead">
        <strong class="tittle3 font-weight-bold">Decline Offer</strong>
      </p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide();">
        <span aria-hidden="true tittle3">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="mt-3">
        <div class="md-form">
          <mdb-select tabindex="1" label="Decline Reason" mdbValidate formControlName="reasonId" [options]="reasonOptions">
          </mdb-select>
          <mdb-error *ngIf="reasonId.invalid && (reasonId.dirty || reasonId.touched)">
            Required
          </mdb-error>
        </div>
      </div>
      
      <div class="md-form">
        <textarea tabindex="2" formControlName="instructions" type="text" name="instructions" id="instructions"
          class="md-textarea form-control" mdbInput></textarea>
        <label for="instructions">Add Comments</label>
      </div>
    </div>

    <div class="modal-footer justify-content-center">
      <button type="submit" mdbBtn class="btn-secondary1 secondary relative waves-light mr-4" mdbWavesEffect>
        Decline Offer
        <i class="fas fa-times ml-1"></i>
      </button>
      <button type="button" mdbBtn color="primary" outline="true"
      class="relative waves-light btn-outline-primary tc-1 blueborder" 
        mdbWavesEffect (click)="modalRef.hide();">
        Close
      </button>
    </div>
  </div>
</form>