import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { AdMediaKit, MdbSelectOptions } from 'src/app/core/api/model';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { BreadCrumbsService } from 'src/app/core/api/services/breadCrumbs.service';
import { MediakitService } from 'src/app/core/api/services/mediakit.service';

@Component({
  selector: 'app-migrate-media-kit',
  templateUrl: './migrate-media-kit.component.html'
})
export class MigrateMediaKitComponent implements OnInit {

  action: Subject<any> = new Subject();
  
  magazineId: number;
  
  username: string;
  
  currentDate: Date;
  
  activeMediaKitOptions: Array<any>;
  
  inActiveMediaKitOptions: Array<any>;

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(private alertService: AlertService,
    public modalRef: MDBModalRef,
    private mediakitService: MediakitService,
    private fb: FormBuilder, 
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);
   
    if(this.isAuthenticated){
      this.username = this.authService.name;
    }

    this.getActiveMediaKits();
   
    this.getInActiveMediaKits();

    this.currentDate = new Date();
  }

  addMigrateMediaKitForm = this.fb.group({
    fromMediaKitId: ['', Validators.required],
    toMediaKitId: ['', Validators.required],
    createdBy: [''],
    createdOn: [''],
    modifiedBy: [''],
    modifiedOn: [''],
  });

  get fromMediaKitId() { return this.addMigrateMediaKitForm.get('fromMediaKitId'); }
  get toMediaKitId() { return this.addMigrateMediaKitForm.get('toMediaKitId'); }
  get createdBy() { return this.addMigrateMediaKitForm.get('createdBy'); }
  get createdOn() { return this.addMigrateMediaKitForm.get('createdOn'); }
  get modifiedBy() { return this.addMigrateMediaKitForm.get('modifiedBy'); }
  get modifiedOn() { return this.addMigrateMediaKitForm.get('modifiedOn'); }


  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.username);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.username);
  }

  validateForm() {
    Object.keys(this.addMigrateMediaKitForm.controls).forEach(field => {
      const control = this.addMigrateMediaKitForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  migrateMediaKitSubmit() {

    this.validateForm();
    if (this.addMigrateMediaKitForm.valid) {
      this.addUserDetails();

      this.mediakitService.migrate(this.addMigrateMediaKitForm.value).subscribe((data: any) => {

        this.modalRef.hide();

        this.action.next();

        this.alertService.success(data.message);
      }, (err) => {
        this.alertService.error(err);
      });
    }
  }

  getActiveMediaKits() {
    this.mediakitService.getActiveMediakits(this.magazineId).subscribe((data: Array<AdMediaKit>) => {
      this.activeMediaKitOptions = data.map((mediaKit: AdMediaKit) => {
        let option = new MdbSelectOptions();
        option.label = mediaKit.mediaKitYear.toString();
        option.value = mediaKit.mediaKitId;
        return option;
      });
    }, (err) => {
      this.alertService.error(err);
    });
  }

  getInActiveMediaKits() {
    this.mediakitService.getInActiveMediakits(this.magazineId).subscribe((data: Array<AdMediaKit>) => {
      this.inActiveMediaKitOptions = data.map((mediaKit: AdMediaKit) => {
        let option = new MdbSelectOptions();
        option.label = mediaKit.mediaKitYear.toString();
        option.value = mediaKit.mediaKitId;
        return option;
      });
    }, (err) => {
      this.alertService.error(err);
    });
  }
}
