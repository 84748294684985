import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject, Subscription } from 'rxjs';
import { Magazines } from '../../core/api/defs/Magazines';
import { MagazineService } from '../../core/api/services/magazine.service'
import { PubicationFrequency } from 'src/app/core/api/defs/PublicationFrequency';
import { MediaClassification } from 'src/app/core/api/defs/MediaClassification';
import { MdbSelectOptions, Tag } from 'src/app/core/api/model';
import { AuthService } from 'src/app/auth/service/auth.service';
import { TagService } from 'src/app/core/api/services/tag.service';
@Component({
  selector: 'app-add-magazine',
  templateUrl: './add-magazine.component.html'
})
export class AddMagazineComponent implements OnInit {
  @ViewChild('multiSelect', /* TODO: add static flag */ { static: true }) multiSelect;
  id: number;
  type: string;
  data: Magazines;
  isEdit: boolean;
  userName: any;
  currentDate: Date;
  action: Subject<any> = new Subject();
  frequncyTypeOptions: Array<any>;
  mediaClassificationOptions: any;
  market: any
  tagList: Array<Tag>;
  frequency: any;
  dropdownSettings = {};
  marketdropdownSettings = {};
  tagSettings = {};
  selectedElement = [{
    "idField": null,
    "textField": ""
  }]

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private magazineService: MagazineService,
    private alertService: AlertService,
    private authService: AuthService,
    private tagService: TagService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();

    this.loadAddressDataOnInit();

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'mediaClassificationId',
      textField: 'mediaClassificationName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: 'Media Classification'
    };

    this.marketdropdownSettings = {
      singleSelection: false,
      idField: 'marketCategoryId',
      textField: 'marketCategoryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: 'Market Categories'
    };

    this.tagSettings = {
      singleSelection: false,
      idField: 'tagId',
      textField: 'tagName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      //itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: 'Tags'
    };
  }

  addMagazineForm = this.fb.group({
    magazineId: [null],
    sellerId: [0],
    magazineName: ['', Validators.required],
    publicationFrequency: [0],
    frequencyTypeId: [0],
    publisherStatement: [''],
    magazineWebsiteUrl: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
    isUsNational: [false],
    isSeasonal: [false],
    isNetRate: [false],
    mediaClassification: this.fb.control([]),
    marketCategories: this.fb.control([]),
    tags: this.fb.control([]),
    isExtraIssue: [false],
    isActive: [true],
    dateEntered: [''],
    dateLastUpdated: [''],
    enteredBy: [''],
    updatedBy: [''],
    rowVersion: ['']
  });

  // Properties to use in html
  get magazineId() { return this.addMagazineForm.get('magazineId'); }
  get sellerId() { return this.addMagazineForm.get('sellerId'); }
  get magazineName() { return this.addMagazineForm.get('magazineName'); }
  get publicationFrequency() { return this.addMagazineForm.get('publicationFrequency'); }
  get frequencyTypeId() { return this.addMagazineForm.get('frequencyTypeId'); }
  get magazineWebsiteUrl() { return this.addMagazineForm.get('magazineWebsiteUrl'); }
  get isUsNational() { return this.addMagazineForm.get('isUsNational'); }
  get isSeasonal() { return this.addMagazineForm.get('isSeasonal'); }
  get isExtraIssue() { return this.addMagazineForm.get('isExtraIssue'); }
  get isNetRate() { return this.addMagazineForm.get('isNetRate'); }
  get isActive() { return this.addMagazineForm.get('isActive'); }
  get rowVersion() { return this.addMagazineForm.get('rowVersion'); }
  get mediaClassification() { return this.addMagazineForm.get('mediaClassification') as FormArray }
  get marketCategories() { return this.addMagazineForm.get('marketCategories') as FormArray }
  get tags() { return this.addMagazineForm.get('tags') as FormArray }
  get dateEntered() { return this.addMagazineForm.get('dateEntered'); }
  get dateLastUpdated() { return this.addMagazineForm.get('dateLastUpdated'); }
  get enteredBy() { return this.addMagazineForm.get('enteredBy'); }
  get updatedBy() { return this.addMagazineForm.get('updatedBy'); }


  close(modalRef) {
    modalRef.hide();
  }

  validateForm() {
    Object.keys(this.addMagazineForm.controls).forEach(field => {
      const control = this.addMagazineForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  addUserDetails() {
    this.dateLastUpdated.patchValue(this.currentDate);
    this.enteredBy.patchValue(this.userName);
    this.dateEntered.patchValue(this.currentDate);
    this.updatedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.dateLastUpdated.patchValue(this.currentDate);
    this.updatedBy.patchValue(this.userName);
  }

  addMagazinesOnSubmit() {
    this.validateForm();
    if (this.addMagazineForm.valid) {
      if (this.isEdit) {
        this.updateUserDetails();
        this.editMagazine();
      }
      else {
        this.addUserDetails();
        this.addMagazine();
      }
    }
  }

  loadAddressDataOnInit() {
    this.getFrequencyType();
    this.getMediaClassification();
    this.getMarket();
    this.getAllTags();
    this.sellerId.patchValue(this.id);
    setTimeout(() => {
      if (this.data != null || this.data != undefined) {
        this.addMagazineForm.patchValue(this.data);
        this.addMagazineForm.controls.mediaClassification.patchValue(this.data.mediaClassification);
      }
    });
  }

  changeFrequency(frequecytype) {
    if (this.frequency) {
      this.frequency.forEach((item, index: number) => {
        if (frequecytype === item.frequencyTypeId) {
          this.publicationFrequency.patchValue(item.issuesPerYear);
        }
      });
    }

  }

  getFrequencyType() {
    this.magazineService.getFrequency().subscribe((data: any) => {
      this.frequncyTypeOptions = data.map((frequncyTypeOptions) => {
        let option = new MdbSelectOptions();
        option.value = frequncyTypeOptions.frequencyTypeId;
        option.label = frequncyTypeOptions.frequencyName;
        return option;
      })
      this.frequency = data;
    })
  }

  getMediaClassification() {
    this.magazineService.getMediaClassification().subscribe((data: any) => {
      this.mediaClassificationOptions = data;
    })
  }

  getMarket() {
    this.magazineService.getMarket().subscribe((data: any) => {
      this.market = data;
    })
  }

  getAllTags() {
    this.tagService.getAllTags().subscribe((data: Array<Tag>) => {
      this.tagList = data;
    });
  }

  addMagazine() {
    this.magazineService.create(this.addMagazineForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Magazine added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          type: "magazine"
        };
        this.action.next(json);
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  onItemSelect(item: MediaClassification) {
    // this.addMagazineForm.setControl('mediaClassification', {})
    this.addMagazineForm.controls.mediaClassification.patchValue([item]);
  }

  onItemSelectmarket(item) {
    //this.addMagazineForm.controls.marketCategories.patchValue([...this.addMagazineForm.controls.marketCategories.value, ...item]);
  }

  onDeSelect(item: any) {
    if (this.isEdit) {
      this.mediaClassification.patchValue(null);
    }
  }

  onDeSelectmarket(marketCategory) {
    this.marketCategories.removeAt(this.marketCategories.value.findIndex(market => market.marketCategoryId === marketCategory.marketCategoryId))
    if (this.isEdit) {
    }
  }

  editMagazine() {
    this.magazineService.update(this.id, this.addMagazineForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Magazine updated successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: 'magazine'
        };

        this.action.next(json);
      },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

}