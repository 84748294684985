import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OfferPageComponent } from './pages/offer-page.component';
import { UserRole } from '../enums/UserRole';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { AuthGuardService } from '../auth/service/auth-guard.service';

const routes: Routes = [
  {
    path: 'offers',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: OfferPageComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.Buyer,
            UserRole.Seller
          ]
        }
      }
    ]
  },

];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class OfferRoutingModule { }
