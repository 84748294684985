import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { EmailconformationService } from '../../core/api/services/emailconformation.service';
import { EmailConformation } from '../../core/api/defs/EmailConformation';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './emailConfirmation.component.html',
  styleUrls: ['./emailConfirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {
  
  confirmationCode: string;
  userId: string;
  result: boolean;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private emailconformationService: EmailconformationService) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {

      this.confirmationCode = params && typeof params.confirmationCode != "undefined" ? encodeURIComponent(params.confirmationCode) : null;
      this.userId = params && typeof params.userId != "undefined" ? params.userId : null;

      if (this.confirmationCode && this.userId) {
        // we get values post and update result to user on home page
        this.emailconformationService.read(this.userId, this.confirmationCode).subscribe((response) => {
          this.result = true;
        }, (err) => {
          this.result = false;
        });
      }

    });
  }

}
