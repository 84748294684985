export const CHANGE_CLASS_MEDIA = 'CHANGE_CLASS_MEDIA';
export const CHANGE_SORT_BY = 'CHANGE_SORT_BY';
export const CHANGE_SORT_BY_ALL = 'CHANGE_SORT_BY_ALL';
export const CHANGE_SORT_BY_DESELECT = 'CHANGE_SORT_BY_DESELECT';
export const CHANGE_SORT_BY_DESELECT_ALL = 'CHANGE_SORT_BY_DESELECT_ALL';
export const CHANGE_MARKET_CATEGORY = 'CHANGE_MARKET_CATEGORY';
export const CHANGE_MARKET_CATEGORY_ALL = 'CHANGE_MARKET_CATEGORY_ALL';
export const CHANGE_MARKET_CATEGORY_DESELECT = 'CHANGE_MARKET_CATEGORY_DESELECT';
export const CHANGE_MARKET_CATEGORY_DESELECT_ALL = 'CHANGE_MARKET_CATEGORY_DESELECT_ALL';
export const CHANGE_PUBLICATION_PERCENTAGE = 'CHANGE_PUBLICATION_PERCENTAGE';
export const CHANGE_PUBLICATION_PERCENTAGE_ALL = 'CHANGE_PUBLICATION_PERCENTAGE_ALL';
export const CHANGE_PUBLICATION_PERCENTAGE_DESELECT = 'CHANGE_PUBLICATION_PERCENTAGE_DESELECT';
export const CHANGE_PUBLICATION_PERCENTAGE_DESELECT_ALL = 'CHANGE_PUBLICATION_PERCENTAGE_DESELECT_ALL';
export const CHANGE_EDUCATION_LEVEL = 'CHANGE_EDUCATION_LEVEL';
export const CHANGE_EDUCATION_LEVEL_ALL = 'CHANGE_EDUCATION_LEVEL_ALL';
export const CHANGE_EDUCATION_LEVEL_DESELECT = 'CHANGE_EDUCATION_LEVEL_DESELECT';
export const CHANGE_EDUCATION_LEVEL_DESELECT_ALL = 'CHANGE_EDUCATION_LEVEL_DESELECT_ALL';
export const CHANGE_EMPLOYMENT_STATUS = 'EMPLOYMENT_STATUS_LEVEL';
export const CHANGE_EMPLOYMENT_STATUS_ALL = 'CHANGE_EMPLOYMENT_STATUS_ALL';
export const CHANGE_EMPLOYMENT_STATUS_DESELECT = 'CHANGE_EMPLOYMENT_STATUS_DESELECT';
export const CHANGE_EMPLOYMENT_STATUS_DESELECT_ALL = 'CHANGE_EMPLOYMENT_STATUS_DESELECT_ALL';
export const CHANGE_OCCUPATION = ' CHANGE_OCCUPATION';
export const CHANGE_OCCUPATION_ALL = ' CHANGE_OCCUPATION_ALL';
export const CHANGE_OCCUPATION_DESELECT = ' CHANGE_OCCUPATION_DESELECT';
export const CHANGE_OCCUPATION_DESELECT_ALL = ' CHANGE_OCCUPATION_DESELECT_ALL';

