<form [formGroup]="addMagazineForm" (ngSubmit)="addMagazinesOnSubmit()">
  <div class="modal-header">
    <div *ngIf="isEdit; else addMagazineTemplate">
      <h5 class="modal-title tittle3" id="exampleModalLabel">Edit Magazine</h5>
    </div>
    <ng-template #addMagazineTemplate>
      <h5 class="modal-title tittle3" id="exampleModalLabel">Add Magazine</h5>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="md-form  mt-0">
          <input mdbInput mdbValidate type="text" formControlName="magazineName" class="form-control" id="magazineName">
          <label for="magazineName">Magazine Name</label>
          <mdb-error *ngIf="magazineName.invalid && (magazineName.dirty || magazineName.touched)">
            Required
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form  mt-0">
          <mdb-select [options]="frequncyTypeOptions" (ngModelChange)="changeFrequency($event)"
            formControlName="frequencyTypeId" placeholder="Select Frequency Type" label="Frequency label"></mdb-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-5">
        <div class=""> <span>Media Classification</span>
          <ng-multiselect-dropdown #multiSelect [formControl]="mediaClassification"
            [placeholder]="'Select Classification'" [settings]="dropdownSettings" [data]="mediaClassificationOptions"
            (onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)">
          </ng-multiselect-dropdown>

        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class=""><span>Market Categories</span>
          <ng-multiselect-dropdown #multiSelect [formControl]="marketCategories"
            [placeholder]="'Select marketCategories'" [settings]="marketdropdownSettings" [data]="market"
            (onSelect)="onItemSelectmarket($event)" (onDeSelect)="onDeSelectmarket(marketCategories)">
          </ng-multiselect-dropdown>

        </div>
      </div>
    </div>
    <div class="mt-3"></div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form  mt-0">
          <input mdbInput mdbValidate type="text" formControlName="publicationFrequency" class="form-control"
            id="publicationFrequency">
          <label for="publicationFrequency">Publication Frequency</label>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form ml-2 mt-0">
          <mdb-checkbox mdbValidate formControlName="isExtraIssue">
            Is Extra Issue Published ?
          </mdb-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-7 col-lg-6">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="magazineWebsiteUrl" class="form-control"
            id="magazineWebsiteUrl">
          <label for="magazineWebsiteUrl">Web Site Url</label>
          <mdb-error *ngIf="magazineWebsiteUrl.invalid && (magazineWebsiteUrl.dirty || magazineWebsiteUrl.touched)">
            <span *ngIf="magazineWebsiteUrl.errors.pattern">Please Enter Valid URL </span>
          </mdb-error>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="md-form ml-2 mt-0">
          <mdb-checkbox mdbValidate formControlName="isUsNational">
            National
          </mdb-checkbox>
          <mdb-error *ngIf="isUsNational.invalid && (isUsNational.dirty || isUsNational.touched)">Required</mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="md-form ml-2 mt-0">
          <mdb-checkbox mdbValidate formControlName="isActive">
            Is Active
          </mdb-checkbox>
          <mdb-error *ngIf="isActive.invalid && (isActive.dirty || isActive.touched)">Required</mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="md-form">
          <textarea id="form7" formControlName="publisherStatement" class="md-textarea form-control"></textarea>
          <label for="form7">Publisher Statement</label>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="md-form">
          <div>hello</div>
          <div class="chip">
              Tag 1
              <mdb-icon fas icon="times" class="" (click)="hideElement=!hideElement"></mdb-icon>
          </div>
        </div>
      </div>
    </div> -->

    <div class="col-sm-12">
      <div class=""><span>Tags</span>
        <ng-multiselect-dropdown #multiSelect [formControl]="tags" [placeholder]="'Select Tag'" [settings]="tagSettings"
          [data]="tagList">
        </ng-multiselect-dropdown>

      </div>
    </div>


  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1 secondary relative waves-light p-2" mdbWavesEffect>Save
      changes</button>
    <button type="button" class="btn btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()"
      mdbWavesEffect>Close</button>
  </div>
</form>