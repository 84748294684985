import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailConfirmationComponent } from './headercomponent/emailConfirmation/emailConfirmation.component';
import { ResetPasswordComponent } from './headercomponent/resetPassword/resetPassword.component';
import { LoginComponent } from './headercomponent/login/login.component';
import { RegistrationComponent } from './headercomponent/registration/registration.component';
import { HeaderOnlyLayoutComponent } from './layout/headerOnly/header-only-layout/header-only-layout.component';
import { MainLayoutComponent } from './layout/mainLayout/main-layout/main-layout.component';
import { RegistrationLiteComponent } from './headercomponent/registration-lite/registration-lite.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './headercomponent/forgot-password/forgot-password.component';
import { SigninCallbackComponent } from './signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './signout-callback/signout-callback.component';
import { SilentRefreshComponent } from './silent-refresh/silent-refresh.component';
import { RedirectToLoginComponent } from './redirect-to-login/redirect-to-login.component';

const appRoutes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },


    ]
  },
  {
    path: 'login',
    component: RedirectToLoginComponent
  },

  {
    path: 'confirm-email',
    component: HeaderOnlyLayoutComponent,
    children: [
      {
        path: '',
        component: EmailConfirmationComponent
      }
    ]
  },

  {
    path: 'registration',
    component: HeaderOnlyLayoutComponent,
    children: [
      {
        path: '',
        component: RegistrationComponent
      }
    ]
  },
  {
    path: 'registration-lite',
    component: HeaderOnlyLayoutComponent,
    children: [
      {
        path: '',
        component: RegistrationLiteComponent
      }
    ]
  },

  {
    path: 'reset-password',
    component: HeaderOnlyLayoutComponent,
    children: [
      {
        path: '',
        component: ResetPasswordComponent
      }
    ]
  },
  {
    path: 'signin-callback',
    component: SigninCallbackComponent
  },
  {
    path: 'signout-callback',
    component: SignoutCallbackComponent
  },
  {
    path: 'silent-refresh',
    component: SilentRefreshComponent
  },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'forgot-password',
    component: HeaderOnlyLayoutComponent,
    children: [
      {
        path: '',
        component: ForgotPasswordComponent
      }
    ]
  },

  {
    path: '**',
    component: HeaderOnlyLayoutComponent,
    children: [
      {
        path: '',
        component: PageNotFoundComponent
      }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
      , { useHash: false } // TODO: remove this once azure storage static websites support rewrite rules
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
