<form [formGroup]="addCirculationForm" (ngSubmit)="addCirculationOnSubmit()">
  <div class="modal-header">
    <div *ngIf="isEdit; else addCirculationTemplate">
      <h5 class="modal-title tittle3" id="exampleModalLabel">Edit Circulation</h5>
    </div>
    <ng-template #addCirculationTemplate>
      <h5 class="modal-title tittle3" id="exampleModalLabel">Add Circulation</h5>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 mx-auto">
        <div class="row">
          <div class="col-xs-6 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="circulationPerIssue" class="form-control"
                id="circulationPerIssue" />
              <label for="circulationPerIssue">Circulation Per Issue</label>
              <mdb-error *ngIf="circulationPerIssue.invalid && (circulationPerIssue.dirty || circulationPerIssue.touched)">
                <span *ngIf="circulationPerIssue.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6">
            <div class="md-form mt-0">
              <mdb-checkbox mdbValidate formControlName="isCirculationPerIssueAudited">
                Is Circulation Per Issue Audited
              </mdb-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="readership" class="form-control"
                id="readership" />
              <label for="readership">Readership</label>
              <mdb-error *ngIf="readership.invalid && (readership.dirty || readership.touched)">
                <span *ngIf="readership.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="newsStandSales" class="form-control"
                id="newsStandSales" />
              <label for="newsStandSales">NewsStandSales</label>
              <mdb-error *ngIf="newsStandSales.invalid && (newsStandSales.dirty || newsStandSales.touched)">
                <span *ngIf="newsStandSales.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="subscribers" class="form-control"
                id="subscribers" />
              <label for="subscribers">Subscribers</label>
              <mdb-error *ngIf="subscribers.invalid && (subscribers.dirty || subscribers.touched)">
                <span *ngIf="subscribers.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="rateBase" class="form-control"
                id="rateBase" />
              <label for="rateBase">Ratebase</label>
              <mdb-error *ngIf="rateBase.invalid && (rateBase.dirty || rateBase.touched)">
                <span *ngIf="rateBase.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="reach" class="form-control" id="reach" />
              <label for="reach">Reach</label>
              <mdb-error *ngIf="reach.invalid && (reach.dirty || reach.touched)">
                <span *ngIf="reach.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="auditedAnnual" class="form-control"
                id="auditedAnnual" />
              <label for="auditedAnnual">Audited Annual</label>
              <mdb-error *ngIf="auditedAnnual.invalid && (auditedAnnual.dirty || auditedAnnual.touched)">
                <span *ngIf="auditedAnnual.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="auditedPerIssue" class="form-control"
                id="auditedPerIssue" />
              <label for="auditedPerIssue">Audited Per Issue</label>
              <mdb-error *ngIf="auditedPerIssue.invalid && (auditedPerIssue.dirty || auditedPerIssue.touched)">
                <span *ngIf="auditedPerIssue.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="reportedAnnual" class="form-control"
                id="reportedAnnual" />
              <label for="reportedAnnual">Reported Annual</label>
              <mdb-error *ngIf="reportedAnnual.invalid && (reportedAnnual.dirty || reportedAnnual.touched)">
                <span *ngIf="reportedAnnual.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="reportedPerIssue" class="form-control"
                id="reportedPerIssue" />
              <label for="reportedPerIssue">Reported Per Issue</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="auditedCirculationInt"
                class="form-control" id="auditedCirculationInt" />
              <label for="auditedCirculationInt">Audited Circulation INT</label>
              <mdb-error *ngIf="auditedCirculationInt.invalid && (auditedCirculationInt.dirty || auditedCirculationInt.touched)">
                <span *ngIf="auditedCirculationInt.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="auditedCirculationTotal"
                class="form-control" id="auditedCirculationTotal" />
              <label for="auditedCirculationTotal">Audited Circulation Total</label>
              <mdb-error *ngIf="auditedCirculationTotal.invalid && (auditedCirculationTotal.dirty || auditedCirculationTotal.touched)">
                <span *ngIf="auditedCirculationTotal.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="auditedCirculationUs"
                class="form-control" id="auditedCirculationUs" />
              <label for="auditedCirculationUs">Audited Circulation US</label>
              <mdb-error *ngIf="auditedCirculationUs.invalid && (auditedCirculationUs.dirty || auditedCirculationUs.touched)">
                <span *ngIf="auditedCirculationUs.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="md-form mt-0">
              <input mdbInput mdbValidate type="text" formControlName="reportedCirculationInt"
                class="form-control" id="reportedCirculationInt" />
              <label for="reportedCirculationInt">Reported Circulation INT</label>
              <mdb-error *ngIf="reportedCirculationInt.invalid && (reportedCirculationInt.dirty || reportedCirculationInt.touched)">
                <span *ngIf="reportedCirculationInt.errors.min">Please Enter Valid Value </span>
              </mdb-error>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn waves-light btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()"
      mdbWavesEffect>Close</button>
  </div>
</form>