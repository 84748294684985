import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { DownloadService } from 'src/app/core/api/services/download.service';
import { saveAs as importedSaveAs } from "file-saver";
import { MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { BuyerSearch, BuyerSearchResult } from 'src/app/core/api/defs/SearchBuyer';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';

@Component({
  selector: 'app-downloadbatch',
  templateUrl: './downloadbatch.component.html'
})
export class DownloadbatchComponent implements OnInit {

  @ViewChild('auto', /* TODO: add static flag */ { static: true })
  public completer: MdbAutoCompleterComponent;
  buyerSearchResultData: Array<BuyerSearch>;
  selectedBuyerSearch: BuyerSearch = new BuyerSearch();
  searchText: string = '';
  partnerkey: any;
  constructor(private fb: FormBuilder,
    private alertService: AlertService,
    private downloadService: DownloadService,
    private buyersearchService: BuyersearchService,
    ) { }

  addDownloadBatchForm = this.fb.group({
    mainPartnerKey: ['', [Validators.required, Validators.maxLength(50)]],
    buyerName:[''],
    buyerId: [null],
  });

  get mainPartnerKey() { return this.addDownloadBatchForm.get('mainPartnerKey'); }
  get buyerId() { return this.addDownloadBatchForm.get('buyerId'); }
  get buyerName() { return this.addDownloadBatchForm.get('buyerName'); }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.completer.selectedItemChanged().subscribe((data: any) => {
      this.searchText = data.text;
      this.selectedBuyerSearch = this.buyerSearchResultData.find(x => x.BuyerName == data.text);
      this.buyerId.patchValue(this.selectedBuyerSearch.BuyerId);
    
    });
  }
  onChange() {
    if (this.searchText != "") {
      this.buyersearchService.searchBuyer(this.searchText).subscribe((data: BuyerSearchResult) => {
        this.buyerSearchResultData = data.value;
      }, (error) => {
        console.log(error);
      });
    }
    }

  validateForm() {
    Object.keys(this.addDownloadBatchForm.controls).forEach(field => {
      const control = this.addDownloadBatchForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  addDownloadBatchFormOnSubmit() {
    this.downloadFiles();
  }
  downloadFiles() {
    this.validateForm();

    if (this.addDownloadBatchForm.valid) {
      this.downloadService.downloadBatch(this.mainPartnerKey.value, this.buyerId.value)
        .subscribe((res: any) => {
          let contentDisposition = res.headers.get('content-disposition');
          let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          importedSaveAs(res.body, filename);
        },
          (err) => {
            this.alertService.error(err);
          }
        );
    }
  }
}
