import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { DayPickerComponent } from './day-picker/day-picker.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { SharedDemoComponent } from './shared-demo.component';
import { AddContactComponent } from './add-contact/add-contact.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MfPaginationComponent } from './mf-pagination/mf-pagination.component';
import { AlertComponent } from '../alert/alert.component';
import { AddAddressComponent } from './add-address/add-address.component';
import { EditDetailsComponent } from './edit-details/edit-details.component';
import { AddMagazineComponent } from './add-magazine/add-magazine.component';
import { AddMediakitComponent } from './add-mediakit/add-mediakit.component';
import { AddDemographicComponent } from './add-demographic/add-demographic.component';
import { AddAudienceComponent } from './add-audience/add-audience.component';
import { AddRatesComponent } from './add-rates/add-rates.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddSellerComponent } from './add-seller/add-seller.component';
import { AddAssetsComponent } from './add-assets/add-assets.component';
import { DeleteConfirmComponent } from './delete-confirm/delete-confirm.component';
import { CreateinitialofferComponent } from './createinitialoffer/createinitialoffer.component';
import { SubmitofferComponent } from './submitoffer/submitoffer.component';
import { OffercounterComponent } from './offercounter/offercounter.component';
import { OfferlineComponent } from './offerline/offerline.component';
import { ViewofferlineComponent } from './viewofferline/viewofferline.component';
import { AddIssueComponent } from './add-issue/add-issue.component';
import { AddCirculationComponent } from './add-circulation/add-circulation.component';
import { EditBuyerpaymentaccountComponent } from './edit-buyerpaymentaccount/edit-buyerpaymentaccount.component';
import { EditOfferackComponent } from './edit-offerack/edit-offerack.component';
import { PhoneMaskDirective } from '../core/directives/phone-mask.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { YearPickerComponent } from './year-picker/year-picker.component';
import { ChangeSellerComponent } from './change-seller/change-seller.component';
import { AddTagComponent } from './add-tag/add-tag.component';
 

@NgModule({
  declarations: [
    TimePickerComponent,
    DayPickerComponent,
    DatePickerComponent,
    SharedDemoComponent,
    AddContactComponent,
    MfPaginationComponent,
    AlertComponent,
    AddAddressComponent,
    EditDetailsComponent,
    AddMagazineComponent,
    AddMediakitComponent,
    AddDemographicComponent,
    AddAudienceComponent,
    AddRatesComponent,
    AddSellerComponent,
    AddAssetsComponent,
    DeleteConfirmComponent,
    CreateinitialofferComponent,
    SubmitofferComponent,
    OffercounterComponent,
    OfferlineComponent,
    OfferlineComponent,
    AddIssueComponent,
    AddCirculationComponent,
    ViewofferlineComponent,
    EditBuyerpaymentaccountComponent,
    EditOfferackComponent,
    PhoneMaskDirective,
    YearPickerComponent,
    ChangeSellerComponent,
    AddTagComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    HttpClientModule,
    NgMultiSelectDropDownModule,
    MatInputModule, 
    MatFormFieldModule, 
    MatNativeDateModule,
    NgbModule,
    MDBBootstrapModulesPro.forRoot()
  ],
  exports: [
    TimePickerComponent,
    DayPickerComponent,
    DatePickerComponent,
    MfPaginationComponent,
    ViewofferlineComponent,
    PhoneMaskDirective
  ],
  entryComponents: [
    AddContactComponent,
    AddAddressComponent,
    EditDetailsComponent,
    AddMagazineComponent,
    AlertComponent,
    AddMediakitComponent,
    AddDemographicComponent,
    AddAudienceComponent,
    AddRatesComponent,
    AddSellerComponent,
    AddAssetsComponent,
    DeleteConfirmComponent,
    CreateinitialofferComponent,
    SubmitofferComponent,
    OffercounterComponent,
    OfferlineComponent,
    AddCirculationComponent,
    AddIssueComponent,
    ChangeSellerComponent
  ]
})
export class SharedModule { }
