import {
    CHANGE_EMPLOYMENT_STATUS,
    CHANGE_EMPLOYMENT_STATUS_ALL,
    CHANGE_EMPLOYMENT_STATUS_DESELECT,
    CHANGE_EMPLOYMENT_STATUS_DESELECT_ALL
} from '../actions'

const employmentStatusReducer = (state = [], action) => {
    switch (action.type) {

        case CHANGE_EMPLOYMENT_STATUS: {
            let result = [...state, action.payload]
            return result;
        }
        case CHANGE_EMPLOYMENT_STATUS_ALL: {
            let result = action.payload
            return result;
        }
     
        case CHANGE_EMPLOYMENT_STATUS_DESELECT_ALL: {
            return []
        }
        default: return state;
    }
}

export default employmentStatusReducer;