import { OnInit, Component, Renderer2 } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MediakitService } from "src/app/core/api/services/mediakit.service";
import { AlertService } from "src/app/core/api/services/alertservice/alert.service";
import { MDBModalService, MDBModalRef } from "ng-uikit-pro-standard";
import { AdMediaKit } from "src/app/core/api/model";
import { AddMediakitComponent } from "src/app/shared/add-mediakit/add-mediakit.component";
import { BreadCrumbs } from "src/app/core/api/defs/BreadCrumbs";
import { BreadCrumbsService } from "src/app/core/api/services/breadCrumbs.service";

@Component({
    selector: 'app-mediakit-info',
    templateUrl: './mediakitInfo.component.html'
})
export class MediakitInfoComponent implements OnInit {

    modalRef: MDBModalRef;
    sellerId: number;
    sellerName: string;
    magazineId: number;
    magazineName: string;
    mediaKitId: number;
    canShowMediaKitDataCard: boolean;
    mediaKitData: AdMediaKit;


    constructor(private router: ActivatedRoute,
        private mediakitService: MediakitService,
        private alertService: AlertService,
        private modalService: MDBModalService,
        private renderer: Renderer2,
        private breadCrumbsService: BreadCrumbsService) {

        router.params.subscribe((params) => {
            this.sellerId = params.sellerId;
            this.magazineId = params.magazineId;
            this.mediaKitId = params.mediaKitId;
        })
    }


    ngOnInit(): void {
        this.getMediakit();

        this.breadCrumbsService.getBreadCrumbs(this.sellerId, this.magazineId, this.mediaKitId).subscribe((data: BreadCrumbs) => {
            if (data != null) {
                this.sellerName = data.sellerName;
                this.magazineName = data.magazineName;
            }
        });
    }

    getMediakit() {
        this.mediakitService.getMediakit(this.magazineId, this.mediaKitId).subscribe((data) => {
            if (data != null) {
                this.canShowMediaKitDataCard = true;
                this.mediaKitData = data;
            }
            else {
                this.canShowMediaKitDataCard = false;
            }
        });
    }

    editMediaKitClick(mediakit) {
        this.modalRef = this.modalService.show(AddMediakitComponent, {
            class: 'modal-lg',
            ignoreBackdropClick: true,
            data: {
                id: this.magazineId,
                isEdit: true,
                mediakit: mediakit
            }
        });

        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {
            // The values are sent in json format
            if (result.type) {
                this.getMediakit();
            }
        });
    }
}