import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store'
import { MagazineSearch } from '../models/magazine-search.model';
import * as MagazineSearchActions from '../actions/magazine-search.action';
import * as fromRoot from '../../../reducers';
import * as fromConfig from './config.reducer';
import * as fromSearchResults from './searchresults.reducer';
import * as fromFacets from './facets.reducer';
import * as fromParameters from './parameters.reducer';
import * as fromInput from './input.reducer';
import * as fromError from './error.reducer';

export interface SearchState {
  input: fromInput.State;
  config: fromConfig.State;
  results: fromSearchResults.State;
  searchFacets: fromFacets.State;
  parameters: fromParameters.State;
  error: fromError.State;

}

export interface State extends fromRoot.State {
  search: SearchState;
}

export const reducers: ActionReducerMap<SearchState> = {
  input: fromInput.reducer,
  config: fromConfig.reducer,
  results: fromSearchResults.reducer,
  searchFacets: fromFacets.reducer,
  parameters: fromParameters.reducer,
  error: fromError.reducer
};

const initialState: SearchState = {
  input: fromInput.InitialState,
  config: fromConfig.InitialState,
  results: fromSearchResults.InitialState,
  searchFacets: fromFacets.InitialState,
  parameters: fromParameters.InitialState,
  error: fromError.InitialState
};

export function reducer(state = initialState, action: MagazineSearchActions.Actions) {

  switch (action.type) {
    case MagazineSearchActions.SEARCH_ERROR:
      return state;
    case MagazineSearchActions.SEARCH_RESET:
      state = initialState;
      return state;
    default:
      return state;
  }
}

export const getSearchState = createFeatureSelector<State, SearchState>('search');

export const getConfigState = createSelector(
  getSearchState,
  (state: SearchState) => state.config
);

export const getIndex = createSelector(
  getConfigState,
  (state: fromConfig.State) => state.index
);

export const getResultsState = createSelector(
  getSearchState,
  (state: SearchState) => state.results
);



export const getResultsCount = createSelector(
  getSearchState,
  (state: SearchState) => state.results.count
);

export const getSkip = createSelector(
  getSearchState,
  (state: SearchState) => {
    let skip: number = state.parameters.skip as number;
    skip = (skip > state.results.count) ? 0 : skip;

    if (state.results.count > 0) {
      skip++;
    }

    return skip;
  }
);

export const getTake = createSelector(
  getSearchState,
  (state: SearchState) => {
    const top: number = state.parameters.top as number;
    let skip: number = state.parameters.skip as number;
    let take = top + skip;
    if (take > state.results.count) {
      return state.results.count;
    }

    if (skip > state.results.count) {
      // to verify
      skip = 1;
    }
    return take;
  }
);

export const getFacetResultsState = createSelector(
  getSearchState,
  (state: SearchState) => state.searchFacets
);

export const getMultiSelectValues = createSelector(
  getFacetResultsState,
  (state: fromFacets.State, props) => {
    if (state.facets[props.key]) {
      const f = (<MagazineSearch.CheckboxFacet>state.facets[props.key]);
      return Object.keys(f.values).map(x => {
        // let item: MagazineSearch.CheckboxFacetItem = {
        //   value: f.values[x].value, count: f.values[x].count, selected: f.values[x].selected
        // };
        // return item;
        return f.values[x].value;
      })
    }
  }
);
