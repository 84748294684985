import {CHANGE_SORT_BY,
    CHANGE_SORT_BY_ALL,
    CHANGE_SORT_BY_DESELECT,
    CHANGE_SORT_BY_DESELECT_ALL} from '../actions'

const sortByReducer = (state = [],action) => {
    switch(action.type){

        case CHANGE_SORT_BY: {
            let result =  [...state,action.payload]
            return result;
        }
        case CHANGE_SORT_BY_ALL : {
            let result = action.payload
            return result;
        }
        case CHANGE_SORT_BY_DESELECT_ALL: {
            return []
        }
        default: return state;
    }
}

export default sortByReducer;