export class Issue {
    issueId: number;
    issueName?: string;
    issueDescription: string;
    coverDate?: string;
    spaceCloseDate?: string;
    inHomeDate?: string;
    insertsDueDate?: string;
    onSaleDate?: string;
    specsDueDate?: string;
    materialsDueDate?: string;
    mediaKitId: number;
    isActive: boolean;
    createdBy: string;
    createdOn: Date;
    modifiedBy: string;
    modifiedOn: Date;
    rowVersion: string;
}