

export class SellerSearch {
    SellerId: number;
    SellerName: string;
    IsActive : boolean;
    PartyId: number;
}

export class SellerSearchResult {
    value: Array<SellerSearch>;
}