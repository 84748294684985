<div class="row justify-content-center p-3 no-gutters">
  <div class="col-xs-12 col-lg-8 col-md-9">
    <div class="card mb-4">
      <div class="card-body">
        <form class="" id="register-form" [formGroup]="registrationLiteForm" action="" (ngSubmit)="onSubmit()"
          method="post" role="form">

          <h2 class="text-center">
            Register Now
          </h2>

          <div class="md-form">
            <input autocomplete="off" mdbInput mdbValidate type="text" formControlName="buyerName" class="form-control"
              required id="buyerName" name="buyerName" tabindex="1" />
            <label for="buyerName">Buyer Name</label>
            <mdb-error *ngIf="buyerName.invalid && (buyerName.dirty || buyerName.touched)">
              Required
            </mdb-error>
          </div>

          <div class="md-form">
            <input autocomplete="off" mdbInput mdbValidate type="text" formControlName="buyerCompanyDescription"
              class="form-control" id="buyerCompanyDescription" tabindex="2" />
            <label for="buyerCompanyDescription">Buyer Company Description</label>
            <mdb-error
              *ngIf="buyerCompanyDescription.invalid && (buyerCompanyDescription.dirty || buyerCompanyDescription.touched)">
              Required
            </mdb-error>
          </div>

          <div class="text-muted">
            ** Primary Contact Details
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="md-form">
                <input autocomplete="off" mdbInput mdbValidate type="text" formControlName="firstName"
                  class="form-control" required id="firstName" tabindex="3" />
                <label for="firstName">First Name</label>
                <mdb-error *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="md-form">
                <input autocomplete="off" mdbInput mdbValidate type="text" formControlName="lastName"
                  class="form-control" required id="lastName" tabindex="4" />
                <label for="lastName">Last Name</label>
                <mdb-error *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
          </div>

          <div class="md-form">
            <input autocomplete="off" mdbInput mdbValidate type="text" formControlName="email" class="form-control"
              required id="email" aria-describedby="materialRegisterFormEmailHelpBlock" tabindex="5" />
            <label for="email">Email</label>
            <mdb-error *ngIf="email.invalid && (email.dirty || email.touched)">
              <span *ngIf="email.errors.required">Required</span>
              <span *ngIf="email.errors.email">Enter Valid Email Address</span>
            </mdb-error>
            <small id="materialRegisterFormEmailHelpBlock" class="form-text text-muted mb-4 pt-2">
              Confirmation email will be sent to this address.
            </small>
          </div>

          <div class="md-form">
            <input type="password" formControlName="password" id="materialRegisterFormPassword" class="form-control"
              aria-describedby="materialRegisterFormPasswordHelpBlock" mdbInput mdbValidate tabindex="6" />
            <label for="materialRegisterFormPassword">Password</label>
            <mdb-error *ngIf="password.invalid && (password.dirty || password.touched)">
              Required
            </mdb-error>
            <small id="materialRegisterFormPasswordHelpBlock" class="form-text text-muted mb-4 pt-2">
              At least 8 characters, including 1 uppercase, 1 lowercase, 1 digit and 1 special character
            </small>
          </div>

          <div class="md-form">
            <input autocomplete="off" mdbInput mdbValidate type="password" formControlName="confirmPassword"
              class="form-control" required validateequal="password" id="confirmPassword" tabindex="7" />
            <label for="confirmPassword">Confirm Password</label>
            <mdb-error *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
              {{getConfirmPasswordValidationErrorMessage()}}
            </mdb-error>
          </div>

           <!-- TO DO: Need to uncomment when we want to include address. (UI & API) -->

          <!-- <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <mdb-select label="Telephone Type" mdbValidate formControlName="telephoneTypeId"
                  [options]="telephoneTypeOptions" tabindex="8">
                </mdb-select>
                <mdb-error *ngIf="telephoneTypeId.invalid && (telephoneTypeId.dirty || telephoneTypeId.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <input id="phone" mdbInput mdbValidate type="text" class="form-control" appPhoneMask formControlName="phone" 
                [ngClass]="(registrationLiteForm.controls['phone'].invalid)? 'errmsg' : 'validmsg'" tabindex="9">	
                <label for="phone">Phone Number</label>
               <mdb-error *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                  Required (Only Numbers, 10 digits)
                </mdb-error>
              </div>
            </div>
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
              <div class="md-form mt-0">
                <input mdbInput mdbValidate type="text" formControlName="extension" class="form-control" id="extension"
                  pattern="[0-9]*" tabindex="10">
                <label for="extension">Extn</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <mdb-select label="Address Type" mdbValidate formControlName="addressTypeId"
                  [options]="addressTypeOptions" tabindex="11">
                </mdb-select>
                <mdb-error *ngIf="addressTypeId.invalid && (addressTypeId.dirty || addressTypeId.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <input mdbInput mdbValidate type="text" formControlName="addressLine1" class="form-control"
                  id="addressLine1" tabindex="12" />
                <label for="addressLine1">Address Line 1</label>
                <mdb-error *ngIf="addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <input mdbInput mdbValidate type="text" formControlName="addressLine2" class="form-control"
                  id="addressLine2" tabindex="13" />
                <label for="addressLine2">Address Line 2</label>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <input mdbInput mdbValidate type="text" formControlName="addressLine3" class="form-control"
                  id="addressLine3" tabindex="14" />
                <label for="addressLine3">Address Line 3</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <input mdbInput mdbValidate type="text" formControlName="city" class="form-control" id="city"
                  tabindex="15" />
                <label for="city">City</label>
                <mdb-error *ngIf="city.invalid && (city.dirty || city.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <div class="md-form  mt-0">
                  <mdb-select label="State" tabindex="16" mdbValidate formControlName="state" [options]="stateTypeOptions">
                  </mdb-select>
                  <mdb-error *ngIf="state.invalid && (state.dirty || state.touched)">Required
                  </mdb-error>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <input mdbInput mdbValidate type="text" formControlName="zipCode" class="form-control" id="zipCode"
                  tabindex="17" />
                <label for="zipCode">Zip Code</label>
                <mdb-error *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="md-form mt-0">
                <input mdbInput mdbValidate type="text" formControlName="country" class="form-control" id="country"
                  tabindex="18" />
                <label for="country">Country</label>
                <mdb-error *ngIf="country.invalid && (country.dirty || country.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
          </div> 
          <div class="form-group">
            <div style="height:400px; text-align: left;border:2px solid #ced4da; overflow-y:scroll">
              <div style="color:black" [innerHTML]="'<span>'+viewContent+'</span>'"></div>
            </div>
          </div>

          <div class="md-form">
            <input type="checkbox"
              [ngClass]="{ 'is-invalid' : isAgreementAccepted.invalid && (isAgreementAccepted.dirty || isAgreementAccepted.touched) }"
              formControlName="isAgreementAccepted" class="form-check-input" id="isAgreementAccepted" tabindex="19" />
            <label class="form-check-label" for="isAgreementAccepted">I Agree</label>
            <mdb-error
              *ngIf="isAgreementAccepted.invalid && (isAgreementAccepted.dirty || isAgreementAccepted.touched)">
              Please Accept Agreement
            </mdb-error>
          </div>-->

          <div class="md-form">
            <div class="colorRed">
              ** Please note that the offer submission is restricted until the Buyer Credit gets Approved from
              Mediafiche
            </div>
          </div>

          <div class="md-form">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-lg-6 col-md-6">
                <input mdbBtn type="submit" name="register-submit" id="register-submit" tabindex="20"
                  class="form-control btn2 btn-register" value="Register Now" />
              </div>
            </div>
          </div>

          <div class="text-left">
            Not ready?
            <a [routerLink]="['/login']" id="login-form-link2" style="font-weight:bold; text-decoration: underline">
              Cancel
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>

<!-- {{registrationLiteForm.value | json}} -->