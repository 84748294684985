import { OnInit, Component, Renderer2 } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { AlertService } from "src/app/core/api/services/alertservice/alert.service";
import { AddMagazineComponent } from "src/app/shared/add-magazine/add-magazine.component";
import { MagazineService } from "src/app/core/api/services/magazine.service";
import { Magazines } from "src/app/core/api/defs/Magazines";
import { BreadCrumbs } from "src/app/core/api/defs/BreadCrumbs";
import { BreadCrumbsService } from "src/app/core/api/services/breadCrumbs.service";
import { AddTagComponent } from "src/app/shared/add-tag/add-tag.component";

@Component({
    selector: 'app-magazines',
    templateUrl: './magazines.component.html'
})
export class MagazinesComponent implements OnInit {

    modalRef: MDBModalRef;
    sellerId: number;
    sellerName: string;
    magazines: any;
    showMagazineList: boolean = false;

    constructor(private router: Router,
        private renderer: Renderer2,
        private route: ActivatedRoute,
        private modalService: MDBModalService,
        private alertService: AlertService,
        private magazineService: MagazineService,
        private breadCrumbsService: BreadCrumbsService) {

        route.params.subscribe((a) => {
            this.sellerId = a.sellerId;
        });
    }

    ngOnInit(): void {
        this.getMagazines(this.sellerId);
        this.breadCrumbsService.getBreadCrumbs(this.sellerId).subscribe((data: BreadCrumbs) => {
            if (data != null)
                this.sellerName = data.sellerName;
        })
    }

    getMagazines(sellerId: number) {
        this.magazineService.read(sellerId).subscribe((data: Magazines) => {
            this.magazines = data;
            this.showMagazineList = true;
        })
    }

    addTagsClick() {
        this.modalRef = this.modalService.show(AddTagComponent,{
            class:'modal-lg',
            ignoreBackdropClick: true
        })
    }

    addMagazineClick() {
        this.modalRef = this.modalService.show(AddMagazineComponent, {
            class: 'modal-lg',
            ignoreBackdropClick: true,
            data: {
                id: this.sellerId,
                isEdit: false
            }
        });

        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {
            this.getMagazines(this.sellerId);
        });
    }

    editMagazineClick(magazines) {
        this.modalRef = this.modalService.show(AddMagazineComponent, {
            class: 'modal-lg',
            ignoreBackdropClick: true,
            // [config]:'{backdrop: false, ignoreBackdropClick: true}',
            data: {
                id: this.sellerId,
                isEdit: true,
                data: magazines
            }
        });

        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {
            this.getMagazines(this.sellerId);
        });
    }

    goToMagazine(magazineId) {
        this.router.navigateByUrl('/maintenance/seller/' + this.sellerId + '/magazine/' + magazineId);
    }
}