import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { User } from '../defs/User';
import { environment } from '../../../../environments/environment'
import { Observable } from '../../../../../node_modules/rxjs/Rx';
import { RegistrationLiteModel } from '../defs/RegistrationLiteModel';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  baseUrl: string = `${environment.mfServerUrl}`;

  constructor(private http: HttpClient) { }


  registerUser(user: User): Observable<User> {
    return this.http.post<User>(`${environment.loginUrl}registerUser`, user);
  }

  Agreements(account) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    let url = this.baseUrl + `/api/Account/GetAgreementForParty?accountType=${account}`;
    return this.http.get(url, { headers, responseType: 'text' });
  }

  getAdRates(magazineId: number) {
    return this.http.get(`${this.baseUrl}/api/Magazines/${magazineId}/AdOptions`);
  }

  calltoAgreement(url) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(url, { headers, responseType: 'text' });
  }

  registrationLite(registrationLiteModel: RegistrationLiteModel) {
    return this.http.post<RegistrationLiteModel>(`${this.baseUrl}/api/Account/RegistrationLite`, registrationLiteModel);
  }


}
