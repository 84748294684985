import * as __model from '../model';

export class ViewRate {
    dateEntered: string = "";
    dateLastUpdated: string = "";
    enteredBy: string  = "";
    isActive: boolean = null;
    isExtraIssue: boolean = null;
    isNetRate: boolean = null;
    isSeasonal: boolean = null;
    isUsNational: boolean = null;
    magazineDisplayName: string = "";
    magazineId: number = null;
    magazineName: string = "";
    publisherName:string = "";
    mediaKit: __model.AdMediaKit[];
}


