import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-singleselect',
  templateUrl: './singleselect.component.html',
  styleUrls: ['./singleselect.component.scss']
})

export class SingleselectComponent implements OnInit {
  dropdownSettings: any;
  @Input() selectedItem: string[];
  @Input() singleSelectItems: string[];
  @Input() type: string;
  @Output() select: EventEmitter<string> = new EventEmitter();
  @Output() deSelect: EventEmitter<string> = new EventEmitter();

  constructor() {

  }

  onSelect(item) {
    this.select.emit(item);
  }
  
  onDeSelect(item) {
    this.deSelect.emit(item);
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      // idField: 'count',
      // textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

}
