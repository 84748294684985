<form [formGroup]="offerPaymentForm" (ngSubmit)="offerPaymentFormOnSubmit()">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Offer Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close(modalRef)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="md-form mt-0">
                    <input tabindex="1" mdbInput mdbValidate formControlName="paidAmount" required type="number"
                        class="form-control pl-0 rounded-0" id="paidAmount" name="paidAmount" required />
                    <label for="paidAmount">Offer Paid Amount</label>
                    <mdb-error *ngIf="paidAmount.invalid && (paidAmount.dirty || paidAmount.touched)">
                        Required
                    </mdb-error>
                </div>
            </div>
            <div class="col-sm-6 col-sm-6 col-md-6 col-lg-6">
                <div class="md-form mt-0">
                    <mdb-checkbox mdbValidate formControlName="isPaymentMade" required>
                        Is Payment Made ?
                    </mdb-checkbox>
                    <mdb-error *ngIf="isPaymentMade.invalid && (isPaymentMade.dirty || isPaymentMade.touched)">
                        Required
                    </mdb-error>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                <div class="md-form mt-0">
                    <input tabindex="1" mdbInput mdbValidate formControlName="confirmationNumber" required type="text"
                        class="form-control pl-0 rounded-0" id="confirmationNumber" name="confirmationNumber"
                        required />
                    <label for="confirmationNumber">Confirmation Number</label>
                    <mdb-error
                        *ngIf="confirmationNumber.invalid && (confirmationNumber.dirty || confirmationNumber.touched)">
                        Required
                    </mdb-error>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8 col-md-6 col-lg-6">
                <div class="md-form mt-0">
                    <textarea tabindex="4" formControlName="instructions" type="text" name="instructions"
                        id="instructions" class="md-textarea form-control" mdbInput></textarea>
                    <label for="instructions">Comments</label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close(modalRef)"
            mdbWavesEffect>Close</button>
        <button type="submit" class="btn btn-primary" mdbWavesEffect>Save changes</button>
    </div>
</form>