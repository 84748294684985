import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Audience } from '../defs/Audience';
import { AudienceStaticGroup } from '../defs/AudienceStaticGroup';


@Injectable({
  providedIn: 'root'
})
export class AudienceService {

  constructor(private http: HttpClient) { }


  getAudienceStatistic() {
    let url = `${environment.mfServerUrl}` + "/api/AudienceStatistic";
    return this.http.get(url);
  }
  read(mediaKitId: number): Observable<Audience> {
    return this.http
      .get<Audience>(`${environment.mfServerUrl}/api/Audience/${mediaKitId}`);

  }

  create(audience: Audience): Observable<Audience> {
    return this.http.post<Audience>(`${environment.mfServerUrl}/api/Audience`, audience);
  }

  update(audience: Audience): Observable<Audience> {
    return this.http.put<Audience>(`${environment.mfServerUrl}/api/Audience`, audience);
  }

  delete(audienceId: number) {
    return this.http.delete<any>(`${environment.mfServerUrl}/api/Audience?audienceId=${audienceId}`);
  }
}
