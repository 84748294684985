
import { Component, OnInit, Input, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { SellersearchService } from 'src/app/core/api/services/sellersearch.service';
import { SellerSearchResult, SellerSearch } from '../core/api/defs/SearchSeller';
import { Publisher } from 'src/app/core/api/defs/Seller';
import { TempAuthRequest, SellerContact } from '../core/api/model';
import { ModalDirective, MDBModalService, MdbAutoCompleterComponent, MDBModalRef } from 'ng-uikit-pro-standard';
import { AlertService } from '../core/api/services/alertservice/alert.service';
import { AddContactComponent } from '../shared/add-contact/add-contact.component';
import { AddressSeller } from '../core/api/defs/AddressSeller';
import { AddAddressComponent } from '../shared/add-address/add-address.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

    @ViewChild('auto', /* TODO: add static flag */ {static:true})
    public completer: MdbAutoCompleterComponent;

    modalRef: MDBModalRef;

    searchText: string = '';

    sellerSearchResultData: Array<SellerSearch>;
    //canShowSearchResults: boolean = true;
    selectedSellerSearch: SellerSearch = new SellerSearch();

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.completer.selectedItemChanged().subscribe((data: any) => {
            this.searchText = data.text;
            this.selectedSellerSearch = this.sellerSearchResultData.find(x=> x.SellerName == data.text);
            this.router.navigate(['seller-onboarding/' + this.selectedSellerSearch.SellerId]);
        });
    }

    constructor(private sellerSearchService: SellersearchService,
        private alertService: AlertService,
        private modalService: MDBModalService,
        private router: Router,
        private renderer: Renderer2) {
    }

    onChange() {
        if (this.searchText == "") {
        }
        else {
            this.sellerSearchService.searchSeller(this.searchText).subscribe((data: SellerSearchResult) => {
                this.sellerSearchResultData = data.value;
            }, (error) => {
                console.log(error);
            });
        }
    }


}
