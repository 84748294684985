import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

import { BuyerofferacknowledgementRoutingModule } from './buyerofferacknowledgement-routing.module';
import { BuyerofferackComponent } from './pages/buyerofferack/buyerofferack.component';
import { OfferupdateComponent } from './pages/offerupdate/offerupdate.component';


@NgModule({
  declarations: [BuyerofferackComponent, OfferupdateComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    CoreModule,
    SharedModule,
    BuyerofferacknowledgementRoutingModule
  ]
})
export class BuyerofferacknowledgementModule { }
