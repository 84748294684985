<div class="row">
  <div class="col-sm-10 mx-auto">
    <div class="tab-pane fade show active">
      <div class="container-fluid">

        <div class="tab-pane fade in show active " id="panelSummary" role="tabpanel">
          <div class="my-2 mt-3 mfl-o text-center mf-tittle">
            <span class="ml-2">Seller Search</span>
          </div>
          <div class="jumbotron" style="padding:1.5rem">
            <div class="md-form">
              <input type="text" class="completer-input form-control mdb-autocomplete mb-0" [(ngModel)]="searchText"
                (ngModelChange)="onChange()" [mdbAutoCompleter]="auto" placeholder="Enter Seller Name" />
              <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="No results found">
                <mdb-option *ngFor="let option of sellerSearchResultData" [value]="option.SellerName">
                  <div class="d-flex flex-column">
                    <strong>{{ option.SellerName }}</strong>
                  </div>
                </mdb-option>
              </mdb-auto-completer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
