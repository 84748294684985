<div class="row">
  <div class="col-sm-10 mx-auto">
    <div class="breadCrumps">
      <mdb-breadcrumb customClass="blue-text lighten-4">
        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/buyer-onboarding/']">
          <mdb-icon fas class="mr-1"></mdb-icon> Back to Buyer Search
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/buyer-onboarding/', buyerid]">
          <mdb-icon fas class="mr-1"></mdb-icon> Back to Buyer Info
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
    <div class="tab-pane fade in show active" id="buyerAddressTabPane" role="tabpanel">
      <div class="my-2 mfl-o text-center mf-tittle">
        <span class="ml-2">Buyer Addresses</span>
      </div>
      <div class="jumbotron py-3">
        <div *ngIf="canShowBuyerAddressCard else noAddressBlock">
          <div class="row">
            <div *ngFor="let buyer of Buyeraddressdata; let i = index">
              <div class="col-sm-3 my-2">
                <div class="card" style="width: 16rem;">
                  <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">
                      <div class="text-right">
                        <a class="tittle1" (click)="editAddressOnClick(buyer)">Edit</a>
                      </div>
                      <div>
                        Address Type: {{buyer.addressTypeName}}
                        <br /> Address Description: {{buyer.addressDescription}}
                        <br /> AddressLine1: {{buyer.addressLine1}}
                        <br /> AddressLine2: {{buyer.addressLine2}}
                        <br /> AddressLine3: {{buyer.addressLine3}}
                        <br /> City: {{buyer.addressCity}}
                        <br /> State: {{buyer.addressStateCode}}
                        <br /> Zip Code: {{buyer.addressZip}}
                      </div>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <ng-template #noAddressBlock>
          <div>
            No address found
          </div>
        </ng-template>

        <div class="text-left pt-3">
          <button type="button" mdbBtn size="md" rounded="true"
            class="btn btn-secondary1 secondary relative waves-light" (click)="addAddressOnClick()" mdbWavesEffect>
            Add Address
          </button>
        </div>
      </div>
    </div>
  </div>
</div>