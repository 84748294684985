import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module'
import { SharedModule } from './shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { HeadercomponentComponent } from './headercomponent/headercomponent.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { reducers, metaReducers } from './reducers';
import { CopyrightComponent } from './footercomponent/copyright/copyright.component';
import { TypeinsearchComponent } from './headercomponent/typeinsearch/typeinsearch.component';
import { JwtInterceptor } from '../app/_interceptors/jwt-interceptor';
import { ErrorInterceptor } from '../app/_interceptors/error-interceptor';
import { AppInsightsInterceptor } from '../app/_interceptors/AppInsightsInterceptor';
import { LoginComponent } from './headercomponent/login/login.component';
import { RegistrationComponent } from './headercomponent/registration/registration.component';
import { EqualValidator } from './_interceptors/equal.validator';
import { EmailConfirmationComponent } from './headercomponent/emailConfirmation/emailConfirmation.component';
import { ResetPasswordComponent } from './headercomponent/resetPassword/resetPassword.component';
import { MyErrorHandler } from './MyErrorHandler';
import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';
import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { FeedbackFishModule } from 'feedback-fish-angular';

import { OnboardingModule } from './onboarding/onboarding.module';
import { OnboardingbuyerModule } from './onboardingbuyer/onboardingbuyer.module';
import { MaintenanceModule } from './MagazineMediakit/maintenance.module'
import { MdbSpinnerInterceptor } from './_interceptors/mdb-spinner-interceptor';
import { OfferModule } from './offer/offer.module';
import { CartModule } from './shoppingcart/cart.module';
import { HighlightPipe } from './customPipe/HighlightPipe';
import { HeaderOnlyLayoutComponent } from './layout/headerOnly/header-only-layout/header-only-layout.component';
import { MainLayoutComponent } from './layout/mainLayout/main-layout/main-layout.component';
import { RegistrationLiteComponent } from './headercomponent/registration-lite/registration-lite.component';
import { FullPageMainLayoutComponent } from './layout/fullPageMainLayout/full-page-main-layout/full-page-main-layout.component';
import { BrowseModule } from './browse/browse.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './headercomponent/forgot-password/forgot-password.component';
import { IssueComponent } from './MagazineMediakit/pages/issue/issue.component';
import { PaymentModule } from './payment/payment.module';
import {BuyerofferacknowledgementModule} from './buyerofferacknowledgement/buyerofferacknowledgement.module'
import { DownloadModule } from './download/download.module';
import { TermsandconditionsModule } from './termsandconditions/termsandconditions.module';
import { BuyeractivityModule } from './buyeractivity/buyeractivity.module';
import { SigninCallbackComponent } from './signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './signout-callback/signout-callback.component';
import { SilentRefreshComponent } from './silent-refresh/silent-refresh.component';
import { AuthGuardService } from './auth/service/auth-guard.service';
import { RedirectToLoginComponent } from './redirect-to-login/redirect-to-login.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    BrowseModule,
    SharedModule,
    OfferModule,
    CartModule,
    OnboardingModule,
    OnboardingbuyerModule,
    MaintenanceModule,
    PaymentModule,
    BuyerofferacknowledgementModule,
    DownloadModule,
    TermsandconditionsModule,
    BuyeractivityModule,
    AppRoutingModule,
    NgbModule, 
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FeedbackFishModule,
    NgMultiSelectDropDownModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
      metaReducers
    } ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.ApplicationInsightsInstrumentationKey
    }),
    // MDBBootstrapModule.forRoot()
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HeadercomponentComponent,
    CopyrightComponent,
    TypeinsearchComponent,
    LoginComponent,
    RegistrationComponent,
    EqualValidator,
    EmailConfirmationComponent,
    ResetPasswordComponent,
    HighlightPipe,
    HeaderOnlyLayoutComponent,
    MainLayoutComponent,
    RegistrationLiteComponent,
    FullPageMainLayoutComponent,
    ForgotPasswordComponent,
    IssueComponent,
    SigninCallbackComponent,
    SignoutCallbackComponent,
    SilentRefreshComponent,
    RedirectToLoginComponent
  ],
  providers: [
    AuthGuardService,
    //AuthenticationService,
    MDBSpinningPreloader,
    { provide: HTTP_INTERCEPTORS, useClass: MdbSpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppInsightsInterceptor, multi: true },
    { provide: ErrorHandler, useClass: MyErrorHandler },
    AppInsightsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
