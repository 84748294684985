import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Offers } from 'src/app/core/api/model';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { OfferService } from 'src/app/core/api/services/offer.service';
import { EditOfferackComponent } from 'src/app/shared/edit-offerack/edit-offerack.component';

@Component({
  selector: 'app-offerupdate',
  templateUrl: './offerupdate.component.html',
  styleUrls: ['./offerupdate.component.css']
})
export class OfferupdateComponent implements OnInit {

  modalRef: MDBModalRef;
  canShowBuyerDataCard: boolean = false;
  offerNumber: string;
  buyerData: Offers;

  constructor(private router: ActivatedRoute,
    private offerService: OfferService,
    private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2) {

    router.params.subscribe((params) => {
      this.offerNumber = params.offerNumber;
    })
  }

  ngOnInit(): void {
    this.getOfferData(this.offerNumber);
  }

  getOfferData(offerNumber: string) {
    this.offerService.getofferByNumber(offerNumber).subscribe((data: any) => {
      if (data != null) {
        this.canShowBuyerDataCard = true;
        this.offerNumber = data.offerNumber
        this.buyerData = data;
      }
      else {
        this.canShowBuyerDataCard = false;
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  editBuyerClick(offer) {
    this.modalRef = this.modalService.show(EditOfferackComponent, {
        class: 'modal-lg',
        ignoreBackdropClick: true,
        data: {
          data: offer,
          offerid: offer.offerId,
          offernumber: offer.offerNumber
        }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      if (result != null) {
        if (result.type == "offer") {
          // Invoke API to get the data
          this.getOfferData(this.offerNumber);
        }
      }
    });
}

}
