import * as __model from '../model';
export class OfferData {
        offerNumber?: string;
        offerId?: number;
        magazineName?: string;
        magazineId?: number;
        magazineCoverUrl?: string;
        sellerName?: string;
        offerState?: string;
        offerLineVms?: __model.offerLine[];
        adSize?: string;
        adType?: string;
        adColor?: string;
        adPrice?: number;
        fferredRate?: number;
        offerNotification?: string;
        currentResponder?: string;
}




export interface buyerInfo {
        buyerName?: string;
}
export interface sellerInfo {
        sellerName?: string;
}

