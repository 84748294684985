import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { OfferRoutingModule } from './offer-routing.module';
import { OfferPageComponent } from './pages/offer-page.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AcceptofferComponent } from './pages/acceptoffer/acceptoffer.component';
import { OfferhistoryComponent } from './pages/offerhistory/offerhistory.component';
import { OfferactivityComponent } from './pages/offeractivity/offeractivity.component';
import { OffertimerComponent } from './pages/offeractivity/offertimer.component';
import { SharedModule } from '../shared/shared.module';
import { DeclineOfferComponent } from './pages/decline-offer/decline-offer.component';
import { DeclineOfferConfirmationComponent } from './pages/decline-offer-confirmation/decline-offer-confirmation.component';


@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    OfferRoutingModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    CoreModule,
    SharedModule
  ],
  declarations: [OfferPageComponent,
    AcceptofferComponent,
    OfferhistoryComponent,
    OfferactivityComponent,
    OffertimerComponent,
    DeclineOfferComponent,
    DeclineOfferConfirmationComponent
  ],
  entryComponents: [AcceptofferComponent, 
    OfferhistoryComponent,
     DeclineOfferComponent,
     DeclineOfferConfirmationComponent]
})
export class OfferModule { }
