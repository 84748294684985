import { Component, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { DeclineOfferComponent } from '../decline-offer/decline-offer.component';

@Component({
  selector: 'app-decline-offer-confirmation',
  templateUrl: './decline-offer-confirmation.component.html'
})
export class DeclineOfferConfirmationComponent implements OnInit {

  offerId: number;
  action: Subject<any> = new Subject();
 
  constructor(public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private renderer: Renderer2) { }

  ngOnInit() {
  }

  showDeclineModal() {
    this.modalRef.hide();

    this.modalRef = this.modalService.show(DeclineOfferComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      data: {
        offerId: this.offerId,
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
    });
  }

}
