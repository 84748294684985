<div class="row">
  <div class="col-sm-10 mx-auto">
    <div class="container-fluid">
      <div class="breadCrumps">
        <mdb-breadcrumb customClass="blue-text lighten-4">
          <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance']">
            <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
          </mdb-breadcrumb-item>
          <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId]">
            <mdb-icon fas class="mr-1"></mdb-icon> {{sellerName}}
          </mdb-breadcrumb-item>
          <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId,'magazines']">
            <mdb-icon fas class="mr-1"></mdb-icon> Magazines
          </mdb-breadcrumb-item>
          <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineId]">
            <mdb-icon fas class="mr-1"></mdb-icon> {{magazineName}}
          </mdb-breadcrumb-item>
          <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineId, 'mediakits']">
            <mdb-icon fas class="mr-1"></mdb-icon> Mediakits
          </mdb-breadcrumb-item>
          <mdb-breadcrumb-item class="tittle3"
            [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineId, 'mediakit', mediakitId]">
            <mdb-icon fas class="mr-1"></mdb-icon> {{mediaKitName}}
          </mdb-breadcrumb-item>
          <mdb-breadcrumb-item class="active">
            <mdb-icon fas class="mr-1"></mdb-icon>Magazine Data
          </mdb-breadcrumb-item>
        </mdb-breadcrumb>
      </div>

      <div class="mt-3">
        <div class="mf3-center">
          <h3 class="ml-2">Magazine Data</h3>
          <hr />
        </div>
        <nav class="mt-3">
          <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist" style="background-color: #f5f5f5">
            <a class="nav-item nav-link active tittle3" [routerLink]="['demographics']" style="background: none; color: #17a2b8"
              id="nav-demographics-tab" data-toggle="tab" href="# nav-demographics" role="tab"
              aria-controls="nav-demographics" aria-selected="true">Demographics</a>
            <a class="nav-item nav-link tittle3" [routerLink]="['audience']" style="background: none; color: #17a2b8"
              id="nav-audience-tab" data-toggle="tab" href="#nav-audience" role="tab" aria-controls="nav-audience"
              aria-selected="false">Audience</a>
            <a class="nav-item nav-link tittle3" [routerLink]="['circulation']" style="background: none; color: #17a2b8"
              id="nav-circulation-tab" data-toggle="tab" href="#nav-circulation" role="tab"
              aria-controls="nav-circulation" aria-selected="false">Circulation</a>
            <a class="nav-item nav-link tittle3" [routerLink]="['rates']" style="background: none; color: #17a2b8"
              id="nav-rates-tab" data-toggle="tab" href="#nav-rates" role="tab" aria-controls="nav-rates"
              aria-selected="false">Rates</a>
            <a class="nav-item nav-link tittle3" [routerLink]="['issue']" style="background: none; color: #17a2b8"
              id="nav-rates-tab" data-toggle="tab" href="#nav-rates" role="tab" aria-controls="nav-rates"
              aria-selected="false">Issue</a>
          </div>
        </nav>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>