import { OnInit, Input, Component, Output, EventEmitter, OnChanges } from "@angular/core";

@Component({
    selector: 'app-mf-pagination',
    templateUrl: './mf-pagination.component.html'
})
export class MfPaginationComponent implements OnInit, OnChanges {

    @Input()
    TotalCount: number;

    @Input()
    CurrentPageIndex: number = 1;

    @Input()
    PageSize: number = 10;

    @Output()
    OnPageChanged = new EventEmitter<any>();

    TotalPages: number;

    pageChangeOnClick(currentPageIndex: number) {
        this.CurrentPageIndex = currentPageIndex;
        this.OnPageChanged.emit(this.CurrentPageIndex);
    }

    previousOnClick() {
        if (this.CurrentPageIndex > 1) {
            this.CurrentPageIndex--;
            this.OnPageChanged.emit(this.CurrentPageIndex);
        }
    }

    nextOnClick() {
        if (this.CurrentPageIndex < this.TotalPages) {
            this.CurrentPageIndex++;
            this.OnPageChanged.emit(this.CurrentPageIndex);
        }
    }

    get isPreviousDisabled() {
        return this.CurrentPageIndex == 1;
    }

    get isNextDisabled() {
        return this.CurrentPageIndex == this.TotalPages;
    }

    get isPaginationVisible() {
        return this.TotalCount > 0;
    }

    getPageNumbers(): Array<string> {
        let delta = 2;
        let range = []
        let rangeWithDots = []
        let l: number;

        range.push(1);

        if (this.TotalPages <= 1) {
            return range;
        }

        for (let i = this.CurrentPageIndex - delta; i <= this.CurrentPageIndex + delta; i++) {
            if (i < this.TotalPages && i > 1) {
                range.push(i);
            }
        }

        range.push(this.TotalPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push("...");
                }
            }

            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    ngOnInit() {
        // this.TotalPages = Math.ceil(this.TotalCount / this.PageSize);
    }

    ngOnChanges(){
        this.TotalPages = Math.ceil(this.TotalCount / this.PageSize);
    }
}