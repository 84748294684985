import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { UserRole } from 'src/app/enums/UserRole';
import { BuyerofferackComponent } from './pages/buyerofferack/buyerofferack.component';
import { OfferupdateComponent } from './pages/offerupdate/offerupdate.component';
import { AuthGuardService } from '../auth/service/auth-guard.service';

const routes: Routes = [
  {
    path: 'offer-account-acknowledgement',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: BuyerofferackComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFAccountingAdmin
          ]
        }
      }
    ]
  },
  {
    path: 'offer-account-acknowledgement/offer/:offerNumber',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: OfferupdateComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFAccountingAdmin
          ]
        }
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuyerofferacknowledgementRoutingModule { }
