import {
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';

import sortByReducer from './sortByReducer';
import mediaClassReducer from './mediaClassReducer';
import marketCategoryReducer from './marketCategoryReducer'
import publicationPercentageReducer from './percentagePublicationReducer';
import educationLevelReducer from './educationLevelReducer';
import employmentStatusReducer from './employmentStatusReducer';
import occupationReducer from './occupationReducer';

export default {
  mediaClassReducer: mediaClassReducer,
  sortByReducer: sortByReducer,
  marketCategoryReducer: marketCategoryReducer,
  publicationPercentageReducer: publicationPercentageReducer,
  educationLevelReducer: educationLevelReducer,
  employmentStatusReducer: employmentStatusReducer,
  occupationReducer: occupationReducer,

}

export interface State {

}

export const reducers: ActionReducerMap<State> = {

};

export const metaReducers: MetaReducer<State>[] = [];