<div class="row justify-content-center">
  <div class="col-sm-10">
    <div class="font-weight-bolder py-3">
      <div class="row col-sm-12">
        <div class="font-bold py-3">
          <i class="fas fa-arrow-left pr-2 tc-2" aria-hidden="true"></i>
          <a class="tc-2" [routerLink]="['/browse']">Back to Search & Browse</a>
        </div>
      </div>
    </div>
    <div>
      <div class="tab-pane fade in show active" id="MagazineTabPane" role="tabpanel">
        <div class="tittle1 card-header mf3-center mb-3">
          <h4 class="text-center font-weight-dark whiteColor">
            Selected Ads Cart
          </h4>
        </div>
        <div *ngIf="cartUpdates?.data.length else emptyResult">
          <div *ngIf="cartUpdates">
            <div class="card mb-3" *ngFor="let pub of cartUpdates?.data">
              <div class="card-body ml-4 mr-2">
                <h2 class="card-header-title mx-auto mb-4 lighten-5">
                  <span CLASS="tittle1"> {{pub.sellerName}}</span>
                </h2>
                <hr />
                <div *ngFor="let magazines of pub.magazines">
                  <div class="row">
                    <div class="col-sm-12 col-md-2 col-lg-2 text-center">
                      <div>
                        <img src="{{magazines.magazineCoverThumbnailUrl}}" class="img-thumbnail" alt="thumbnail"
                          style="width: 100px" />
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-10 col-lg-10">
                      <h5>
                        <a [routerLink]="['/browse/magazine-details/', magazines.magazineId, 'demographics']"
                          class="tittle3 mainTittle">
                          <strong>{{magazines.magazineName}}</strong>
                        </a>
                      </h5>

                      <table mdbTable borderless="true" class="mt-4">
                        <thead>
                          <th scope="col" class="tittle3">
                            Ad Type
                          </th>
                          <th scope="col" class="tittle3">
                            Ad Color
                          </th>
                          <th scope="col" class="tittle3">
                            Ad Size
                          </th>
                          <th scope="col" class="tittle3">
                            Rate Card
                          </th>
                          <th scope="col"></th>

                        </thead>
                        <tbody>
                          <tr mdbTableCol *ngFor="let item of magazines.wishlistLines">
                            <td>
                              {{item.adTypeName}}</td>
                            <td>
                              {{item.adColor}}
                            </td>
                            <td>
                              {{item.adSizeName}}
                            </td>
                            <td>
                              {{ '$' + item.rate}}
                            </td>
                            <td>
                              <a class="tittle1 saveremove" (click)="removeCartItem(item.wishlistLineId)">
                                <i class="far fa-trash-alt"></i>
                              </a>
                            </td>
                            <td>
                              <a class="tittle1 saveremove" (click)="createInitialOffer(item,magazines)">
                                Make Offer
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #emptyResult>
          <div class="container-fluid text-center py-5">
            Your Shopping Cart is empty
          </div>
        </ng-template>
      </div>

      <div class="pt-4" *ngIf="cartUpdates?.data.length">
        <app-mf-pagination [TotalCount]="totalCartCount" [CurrentPageIndex]="currentPageIndex"
          (OnPageChanged)="mfPaginationOnPageChanged($event)"></app-mf-pagination>
      </div>
    </div>
  </div>