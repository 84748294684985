<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="breadCrumps">
            <mdb-breadcrumb customClass="blue-text lighten-4">
                <mdb-breadcrumb-item class="tittle3" [routerLink]="['/buyer-onboarding']">
                    <mdb-icon fas class="mr-1"></mdb-icon> Back to Buyer Search
                </mdb-breadcrumb-item>
            </mdb-breadcrumb>
        </div>
        <div class="tab-pane fade in show active" id="buyerDataTabPane" role="tabpanel">
            <div class="my-2 mfl-o text-center mf-tittle">
                <span class="ml-2">Buyer Information</span>
            </div>
            <div class="jumbotron py-3">
                <div *ngIf="canShowBuyerDataCard else noDataBlock">
                    <div class="row">
                        <div class="col-sm-3 my-2">
                            <div class="card" style="width: 16rem;">
                                <div class="card-body">
                                    <div class="text-right">
                                        <a class="tittle1" (click)="editBuyerClick(datacard)">Edit</a>
                                    </div>
                                    <h6 class="card-subtitle mb-2 text-muted">
                                        <div>

                                            Name:{{datacard?.buyerName}}
                                            <br />Company Discription: {{datacard?.buyerCompanyDescription}}
                                            <br /> Buyer DBA: {{datacard?.buyerDBA}}
                                            <br /> Buyer DNB: {{datacard?.buyerDnB}}
                                            <br /> Buyer FEID: {{datacard?.buyerFEID}}
                                        </div>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #noDataBlock>
                    <div>
                        No Buyer Data found
                    </div>
                </ng-template>
                <div class="justify-content-center pt-3">
                    <button type="button" mdbBtn size="md" rounded="true"
                        class="btn btn-secondary1 secondary relative waves-light" (click)="buyerAddress()"
                        mdbWavesEffect>
                        Buyer Address
                    </button>
                    <button type="button" mdbBtn size="md" rounded="true"
                        class="btn btn-secondary1 secondary relative waves-light" (click)="buyerContact()"
                        mdbWavesEffect>
                        Buyer Contact
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>