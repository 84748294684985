import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as MagazineSearchActions from '../actions/magazine-search.action'
import * as fromRoot from '../reducers/searchstate.reducer';
import { buildSearchURI, buildPostBody, searchParameterValidator } from "./uriHelper";
import { MagazineSearch } from '../models/magazine-search.model'
import { environment } from 'src/environments/environment';

@Injectable()
export class SearchEffects {

    // private httpOptions = {
    //     // for realestat search
    //     headers: new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'api-key': '048D2DD5F4F0D835AD475B072286574E'
    //     })
    // };

    private httpOptions = {
        // for magazine search
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'api-key': environment.azureSearchApiKey
        })
    };

    constructor(private http: HttpClient, private actions$: Actions, private store: Store<fromRoot.State>) { }

    // @Effect()
    // search$: Observable<Action> = this.actions$.pipe(
    //     ofType<MagazineSearchActions.SearchBegin>(MagazineSearchActions.SEARCH_BEGIN),
    //     map(action => action.payload),
    //     withLatestFrom(this.store.pipe(select(fromRoot.getSearchState))),
    //     tap(x => console.log(x)),
    //     switchMap(([action, state]) => [
    //         new MagazineSearchActions.SearchComplete(action),
    //         new MagazineSearchActions.SearchComplete(action)
    //     ]));

    @Effect()
    search$: Observable<Action> = this.actions$.pipe(
        ofType<MagazineSearchActions.SearchBegin>(MagazineSearchActions.SEARCH_BEGIN),
        withLatestFrom(this.store.pipe(select(fromRoot.getSearchState))),
        switchMap(([action, state]) => {
            const x = state as fromRoot.SearchState;
            const searchURI = buildSearchURI(x.config, x.parameters);
            const postBody = buildPostBody(x.parameters, x.input.input, searchParameterValidator, x.searchFacets);
            this.http.post(searchURI, postBody, this.httpOptions).subscribe((json) => {
                const results: {}[] = json["value"];
                let count: number = json["@odata.count"];
                count = count >= 0 ? count : -1;
                this.store.dispatch(new MagazineSearchActions.SearchResults({ results: results, date: Date.now(), count: count }));
                const facets: { [key: string]: MagazineSearch.FacetResult[] } = json["@search.facets"];
                if (facets) {
                    if(x.searchFacets.initalized) {
                        this.store.dispatch(new MagazineSearchActions.SearchFacetUpdateValues({ facets: facets }));
                    }
                    else {
                        this.store.dispatch(new MagazineSearchActions.SearchFacetSetValues({ facets: facets }));
                    }
                }
            }, (errMsg) => {
                this.store.dispatch(new MagazineSearchActions.SearchError({ message: errMsg }));
            });
            return [new MagazineSearchActions.SearchComplete()];
        }),
        map(x => {
            return x;
        }));

    // @Effect()
    // search$: Observable<Action> = this.actions$.pipe(
    //     ofType<MagazineSearchActions.SearchBegin>(MagazineSearchActions.SEARCH_BEGIN),
    //     map(action => action.payload),
    //     withLatestFrom(this.store.pipe(select(fromRoot.getSearchState))),
    //     switchMap(([action, state]) => {
    //         const x = state as fromRoot.SearchState;
    //         const searchURI = buildSearchURI(x.config, x.parameters);
    //         const postBody = buildPostBody(x.parameters, x.input.input, searchParameterValidator, x.facets);
    //         this.http.post(searchURI, postBody, this.httpOptions).pipe(map(json => {
    //             const result = JSON.stringify(json);
    //             const results: {}[] = json["value"];
    //             let count: number = json["@odata.count"];
    //             count = count >= 0 ? count : -1;
    //             const facets: { [key: string]: MagazineSearch.FacetResult[] } = json["@search.facets"];
    //             return of(new MagazineSearchActions.SearchResults({ results: results, date: Date.now(), count: count }));
    //         }, (error) => {
    //             return [
    //                 new MagazineSearchActions.SearchError({ message: error.message })
    //             ]
    //         }));
    //     }))
}
