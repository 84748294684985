import { Component, OnInit, Renderer2 } from '@angular/core';
import { CartService } from 'src/app/core/api/services/cart.service';
import { shoppingCart, CreateOffer, offerLine, Offer, OfferData } from 'src/app/core/api/model';
import { AlertService } from '../../../core/api/services/alertservice/alert.service';
import { OfferService } from 'src/app/core/api/services/offer.service';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { CreateinitialofferComponent } from 'src/app/shared/createinitialoffer/createinitialoffer.component';
import { DeleteConfirmComponent } from 'src/app/shared/delete-confirm/delete-confirm.component';

@Component({
  selector: 'app-shoppingcart',
  templateUrl: './shoppingcart.component.html',
  styleUrls: ['./shoppingcart.component.scss']
})
export class ShoppingcartComponent implements OnInit {
  cartUpdates: shoppingCart;
  offeritem = [];
  offer: CreateOffer;
  totalCartCount: number = 0;
  currentPageIndex: number;
  pageSize: number = 10;
  offerList: CreateOffer[];
  modalRef: MDBModalRef;

  constructor(private cartService: CartService,
    private modalService: MDBModalService,
    private renderer: Renderer2,

    private alertService: AlertService,
    private offerService: OfferService) {

  }

  getAllWishlistItems(currentPageIndex: number) {
    this.cartService.getAllWishlist(currentPageIndex - 1, this.pageSize).subscribe((data: shoppingCart) => {
      this.totalCartCount = data.count;
      this.cartUpdates = data;
      this.goToTop();
    }, (err) => {
      this.alertService.error(err);
    });
  }

  // Method to remove cart item from cart
  removeCartItem(wishlistLineId) {

    this.modalRef = this.modalService.show(DeleteConfirmComponent, {
      class: 'modal-sm modal-notify modal-warning',
      ignoreBackdropClick: true,
      data: {
        deleteName: "Cart Item"
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      if (result.status) {

        this.cartService.delete(wishlistLineId).subscribe((data) => {

          this.cartService.shopingCartTotalItems();

          this.currentPageIndex = 1;
          this.getAllWishlistItems(this.currentPageIndex);

          this.alertService.success('Cart Item Deleted Successfully');

          this.goToTop();
        }, err => {
          this.alertService.error(err);
        });
      }

      this.modalRef.hide();
    });
  }
  createInitialOffer(cartItem, magazine) {
    this.modalRef = this.modalService.show(CreateinitialofferComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      data: {
        magazineid: magazine.magazineId,
        adRateid: cartItem.adRateId,
        selectedAdPrice: cartItem.rate,
        wishlistLineId: cartItem.wishlistLineId,
        page: 'shoppingcart'
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
      
      this.cartService.delete(result).subscribe((data) => {

        this.cartService.shopingCartTotalItems();

        this.currentPageIndex = 1;
        this.getAllWishlistItems(this.currentPageIndex);

        this.goToTop();
      }, err => {
        this.alertService.error(err);
      });
      // The values are sent in json forma
      //  this.getOfferData(this.magazineId);
    });
  }

  goToTop() {
    window.scrollTo(0, 0);
  }

  // This method is invoked as a callback from pagination. 
  mfPaginationOnPageChanged(currentPageNumber: any) {
    this.currentPageIndex = currentPageNumber;
    this.getAllWishlistItems(currentPageNumber);
  }

  ngOnInit() {
    this.currentPageIndex = 1;
    this.getAllWishlistItems(this.currentPageIndex);
  }

}