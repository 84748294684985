<div class="md-form my-0">
    <input type="text" style="color:#000F55;border-bottom-color:#333" class="completer-input form-control mdb-autocomplete mb-0" [(ngModel)]="searchInput"
        (ngModelChange)="onChange()" [mdbAutoCompleter]="auto" placeholder="Search Magazine Titles" />
    <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="No results found">
        <mdb-option *ngFor="let r of magazineSearchResultData" [value]="r.MagazineName">
            <div class="d-flex  align-items-left w-100">
                <img src="{{r.URL}}" />
                <span class="mt-1 pl-1" [innerHTML]="r.MagazineName | highlight : searchInput"></span>
            </div>
        </mdb-option>
    </mdb-auto-completer>
</div>