import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { AddressSeller, Publisher } from 'src/app/core/api/model';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { SellersearchService } from 'src/app/core/api/services/sellersearch.service';
import { AddAddressComponent } from 'src/app/shared/add-address/add-address.component';

@Component({
  selector: 'app-selleraddress',
  templateUrl: './selleraddress.component.html'
})
export class SelleraddressComponent implements OnInit {

  sellerDetails: Publisher;
  sellerAddresses: Array<AddressSeller>;
  canShowSellerAddressCard: boolean = false;
  sellerid: any;
  modalRef: MDBModalRef;
  constructor(private sellerSearchService: SellersearchService,
    private alertService: AlertService,
    private modalService: MDBModalService,
    private router: Router,
    private renderer: Renderer2,
    public route: ActivatedRoute) {
    route.params.subscribe(params => {
      this.sellerid = params.id;
      this.getSellerAddress(this.sellerid);
    })
  }
  getSellerAddress(sellerId: number) {
    this.sellerSearchService.getSellerAddresses(sellerId).subscribe((data: Array<AddressSeller>) => {
      this.sellerAddresses = data;
      this.canShowSellerAddressCard = data.length > 0;
    });
  }
  ngOnInit(): void {
  }

      // Code for Add Seller Address
      addAddressOnClick() {
        this.modalRef = this.modalService.show(AddAddressComponent, {
            class: 'modal-lg',
            data: {
                id: this.sellerid,
                type: "seller",
                isEdit: false
            }
        });

        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {

            // The values are sent in json format
            if (result != null) {

                if (result.type == "seller") {
                    // Invoke API to get the data
                    this.getSellerAddress(result.id);
                }
            }
        });
    }

    // code for editing Seller
    editAddressOnClick(address) {
        this.modalRef = this.modalService.show(AddAddressComponent, {
            class: 'modal-lg',
            data: {
                id: this.sellerid,
                type: "seller",
                data:address,
                isEdit:true
            }
        });

        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {

            // The values are sent in json format
            if (result != null) {

                if (result.type == "seller") {
                    // Invoke API to get the data
                    this.getSellerAddress(result.id);
                }
            }
        });

    }
    
}
