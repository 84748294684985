import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Offers, Offer, AcceptDeclineOffer, OfferData, Counter } from '../model';
import { OfferPayment } from '../defs/OfferPayment';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  public offerObservable = new Subject<any>();
  public offerLineObservable = new Subject<any>();

  constructor(private http: HttpClient) { }

  emitOfferConfig(val) {
    this.offerObservable.next(val);
  }

  emitOfferLineConfig(val) {
    this.offerObservable.next(val);
  }

  // TO DO:  CHECK THIS
  create(createOffer: Offers): Observable<Offers> {
    return this.http.post<Offers>(`${environment.mfServerUrl}/api/Offers/Post/InitialOffer`, createOffer);
  }

  post(createOffer: Offers): Observable<number> {
    return this.http.post<number>(`${environment.mfServerUrl}/api/Offers`, createOffer);
  }

  put(createOffer: Offers): Observable<number> {
    return this.http.put<number>(`${environment.mfServerUrl}/api/Offers/Put/InitialOffer`, createOffer);
  }

  getOfferDetails(offerId: number): Observable<Offers> {

    return this.http.get<Offers>(`${environment.mfServerUrl}/api/Offers/Get/InitialOffer/${offerId}`);  ///api/Offers{offerId}
  }

  getInitialOffer(offerId: number): Observable<Offers> {
    return this.http.get<Offers>(`${environment.mfServerUrl}/api/Offers/GetInitialOffer/${offerId}`)
  }
  submit(offerId: number): Observable<any> {
    return this.http.post<any>(`${environment.mfServerUrl}/api/Offers/Post/SubmitInitialOffer/${offerId}`, offerId);
  }


  getOfferLines(offerId: number): Observable<any> {
    return this.http.get<any>(`${environment.mfServerUrl}/api/OfferLine/${offerId}`)
  }

  submitCounterOffer(counter: Counter): Observable<any> {
    return this.http.post<any>(`${environment.mfServerUrl}/api/Offers/Post/Counter`, counter);
  }

  // submit(createOffer: CreateOffer): Observable<CreateOffer> {
  //   return this.http.post<CreateOffer>(`${environment.mfServerUrl}/api/Offers/Submit`, createOffer);
  // }

  readByPage(page: Number): Observable<Offer> {
    return this.http.get<Offer>(`${environment.mfServerUrl}/api/Offers?pageIndex=${page}&pageSize=10&filterBy=All`);
  }

  accept(offerId: number): Observable<any> {
    return this.http.post<any>(`${environment.mfServerUrl}/api/Offers/Post/Accept/${offerId}`, offerId);
  }

  decline(AcceptDeclineOffer: AcceptDeclineOffer): Observable<any> {
    return this.http.post<any>(`${environment.mfServerUrl}/api/Offers/Post/Decline`, AcceptDeclineOffer);
  }

  offerhistory(offerId) {
    return this.http.get<Offer>(`${environment.mfServerUrl}/api/OfferHistory/${offerId}`);
  }

  getReason() {
    return this.http.get(`${environment.mfServerUrl}/api/Reason`)
  }

  delete(offerid: number) {
    return this.http.delete(`${environment.mfServerUrl}/api/Offers/Delete/InitialOffer/${offerid}`)
  }

  deleteLine(LineId: number) {
    return this.http.delete(`${environment.mfServerUrl}/api/OfferLine/${LineId}`)
  }
  getofferById(offerId) {
    return this.http.get<Offer>(`${environment.mfServerUrl}/api/Offers/${offerId}`);
  }
  getofferByNumber(offerNumber) {
    return this.http.get<Offer>(`${environment.mfServerUrl}/api/Offers/OfferNumber/${offerNumber}`);
  }

  paymentAcknowledgment(offerPayment: OfferPayment) {
    return this.http.put<OfferPayment>(`${environment.mfServerUrl}/api/Offers/PaymentReceivedAcknowledgement`, offerPayment);
  }
}
