import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BuyeraccountacknowledgementComponent} from './pages/buyeraccountacknowledgement/buyeraccountacknowledgement.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { BuyerdetailComponent } from './pages/buyerdetail/buyerdetail.component';

@NgModule({
  declarations: [BuyeraccountacknowledgementComponent, BuyerdetailComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    CoreModule,
    SharedModule,
    PaymentRoutingModule 
  ]
})
export class PaymentModule { }
