import { Component, OnInit, Renderer2 } from '@angular/core';
import { CreateOffer, Offers } from '../../core/api/model';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { AlertService } from '../../core/api/services/alertservice/alert.service';
import { OfferService } from '../../core/api/services/offer.service';
import { Subject } from 'rxjs';
import { CreateinitialofferComponent } from '../createinitialoffer/createinitialoffer.component';

@Component({
  selector: 'app-submitoffer',
  templateUrl: './submitoffer.component.html'
})
export class SubmitofferComponent implements OnInit {
  selecteddate: any;
  isedit: boolean;
  adRateid: number;
  selectedAd: any;
  page: any;
  wishlistLineId?: number;
  action: Subject<any> = new Subject();
  offerId: number;
  offer: Offers;
  link: any;
  constructor(private modalService: MDBModalService,
    public modalRef: MDBModalRef,
    private renderer: Renderer2,
    private alertService: AlertService,
    private offerService: OfferService) { }

  ngOnInit() {
    this.link = 'https://sbcs.billerpayments.com/app/cust/login.do?bsn=mediafiche';
    this.offerService.getOfferDetails(this.offerId).subscribe(x => {
      this.offer = x;
    })
  }

  submitclick() {
    this.submiteditoffer(this.offer);
  }

  submiteditoffer(offer: any) {

    this.offerService.submit(offer.offerId).subscribe((data: any) => {

      // Set the json in Subscribe so that once the modal is closed 
      // API will be invoked to get the new results

      if (this.page == 'shoppingcart') {
        this.action.next(this.wishlistLineId);
      }
      else {
        this.action.next();
      }

      this.alertService.success('Offer Submitted Successfully');

    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  editOffer() {
    this.modalRef.hide();

    this.modalRef = this.modalService.show(CreateinitialofferComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      data: {
        offerid: this.offer.offerId,
        magazineid: this.offer.magazineId,
        page: this.page,
        wishlistLineId: this.wishlistLineId
      }
    });

    // TO DO : Need to revisit. this is temp fix.
    setTimeout(() => {
      //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
      this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
    }, 500);

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
      // The values are sent in json forma
      this.action.next(result);
    });
  }
}
