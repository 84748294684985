import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { AddaddressService } from 'src/app/core/api/services/addaddress.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { TelephoneType, MdbSelectOptions, ContactType } from 'src/app/core/api/model';
import { Subject, Subscription } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { AddessType } from '../../core/api/defs/AddressType';
import { States } from '../../core/api/defs/States';
import { Address } from '../../core/api/defs/Buyer';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html'
})
export class AddAddressComponent implements OnInit {

  addressTypeOptions: Array<any>;
  stateTypeOptions: Array<any>;

  id: number;
  type: string;
  data: Address;
  userName: any;
  currentDate: Date;
  isEdit: boolean;

  action: Subject<any> = new Subject();

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private addaddressService: AddaddressService,
    private alertService: AlertService, 
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);
    
    if(this.isAuthenticated){
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();
   
    // API to fetch the address types
    this.getAddressTypes();

    this.getStates();

    this.loadAddressDataOnInit();
  }

  addAddressForm = this.fb.group({
    addressId: [''],
    addressTypeId: ['', Validators.required],
    addressDescription: [''],
    addressLine1: ['', Validators.required],
    addressLine2: [''],
    addressLine3: [''],
    addressCity: ['', Validators.required],
    addressStateCode: ['', Validators.required],
    addressZip: ['', Validators.required],
    addressCountryCode: [{ value: 'US', disabled: true }, Validators.required],
    rowVersion: [''],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
    isActive: [true, Validators.required]
  });

  // Properties to use in html

  get addressId() { return this.addAddressForm.get('addressId'); }
  get addressTypeId() { return this.addAddressForm.get('addressTypeId'); }
  get addressDescription() { return this.addAddressForm.get('addressDescription'); }
  get addressLine1() { return this.addAddressForm.get('addressLine1'); }
  get addressLine2() { return this.addAddressForm.get('addressLine2'); }
  get addressLine3() { return this.addAddressForm.get('addressLine3'); }
  get addressCity() { return this.addAddressForm.get('addressCity'); }
  get addressStateCode() { return this.addAddressForm.get('addressStateCode'); }
  get addressZip() { return this.addAddressForm.get('addressZip'); }
  get addressCountryCode() { return this.addAddressForm.get('addressCountryCode'); }
  get rowVersion() { return this.addAddressForm.get('rowVersion'); }
  get isActive() { return this.addAddressForm.get('isActive'); }
  get createdOn() { return this.addAddressForm.get('createdOn'); }
  get createdBy() { return this.addAddressForm.get('createdBy'); }
  get modifiedOn() { return this.addAddressForm.get('modifiedOn'); }
  get modifiedBy() { return this.addAddressForm.get('modifiedBy'); }

  close(modalRef) {
    modalRef.hide();
  }

  validateForm() {
    Object.keys(this.addAddressForm.controls).forEach(field => {
      const control = this.addAddressForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }
  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  addAddressOnSubmit() {
    this.validateForm();

    if (this.addAddressForm.valid) {
      if (this.type == "buyer") {

        if (this.isEdit) {
          this.updateUserDetails();
          this.editBuyerAddress();
        }
        else {
          this.addUserDetails();
          this.addBuyerAddress()
        }
      }
      else if (this.type == "seller") {
        if (this.isEdit) {
          this.updateUserDetails();
          this.editSellerAddress();
        }
        else {
          this.addSellerAddress();
          this.addUserDetails();
        }
      }
    }
  }

  getAddressTypes() {
    this.addaddressService.getAddressTypes().subscribe((data: Array<AddessType>) => {

      if (data != null) {
        this.addressTypeOptions = data.map((AddessType) => {
          let option = new MdbSelectOptions();
          option.label = AddessType.addressTypeName;
          option.value = AddessType.addressTypeId;
          return option;
        });
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  getStates() {
    this.addaddressService.getStates('US').subscribe((data: Array<States>) => {
      if (data != null) {

        this.stateTypeOptions = data.map((state: States) => {
          let option = {
            label: state.stateName,
            value: state.stateCode
          };

          return option;
        });
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  loadAddressDataOnInit() {

    setTimeout(() => {
      if (this.data != null || this.data != undefined) {
        this.addAddressForm.patchValue(this.data);
      }
    });
  }

  addBuyerAddress() {
    this.addaddressService.addBuyerAddress(this.id, this.addAddressForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Address added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };
        this.action.next(json);

      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  editBuyerAddress() {
    this.addaddressService.editBuyerAddress(this.id, this.addAddressForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Address updated successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  addSellerAddress() {
    this.addaddressService.addSellerAddress(this.id, this.addAddressForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Address added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  editSellerAddress() {
    this.addaddressService.editSellerAddress(this.id, this.addAddressForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Address updated successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }
}
