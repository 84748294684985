import { Component, OnInit, Renderer2 } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import {AddRatesComponent} from '../../../shared/add-rates/add-rates.component'
import { RatesService } from 'src/app/core/api/services/rates.service';
import { DeleteConfirmComponent } from 'src/app/shared/delete-confirm/delete-confirm.component';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {

  selectedMediaKitId: any;

  modalRef: MDBModalRef;

  adRates:any;

  showAdrateList:boolean = false;
  
  constructor( private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private router: Router,
    private ratesService: RatesService,
    private route: ActivatedRoute,) { }
  optionsSelect: Array<any>;

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.selectedMediaKitId = params.mediaKitId;
    });

    this.getRates(this.selectedMediaKitId);
  }

  getRates(mediakitId){
    this.ratesService.read(mediakitId).subscribe((data: any) => {
      this.adRates = data;
      this.showAdrateList = true;
    })
  }
  addRatesClick() { 
    this.modalRef = this.modalService.show(AddRatesComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.selectedMediaKitId,
        isEdit: false
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {

      // The values are sent in json format
      this.getRates(this.selectedMediaKitId);
    });
  }

  editRatesClick(adrate) {
    this.modalRef = this.modalService.show(AddRatesComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: 1,
        isEdit: true,
        adrate: adrate
      }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.getRates(this.selectedMediaKitId);
    });
  } 
  deleteAdrate(adRateId) {
    this.modalRef = this.modalService.show(DeleteConfirmComponent, {
      class: 'modal-sm modal-notify modal-warning',
      ignoreBackdropClick: true,
      data: {
        deleteName : 'AdRate'
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result.status == 'delete') {
        this.ratesService.delete(adRateId).subscribe((data: any) => {
          this.alertService.success("Ad Rate is Deleted Successfully");  
          this.getRates(this.selectedMediaKitId);
        }, (errMsg) => {
          this.alertService.error(errMsg);
        });
      }
    });
  }

}


