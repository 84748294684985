import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject, Subscription } from 'rxjs';
import { MediakitService } from 'src/app/core/api/services/mediakit.service';
import { Audience } from 'src/app/core/api/defs/Audience';
import { AudienceService } from 'src/app/core/api/services/audience.service';
import { AudienceStaticGroup } from 'src/app/core/api/defs/AudienceStaticGroup';
import { AuthService } from 'src/app/auth/service/auth.service';
@Component({
  selector: 'app-add-audience',
  templateUrl: './add-audience.component.html'
})
export class AddAudienceComponent implements OnInit {

  id: number;

  type: string;

  audience: Audience;
  
  isEdit: boolean;

  optionsSelect: Array<any>;

  action: Subject<any> = new Subject();

  audiencegrouplist: Array<any>;

  dropdownList = [];

  settings = {};

  dropdownSettings = {};

  selectedElement = [];

  userName: any;
  
  currentDate: Date = new Date();

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private mediakitService: MediakitService,
    private alertService: AlertService,
    private audienceService: AudienceService, 
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if(this.isAuthenticated){
      this.userName = this.authService.name;
    }

    this.loadDataOnInit();
    this.getAdTypes();
    this.settings = {
      singleSelection: true,
      idField: 'audienceStatisticId',
      textField: 'audienceStatisticName',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      itemsShowLimit: 3,
      defaultOpen: false
    };
  }

  addAudienceForm = this.fb.group({
    audienceId: [null],
    mediaKitId: [null],
    audienceStatisticId: [null],
    value: [null, Validators.min(1)],
    isActive: [true, Validators.required],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
    rowVersion: ['']
  });

  // Properties to use in html
  get audienceId() { return this.addAudienceForm.get('value'); }
  get mediaKitId() { return this.addAudienceForm.get('mediaKitId'); }
  get audienceStatisticId() { return this.addAudienceForm.get('audienceStatisticId'); }
  get value() { return this.addAudienceForm.get('value'); }
  get isActive() { return this.addAudienceForm.get('isActive'); }
  get createdOn() { return this.addAudienceForm.get('createdOn'); }
  get createdBy() { return this.addAudienceForm.get('createdBy'); }
  get modifiedOn() { return this.addAudienceForm.get('modifiedOn'); }
  get modifiedBy() { return this.addAudienceForm.get('modifiedBy'); }


  close(modalRef) {
    modalRef.hide();
  }

  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  validateForm() {
    Object.keys(this.addAudienceForm.controls).forEach(field => {
      const control = this.addAudienceForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  getAdTypes() {
    this.audienceService.getAudienceStatistic().subscribe((data: Array<AudienceStaticGroup>) => {
      this.audiencegrouplist = data;
      if (this.isEdit) {
        this.audiencegrouplist.forEach((item) => {
          if (item.audienceStatisticId == this.audience.audienceStatisticId) {
            this.selectedElement = [item];
          }
        });
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  addAudienceOnSubmit() {

    this.validateForm();

    if (this.addAudienceForm.valid) {
      if (this.isEdit) {
        this.updateUserDetails();
        this.editAudience();
      }
      else {
        this.addUserDetails();
        this.addAudience()
      }
    }
  }

  loadDataOnInit() {

    setTimeout(() => {
      if (this.audience != null || this.audience != undefined) {
        this.addAudienceForm.patchValue(this.audience);
      }
    });

    this.mediaKitId.patchValue(this.id);
  }


  onItemSelect(item) {
    this.audienceStatisticId.patchValue(item.audienceStatisticId);
  }

  onDeSelect(item: any) {
    if (this.isEdit) {
      this.audienceStatisticId.patchValue(this.audience.audienceStatisticId);
    }
    this.audienceStatisticId.patchValue(null);
  }

  addAudience() {
    this.audienceService.create(this.addAudienceForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Audience added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: "edit"
        };
        this.action.next(json);

      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  editAudience() {
    this.audienceService.update(this.addAudienceForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Audience updated successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }
}