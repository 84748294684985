import { Injectable, Output } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { shoppingCart } from '../defs/ShoppingCart';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddcontactService {

  baseUrl : string =  `${environment.identityProviderRootUrl}`;

  constructor(private http: HttpClient) { }

  addSellerContact(id: number, contact: any){
    let url = this.baseUrl + `/api/Seller/${id}/Contact`;
    return this.http.post<any>(url, contact);
  }

  addBuyerContact(id: number, contact: any) : Observable<any> {
    let url = this.baseUrl + `/api/Buyer/${id}/Contact`;
    return this.http.post<any>(url, contact);
  }

  editSellerContact(id: number, contact: any){
    let url = this.baseUrl + `/api/Seller/${id}/Contact`;
    return this.http.put<any>(url, contact);
  }

  editBuyerContact(id: number, contact: any) : Observable<any> {
    let url = this.baseUrl + `/api/Buyer/${id}/Contact`;
    return this.http.put<any>(url, contact);
  }

  getTelephoneTypes(){
    let url = this.baseUrl + "/api/Contact/TelephoneType";
    return this.http.get(url);
  }

  getContactTypes(){
    let url = this.baseUrl + "/api/Contact/ContactType";
    return this.http.get(url);
  }

}
