import { Component, OnInit } from '@angular/core'
import { OfferService } from '../../../core/api/services/offer.service';
import { Offer, AcceptDeclineOffer, OfferData } from '../../../core/api/model';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { AlertService } from '../../../core/api/services/alertservice/alert.service';

@Component({
  selector: 'app-offerhistory',
  templateUrl: './offerhistory.component.html',
  styleUrls: ['./offerhistory.component.scss']
}) 
export class OfferhistoryComponent implements OnInit {
  constructor(public modalRef: MDBModalRef, private offerService: OfferService, private alertService: AlertService) { }
  offer?: OfferData;
  offerId: number;
  offerhistoryData:any;
  action: Subject<any> = new Subject();
  ngOnInit() {
    this.offerService.offerhistory(this.offerId).subscribe((response: any) => {
      if(response){
        this.offerhistoryData = response;
      }
    },(errMsg) => {
        this.alertService.error(errMsg);
      });
  
  }
  //Accept offer
  close(){
    this.modalRef.hide()
  }
    
}
