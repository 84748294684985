import * as MagazineSearchActions from '../actions/magazine-search.action'
import { environment } from '../../../../environments/environment';

export interface State {
  index: string,
  queryKey: string,
  service: string
}

export const InitialState: State = {
  index: environment.magazineSearchIndex,
  queryKey: '',
  service: environment.magazineSearchService
};

export function reducer(state = InitialState, action: MagazineSearchActions.Actions) {

  switch (action.type) {
    case MagazineSearchActions.SET_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
}
