import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartRoutingModule } from './cart-routing.module';
import { ShoppingcartComponent } from './pages/shoppingcart/shoppingcart.component';
import { MDBBootstrapModulesPro,MDBSpinningPreloader} from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    CartRoutingModule,
    SharedModule
  ],
  declarations: [ShoppingcartComponent]
})
export class CartModule { }