<form [formGroup]="addIssueForm" (ngSubmit)="addIssueOnSubmit()">
  <div class="modal-header">
    <div *ngIf="isEdit; else addIssueTemplate">
      <h5 class="modal-title tittle3" id="exampleModalLabel">Edit Issue</h5>
    </div>
    <ng-template #addIssueTemplate>
      <h5 class="modal-title tittle3" id="exampleModalLabel">Add Issue</h5>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <input mdbInput mdbValidate type="text" formControlName="issueName" class="form-control" id="issueName">
          <label for="issueName">Issue Name</label>
          <mdb-error *ngIf="issueName.invalid && (issueName.dirty || issueName.touched)">
            Required
          </mdb-error>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <input mdbInput type="text" formControlName="issueDescription" class="form-control" id="issueDescription">
          <label for="issueDescription">Issue Description</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <mdb-date-picker name="coverDate" [options]="myDatePickerOptions" label="Cover Date"
            formControlName="coverDate"></mdb-date-picker>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <mdb-date-picker name="spaceCloseDate" [options]="myDatePickerOptions" label="Space Close Date"
            formControlName="spaceCloseDate"></mdb-date-picker>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <mdb-date-picker name="onSaleDate" [options]="myDatePickerOptions" label="OnSale Date"
            formControlName="onSaleDate"></mdb-date-picker>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <mdb-date-picker name="materialsDueDate" [options]="myDatePickerOptions" label="Materials Due Date"
            formControlName="materialsDueDate"></mdb-date-picker>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <mdb-date-picker name="inHomeDate" [options]="myDatePickerOptions" label="InHome Date"
            formControlName="inHomeDate"></mdb-date-picker>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <mdb-date-picker name="specsDueDate" [options]="myDatePickerOptions" label="Specs Due Date"
            formControlName="specsDueDate"></mdb-date-picker>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <mdb-date-picker name="insertsDueDate" [options]="myDatePickerOptions" label="Inserts Due Date"
            formControlName="insertsDueDate"></mdb-date-picker>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="md-form  mt-0">
          <mdb-checkbox mdbValidate formControlName="isActive">
            Is Active
            <mdb-error *ngIf="isActive.invalid && (isActive.dirty || isActive.touched)">
              Required
            </mdb-error>
          </mdb-checkbox>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn waves-light btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()"
      mdbWavesEffect>Close</button>
  </div>
</form>