import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../auth/service/auth-guard.service';
import { UserRole } from '../enums/UserRole';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { BuyeractivitylistComponent } from './buyeractivitylist/buyeractivitylist.component';
import { BuyerlistComponent } from './buyerlist/buyerlist.component';


const routes: Routes = [
    {
      path: 'buyer-activity',
      component: MainLayoutComponent,
      children: [
        {
          path: '',
          component: BuyerlistComponent,
          canActivate: [AuthGuardService],
          data: {
            roles: [
              UserRole.MFMarketingAdmin
            ]
          }
        }
      ]
    },
    {
      path: 'buyer-activity/:id',
      component: MainLayoutComponent,
      children: [
        {
          path: '',
          component: BuyeractivitylistComponent,
          canActivate: [AuthGuardService],
          data: {
            roles: [
              UserRole.MFMarketingAdmin
            ]
          }
        }
      ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuyeractivityRoutingModule { }
