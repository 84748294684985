import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Tag } from "../model";


@Injectable({
    providedIn: 'root'
})
export class TagService {

    baseUrl: string = `${environment.apiRootUrl}`;

    constructor(private http: HttpClient) { }

    getAllTags(searchText?: any): Observable<Array<Tag>> {

        let tagUrl = `${this.baseUrl}/api/Tag`

        if (searchText != null) {
            tagUrl = tagUrl + `?searchText=${searchText}`;
        }

        return this.http.get<Array<Tag>>(tagUrl);
    }

    addTag(tag: Tag) {
        return this.http.post(`${this.baseUrl}/api/Tag`, tag);
    }

}