import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEventType } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { MDBSpinningPreloader } from "ng-uikit-pro-standard";

@Injectable()
export class MdbSpinnerInterceptor implements HttpInterceptor{
    Request:any;
    constructor(private mdbSpinningPreloader: MDBSpinningPreloader){
    }
    intercept(request: HttpRequest<any>, next: HttpHandler){
          this.Request = request
        return next.handle(this.Request).pipe(
            tap(res=>{
                if(this.Request.url.indexOf('titlesearch') == -1){
                    if(res.type== HttpEventType.Sent){
                        // show loader when api is invoked
                        this.mdbSpinningPreloader.start();}
                    if(res.type === HttpEventType.Response){
                        // hide loader when api received the response
                        this.mdbSpinningPreloader.stop();
                    }
            }
              
            })
        );
    }
}