import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private mdbSpinningPreloader: MDBSpinningPreloader) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(catchError(err => {

                let errorMessage = '';
                if (err.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `Error: ${err.message}`;
                }
                else if (err.error instanceof Blob) {
                    if (err.status == 422) {
                        errorMessage = err.message;
                    }
                }
                else if (err.status == 422) {
                    // server-side error
                    errorMessage = err.error.message;//`Error Code: ${error.status}\nMessage: ${error.message}`;
                }
                else {
                    errorMessage = err.message;
                }

                this.mdbSpinningPreloader.stop();

                return throwError(errorMessage);
            }));
    }
}