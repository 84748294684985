import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/service/auth.service';

@Component({
  selector: 'app-signin-callback',
  templateUrl: './signin-callback.component.html',
  styleUrls: ['./signin-callback.component.css']
})
export class SigninCallbackComponent implements OnInit {

  constructor(private authService: AuthService,
    private router: Router) {
  }

  ngOnInit() {
    this.authService.completeAuthentication().then(_ => {
      this.authService.localStorageConfig.stateStore.get("returnUrl").then(x => {

        x = x == null ? "/": x;
       
        this.router.navigate([x], { replaceUrl: true })
      })
    });
  }

}
