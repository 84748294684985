import { OnInit, Component, Renderer2 } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SellersearchService } from "src/app/core/api/services/sellersearch.service";
import { AlertService } from "src/app/core/api/services/alertservice/alert.service";
import { Seller } from "src/app/core/api/defs/Seller";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { AddSellerComponent } from "src/app/shared/add-seller/add-seller.component";
import { BreadCrumbs } from 'src/app/core/api/defs/BreadCrumbs';
import { BreadCrumbsService } from "src/app/core/api/services/breadCrumbs.service";

@Component({
    selector: 'app-seller-info',
    templateUrl: './sellerInfo.component.html'
})
export class SellerInfoComponent implements OnInit {

    modalRef: MDBModalRef;
    canShowSellerDataCard: boolean = false;
    sellerId: number;
    sellerData: Seller;

    constructor(private router: ActivatedRoute,
        private sellerSearchService: SellersearchService,
        private alertService: AlertService,
        private modalService: MDBModalService,
        private renderer: Renderer2,
        private breadCrumbsService: BreadCrumbsService) {

        router.params.subscribe((params) => {
            this.sellerId = params.sellerId;
        })
    }

    ngOnInit(): void {
        this.getSellerData(this.sellerId);
    }

    getSellerData(sellerId: number) {

        this.sellerSearchService.getSellerDetails(sellerId).subscribe((data: Seller) => {
            if (data != null) {
                this.canShowSellerDataCard = true;
                this.sellerId = data.sellerId
                this.sellerData = data;
            }
            else {
                this.canShowSellerDataCard = false;
            }
        }, (errMsg) => {
            this.alertService.error(errMsg);
        });
    }

    editSellerClick(datacard) {
        this.modalRef = this.modalService.show(AddSellerComponent, {
            class: 'modal-lg',
            data: {
                id: this.sellerData.sellerId,
                type: "seller",
                data: datacard,
                isEdit: true

            }
        });
        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {
            // The values are sent in json format
            if (result != null) {
                if (result.type == "seller") {
                    // Invoke API to get the data
                    this.getSellerData(result.id);
                    //this.selectedSellerSearch.SellerId = result.id;

                }
            }
        });
    }
}