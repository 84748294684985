import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { AlertComponent } from 'src/app/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  modalRef: MDBModalRef;

  constructor(private modalService: MDBModalService) {
  }

  success(message: string) {
    this.modalService.show(AlertComponent, {
      class: 'modal-sm modal-frame modal-top',
      data: {
        message: message,
        type: "Success"
      }
    });
  }

  error(message: string) {
    this.modalService.show(AlertComponent, {
      class: 'modal-sm modal-frame modal-top',
      data: {
        message: message,
        type: "Error"
      }
    });
  }
}
