<div class="row sidebar">
  <div class="container-fluid my-3">
    <div style="flex-direction: row-reverse; display: flex; flex: 1;" class="mt-3" *ngIf="!getUser">
      <a class="mr-1" class="tittle1" (click)="reload();">
        <span> clear filter(s)</span>
      </a>
    </div>
    <div class="mt-3 labelColor" *ngIf="getUser">
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6">
          <a class="tittle1" (click)="toggleFilters()" id="bt">
            {{buttonName}}
          </a>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6" style="text-align:right">
          <a class="mr-1" class="tittle1" (click)="reload();">
            <span> clear filter(s)</span>
          </a>
        </div>
      </div>
    </div>
    <div class="mt-3 labelColor">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="materialUnchecked" [(ngModel)]="nationalPublication"
          (change)="isusnationalCheck('IsUsNational',$event)">
        <label class="form-check-label" for="materialUnchecked">National Publications</label>
      </div>
    </div>
    <div class="mt-3 labelColor">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="isRegionalUnchecked" [(ngModel)]="IsRegionalCheck"
          (change)="isRegionalCheck('IsRegional',$event)">
        <label class="form-check-label" for="isRegionalUnchecked">Regional</label>
      </div>
    </div>

    <div class="mt-3 labelColor">
      <label>Media Class</label>
      <app-multiselect [items]="mediaClassValue$ | async" type="AdTypes"
        (selectItem)="multiSelect('MediaClassificationName',$event)"
        (deSelectItem)="multiDeselect('MediaClassificationName',$event)"
        (selectAllItems)="multiSelectAll('MediaClassificationName',$event)"
        (deSelectAllItems)="multiDeselectAll('MediaClassificationName',$event)" [magazine]="chooseAdSelected">
      </app-multiselect>
    </div>


    <div class="mt-3 labelColor">
      <label>Sort By</label>
      <app-singleselect [singleSelectItems]=sortByItems [selectedItem]=sortBySelected (select)="sortBySelect($event)"
        (deSelect)="sortByDeSelect($event)"></app-singleselect>
    </div>
    <div class="mt-3 labelColor">
      <label>Keyword(s)</label>
      <input class="form-control" [(ngModel)]="searchText" #input placeholder="Search...">
    </div>
    <div class="mt-3 labelColor">
      <label>Market Category</label>
      <app-multiselect [items]="marketCategoryValue$ | async" type="AdTypes"
        (selectItem)="multiSelect('MarketCategories',$event)" (deSelectItem)="multiDeselect('MarketCategories',$event)"
        (selectAllItems)="multiSelectAll('MarketCategories',$event)"
        (deSelectAllItems)="multiDeselectAll('MarketCategories',$event)" [magazine]="chooseAdSelected">
      </app-multiselect>
    </div>

    <!-- <div class="mt-3 labelColor">
      <label>Choose Ads</label>
      <app-multiselect [items]="adTypesValues$ | async" type="AdTypes" (selectItem)="multiSelect('AdTypes',$event)"
        (deSelectItem)="multiDeselect('AdTypes',$event)" (selectAllItems)="multiSelectAll('AdTypes',$event)"
        (deSelectAllItems)="multiDeselectAll('AdTypes',$event)" [magazine]="chooseAdSelected"></app-multiselect>
    </div>
     <div class="mt-3 labelColor">
      <label>Publication Frequency</label>
      <app-multiselect [items]="publicationFrequencyValues$ | async" type="PublicationFrequency"
        (selectItem)="multiSelect('PublicationFrequency',$event)"
        (deSelectItem)="multiDeselect('PublicationFrequency',$event)"
        (selectAllItems)="multiSelectAll('PublicationFrequency',$event)"
        (deSelectAllItems)="multiDeselectAll('PublicationFrequency',$event)" [magazine]="publicationFrequencySelected">
      </app-multiselect>
    </div>
    <div class="mt-3 pb-2 labelColor">
      <label>Issue Date</label>
      <app-multiselect [items]="issueDateValues$ | async" type="Issues" (selectItem)="multiSelect('Issues',$event)"
        (deSelectItem)="multiDeselect('Issues',$event)" (selectAllItems)="multiSelectAll('Issues',$event)"
        (deSelectAllItems)="multiDeselectAll('Issues',$event)" [magazine]="issueDateValueSelected"></app-multiselect>
    </div> -->
    <div *ngIf="show" class="mt-3 labelColor">
      <label>Average Household Income</label>
      <app-singleselectstatic [items]="sortByHHI" [selectedItem]=sortBySelectedHHI type="Household_Income"
        (select)="multiStaticSelect('Household_Income',$event)"
        (selectAllItems)="multiStaticSelectAll('Household_Income',$event)"
        (deSelectAllItems)="multiStaticDeselectAll('Household_Income',$event)"
        (deSelect)="multiStaticDeselect('Household_Income',$event)"></app-singleselectstatic>
    </div>
    <div *ngIf="show" class="mt-3 labelColor">
      <label>Average Household Net Worth</label>
      <app-singleselectstatic [items]="sortByHHNW" [selectedItem]=sortBySelectedHHNWI type="Net_Worth"
        (select)="multiStaticSelect('Net_Worth',$event)" (selectAllItems)="multiStaticSelectAll('Net_Worth',$event)"
        (deSelectAllItems)="multiStaticDeselectAll('Net_Worth',$event)"
        (deSelect)="multiStaticDeselect('Net_Worth',$event)">
      </app-singleselectstatic>
    </div>
    <div class="mt-3 labelColor">
      <label>Circulation</label>
      <app-singleselectstatic [items]="circulation" [selectedItem]=sortBySelectedCirculation type="Circulation"
        (select)="multiStaticSelect('Circulation',$event)" (selectAllItems)="multiStaticSelectAll('Circulation',$event)"
        (deSelectAllItems)="multiStaticDeselectAll('Circulation',$event)"
        (deSelect)="multiStaticDeselect('Circulation',$event)"></app-singleselectstatic>
    </div>
    <div *ngIf="show" class="mt-3 labelColor">
      <label>Median Reader Age</label>
      <app-singleselectstatic [items]="age" [selectedItem]=sortByAge type="Circulation"
        (select)="multiStaticSelect('Age',$event)" (selectAllItems)="multiStaticSelectAll('Age',$event)"
        (deSelectAllItems)="multiStaticDeselectAll('Age',$event)" (deSelect)="multiStaticDeselect('Age',$event)">
      </app-singleselectstatic>
    </div>
    <div *ngIf="show" class="mt-3 labelColor">
      <label>Education</label>
      <app-singleselectstatic [items]="education" [selectedItem]=sortByEducation type="education"
        (select)="singleEducationStaticSelect('Education',$event)"
        (selectAllItems)="multiStaticSelectAll('Education',$event)"
        (deSelectAllItems)="multiStaticDeselectAll('Education',$event)"
        (deSelect)="singleEducationStaticDeSelect('Education',$event)"></app-singleselectstatic>
    </div>
  </div>