import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { OfferService } from 'src/app/core/api/services/offer.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject } from 'rxjs';
import { Offers, MdbSelectOptions } from 'src/app/core/api/model';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-offercounter',
  templateUrl: './offercounter.component.html'
})
export class OffercounterComponent implements OnInit {
  offerid: number;
  offer: Offers;
  action: Subject<any> = new Subject();
  reasonTypeOptions : Array<any>;

  constructor(public modalRef: MDBModalRef,
    private offerService: OfferService,
    private alertService: AlertService,
    private fb: FormBuilder) { }

  counterOfferForm = this.fb.group({
    offerId: [''],
    instructions: [''],
    isFinalOffer: [false],
    offeredRate: ['', Validators.required],
    reasonId: ['', Validators.required]
  });

  get offerId() { return this.counterOfferForm.get('offerId'); }
  get instructions() { return this.counterOfferForm.get('instructions'); }
  get isFinalOffer() { return this.counterOfferForm.get('isFinalOffer'); }
  get offeredRate() { return this.counterOfferForm.get('offeredRate'); }
  get reasonId() { return this.counterOfferForm.get('reasonId'); }

  ngOnInit() {
    this.getReasons();
  }

  counterOfferFormOnSubmit() {

    this.validateForm();
    
    if (this.counterOfferForm.valid) {
      this.offerId.patchValue(this.offerid);
      this.offerService.submitCounterOffer(this.counterOfferForm.getRawValue()).subscribe((response: any) => {
        this.alertService.success('Offer Countered successfully');
        this.action.next(response);
        this.modalRef.hide();
      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
    }
  }

  getReasons() {
    this.offerService.getReason().subscribe((response: any) => {
      this.reasonTypeOptions = response.map((reasonType) => {
        let option = new MdbSelectOptions();
        option.label = reasonType.reasonName;
        option.value = reasonType.reasonId;

        return option;
      });
    });
  }

  validateForm() {
    Object.keys(this.counterOfferForm.controls).forEach(field => {
      const control = this.counterOfferForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  close() {
    this.modalRef.hide();
  }
}