import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbAutoCompleterComponent, MDBModalRef, MDBModalService, ModalDirective } from 'ng-uikit-pro-standard';
import { Offers } from 'src/app/core/api/defs/Offers';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { OfferService } from 'src/app/core/api/services/offer.service';

@Component({
  selector: 'app-buyerofferack',
  templateUrl: './buyerofferack.component.html'
})
export class BuyerofferackComponent implements OnInit {

  constructor(private offerService: OfferService,
    private alertService: AlertService,
    private router: Router,
    private fb: FormBuilder) {
  }
  ngOnInit() {
  }

  offerAckForm = this.fb.group({
    offerNumber: ['', Validators.required]
  });

  get offerNumber() { return this.offerAckForm.get('offerNumber'); }


  validateForm() {
    Object.keys(this.offerAckForm.controls).forEach(field => {
      const control = this.offerAckForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }


  offerAckFormSubmit() {

    this.validateForm();

    if (this.offerAckForm.valid) {
      this.offerService.getofferByNumber(this.offerNumber.value).subscribe((data: any) => {

        if (data != null) {
          this.router.navigateByUrl("offer-account-acknowledgement/offer/" + data.offerNumber);
        }
        else {
          this.alertService.error("Please enter valid Offer Id");
        }
      }, (error) => {
        this.alertService.error(error);
      });
    }
  }

}