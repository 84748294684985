import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsandconditionsRoutingModule } from './termsandconditions-routing.module';
import { TermsandconditionComponent } from './pages/termsandcondition/termsandcondition.component';


@NgModule({
  declarations: [TermsandconditionComponent],
  imports: [
    CommonModule,
    TermsandconditionsRoutingModule
  ]
})
export class TermsandconditionsModule { }
