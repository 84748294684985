<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="breadCrumps">
            <mdb-breadcrumb customClass="blue-text lighten-4">
                <mdb-breadcrumb-item class="tittle3" [routerLink]="['/buyer-account-acknowledgement']">
                    <mdb-icon fas class="mr-1"></mdb-icon> Back to Buyer Search
                </mdb-breadcrumb-item>
            </mdb-breadcrumb>
        </div>
        <div *ngIf="canShowBuyerDataCard" class="tab-pane fade in show active" id="BuyerTabPane" role="tabpanel">
            <div class="my-2 mfl-o mf3-center mf-tittle">
                <span class="ml-2">Buyer Information</span>
            </div>
            <div class="jumbotron py-3">
                <table class="table table-hover buyertable">
                    <thead>
                        <tr>
                            <th style="width:8%">Buyer Id</th>
                            <th style="width:8%">Buyer Name</th>
                            <th style="width:8%">Company Description</th>
                            <th style="width:8%">Is Payment Account Setup</th>
                            <th style="width:8%">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="cursor: pointer;">
                            <td style="width:8%">
                                {{buyerData?.buyerId}}</td>
                            <td style="width:8%">
                                {{buyerData?.buyerName}}</td>
                            <td style="width:8%">
                                {{buyerData?.buyerCompanyDescription}}</td>
                            <td style="width:8%">
                                {{buyerData?.isPaymentAccountSetup}}</td>
                            <td style="width:8%"> <span (click)="editBuyerClick(buyerData)">
                                    <mdb-icon fas icon="edit"></mdb-icon>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>