import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { MagazineService } from './api/services/magazine.service';
import { reducers } from './search/reducers/searchstate.reducer';
import { SearchEffects } from './search/effects/search.effect';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('search', reducers),
    EffectsModule.forFeature([SearchEffects]),
  ],
  declarations: [],
  providers: [MagazineService]
})
export class CoreModule { }
