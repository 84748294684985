import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { TagService } from 'src/app/core/api/services/tag.service';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.css']
})
export class AddTagComponent implements OnInit {

  userName: any;
  currentDate: Date;
  action: Subject<any> = new Subject();
  isAuthenticated: boolean;

  subscription: Subscription;
  isEdit: boolean;


  constructor(public modalRef: MDBModalRef,
    private tagService: TagService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();

  }

  addTagDetails = this.fb.group({
    tagId: [''],
    tagName: ['', Validators.required],
    isActive: [true],
    rowVersion: [''],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: ['']
  })


  get tagId() { return this.addTagDetails.get('tagId'); }
  get tagName() { return this.addTagDetails.get('tagName'); }
  get isActive() { return this.addTagDetails.get('isActive'); }
  get createdOn() { return this.addTagDetails.get('createdOn'); }
  get createdBy() { return this.addTagDetails.get('createdBy'); }
  get modifiedOn() { return this.addTagDetails.get('modifiedOn'); }
  get modifiedBy() { return this.addTagDetails.get('modifiedBy'); }


  validateForm() {
    Object.keys(this.addTagDetails.controls).forEach(field => {
      const control = this.addTagDetails.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  tagSubmit() {
    this.validateForm();
    if (this.addTagDetails.valid) {
      this.addUserDetails();
      this.addTags();

    }
  }

  addTags() {
    this.tagService.addTag(this.addTagDetails.value).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Tags added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: data.tagId,
        };

        this.action.next(json);

      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

}
