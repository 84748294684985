import * as __model from '../model';

export class ContactData {
  contactType?: string;
  firstName?: string;
  lastName?: string;
  contactTitle?: string;
  email?: string;
  phone?: string;
  ext?: string;
  addresses?: __model.Address[];
}
