<div *ngIf="offerlines">
  <div class="row mt-3">
    <div class="col">
      <strong class="td-u font-weight-bold">
        Number
      </strong>
    </div>
    <div class="col">
      <strong class="td-u font-weight-bold ">
        <span>Cover Date </span>
      </strong>
    </div>
    <div class="col">  
        <strong class="td-u font-weight-bold">Offered Price Per Insertion</strong>
    </div>
    <div class="col" *ngIf="offerstatus == 'New Offer'">
      <div *ngIf="offerstatus == 'New Offer'"></div>
    </div>
  </div>
  <div *ngFor="let line of offerline" class="row mt-3">
    <div class="col">
      <div *ngIf="line.offerLineNumber" class="tc-2 ">
        &nbsp;{{line.offerLineNumber}}
      </div>
    </div>
    <div class="col">
      <div *ngIf="line.coverDate" class="tc-2 ">
        &nbsp;{{line.coverDate}}
      </div>
    </div>
    <div class="col">
      <div class="tc-2">
        {{ line.rate | number: '1.2-2'}}
      </div>
    </div>
    <div class="col" *ngIf="offerstatus == 'New Offer'">
      <div *ngIf="offerstatus == 'New Offer'" style="width:8%; color:#FF7500"><a><i class="far fa-trash-alt"
            (click)="deleteLine(line.offerLineId)"></i></a>
      </div>
    </div>
  </div>
  <div class="row justify-content-end" *ngIf="showdetails"><a *ngIf="offerLineCount > 2" style="color:#007bff" (click)="showOfferLineModal()">Show More</a></div>
</div>
