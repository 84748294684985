import * as MagazineSearchActions from '../actions/magazine-search.action';

export interface State {
  error: string;
}

export const InitialState: State = {
  error: ''
};

export function reducer(state = InitialState, action: MagazineSearchActions.Actions) {

  switch (action.type) {
    case MagazineSearchActions.SEARCH_ERROR:
      return { ...state, error: action.payload.message };
    case MagazineSearchActions.SEARCH_RESET:
      state = InitialState;
      return state;
    default:
      return state;
  }
}
