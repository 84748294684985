import * as MagazineSearchActions from '../actions/magazine-search.action'
import { MagazineSearch } from '../models/magazine-search.model';

export interface State {
  facetMode: MagazineSearch.FacetMode,
  globalFilters: { [key: string]: string },
  facets: { [key: string]: MagazineSearch.Facet },
  initalized: boolean
}

export const InitialState: State = {
  facetMode: "simple",
  globalFilters: {},
  facets: {},
  initalized: false
};

export function reducer(state = InitialState, action: MagazineSearchActions.Actions) {
  switch (action.type) {
    case MagazineSearchActions.SEARCH_FACETS_CLEAR:
      return clearFacetsSelections(state, action);
    case MagazineSearchActions.SEARCH_FACETS_ADD_CHECKBOX:
      return addCheckboxFacet(state, action);
    case MagazineSearchActions.SEARCH_FACETS_ADD_RANGE:
      return addRangeFacet(state, action);
    case MagazineSearchActions.SEARCH_FACETS_TOGGLE_CHECKBOX:
      return toggleFacetSelection(state, action);
    case MagazineSearchActions.SEARCH_FACETS_SINGLE_TOGGLE_CHECKBOX:
      return toggleFacetSingleSelection(state, action);
    case MagazineSearchActions.SEARCH_FACETS_TOGGLE_ALL_CHECKBOX:
      return toggleFacetAllSelection(state, action);
    case MagazineSearchActions.SEARCH_FACETS_SET_RANGE:
      return setFacetRange(state, action);
    case MagazineSearchActions.SEARCH_FACETS_UPDATE_VALUES:
      return updateFacetsValues(state, action);
    case MagazineSearchActions.SEARCH_FACETS_SET_VALUES:
      return setFacetsValues(state, action);
    case MagazineSearchActions.SEARCH_RESET:
      state = InitialState;
      return state;
    default:
      return state;
  }
}

function setFacetsValues(state: State, action: MagazineSearchActions.SearchFacetSetValues): State {
  let facets: { [key: string]: MagazineSearch.Facet } = {};
  const keysToUpdate = Object.keys(action.payload.facets).filter((key) => {
    const facet = state.facets[key];
    return facet;
  });
  keysToUpdate.forEach((key) => {
    const facet = state.facets[key];
    const facetResults: MagazineSearch.FacetResult[] = action.payload.facets[key];
    switch (facet.type) {
      case "CheckboxFacet":
        facets[key] = setCheckboxFacetValues(facet as MagazineSearch.CheckboxFacet, facetResults);
        break;
      case "RangeFacet":
        facets[key] = setRangeFacetValues(facet as MagazineSearch.RangeFacet, facetResults);
        break;
      default: break;
    }
  });
  return { ...state, facets: facets, initalized: true }
}

const odataString = "@odata";

function updateFacetsValues(state: MagazineSearch.Facets, action: MagazineSearchActions.SearchFacetUpdateValues): State {
  const updatedFacets: { [key: string]: MagazineSearch.Facet } = {};
  // filter out @odata type annotations
  const keys = Object.keys(action.payload.facets).filter((key) => { return key.toLowerCase().indexOf(odataString) < 0; });
  keys.forEach((key) => {
    let facet = state.facets[key];
    const currentItem = action.payload.facets[key];
    switch (facet.type) {
      case "RangeFacet":
        updatedFacets[key] = {
          ...facet,
          lowerBucketCount: currentItem[0].count,
          middleBucketCount: currentItem[1].count,
          upperBucketCount: currentItem[2].count
        };
        break;
      case "CheckboxFacet":
        // set counts for values that got updates
        const checkboxFacet = facet as MagazineSearch.CheckboxFacet;
        const hasSelection = facet.filterClause.length > 0;
        const updatedFacet: MagazineSearch.CheckboxFacet = hasSelection ? mergeCheckboxFacetValues(checkboxFacet, currentItem) : setCheckboxFacetValues(checkboxFacet, currentItem);
        updatedFacets[key] = updatedFacet;
        break;
      default: break;
    }
  });
  //const facets = updateObject(state.facets, updatedFacets);
  return { ...state, facets: updatedFacets, initalized: true }
}

function mergeCheckboxFacetValues(facet: MagazineSearch.CheckboxFacet, facetResults: MagazineSearch.FacetResult[]): MagazineSearch.CheckboxFacet {
  let values: { [key: string]: MagazineSearch.CheckboxFacetItem } = {};
  const currentItemKeys = facetResults.map((item) => { return item.value.toString(); });

  Object.keys(facet.values).forEach((valueKey) => {
    // do we have an update for the current key
    const updateIndex = currentItemKeys.indexOf(valueKey);
    if (updateIndex >= 0) {
      const item = facetResults[updateIndex];
      values[valueKey] = {
        count: item.count,
        value: item.value,
        selected: facet.values[item.value] ? facet.values[item.value].selected : false
      };
    }
    else {
      const value = facet.values[valueKey];
      values[valueKey] = {
        count: 0,
        selected: value.selected,
        value: value.value
      };
    }
  });

  // fill in new values at the end
  facetResults.forEach((item) => {
    if (!values[item.value]) {
      values[item.value] = {
        count: item.count,
        value: item.value,
        selected: facet.values[item.value] ? facet.values[item.value].selected : false
      };
    }
  });

  return { ...facet, values: values };
}

function setFacetRange(state: State, action: MagazineSearchActions.SearchFacetsSetRange): State {
  const { key, lowerBound, upperBound } = action.payload;
  const existingFacet = state.facets[key];
  if (!existingFacet) {
    throw new Error(`Must be called for existing facet, key: ${key}`);
  }
  if (existingFacet.type !== "RangeFacet") {
    throw new Error(`SET_FACET_RANGE must be called on facet of type 'RangeFacet', actual: ${existingFacet.type}`);
  }
  const existingRangeFacet = existingFacet as MagazineSearch.RangeFacet;
  const newRangeFacet = { ...existingRangeFacet, filterLowerBound: lowerBound, filterUpperBound: upperBound };
  const filter = buildRangeFilter(newRangeFacet);
  const facetClause = getRangeFacetClause(newRangeFacet.dataType, newRangeFacet.key, lowerBound, upperBound);
  const newFacetWithFilter = { ...newRangeFacet, filterClause: filter, facetClause: facetClause };
  const facets = updateObjectAtKey(state.facets, newFacetWithFilter, key);
  return { ...state, facets: facets };
}

function buildRangeFilter(facet: MagazineSearch.RangeFacet): string {
  let lowerFilter;
  let upperFilter;
  switch (facet.dataType) {
    case "number":
      lowerFilter = facet.filterLowerBound;
      upperFilter = facet.filterUpperBound;
      break;
    case "date":
      lowerFilter = (facet.filterLowerBound as Date).toISOString();
      upperFilter = (facet.filterUpperBound as Date).toISOString();
      break;
    default:
      break;
  }

  if (facet.min === facet.filterLowerBound && facet.max === facet.filterUpperBound) {
    return "";
  }
  if (facet.min === facet.filterLowerBound) {
    return `${facet.key} le ${upperFilter}`;
  }
  if (facet.max === facet.filterUpperBound) {
    return `${facet.key} ge ${lowerFilter}`;
  }

  return `${facet.key} ge ${lowerFilter} and ${facet.key} le ${upperFilter}`;
}

function addRangeFacet(state: State, action: MagazineSearchActions.SearchFacetsAddRange): State {
  let { key, min, max, dataType } = action.payload;

  if (state.facets[key]) {
    // already exists so do nothing to avoid overwriting current data
    return state;
  }

  switch (dataType) {
    case "number":
    case "date":
      break;
    default:
      throw new Error("dataType of RangeFacet must be 'number' | 'date'");
  }

  const filterLowerBound = min,
    filterUpperBound = max;

  const rangeFacet: MagazineSearch.RangeFacet = {
    type: "RangeFacet",
    dataType,
    key,
    min,
    max,
    filterLowerBound: min,
    filterUpperBound: max,
    lowerBucketCount: 0,
    middleBucketCount: 0,
    upperBucketCount: 0,
    filterClause: "",
    facetClause: getRangeFacetClause(dataType, key, filterLowerBound, filterUpperBound)
  };
  const facets = updateObjectAtKey(state.facets, rangeFacet, key);
  return { ...state, facets: facets };
}

function getRangeFacetClause(dataType: MagazineSearch.RangeDataType, key: string, filterLowerBound: number | Date, filterUpperBound: number | Date): string {
  let lowerClause;
  let upperClause;
  switch (dataType) {
    case "number":
      lowerClause = filterLowerBound;
      upperClause = filterUpperBound;
      break;
    case "date":
      lowerClause = (filterLowerBound as Date).toISOString();
      upperClause = (filterUpperBound as Date).toISOString();
      break;
    default:
      break;
  }
  return `${key},values:${lowerClause}|${upperClause}`;
}


function setRangeFacetValues(facet: MagazineSearch.RangeFacet, facetResults: MagazineSearch.FacetResult[]): MagazineSearch.RangeFacet {
  return {
    ...facet,
    filterLowerBound: facet.min,
    filterUpperBound: facet.max,
    lowerBucketCount: 0,
    upperBucketCount: 0,
    middleBucketCount: facetResults[1].count,
    filterClause: ""
  };
}

function setCheckboxFacetValues(facet: MagazineSearch.CheckboxFacet, facetResults: MagazineSearch.FacetResult[]): MagazineSearch.CheckboxFacet {
  let values: { [key: string]: MagazineSearch.CheckboxFacetItem } = {};
  facetResults.forEach((facetResult) => {
    const { value, count } = facetResult;
    values[value] = {
      value,
      count,
      selected: false
    };
  });
  return { ...facet, values: values, filterClause: "" };
}

function addCheckboxFacet(state: State, action: MagazineSearchActions.SearchFacetsAddCheckbox): State {
  const { dataType, key, sort, count } = action.payload;

  if (state.facets[key]) {
    // already exists so do nothing to avoid overwriting current data
    return state;
  }

  switch (dataType) {
    case "number":
    case "collection":
    case "string":
    case "boolean":
    case "array":
    case "education":
      break;
    default:
      throw new Error("dataType of CheckboxFacet must be 'number' | 'collection' | 'string'");
  }
  const checkFacet: MagazineSearch.CheckboxFacet = {
    type: "CheckboxFacet",
    key,
    dataType,
    values: {},
    count,
    sort,
    filterClause: "",
    facetClause: `${key},count:${count},sort:${sort}`
  };
  const facets = updateObjectAtKey(state.facets, checkFacet, key);
  return { ...state, facets: facets };
}

function toggleFacetSelection(state: State, action: MagazineSearchActions.SearchFacetsToggleCheckbox): State {
  const { key, value } = action.payload;
  const existingFacet = state.facets[key];
  const boolValues: string[] = ['true', 'false'];

  if (!existingFacet) {
    throw new Error(`Must be called for existing facet, key: ${key}`);
  }
  if (existingFacet.type !== "CheckboxFacet") {
    throw new Error(`TOGGLE_CHECKBOX_SELECTION must be called on facet of type 'CheckboxFacet', actual: ${existingFacet.type}`);
  }
  // cleanup required as boolean type just update new value and never clears old flag
  if (existingFacet.dataType == "boolean") {
    boolValues.map((val: string) => {
      if (val != value) {
        delete existingFacet.values[val];
      }
    });
  }

  const checkboxFacet = existingFacet as MagazineSearch.CheckboxFacet;
  const oldFacetItem = checkboxFacet.values[value];
  //const updatedFacetItem = { ...oldFacetItem, selected: !oldFacetItem.selected };
  let updatedFacetItemLet
  if (oldFacetItem) {
    updatedFacetItemLet = { ...oldFacetItem, selected: !oldFacetItem.selected };
  } else {
    updatedFacetItemLet = {
      selected: true
    }
  }
  const updatedFacetItem = updatedFacetItemLet;
  //const newValue: { [key: string]: MagazineSearch.CheckboxFacetItem } = {};
  const values = updateObjectAtKey(checkboxFacet.values, updatedFacetItem, value.toString());
  const newFacet = { ...checkboxFacet, values: values };
  const filterClause = buildCheckboxFilter(newFacet);
  const newFacetWithFilter = { ...newFacet, filterClause: filterClause };
  const facets = updateObjectAtKey(state.facets, newFacetWithFilter, key);
  return { ...state, facets: facets };
}

function toggleFacetSingleSelection(state: State, action: MagazineSearchActions.SearchFacetsSingleToggleCheckbox): State {
  const { key, value } = action.payload;
  const existingFacet = state.facets[key];
  if (!existingFacet) {
    throw new Error(`Must be called for existing facet, key: ${key}`);
  }
  if (existingFacet.type !== "CheckboxFacet") {
    throw new Error(`TOGGLE_CHECKBOX_SINGLE_SELECTION must be called on facet of type 'CheckboxFacet', actual: ${existingFacet.type}`);
  }
  const checkboxFacet = existingFacet as MagazineSearch.CheckboxFacet;
  const oldFacetItem = checkboxFacet.values[value];
  //const updatedFacetItem = { ...oldFacetItem, selected: !oldFacetItem.selected };
  let updatedFacetItemLet
  if (oldFacetItem) {
    updatedFacetItemLet = { ...oldFacetItem, selected: !oldFacetItem.selected };
  } else {
    updatedFacetItemLet = {
      selected: true
    }
  }
  Object.keys(checkboxFacet.values).forEach(function (item) {
    if (item != key)
      checkboxFacet.values[item].selected = false;
  });
  const updatedFacetItem = updatedFacetItemLet;
  //const newValue: { [key: string]: MagazineSearch.CheckboxFacetItem } = {};
  const values = updateObjectAtKey(checkboxFacet.values, updatedFacetItem, value.toString());
  const newFacet = { ...checkboxFacet, values: values };
  const filterClause = buildCheckboxFilter(newFacet);
  const newFacetWithFilter = { ...newFacet, filterClause: filterClause };
  const facets = updateObjectAtKey(state.facets, newFacetWithFilter, key);
  return { ...state, facets: facets };
}


function toggleFacetAllSelection(state: State, action: MagazineSearchActions.SearchFacetsToggleAllCheckbox) {

  const { key, values, boolType } = action.payload;
  const existingFacet = state.facets[key];
  let updatedState = state;
  if (existingFacet.type !== "CheckboxFacet") {
    throw new Error(`TOGGLE_CHECKBOX_SELECTION must be called on facet of type 'CheckboxFacet', actual: ${existingFacet.type}`);
  }
  let checkboxFacet = existingFacet as MagazineSearch.CheckboxFacet;
  if (values && values.length) {
    for (let i = 0; i < values.length; i++) {
      const oldFacetItem = checkboxFacet.values[values[i]];
      //const updatedFacetItem = { ...oldFacetItem, selected: !oldFacetItem.selected };
      let updatedFacetItemLet
      if (oldFacetItem) {
        updatedFacetItemLet = { ...oldFacetItem, selected: !oldFacetItem.selected };
      } else {
        updatedFacetItemLet = {
          selected: true
        }
      }
      const updatedFacetItem = updatedFacetItemLet
      //let newValue: { [key: string]: MagazineSearch.CheckboxFacetItem } = {};
      checkboxFacet.values[values[i]] = updatedFacetItem;
      let value = checkboxFacet.values;
      //let value = updateObjectAtKey(checkboxFacet.values, updatedFacetItem, values[i].toString());
      let newFacet = { ...checkboxFacet, values: value };
      let filterClause = buildCheckboxFilter(newFacet);
      let newFacetWithFilter = { ...newFacet, filterClause: filterClause };
      let facets = updateObjectAtKey(updatedState.facets, newFacetWithFilter, key);

      updatedState = { ...updatedState, facets: facets };
    }
  }
  return updatedState;
}

function buildCheckboxFilter(facet: MagazineSearch.CheckboxFacet): string {
  const selectedFacets = Object.keys(facet.values).filter((value) => {
    return facet.values[value].selected;
  });

  let clauses = selectedFacets.map((selectedValue) => {
    let clause;
    switch (facet.dataType) {
      case "number":
        clause = `${facet.key} eq ${facet.values[selectedValue].value}`;
        break;
      case "string":
        clause = `${facet.key} eq '${escape(facet.values[selectedValue].value as string)}'`;
        break;
      case "collection":
        clause = `${facet.key}/any(t: t eq '${facet.values[selectedValue].value}')`;
        break;
      case "array":
        if (selectedValue.indexOf('-') > -1) {
          let values: any;
          values = selectedValue;
          selectedValue = values.split("-");
          clause = `${facet.key} ge ${selectedValue[0]} and ${facet.key} le ${selectedValue[1]}`;
        } else {
          clause = `${facet.key} ge ${selectedValue}`;
        }
        break;
      case "education":
        clause = `${facet.key} ge ${selectedValue}`;
        break;
      case "boolean":
        let boolVal = (selectedValue === 'true');
        //  toggleBoolFacet(facet); 
        if (selectedValue !== "") {
          clause = `${facet.key} eq ${boolVal}`;
        } else {
          clause = ``;
        }
        break;
      default:
        clause = "";
        break;
    }
    return clause;
  });
  let filter = clauses.join(" or ");
  filter.length ? filter = `(${filter})` : filter = "";
  return filter;
}


function updateObjectAtKey<T>(oldObject: { [key: string]: T }, entry: T, key: string) {
  let newObject: { [key: string]: T } = {};
  newObject[key] = entry;
  return { ...oldObject, ...newObject };

}

function clearFacetsSelections(state: State, action: MagazineSearchActions.SearchFacetsClear): State {
  let facets: { [key: string]: MagazineSearch.Facet } = {};
  Object.keys(state.facets).forEach((key) => {
    const facet = state.facets[key];
    switch (facet.type) {
      case "CheckboxFacet":
        const values: { [key: string]: MagazineSearch.CheckboxFacetItem } = {};
        Object.keys(facet.values).forEach((value) => {
          const currentItem = facet.values[value];
          const item = { ...currentItem, selected: false, count: 0 };
          values[value] = item;
        });
        facets[key] = { ...facet, values: values, filterClause: "" };
        break;
      case "RangeFacet":
        facets[key] = {
          ...facet,
          filterLowerBound: facet.min,
          filterUpperBound: facet.max,
          lowerBucketCount: 0,
          middleBucketCount: 0,
          upperBucketCount: 0,
          filterClause: ""
        };
        break;
      default: break;
    }
  });
  return { ...state, facets: facets };
}

function escape(filter: string) {
  // http://docs.oasis-open.org/odata/odata/v4.0/errata02/os/complete/abnf/odata-abnf-construction-rules.txt
  // two consecutive single quotes represent one within a string literal
  return filter.replace(/'/g, "''");
}
