import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../core/api/services/alertservice/alert.service';
import { PasswordresetService } from '../../core/api/services/passwordreset.service'
import { CartService } from '../../core/api/services/cart.service';

export class login {
  userName: string;
  password: string;
  rememberMe: boolean;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    //public authenticationService: AuthenticationService,
    public _passwordresetService: PasswordresetService,
    private alertService: AlertService,
    public cartService: CartService) {
  }

  ngOnInit() {
    this.createForm();

    //we are setting username and remember me from local storage
    this.loginForm.controls.userName.setValue(localStorage.getItem("lastLoggedUser"));
    this.loginForm.controls.rememberMe.setValue(localStorage.getItem("lastLoggedUser"));

    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params && params.returnUrl || '/';
    });
  }

  get userName() { return this.loginForm.get('userName'); }
  get password() { return this.loginForm.get('password'); }
  get rememberMe() { return this.loginForm.get('rememberMe'); }

  createForm(): void {
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required],
      rememberMe: [false]
    });
  }

  // when user clicks on remenber me we storing the name in local storage
  updateRememberMe(userName: string, rememberMe: boolean) {
    if (rememberMe) {
      localStorage.setItem("lastLoggedUser", userName);
    } else {
      localStorage.removeItem("lastLoggedUser");
    }
  }

  validateForm() {
    Object.keys(this.loginForm.controls).forEach(field => {
      const control = this.loginForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  onSubmit() {

    this.validateForm();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    let login: login = this.loginForm.value;

    //remember me is called on submit
    this.updateRememberMe(login.userName, login.rememberMe);

    // this.authenticationService.login(login.userName, login.password).subscribe(data => {
    //   this.alertService.success('Login successful');

    //   // if (data.roles.indexOf(UserRole.Buyer) != -1) {
    //   //   this.cartService.shopingCartTotalItems();
       
    //   // }

    //   if (this.returnUrl) {
    //     this.router.navigate([this.returnUrl]);
    //   }
    // }, (err) => {
    //   this.alertService.error('Login unsuccessful');
    // });
  }

  // //when user click on the forgot password we will send the email to user 
  // forgotPassword(e) {
  //   e.preventDefault();

  //   this.userName.markAsTouched();

  //   let login: login = this.loginForm.value;

  //   if (login.userName) {
  //     this._passwordresetService.read(login.userName).subscribe(data => {
  //       this.alertService.success(data.message);
  //     }, err => {
  //       this.alertService.error(err);
  //     });
  //   }
  // }
}
