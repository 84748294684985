<form [formGroup]="addAssetsForm" enctype="multipart/form-data" (ngSubmit)="addAssetsOnSubmit()">
  <div class="modal-header">
    <h5 class="modal-title tittle3" id="exampleModalLabel">Add Assets</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form  mt-0">
          <input mdbInput mdbValidate type="text" formControlName="assetName" class="form-control" id="assetName">
          <label for="assetName">Asset Name</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="md-form mt-0">
          <input mdbInput mdbValidate type="text" formControlName="assetDescription" class="form-control"
            id="assetDescription" />
          <label for="assetDescription">Asset Description</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="md-form mt-0">
          <input class="file-input" type="file" (change)="onFileChange($event)" mdbInput mdbValidate formControlName="uploadFile" class="form-control"
            id="uploadFile" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary1" mdbWavesEffect>Save changes</button>
    <button type="button" class="btn btn-outline-primary tc-1 blueborder" data-dismiss="modal" (click)="modalRef.hide()" mdbWavesEffect>Close</button>
  </div>
</form>