import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordresetService } from '../../core/api/services/passwordreset.service';
import { ResetPassword } from '../../core/api/defs/ResetPassword';
import { AlertService } from '../../core/api/services/alertservice/alert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetpasswordForm: FormGroup;


  constructor(private router: Router,
    private route: ActivatedRoute,
    private _passwordresetService: PasswordresetService,
    private formBuilder: FormBuilder,
    private alertService: AlertService) { }

  ngOnInit() {
    this.createForm();

    this.route.queryParams.subscribe(params => {
      this.userId.patchValue(params.userId);
      this.confirmationCode.patchValue( encodeURIComponent(params.confirmationCode));
    });
  }

  createForm(): void {
    this.resetpasswordForm = this.formBuilder.group({
      userId: [''],
      confirmationCode: [''],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
    });
  }


  get password() { return this.resetpasswordForm.get('password'); }
  get confirmPassword() { return this.resetpasswordForm.get('confirmPassword'); }
  get userId() { return this.resetpasswordForm.get('userId'); }
  get confirmationCode() { return this.resetpasswordForm.get('confirmationCode'); }

  getPasswordValidationErrorMessage(): string {
    let err = this.resetpasswordForm.controls.password.errors;

    if (err != null) {
      if (err.required) {
        return "Required";
      }

      if (err.pattern != null) {
        return "At least 8 characters, including one uppercase, one lowercase, one number and must have at least one special character";
      }
    }

    return null;
  }

  getConfirmPasswordValidationErrorMessage(): string {
    let err = this.resetpasswordForm.controls.confirmPassword.errors;

    if (err != null) {
      if (err.required) {
        return "Required";
      }

      if (err.validateEqual == false) {
        return "Confirm Password doesn't match with Password";
      }
    }

    return null;
  }

  validateForm() {
    Object.keys(this.resetpasswordForm.controls).forEach(field => {
      const control = this.resetpasswordForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  onSubmit() {
    this.validateForm();

    if (this.resetpasswordForm.invalid) {
      return;
    }

    let resetPassword: ResetPassword = this.resetpasswordForm.value;

    this._passwordresetService.create(resetPassword).subscribe((data: any) => {
      this.alertService.success(data.message);
    }, err => {
      this.alertService.error(err);
    });

    // if (this.forgotPassword.userId && this.forgotPassword.confirmationCode) {
    // }
  }
}