<div class="modal-content" class="justify-content-center">
  <!--Header-->
  <div class="modal-header">
    <h4 class="modal-title" id="myofferLineModalLabel">
      <strong class="font-weight-bold">Offer Lines - ({{offerNumber}})</strong>
    </h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <!--Body-->
  <div class="modal-body">
    <app-viewofferline [offerid]="offerId" [offerstatus]="offerstatus" [offerlines]="offerline" [showdetails]="false"></app-viewofferline> 
  </div>
  <!--Footer-->
  <div class="modal-footer justify-content-end">
    <button mdbBtn color="primary" outline="true" class="relative waves-light btn-outline-primary tc-1 blueborder"
      mdbWavesEffect (click)="close()">Close</button>
  </div>
</div>

<!--/.Content-->