<div class="row">
  <div class="col-sm-10 mx-auto">
    <div class="tab-pane fade show active">
      <div class="container-fluid">
        <div class="breadCrumps">
          <mdb-breadcrumb customClass="blue-text lighten-4">
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance']">
              <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId]">
              <mdb-icon fas class="mr-1"></mdb-icon> {{sellerName}}
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId,'magazines']">
              <mdb-icon fas class="mr-1"></mdb-icon> Magazines
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance/seller', sellerId, 'magazine', magazineId ]">
              <mdb-icon fas class="mr-1"></mdb-icon> {{magazineName}}
            </mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="active">
              <mdb-icon fas class="mr-1"></mdb-icon> Mediakits
            </mdb-breadcrumb-item>
          </mdb-breadcrumb>
        </div>
        <div class="tab-pane fade in show active" id="MediaKitTabPane" role="tabpanel">
          <div *ngIf="showMediaList">
            <div class="my-2 mfl-o mf3-center mf-tittle">
              <span class="ml-2">Mediakits List</span>
            </div>
            <div class="jumbotron py-3">
              <table class="table table-hover magazinetable">
                <thead>
                  <tr>
                    <th style="width:8%">MediaKit Id</th>
                    <th style="width:8%">Year</th>
                    <th style="width:8%">Active</th>
                    <th style="width:8%">Migrated?</th>
                    <th style="width:8%"></th>
                  </tr>
                </thead>
                <tbody>

                  <tr style="cursor: pointer;" *ngFor="let mediakit of mediakits; let i = index">
                    <td (click)="goToMediaKit(mediakit.mediaKitId)" style="width:8%">
                      {{mediakit.mediaKitId}}
                    </td>
                    <td (click)="goToMediaKit(mediakit.mediaKitId)" style="width:8%">
                      {{mediakit.effectiveStartDate | date: 'yyyy' }}</td>
                    <td (click)="goToMediaKit(mediakit.mediaKitId)" style="width:8%" *ngIf="mediakit.isActive">Y</td>
                    <td (click)="goToMediaKit(mediakit.mediaKitId)" style="width:8%" *ngIf="!mediakit.isActive">N</td>
                    <td (click)="goToMediaKit(mediakit.mediaKitId)" style="width:8%"
                      *ngIf="mediakit.isMigrated == true">
                      Y
                    </td>
                    <td (click)="goToMediaKit(mediakit.mediaKitId)" style="width:8%"
                      *ngIf="mediakit.isMigrated == false || mediakit.isMigrated == null">
                      N
                    </td>
                    <td  class="tittle1">
                      <a (click)="deleteMediakit(mediakit.mediaKitId)">
                        <i class="far fa-trash-alt"></i>
                      </a>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="text-center mb-3" *ngIf="!showMediaList">
            Sorry, we have no media kit for this Publication.
          </div>
          <div class="row justify-content-center pb-3">
            <div class="">
              <button type="button" mdbBtn size="md" class="btn btn-secondary1 secondary relative waves-light"
                (click)="addMediakitClick()" mdbWavesEffect>Add Media Kit Details
              </button>
            </div>
            <div class="">
              <button type="button" mdbBtn size="md" class="btn btn-secondary1 secondary relative waves-light"
                (click)="migrateMediaKitClick()" mdbWavesEffect>Migrate Media Kit Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>