<form [formGroup]="offerAckForm" (ngSubmit)="offerAckFormSubmit()">
    <div class="row">
        <div class="col-sm-10 mx-auto">
            <div class="tab-pane fade show active">
                <div class="container-fluid">
                    <div class="tab-pane fade in show active " id="panelSummary" role="tabpanel">
                        <div class="my-2 mt-3 mfl-o mf3-center mf-tittle">
                            <span class="ml-2">Offer Search</span>
                        </div>
                        <div class="jumbotron" style="padding:1.5rem">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                                    <div class="md-form mt-0">
                                        <input mdbInput mdbValidate type="text" formControlName="offerNumber"
                                            class="form-control" id="searchText" required />
                                        <label for="offerNumber">Enter Offer Number</label>
                                        <mdb-error *ngIf="offerNumber.invalid && (offerNumber.dirty || offerNumber.touched)">Required</mdb-error>
                                    </div>
                                </div>
                                <div class="row col-sm-12 justify-content-center">
                                    <button type="submit" class="btn-secondary1 border-radius btn-md waves-light btn"
                                        mdbWavesEffect>Search Offer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>