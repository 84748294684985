<div *ngIf="!isNewOffer && !isTimerStopped">

  
  <div *ngIf="isTimerExpired; else timerBlock">
    <div>
      <div class="tc-2">
        Offer has
      </div>
      <div class="tittle1 font-weight-bold">Expired</div>
    </div>
  </div>

  <ng-template #timerBlock>
    <div>
      <div class="tc-2">
        Offer expires in
      </div>
      <div class="tittle1 font-weight-bold">{{displayTimerText}}</div>
    </div>
  </ng-template>

</div>