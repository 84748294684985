import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject, Subscription } from 'rxjs';
import { Demographic } from '../../core/api/defs/Demographic';
import { DemoStaticGroup } from '../../core/api/defs/DemoStaticGroup'
import { DemographicsService } from 'src/app/core/api/services/demographics.service';
import { MdbSelectOptions } from 'src/app/core/api/model';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-add-demographic',
  templateUrl: './add-demographic.component.html'
})
export class AddDemographicComponent implements OnInit {
  
  @ViewChild('multiSelect', /* TODO: add static flag */ {static:true}) 
  multiSelect;

  id: number;

  type: string;

  data: any;

  isEdit: boolean;

  demographic: Demographic

  demoStaticGroupOptions: Array<any>;

  action: Subject<any> = new Subject();
 
  settings = {};
 
  userName: any;
 
  currentDate: Date; 
 
  demogaphicgrouplist: Array<any>;
 
  dropdownList = [];

  dropdownSettings = {};

  selectedElement = []

  isAuthenticated: boolean;

  subscription: Subscription;

  
  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private demographicsService: DemographicsService,
    private alertService: AlertService, 
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if(this.isAuthenticated){
      this.userName = this.authService.name;
    }

    this.currentDate = new Date();
    
    this.loadAddressDataOnInit();
    
    this.getAdTypes();
    
    this.settings = {
      singleSelection: true,
      idField: 'demoStatisticId',
      textField: 'demoStatisticName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: 'Demo Statistic'
    };
  }

  addDemographicForm = this.fb.group({
    mediaKitId: [],
    demoStatisticId: [''],
    value: ['', Validators.min(1)],
    demoId: [null],
    isActive: [true, Validators.required],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
    rowVersion: ['']
  });

  // Properties to use in html

  get mediaKitId() { return this.addDemographicForm.get('mediaKitId'); }
  get demoStatisticId() { return this.addDemographicForm.get('demoStatisticId'); }
  get value() { return this.addDemographicForm.get('value'); }
  get demoId() { return this.addDemographicForm.get('demoId'); }
  get isActive() { return this.addDemographicForm.get('isActive'); }
  get createdOn() { return this.addDemographicForm.get('createdOn'); }
  get createdBy() { return this.addDemographicForm.get('createdBy'); }
  get modifiedOn() { return this.addDemographicForm.get('modifiedOn'); }
  get modifiedBy() { return this.addDemographicForm.get('modifiedBy'); }
 
  close(modalRef) {
    modalRef.hide();
  }

  validateForm() {
    Object.keys(this.addDemographicForm.controls).forEach(field => {
      const control = this.addDemographicForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  updateUserDetails() {
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  getAdTypes() {
    this.demographicsService.getDemoStatistic().subscribe((data: Array<DemoStaticGroup>) => {
      this.demogaphicgrouplist = data;
      if (this.isEdit) {
        this.demogaphicgrouplist.forEach((item) => {
          if (item.demoStatisticId == this.demographic.demoStatisticId) {
            this.selectedElement = [item];
          }
        });
      }
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  addDemographicOnSubmit() {
    this.validateForm();

    if (this.addDemographicForm.valid) {
      if (this.isEdit) {
        this.updateUserDetails();
        this.editDemographic();
      }
      else {
        this.addUserDetails();
        this.addDemographic();
      }
    }

  }

  loadAddressDataOnInit() {
    setTimeout(() => {
      if (this.demographic != null || this.demographic != undefined) {
        this.addDemographicForm.patchValue(this.demographic);

        // this.selectedItem = this.demographic.demoStatisticId;
      }
    });

    this.mediaKitId.patchValue(this.id);
  }

  onItemSelect(item) {
    this.demoStatisticId.patchValue(item.demoStatisticId);
  }

  onDeSelect(item: any) {
    if (this.isEdit) {
      this.demoStatisticId.patchValue(this.demographic.demoStatisticId)
    }
    this.demoStatisticId.patchValue(null);
  }

  addDemographic() {
    this.demographicsService.create(this.addDemographicForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Demographic added successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: "edit"
        };
        this.action.next(json);

      }, (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  editDemographic() {
    this.demographicsService.update(this.addDemographicForm.getRawValue()).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.alertService.success('Demographic updated successfully');
        // Set the json in Subscribe so that once the modal is closed 
        // API will be invoked to get the new results
        let json = {
          id: this.id,
          type: this.type
        };

        this.action.next(json);
      },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }
}