import * as __model from '../model';
import { offerLine } from '../model'

export class CreateOffer {
    offerDate?:string;
    magazineId?:number; 
    comment?:String;
    magazineName?:String;
    buyItNowAmount?:number;
    yourOfferAmount?:String;
    offerLine?: __model.offerLine[]; 
    offerId: number;                                                            
}

