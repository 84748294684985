<div class="modal-content">
  <!--Header-->
  <div class="modal-header">
    <h4 class="modal-title tittle3 font-weight-bold" id="myacceptofferModalLabel">Accept Offer</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <!--Body-->
  <div class="modal-body">
    <h5 class="tc-2">
      <span class="ft-s1 tittle1 font-weight-bold">({{offer?.offerNumber}})</span>
    </h5>
    <table class="table table-hover">
      <thead>
        <tr>
          <th># of Lines</th>
          <th>Cover Date</th>
          <th>Offered Price Per Insertion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let line of offerlines; let $index = index">
          <td scope="row">{{$index+1}}</td>
          <td>{{line.coverDate}}</td>
          <td>{{line.rate | number: '1.2-2'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!--Footer-->
  <div class="modal-footer">
    <div class="text-center">
      <p>
        By accepting this offer you are agreeing to the
        <a class="tittle1" target="_blank" href={{id4TermsAndConditionsUrl}} >terms and conditions</a> as specified. You are agreeing to accept
        responsibilty for delivery (in accordance with
        the payment terms) of the ad(s), issue dates, price(s), and total amount that appear in the Offer.
      </p>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" mdbBtn mdbWavesEffect data-dismiss="modal"
      class="btn-secondary1 secondary relative waves-light mr-4" (click)="AcceptOffer();">
      Accept Offer
    </button>
    <button type="button" mdbBtn color="primary" outline="true"
      class="relative waves-light btn-outline-primary tc-1 blueborder" 
      mdbWavesEffect (click)="close()">Close</button>
  </div>
</div>
<!--/.Content-->