
import * as __model from '../model';

export class BofABatchOfferDetails {

    mainPartnerKey: string;

    offerNumber: string;

    billingDate: Date;

    paymentDueDate?: Date

    invoiceNumber: string;

    invoiceDescription: string;

    partialInvoicePaymentEligiblityIndicator: string;
}