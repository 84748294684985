import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Publisher, Seller } from '../defs/Seller';
import { SellerAddContact } from '../model';
import { SellerSearchResult, SellerSearch } from '../defs/SearchSeller';
import { TempAuthRequest } from '../defs/TempAuthRequest';
import { SellerContact } from '../defs/SellerContact';
import { Buyer, Address } from '../defs/Buyer';
import { AddressSeller } from 'src/app/core/api/defs/AddressSeller';


interface browseSearchInputs {
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class SellersearchService {

  baseUrl: string = `${environment.identityProviderRootUrl}`;

  // Call for sller based on Id
  getSellerDetails(SellerId: number) {
    return this.http.get<any>(`${this.baseUrl}` + '/api/Seller/' + SellerId);
  }

  getSellerAddresses(sellerId: number){
    return this.http.get<Array<AddressSeller>>(`${this.baseUrl}` + '/api/Seller/' + sellerId + '/Addresses' );
  }

  getSellerContacts(sellerId: number){
    return this.http.get<any>(`${this.baseUrl}` + '/api/Seller/' + sellerId + '/Contacts' );
  }

  //Seller tittle search
  params = new HttpParams().set("api-version", environment.azureSearchApiVersion);
  headers = new HttpHeaders()
    .append("Content-Type", "application/json")
    .append("Access-Control-Allow-Credentials", "true")
    .append("Access-Control-Allow-Origin", "*")
    .append("Access-Control-Allow-Methods", "*")
    .append("api-key", environment.azureSearchApiKey);

  constructor(private http: HttpClient) { }

  searchSeller(val: string): Observable<SellerSearchResult> {

    return this.http.get<SellerSearchResult>(`${environment.azureSellerSearchUrl}` + '?search=' + val + "*",
      {
        params: this.params,
        headers: this.headers
      });
  }

  addSeller(seller: Seller): Observable<Seller> {
    let url = this.baseUrl + `/api/Seller`;
    return this.http.post<any>(url, seller);
  }

  editSeller(seller: Seller) {
    let url = this.baseUrl + `/api/Seller`;
    return this.http.put<any>(url, seller);
  }

  generateCode(sellerSearch: SellerSearch): Observable<TempAuthRequest> {

    let tempAuthRequest = new TempAuthRequest();
    tempAuthRequest.partyName = sellerSearch.SellerName;
    tempAuthRequest.partyType = "Seller";
    tempAuthRequest.partyId = sellerSearch.PartyId;

    let url = this.baseUrl + "/api/Account/CreateTempAuth";

    return this.http.post<TempAuthRequest>(url, tempAuthRequest);
  }

  updateSellerPrimaryContact(sellerContact: any) {
    let url = this.baseUrl + `/api/Seller/${sellerContact.sellerId}/PrimaryContact`;
    return this.http.put<any>(url, sellerContact);
  }

  updateSellerSecondaryContact(sellerContact: any){

    let url = this.baseUrl + `/api/Seller/${sellerContact.sellerId}/SecondaryContact`;
    return this.http.put<any>(url, sellerContact);
  }

  anyRegisteredPrimaryContact(sellerId: number) {
    let url = this.baseUrl + `/api/Seller/${sellerId}/AnyRegisteredPrimaryContact`;
    return this.http.get(url);
  }

}
