import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, Pipe, PipeTransform } from '@angular/core';
import { BrowseSearchService } from '../../core/api/browseService/browse-search.service';
import { MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-typeinsearch',
  templateUrl: './typeinsearch.component.html',
  styleUrls: ['./typeinsearch.component.scss']
})
export class TypeinsearchComponent implements OnInit, AfterViewInit {

  @Input() searchInput: string;
  
  @Output() onSelectCallback = new EventEmitter<any>();

  magazineSearchResultData: Array<any>;

  @ViewChild('auto', /* TODO: add static flag */ {static:true}) public completer: MdbAutoCompleterComponent;

  constructor(private _service: BrowseSearchService) { }

  ngOnInit() {
    this.searchInput = "";
  }

  ngAfterViewInit() {
    this.completer.selectedItemChanged().subscribe((data: any) => {
      this.searchInput = data.text ? data.text : "";
      let foundData = this.magazineSearchResultData.find(x=> x.MagazineName == data.text);
      this.onSelectCallback.emit(foundData);
    });
  }

  onChange() {
    this._service.azureSearchMagazineWithIcons(this.searchInput).subscribe((data: any) => {
      this.magazineSearchResultData = data.value;
    }, (error) => {
      console.log(error);
    });
  }
}