import * as MagazineSearchActions from '../actions/magazine-search.action'
import { MagazineSearch } from '../models/magazine-search.model';
import { environment } from 'src/environments/environment';

export interface State {
  count: boolean;
  top: number;
  skip: number;
  orderby: string;
  searchMode: MagazineSearch.SearchMode;
  scoringProfile: string;
  select: string;
  searchFields: string;
  minimumCoverage?: string;
  apiVersion: string;
  queryType: MagazineSearch.QueryType;
  scoringParameters: string[];
  highlight: string;
  highlightPreTag: string;
  highlightPostTag: string;
}

export const InitialState: State = {
  count: true,
  orderby: 'Circulation desc',
  scoringProfile: '',
  searchFields: 'MagazineName, PublisherName, PublisherStatement, Tags',
  select: 'MagazineId, MagazineName, PublicationFrequency, PublisherName, PublisherStatement, MediaKitUrl, CoverUrl, CoverThumbnailUrl, MediaClassificationName, MarketCategories, Readership, Circulation, AdTypes, Issues, Age, Reader_Income, Average_Income, Median_Income, Household_Income, Average_HHI, Median_HHI, Net_Worth, Some_College, RateBase, AuditedAnnual, ReportedAnnual, AuditedPerIssue, ReportedPerIssue, Subscribers, NewsStandSales, EnteredBy, AssetFolder, IsUsNational, IsRegional',
  skip: 0,
  top: 50,
  apiVersion: environment.azureSearchApiVersion,
  searchMode: 'all',
  queryType: 'simple',
  highlight: '',
  highlightPreTag: '',
  highlightPostTag: '',
  scoringParameters: []
};

export function reducer(state = InitialState, action: MagazineSearchActions.Actions) {
let skip
  switch (action.type) {
    case MagazineSearchActions.SEARCH_PARAMETERS_UPDATE:
      return { ...state, ...action.payload  };
    case MagazineSearchActions.SEARCH_PAGENATION_INCREMENT_SKIP:
      return { ...state, skip: state.skip + state.top };
    case MagazineSearchActions.SEARCH_PAGENATION_DECREMENT_SKIP:
      skip = state.skip - state.top;
      skip = skip >= 0 ? skip : 0;
      return { ...state, skip: skip };
    case MagazineSearchActions.SEARCH_PAGINATION_SET_PAGE:
      skip = (action.payload.page - 1) * state.top;
      skip = skip >= 0 ? skip : 0;
      skip = skip <= 100000 ? skip : 100000;
      return { ...state, skip: skip };
      case MagazineSearchActions.SEARCH_RESET:
       state = InitialState;
      return state;
    default:
      return state;
  }
}
