import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { OfferService } from 'src/app/core/api/services/offer.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-offerline',
  templateUrl: './offerline.component.html'
})
export class OfferlineComponent implements OnInit {
  offerId: any;
  offerstatus: string;
  offerline: any;
  offerNumber: string;
  action: Subject<any> = new Subject();

  constructor(public modalRef: MDBModalRef,
    private offerService: OfferService,
    private alertService: AlertService) { 
      this.offerService.offerObservable.subscribe(value => {
        if (value) {
          this.close();
        }
      })
    }


  ngOnInit() {
    this.getlines();
  }

  getlines() {
    this.offerService.getOfferLines(this.offerId).subscribe(data => {
      this.offerline = data;
    })
  }


  close() {
    this.modalRef.hide();
  }
}
