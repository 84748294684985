<div class="row">
  <div class="col-sm-12 mx-auto">
    <div class="tab-pane fade show active">
      <div class="container-fluid">
        <div class="tab-pane fade in show active" id="MagazineTabPane" role="tabpanel">
          <div  class="my-2 mt-5 mfl-o mf3-center mf-tittle">
            <span class="ml-2">Rates List</span>
          </div>
          <div class="jumbotron py-3">
            <table class="table table-hover magazinetable table-responsive">
              <thead>
                <tr>
                  <th style="width:8%">Ad Type</th>
                  <th style="width:8%">Ad Color</th>
                  <th style="width:8%">Ad Unit</th>
                  <th style="width:8%">Placement</th>
                  <th style="width:8%">Print source</th>
                  <th style="width:8%">Orientation</th>
                  <th style="width:12%">Ad Size Description</th>
                  <th style="width:8%">Is Active</th>
                  <th style="width:6%">Edit</th>
                  <th style="width:6%">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ad of adRates; let i = index">
                  <td style="width:8%">{{ad.adTypeName}}</td>
                  <td style="width:8%">{{ad.adColorName}}</td>
                  <td style="width:8%">{{ad.adUnitName}}</td>
                  <td style="width:8%">{{ad.adPlacementName}}</td>
                  <td style="width:8%">{{ad.adPrintSourceName}}</td>
                  <td style="width:8%">{{ad.adOrientationName}}</td>
                  <td style="width:12%">{{ad.adSizeDescription}}</td>
                  <td style="width:12%">{{ad.isActive}}</td>
                  <td style="width:6%;" class="tittle1"><a (click)="editRatesClick(ad)"><mdb-icon fas icon="edit"></mdb-icon></a></td>
                  <td style="width:6%;" class="tittle1"><a><i (click)="deleteAdrate(ad.adRateId)" class="far fa-trash-alt"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row pb-3">
            <div class="col-sm-12 text-center">
              <button type="button" mdbBtn size="md" class="btn btn-secondary1 secondary relative waves-light mr-4"
                (click)="addRatesClick()" mdbWavesEffect>Add Rates
              </button>
            </div>
          </div>  
        </div>
      </div>
    </div>   
  </div>
</div>