<div class="tab-pane fade in show active" id="buyerListTabPane" role="tabpanel">
    <div class="my-2 mfl-o mf3-center mf-tittle">
        <span class="ml-2">Buyer Search</span>
    </div>
    <div class="jumbotron py-3">
        <div class="md-form">
            <input type="text" class="completer-input form-control mdb-autocomplete mb-0" [(ngModel)]="searchText"
                (ngModelChange)="onChange()" [mdbAutoCompleter]="auto" placeholder="Enter Buyer Name" />
            <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="No results found">
                <mdb-option *ngFor="let option of buyerSearchResultData" [value]="option.BuyerName">
                    <div class="d-flex flex-column">
                        <strong>{{ option.BuyerName }}</strong>
                    </div>
                </mdb-option>
            </mdb-auto-completer>
        </div>
    </div>
</div>
<div class="tab-pane fade in show active" id="buyerListTabPane" role="tabpanel">
    <div class="my-2 mfl-o mf-tittle mf3-center">
        <span class="ml-2">Buyers List</span>
    </div>
    <div class="jumbotron py-3">
        <div class="mb-5 table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Buyer Name</th>
                        <th scope="col">Company Description</th>
                        <th scope="col" class="mf3-center">Payment Account Setup Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="o-curser-pointer" *ngFor="let buyer of buyer; let i = index" [routerLink]="['/buyer-activity/' ,buyer.buyerId]">
                        <td scope="col"> {{buyer.buyerName}}</td>
                        <td scope="col"> {{buyer.buyerCompanyDescription}}</td>
                        <td scope="col" class="mf3-center"> <span *ngIf="buyer.isPaymentAccountSetup; else elseBlock">Yes</span>
                            <ng-template #elseBlock>No</ng-template></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="canShowPagination" class="mt-3">
            <app-mf-pagination [TotalCount]="totalCount" [CurrentPageIndex]="currentPageIndex"
                (OnPageChanged)="mfPaginationOnPageChanged($event)"></app-mf-pagination>
        </div>
    </div>
</div>
