import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../../core/search/reducers/searchstate.reducer';
import { environment } from 'src/environments/environment';
import { ViewrateService } from '../../../../core/api/services/viewrate.service'

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss']
})
export class AudienceComponent implements OnInit {

  results: any[];
  assetTypeId: number[];
  constructor(private _ViewrateService: ViewrateService) {
this.assetTypeId = [2]
  }

  ngOnInit() {
    this._ViewrateService.assetsList.subscribe((response: any) => {
      this.results = response;
    });
  }

  hasAudience() {
    if(this.results){
      return this.results.find((result) => (this.assetTypeId.indexOf(result.assetTypeId) != -1) ? true : false);
    }
    }


  onMedialinkClick(MediaKitURL) {
    window.close();
    let w = window.open(MediaKitURL, "MediaKitPopup", "height=600,width=800");
    w.focus();
  }

}
