import { Component, OnInit, Renderer2 } from '@angular/core';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { IssueService } from 'src/app/core/api/services/issue.service';
import { Issue } from 'src/app/core/api/model';
import { AddIssueComponent } from 'src/app/shared/add-issue/add-issue.component';
import { DeleteConfirmComponent } from 'src/app/shared/delete-confirm/delete-confirm.component';

@Component({
  selector: 'app-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.scss']
})
export class IssueComponent implements OnInit {

  selectedMediakitId: number;
  modalRef: MDBModalRef;
  issues: Array<Issue>;

  constructor(private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private route: ActivatedRoute,

    private issueService: IssueService) { }

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.selectedMediakitId = params.mediaKitId;
    });

    this.getIssues(this.selectedMediakitId);
  }

  getIssues(mediaKitId: number) {
    this.issueService.read(mediaKitId).subscribe((data: Array<Issue>) => {
      if (data) {
        this.issues = data;
      }
    }, (err) => {
      this.alertService.error(err);
    });
  }

  addIssueClick() {
    this.modalRef = this.modalService.show(AddIssueComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.selectedMediakitId,
        isEdit: false
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
        this.getIssues(this.selectedMediakitId);
      }
    });
  }

  editIssueClick(issue) {
    this.modalRef = this.modalService.show(AddIssueComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.selectedMediakitId,
        isEdit: true,
        issue: issue
      }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
        this.getIssues(this.selectedMediakitId);
      }
    });
  }

  deleteIssueClick(issueId) {
    this.modalRef = this.modalService.show(DeleteConfirmComponent, {
      class: 'modal-sm modal-notify modal-warning',
      ignoreBackdropClick: true,
      data: {
        deleteName: 'Issue'
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result.status == 'delete') {
        this.issueService.delete(issueId).subscribe((data: Issue) => {
          this.alertService.success("Issue is Deleted Successfully")
          this.getIssues(this.selectedMediakitId);
        }, (errMsg) => {
          this.alertService.error(errMsg);
        });
      }
    });
  }
}
