import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { MediakituploadService } from 'src/app/core/api/services/mediakitupload.service';
import { MediakitAssets } from 'src/app/core/api/defs/MediakitAssets';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-add-assets',
  templateUrl: './add-assets.component.html'
})
export class AddAssetsComponent implements OnInit {
  
  assetsTypeid: number;
  
  mediakitid: number;
  
  magazineid: number;
  
  isEdit: boolean;
  
  userName: any;
  
  currentDate: string;
  
  action: Subject<any> = new Subject();

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private mediakituploadService: MediakituploadService,
    private alertService: AlertService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if(this.isAuthenticated){
      this.userName = this.authService.name;
    }

    this.currentDate = new Date().toISOString();
  }

  addAssetsForm = this.fb.group({
    assetId: [null],
    assetName: [''],
    assetDescription: [''],
    assetCategoryId: [null],
    mediakitId: [null],
    assetTypeId: [null],
    assetFormatId: [null],
    magazineId: [null],
    appStorageId: [null],
    uploadFile: [''],
    isActive: [true],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
    rowVersion: ['']
  });

  // Properties to use in html

  get assetId() { return this.addAssetsForm.get('assetId'); }
  get assetName() { return this.addAssetsForm.get('assetName'); }
  get assetDescription() { return this.addAssetsForm.get('assetDescription'); }
  get assetCategoryId() { return this.addAssetsForm.get('assetCategoryId'); }
  get mediakitId() { return this.addAssetsForm.get('mediakitId'); }
  get assetTypeId() { return this.addAssetsForm.get('assetTypeId'); }
  get assetFormatId() { return this.addAssetsForm.get('assetFormatId'); }
  get magazineId() { return this.addAssetsForm.get('magazineId'); }
  get appStorageId() { return this.addAssetsForm.get('appStorageId'); }
  get uploadFile() { return this.addAssetsForm.get('uploadFile'); }
  get createdOn() { return this.addAssetsForm.get('createdOn'); }
  get createdBy() { return this.addAssetsForm.get('createdBy'); }
  get modifiedOn() { return this.addAssetsForm.get('modifiedOn'); }
  get modifiedBy() { return this.addAssetsForm.get('modifiedBy'); }

  close(modalRef) {
    modalRef.hide();
  }

  addUserDetails() {
    this.createdOn.patchValue(this.currentDate);
    this.createdBy.patchValue(this.userName);
    this.modifiedOn.patchValue(this.currentDate);
    this.modifiedBy.patchValue(this.userName);
  }

  validateForm() {
    Object.keys(this.addAssetsForm.controls).forEach(field => {
      const control = this.addAssetsForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addAssetsForm.patchValue({
        uploadFile: file
      });
    }
  }

  addAssetsOnSubmit() {
    this.addUserDetails();
    const formData = new FormData();
    formData.append('mediakitId', this.mediakitid.toString());
    formData.append('magazineId', this.magazineid.toString());
    formData.append('assetTypeId', this.assetsTypeid.toString());
    formData.append('assetName', this.addAssetsForm.get('assetName').value);
    formData.append('assetDescription', this.addAssetsForm.get('assetDescription').value);
    formData.append('uploadFile', this.addAssetsForm.get('uploadFile').value);
    formData.append('isActive', this.addAssetsForm.get('isActive').value);
    formData.append('createdOn', this.addAssetsForm.get('createdOn').value);
    formData.append('createdBy', this.addAssetsForm.get('createdBy').value);
    formData.append('modifiedOn', this.addAssetsForm.get('modifiedOn').value);
    formData.append('modifiedBy', this.addAssetsForm.get('modifiedBy').value);
    formData.append('rowVersion', this.addAssetsForm.get('rowVersion').value);

    this.validateForm();
    if (this.addAssetsForm.valid) {
      this.mediakituploadService.create(formData).subscribe(
        (data: any) => {
          this.modalRef.hide();
          this.alertService.success('Assets added successfully');
          // Set the json in Subscribe so that once the modal is closed 
          // API will be invoked to get the new results
          let json = {
            assetsTypeid: data.assetsTypeid,
            mediakitid: data.mediakitid,
            magazineId: data.magazineId
          };
          this.action.next(json);

        }, (errMsg) => {
          this.alertService.error(errMsg);
        });
    }
  }
}