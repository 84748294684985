import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Buyer } from 'src/app/core/api/defs/Buyer';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';

@Component({
  selector: 'app-buyeractivitylist',
  templateUrl: './buyeractivitylist.component.html'
})
export class BuyeractivitylistComponent implements OnInit {
  searchText: string = '';
  currentPageIndex: any;
  buyer: any;
  buyerId: number;
  activitys: any;
  totalCount: number;
  canShowPagination: boolean = false;
  constructor(private buyersearchService: BuyersearchService,
    private alertService: AlertService,
    private router: Router,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private renderer: Renderer2) {
    route.params.subscribe(params => {
      this.buyerId = params.id
    })
  }
  ngOnInit(): void {
    this.buyerActivityService(1);
    this.getBuyerActivity();
  }
  getBuyerActivity() {
    this.buyersearchService.getBuyerActivity(this.buyerId).subscribe((response: any) => {
      this.buyer = response
    })
  }
  goToTop() {
    window.scrollTo(0, 0);
  }

  mfPaginationOnPageChanged(currentPageNumber: any) {
    this.buyerActivityService(currentPageNumber);
  }
  //readByPage
  buyerActivityService(currentPageNumber: number) {
    this.buyersearchService.getBuyerActivityLine(currentPageNumber - 1, this.buyerId).subscribe((response: any) => {
      this.totalCount = response.count;
      this.currentPageIndex = currentPageNumber;
      this.activitys = response.data;
      this.goToTop();
      setTimeout(() => {
        this.canShowPagination = true;
      });

    });
  }
}
