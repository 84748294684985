
import { Component, OnInit, Input, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';
import { BuyerSearchResult, BuyerSearch } from '../core/api/defs/SearchBuyer';
import { Buyer, Address, BuyerAddressData } from 'src/app/core/api/defs/Buyer';
import { TempAuthRequest, SellerContact, buyerInfo, BuyerContact } from '../core/api/model';
import { ModalDirective, MDBModalService, MdbAutoCompleterComponent, MDBModalRef } from 'ng-uikit-pro-standard';
import { AlertService } from '../core/api/services/alertservice/alert.service';
import { AddContactComponent } from '../shared/add-contact/add-contact.component';
import { AddAddressComponent } from '../shared/add-address/add-address.component';
import { EditDetailsComponent } from '../shared/edit-details/edit-details.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-onboardingbuyer',
    templateUrl: './onboardingbuyer.component.html',
    styleUrls: ['./onboardingbuyer.component.scss']
})
export class OnboardingbuyerComponent implements OnInit {

    @ViewChild('generatedCodeModal', /* TODO: add static flag */ { static: true })
    public generatedCodeModal: ModalDirective;

    @ViewChild('auto', /* TODO: add static flag */ { static: true })
    public completer: MdbAutoCompleterComponent;

    modalRef: MDBModalRef;

    searchText: string = '';
    Buyeraddressdata: BuyerAddressData;
    buyerSearchResultData: Array<BuyerSearch>;
    buyerPrimaryContactAny: boolean = false;
    hasRegisteredPrimaryContact: boolean = false;
    selectedBuyerSearch: BuyerSearch = new BuyerSearch();
    buyerDetails: BuyerSearch;

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.completer.selectedItemChanged().subscribe((data: any) => {
            this.searchText = data.text;
            this.selectedBuyerSearch = this.buyerSearchResultData.find(x => x.BuyerName == data.text);
            this.router.navigate(['buyer-onboarding/' + this.selectedBuyerSearch.BuyerId]);
        });
    }

    constructor(private buyersearchService: BuyersearchService,
        private alertService: AlertService, 
        private router: Router,
        private modalService: MDBModalService,
        private renderer: Renderer2) {
    }

    onChange() {
        if (this.searchText == "") {
        }
        else {
            this.buyersearchService.searchBuyer(this.searchText).subscribe((data: BuyerSearchResult) => {
                this.buyerSearchResultData = data.value;
            }, (error) => {
                console.log(error);
            });
        }
    }

    addBuyerClick() {
        this.modalRef = this.modalService.show(EditDetailsComponent, {
            class: 'modal-lg',
            data: {
                type: "buyer",
                isEdit: false
            }
        });
        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {
            // The values are sent in json format
            if (result != null) {
                if (result.type == "buyer") {
                    // Invoke API to get the data

                }
            }
        });
    }
}
