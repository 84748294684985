import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MdbAutoCompleterComponent, MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Magazines } from 'src/app/core/api/defs/Magazines';
import { MagazineService } from 'src/app/core/api/services/magazine.service';
import { ChangeSellerComponent } from 'src/app/shared/change-seller/change-seller.component';

@Component({
  selector: 'app-assign-seller-to-magazine',
  templateUrl: './assign-seller-to-magazine.component.html',
  styleUrls: ['./assign-seller-to-magazine.component.css']
})
export class AssignSellerToMagazineComponent implements OnInit, AfterViewInit {

  @ViewChild('auto', /* TODO: add static flag */ { static: true })
  public completer: MdbAutoCompleterComponent;
  searchText: string;
  magazineSearchResult: Magazines[];
  modalRef: MDBModalRef;

  constructor(private magazineService: MagazineService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private route: Router) { }

  ngOnInit(): void {
    this.searchText = "";
  }

  ngAfterViewInit() {
    this.completer.selectedItemChanged().subscribe((data: any) => {

      this.searchText = data.text;

      this.changeSeller(data.element.el.nativeElement.id);
    });
  }

  onChange() {
    if (this.searchText != "") {
      this.magazineService.noSellerMagazines(this.searchText).subscribe((data: Magazines[]) => {
        this.magazineSearchResult = data;
      }, (error) => {
        console.log(error);
      });
    }
  }


  changeSeller(selectedMagazineId) {
    this.modalRef = this.modalService.show(ChangeSellerComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      // [config]:'{backdrop: false, ignoreBackdropClick: true}',
      data: {
        id: 0,
        magazineid: selectedMagazineId
      }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      if (result != null) {
        if (result.type) {
          this.route.navigateByUrl('/maintenance/no-seller-magazines');
        }
      }
    });
  }
}