import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {
  dropdownSettings:any;
 // magazine
  @Input() items:string[];
  @Input() type: string;
  @Output() selectItem: EventEmitter<Object> = new EventEmitter();
  @Output() deSelectItem: EventEmitter<Object> = new EventEmitter();
  @Output() selectAllItems: EventEmitter<Object> = new EventEmitter();
  @Output() deSelectAllItems: EventEmitter<Object> = new EventEmitter();
  @Input() magazine:string[] | number[]; 
  
  constructor() {
   }

  onSelectItem(item) {
    this.selectItem.emit(item);
  }
  
  onDeSelectItem(item) {
    this.deSelectItem.emit(item);
  }
  onSelectAllItems(items) {
    this.selectAllItems.emit(items);
  }
  
  onDeSelectAllItems(items) {
    this.deSelectAllItems.emit(items);
  }
  
  
  onMediaClassSelect(items){
    this.selectItem.emit(items);
   }
  
   onMediaClassSelectAll(items){
     this.selectAllItems.emit(items);
   }
   
   onMediaClassDeSelect(items){
    this.deSelectItem.emit(items);
   }
 
   onMediaClassDeSelectAll(items){
    this.deSelectAllItems.emit(this.items);
   }


  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'count',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  
    /*this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
       {
       count: 15,
      dataType: "collection",
       key: "AdTypes",
     sort: "count"
      }
        ));*/
  }

}
