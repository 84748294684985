<form [formGroup]="buyerPaymentAccountForm" (ngSubmit)="buyerPaymentAccountOnSubmit()">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Edit Details</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close(modalRef)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="md-form ml-2 mt-0">
                  <mdb-checkbox mdbValidate formControlName="isPaymentAccountSetup">
                    Is Payment Account Setup ?
                  </mdb-checkbox>
                  <mdb-error *ngIf="isPaymentAccountSetup.invalid && (isPaymentAccountSetup.dirty || isPaymentAccountSetup.touched)">Required</mdb-error>
                </div>
              </div>
          </div>
      </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-secondary1 p-2">Save changes</button>
       <button type="button" class="btn relative waves-light btn-outline-primary blueborder" data-dismiss="modal" (click)="modalRef.hide()" mdbWavesEffect>Close</button>
    </div>
  </form>
  