import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { MDBModalService, MDBModalRef, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { AddMagazineComponent } from '../../shared/add-magazine/add-magazine.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Publisher } from '../../core/api/model';
import { SellersearchService } from '../../core/api/services/sellersearch.service'
import { SellerSearchResult, SellerSearch } from '../../core/api/defs/SearchSeller';
import { MagazineService } from '../../core/api/services/magazine.service';
import { Magazines } from '../../core/api/defs/Magazines'
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Seller } from 'src/app/core/api/defs/Seller';
import { AddSellerComponent } from '../../shared/add-seller/add-seller.component';
import { PubicationFrequency } from 'src/app/core/api/defs/PublicationFrequency';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  @ViewChild('auto', /* TODO: add static flag */ {static:true})
  public completer: MdbAutoCompleterComponent;

  modalRef: MDBModalRef;
  searchText: string;
  showMagazineList: boolean = false;
  sellerDetails: Publisher;
  sellerSearchResultData: Array<SellerSearch>;
  selectedSellerSearch: any;
  magazines: any;
  sellerdata: Seller;
  sellerId: number;
  frequency = [];
  canShowSellerDataCard: boolean = false;
  constructor(
    private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private sellerSearchService: SellersearchService,
    private magazineService: MagazineService) { }

  ngOnInit(): void {
    this.searchText = '';
  }

  onChange() {
    if (this.searchText == "") {
      this.sellerDetails = null;
      this.showMagazineList = false;
    }
    else {
      this.sellerSearchService.searchSeller(this.searchText).subscribe((data: SellerSearchResult) => {
        this.sellerSearchResultData = data.value;
      }, (error) => {
        console.log(error);
      });
    }
  }

  addSellerClick() {
    this.modalRef = this.modalService.show(AddSellerComponent, {
      class: 'modal-lg',
      data: {
        type: "seller",
        isEdit: false
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
        if (result.type) {
          // // Invoke API to get the data
          // this.getSellerData(result.id);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.completer.selectedItemChanged().subscribe((data: any) => {
      this.searchText = data.text;
      this.selectedSellerSearch = this.sellerSearchResultData.find(x => x.SellerName == data.text);
      this.sellerId = this.selectedSellerSearch.SellerId;
      this.router.navigateByUrl("/maintenance/seller/" + this.sellerId);
      //this.getSellerData(this.selectedSellerSearch.SellerId);
    });
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

}