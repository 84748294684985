<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="breadCrumps">
            <mdb-breadcrumb customClass="blue-text lighten-4">
                <mdb-breadcrumb-item class="tittle3" [routerLink]="['/maintenance']">
                    <mdb-icon fas class="mr-1"></mdb-icon> Back to Seller Search
                </mdb-breadcrumb-item>
            </mdb-breadcrumb>
        </div>

        <div class="tab-pane fade in show active" id="buyerDataTabPane" role="tabpanel">
            <div class="my-2 mfl-o mf3-center mf-tittle">
                <span class="ml-2">Seller Information</span>
            </div>
            <div class="jumbotron py-3">
                <div *ngIf="canShowSellerDataCard else noDataBlock">
                    <div class="row">
                        <div class="col-xs-10 col-sm-10 my-2">
                            <h6>
                                <div>
                                    Name: {{sellerData?.sellerName}}
                                    <br />Company Description: {{sellerData?.sellerCompanyDescription}}
                                    <br /> Seller DBA: {{sellerData?.sellerDba}}
                                    <br /> Seller DNB: {{sellerData?.sellerDnB}}
                                    <br /> Seller FEID: {{sellerData?.sellerFeid}}
                                </div>
                            </h6>
                        </div>
                        <div class="col-xs-2 col-sm-2 my-2">
                            <div class="text-right">
                                <a class="tittle1" (click)="editSellerClick(sellerData)">
                                    <mdb-icon fas icon="edit"></mdb-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #noDataBlock>
                    <div>
                        No Seller Data found
                    </div>
                </ng-template>
            </div>
        </div>

        <div class="row pb-3">
            <div class="col-sm-12 text-center">
                <button *ngIf="canShowSellerDataCard" type="button" mdbBtn size="md"
                    [routerLink]="['/maintenance/seller', sellerId, 'magazines' ]"
                    class="btn btn-secondary1 secondary relative waves-light">Go to This Seller Magazines</button>
            </div>
        </div>
    </div>
</div>