
import { Component, OnInit, Input, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';
import { BuyerSearchResult, BuyerSearch } from '../../../core/api/defs/SearchBuyer';
import { Buyer, Address, BuyerAddressData } from 'src/app/core/api/defs/Buyer';
import { TempAuthRequest, SellerContact, buyerInfo, BuyerContact } from '../../../core/api/model';
import { ModalDirective, MDBModalService, MdbAutoCompleterComponent, MDBModalRef } from 'ng-uikit-pro-standard';
import { AlertService } from '../../../core/api/services/alertservice/alert.service';
import { AddContactComponent } from '../../../shared/add-contact/add-contact.component';
import { AddAddressComponent } from '../../../shared/add-address/add-address.component';
import { EditDetailsComponent } from '../../../shared/edit-details/edit-details.component';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-buyeraccountacknowledgement',
  templateUrl: './buyeraccountacknowledgement.component.html',
  styleUrls: ['./buyeraccountacknowledgement.component.css']
})
export class BuyeraccountacknowledgementComponent implements OnInit {


  @ViewChild('generatedCodeModal', /* TODO: add static flag */ { static: true })
  public generatedCodeModal: ModalDirective;

  @ViewChild('auto', /* TODO: add static flag */ { static: true })
  public completer: MdbAutoCompleterComponent;

  modalRef: MDBModalRef;

  searchText: string = '';
  buyerSearchResultData: Array<BuyerSearch>;
  canShowSearchResults: boolean = true;
  canShowBuyerDataCard: boolean = false;
  selectedBuyerSearch: BuyerSearch = new BuyerSearch();
  datacard: any;
  buyerId: number;
  buyerDetails: BuyerSearch;

  constructor(private buyersearchService: BuyersearchService,
    private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,) {
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.completer.selectedItemChanged().subscribe((data: any) => {
      this.searchText = data.text;
      this.selectedBuyerSearch = this.buyerSearchResultData.find(x => x.BuyerName == data.text);

      this.buyerId = this.selectedBuyerSearch.BuyerId;
      this.router.navigateByUrl("buyer-account-acknowledgement/buyer/" + this.buyerId);
      // this.getBuyerData(this.selectedBuyerSearch.BuyerId);
    });
  }



  onChange() {
    if (this.searchText == "") {
      this.buyerDetails = null;
      this.canShowBuyerDataCard = false;
    }
    else {
      this.buyersearchService.searchBuyer(this.searchText).subscribe((data: BuyerSearchResult) => {
        this.buyerSearchResultData = data.value;
      }, (error) => {
        console.log(error);
      });
    }
  }




}
