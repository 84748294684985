import { Component, OnInit} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ViewrateService } from 'src/app/core/api/services/viewrate.service';

@Component({
  selector: 'app-circulation',
  templateUrl: './circulation.component.html',
  styleUrls: ['./circulation.component.scss']
})
export class CirculationComponent implements OnInit {

  results: any[];
  assetTypeId: number[];
  constructor(private _ViewrateService: ViewrateService) {
    this.assetTypeId = [5]
  }

  ngOnInit() {
    this._ViewrateService.assetsList.subscribe((response: any) => {
      this.results = response;
    });
  }

  hasCirculation() {
    if(this.results){
    return this.results.find((result) => (this.assetTypeId.indexOf(result.assetTypeId) != -1) ? true : false);
  }
}

  onMedialinkClick(MediaKitURL){
    window.close();
    let w = window.open(MediaKitURL,"MediaKitPopup", "height=600,width=800");
    w.focus();
  }

}
