import { Component, OnInit, ViewChild } from '@angular/core';
import { TypeinsearchComponent } from './typeinsearch/typeinsearch.component';
import { Router } from "@angular/router";
import { LoginComponent } from './login/login.component';
import { CartService } from '../core/api/services/cart.service';
import { UserRole } from '../enums/UserRole';
import { environment } from '../../environments/environment'
import { AuthService } from '../auth/service/auth.service';
import { Subscription } from 'rxjs/Rx';

@Component({
  selector: 'app-headercomponent',
  templateUrl: './headercomponent.component.html',
  styleUrls: ['./headercomponent.component.css']
})
export class HeadercomponentComponent implements OnInit {

  cartTotalItems: any
  
  selectedMagazine: any

  selectedMagazineid: number;

  paymentSetuplink: string;
  
  //makePaymentlink: string;
  @ViewChild(TypeinsearchComponent, /* TODO: add static flag */ { static: true }) searchVal: any;

  @ViewChild(LoginComponent, /* TODO: add static flag */ { static: true }) login: LoginComponent;

  show: boolean = false;

  isAuthenticated: boolean;
  
  subscription: Subscription;

  constructor(private router: Router,
    public cartService: CartService,
    public authService: AuthService) {

    this.cartService.shoppingTotalItems.subscribe((response) => {
      this.cartTotalItems = response;
    });
  }

  get IsMFSystemAdmin() {
    if(this.isAuthenticated){
      return this.authService.getUserRoles().indexOf(UserRole.MFSystemAdmin) != -1;
    }

    return false;
  }

  get IsMFSupportAdmin() {
    if(this.isAuthenticated){
      return this.authService.getUserRoles().indexOf(UserRole.MFSupportAdmin) != -1;
    }

    return false;
  }

  get IsBuyer() {
    if(this.isAuthenticated){
      return this.authService.getUserRoles().indexOf(UserRole.Buyer) != -1;
    }

    return false;
  }

  get IsSeller() {
    if(this.isAuthenticated){
      return this.authService.getUserRoles().indexOf(UserRole.Seller) != -1;
    }

    return false;
  }

  get IsUserLoggedIn() {
    return this.authService.isLoggedIn();
  }

  get IsMFAccountAdmin() {
    if(this.isAuthenticated){
      return this.authService.getUserRoles().indexOf(UserRole.MFAccountingAdmin) != -1;
    }

    return false;
  }

  get IsMFMarketingAdmin() {
    if(this.isAuthenticated){
      return this.authService.getUserRoles().indexOf(UserRole.MFMarketingAdmin) != -1;
    }

    return false;
  }


  get loggedInUserName() {
    if (this.IsUserLoggedIn) {
      return this.authService.name;
    }
    else {
      return "";
    }
  }

  loginClick() {
    this.authService.login();
  }

  logoutClick() {
    this.authService.logout();
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    console.log("authenticated: " + this.isAuthenticated);

    if (this.authService.isLoggedIn() && this.IsBuyer) {
      this.cartService.shopingCartTotalItems();
    }

    this.paymentSetuplink = environment.paymentSetupLink;
    //this.makePaymentlink = environment.makePaymentLink;
  }

  //once we select magazine from list redirect to magazine component along with selectedmagazineid
  magazineSelected = (selectedMagazine: any) => {

    if (selectedMagazine) {
      this.router.navigate(['browse/magazine-details/' + selectedMagazine.MagazineId + '/demographics']);
    }
    this.searchVal = "";
  }
}