import { Component, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { MagazineService } from "src/app/core/api/services/magazine.service";
import { Magazines } from "src/app/core/api/defs/Magazines";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { AlertService } from "src/app/core/api/services/alertservice/alert.service";
import { AddMagazineComponent } from "src/app/shared/add-magazine/add-magazine.component";
import { BreadCrumbs } from "src/app/core/api/defs/BreadCrumbs";
import { BreadCrumbsService } from "src/app/core/api/services/breadCrumbs.service";
import { ChangeSellerComponent } from "src/app/shared/change-seller/change-seller.component";

@Component({
    selector: 'app-magazine-info',
    templateUrl: './magazineInfo.component.html'
})
export class MagazineInfoComponent implements OnInit {

    modalRef: MDBModalRef;
    sellerId: number;
    sellerName: string;
    magazineId: number;
    magazineName: string;
    canShowMagazineDataCard: boolean;
    magazineData: Magazines
    marketCategories: string;
    mediaClassification: string;
    tags: string;

    constructor(private router: ActivatedRoute,
        private route: Router,
        private magazineService: MagazineService,
        private alertService: AlertService,
        private modalService: MDBModalService,
        private renderer: Renderer2,
        private breadCrumbsService: BreadCrumbsService) {

        router.params.subscribe((params) => {
            this.sellerId = params.sellerId;
            this.magazineId = params.magazineId;
        })
    }

    ngOnInit(): void {
        this.getMagazineInfo();

        this.breadCrumbsService.getBreadCrumbs(this.sellerId, this.magazineId).subscribe((data: BreadCrumbs) => {
            if (data != null) {
                this.sellerName = data.sellerName;
                this.magazineName = data.magazineName;
            }
        });
    }

    getMagazineInfo() {
        this.magazineService.getMagazine(this.sellerId, this.magazineId).subscribe((data) => {
            if (data != null) {
                this.canShowMagazineDataCard = true;
                this.magazineData = data;

                this.marketCategories = this.magazineData.marketCategories.map(x => x.marketCategoryName).join(',');
                this.mediaClassification = this.magazineData.mediaClassification.map(x => x.mediaClassificationName).join(',');
                this.tags= this.magazineData.tags.map(x=> x.tagName).join(',');
            }
            else {
                this.canShowMagazineDataCard = false;
            }
        });
    }

    editMagazineClick(magazines) {
        this.modalRef = this.modalService.show(AddMagazineComponent, {
            class: 'modal-lg',
            ignoreBackdropClick: true,
            // [config]:'{backdrop: false, ignoreBackdropClick: true}',
            data: {
                id: this.sellerId,
                isEdit: true,
                data: magazines
            }
        });

        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {
            this.getMagazineInfo();
        });
    }

    changeSeller() {
        this.modalRef = this.modalService.show(ChangeSellerComponent, {
            class: 'modal-lg',
            ignoreBackdropClick: true,
            // [config]:'{backdrop: false, ignoreBackdropClick: true}',
            data: {
                id: this.sellerId,
                magazineid: this.magazineId
            }
        });

        //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
        this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

        // Once the modal is closed trigger the API to get the fresh results
        this.modalRef.content.action.subscribe((result: any) => {
            if (result != null) {
                if (result.type) {
                    this.route.navigateByUrl('/maintenance');
                }
            }
        });
    }
}
