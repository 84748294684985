<!-- Nav tabs -->
<div *ngIf="canShowPagination">
  <app-mf-pagination [TotalCount]="totalOfferCount" [CurrentPageIndex]="currentPageIndex"
    (OnPageChanged)="mfPaginationOnPageChanged($event)"></app-mf-pagination>
</div>
<div class="row">

  <div class="col-sm-12">
    <!-- Tab panels -->

    <!-- Offer Panel -->
    <div class="tab-pane fade in show active" id="panelOffers" role="tabpanel">
        <!--jumbotron main container-->

        <!--countered title-->
        <div class="container-fluid" *ngFor="let offer of offer?.data, let $index = index">
          <div class="card mb-2">
            <div class="card-body ml-4 mr-2">
              <dl class="row">
                <dd>
                  <div class="d-flex">
                    <!-- <mdb-checkbox [inline]="true" class></mdb-checkbox> -->
                    <div class="mfo-status">
                      <img class="d-block" src="{{offer.magazineCoverUrl}}"
                        rel="noopener noreferrer" target="_blank" style="width: 154px; height: 180px;">
                      <p class="offer-status" [ngClass]="offer.offerState ? offer.offerState:''">
                        <span>
                          {{offer.offerState}}
                        </span>
                      </p>
                    </div>
                  </div>
                </dd>
                <dd class="col-xs-3 col-sm-3">
                  <div>
                    <a class="tittle3" rel="noopener noreferrer" target="_blank" *ngIf="offer?.magazineName">
                      <strong
                        [routerLink]="['/browse/magazine-details/', offer.magazineId, 'demographics']">{{offer?.magazineName}}</strong>
                    </a>
                  </div>
                  <div class="tc-2 mb-2" *ngIf="offer.offerNumber">({{offer.offerNumber}})</div>
                  <div class="tc-2 mt-1">
                    <div class="mt-1 ">
                      <span class="font-weight-bolder td-u">Seller Name </span>
                    </div>
                    <span>{{offer.sellerName}}</span>
                    <!--<i class="m-icon m-icon-text-separator" role="img" aria-label="|"></i>
                        <a href=" ">0</a><i class="m-icon m-icon-text-separator" role="img" aria-label="|"></i>
                        <a class="tc-2">100%</a>-->
                  </div>
                  <div class="tc-2 mt-1" *ngIf="offer.clientName">
                    <div class="mt-1 ">
                      <span class="font-weight-bolder td-u">Client Name </span>
                    </div>
                    {{offer.clientName}}
                    <!--initial order and line comments-->
                  </div>

                  <div class="tc-2 mt-1" *ngIf="offer.instructions">
                    <div class="mt-1 ">
                      <span class="font-weight-bolder td-u" *ngIf="offer.offerState != 'Declined'">Comments </span>
                      <span class="font-weight-bolder td-u" *ngIf="offer.offerState == 'Declined'">Reason for
                        Decline</span>
                    </div>
                    {{offer.instructions}}
                    <!--initial order and line comments-->
                  </div>
                </dd>
                <dd class="col-sm-2 text-right ta-l">

                  <app-offertimer [timerInfo]="offer.timerInfo" #timer [offerState]="offer.offerState"></app-offertimer>
             
                  <!-- TO DO: We need to uncomments the below line once we get confirmation -->
                  <!-- <app-offermessages [offerData]="offer"></app-offermessages>  -->
                  <div class="">
                    <div class="">
                      <span class="fontsize">
                        {{offer.offerNotification}}
                      </span>
                    </div>
                  </div>
                </dd>
                <dd class="col text-right">
                  <div>
                    <strong class="td-u font-weight-bold ">Ad Size</strong>
                  </div>
                  <div class="tc-2 ">{{offer.adSize}}</div>
                  <div>
                    <strong class="td-u font-weight-bold ">Ad Type</strong>
                  </div>
                  <div class="tc-2 ">{{offer.adType}}</div>
                  <div>
                    <strong class="td-u font-weight-bold ">Ad Color</strong>
                  </div>
                  <div class="tc-2 ">{{offer.adColor}}</div>
                </dd>
                <dd class="col text-right ta-l">
                  <div class="tc-2 ft-s2">
                    <strong>Current Offer</strong>
                  </div>
                  <div class="">
                    <strong class="tc-2 font-weight-bold">${{offer.offerredRate | number: '1.2-2'}}</strong>
                  </div>
                  <div *ngIf="canShowBuyNowText(offer);">
                    <div class="tc-2 ft-s2">
                      <strong>Rate Card Per Insertion</strong>
                    </div>
                    <div class="">
                      <strong class="tc-2 font-weight-bold">${{offer.adPrice | number: '1.2-2'}}</strong>
                    </div>
                  </div>
                </dd>
              </dl>
              <dl *ngIf="offer.offerLineVms.length > 0">
                <h3><b>Offers</b><br></h3>
                <app-viewofferline [offerid]="offer.offerId" [offerLineCount]="offer.offerLineCount"
                  [offerNumber]="offer.offerNumber" [offerstatus]="offer.offerState" [offerlines]="offer.offerLineVms"
                  [showdetails]="true"></app-viewofferline>
              </dl>
              <dl class="d-flex justify-content-end">
                <div class="row">
                  <!-- Accept offer -->
                  <div class="form-check"
                    *ngIf="(IsBuyer && offer.currentResponder == 'Buyer') || (IsSeller && offer.currentResponder == 'Seller') && timer.displayTimerText != 'Expired'">
                    <button type="button" size="sm" mdbBtn mdbWavesEffect
                      class="btn-secondary1 secondary relative waves-light" mdbWavesEffect id="{{offer.offerId}}"
                      (click)="showAcceptModal(offer,offer.offerId)">
                      <i class="fas fa-user-check"></i> Accept
                    </button>
                  </div>
                  <!-- Counter -->
                  <div class="form-check"
                    *ngIf="(((IsBuyer && offer.currentResponder == 'Buyer') || (IsSeller && offer.currentResponder == 'Seller')) && offer.offerState !='BEST AND FINAL OFFER') && timer.displayTimerText != 'Expired'">
                    <button type="button" mdbBtn size="sm" class="btn-secondary1 secondary relative waves-light"
                      mdbWavesEffect id="{{offer.offerId}}" (click)="counterModel(offer,offer.offerId)">
                      <i class="fas fa-user-edit"></i> Counter
                    </button>
                  </div>
                  <!-- Decline offer -->
                  <div class="form-check"
                    *ngIf="(IsBuyer && offer.currentResponder == 'Buyer') || (IsSeller && offer.currentResponder == 'Seller') && timer.displayTimerText != 'Expired'">
                    <button type="button" mdbBtn size="sm" class="btn-secondary1 secondary relative waves-light"
                      mdbWavesEffect id="{{offer.offerId}}" (click)="showDeclineModal(offer.offerId)">
                      <i class="fas fa-user-times"></i> Decline
                    </button>
                  </div>
                  <div class="form-check" *ngIf="offer.offerState == 'New Offer' && timer.displayTimerText != 'Expired'">
                    <button type="button" mdbBtn size="sm" class="btn-secondary1 secondary relative waves-light"
                      mdbWavesEffect id="{{offer.offerId}}" (click)="showDeleteModal(offer.offerId,offer.magazineName)">
                      <i class="far fa-trash-alt"></i> Delete
                    </button>
                  </div>
                  <div class="form-check" *ngIf="offer.offerState == 'New Offer' && timer.displayTimerText != 'Expired'">
                    <button type="button" mdbBtn size="sm" class="btn-secondary1 secondary relative waves-light"
                      mdbWavesEffect id="{{offer.offerId}}" (click)="callToSubmitOffferPopUp(offer.offerId)">
                      <i class="fas fa-user-edit"></i> Edit / Submit
                    </button>
                  </div>
                  <div class="form-check" *ngIf="offer.offerState != 'New Offer' && timer.displayTimerText != 'Expired'">
                    <button type="button" size="sm" mdbBtn mdbWavesEffect
                      class="btn-secondary1 secondary relative waves-light" mdbBtn
                      class="btn-secondary1 secondary relative waves-light" mdbWavesEffect id="{{offer.offerId}}"
                      (click)="showOfferHistoryModal(offer, offer.offerId)">
                      <i class="fas fa-history"></i> Offer History
                    </button>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      <div class="container-fluid text-center" *ngIf="!offer?.data.length">
        Your Dashboard is empty
      </div>
      <!--/.jumbotron main container-->

    </div>

  </div>
</div>
<!--/. Nav tabs -->
<div *ngIf="canShowPagination">
  <app-mf-pagination [TotalCount]="totalOfferCount" [CurrentPageIndex]="currentPageIndex"
    (OnPageChanged)="mfPaginationOnPageChanged($event)"></app-mf-pagination>
</div>