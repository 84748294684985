import {CHANGE_PUBLICATION_PERCENTAGE,
    CHANGE_PUBLICATION_PERCENTAGE_ALL,
    CHANGE_PUBLICATION_PERCENTAGE_DESELECT,
    CHANGE_PUBLICATION_PERCENTAGE_DESELECT_ALL} from '../actions'

const publicationPercentageReducer = (state = [],action) => {
    switch(action.type){

        case CHANGE_PUBLICATION_PERCENTAGE: {
            let result =  [...state,action.payload]
            return result;
        }
        case CHANGE_PUBLICATION_PERCENTAGE_ALL : {
            let result = action.payload
            return result;
        }
       
        case CHANGE_PUBLICATION_PERCENTAGE_DESELECT_ALL: {
            return []
        }
        default: return state;
    }
}

export default publicationPercentageReducer;