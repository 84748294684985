<mdb-navbar SideClass="navbar sticky-top navbar-expand-lg navbar-light blacksmoke lighten-4 pb-2"
    [containerInside]="false">
    <!-- Navbar brand -->
    <mdb-navbar-brand>
        <div class="row">
            <div class="col">
                <a id="header-marketplace" class="navbar-brand" [routerLink]="['/home']">
                    <img src="../../assets/images/MF_full_web.png" height="38" class="d-inline-block"
                        alt="MediaFiche logo" />
                </a>
            </div>
        </div>
    </mdb-navbar-brand>
    <!-- Collapsible content -->
    <links>
        <div class="mr-auto pl-3" style="flex: 1;border-bottom-color: #333;" *ngIf="IsUserLoggedIn && IsBuyer">
            <app-typeinsearch [ngClass]="{block: !IsUserLoggedIn}" (onSelectCallback)="magazineSelected($event)">
            </app-typeinsearch>
        </div>
        <!-- Links -->
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" [routerLink]="['/home']">
                <a class="nav-link waves-light text-color font-weight-bold" mdbWavesEffect>
                    <i class="fas fa-home tittle1"></i><span class="tittle3">Home</span>
                    <span class="sr-only">(current)</span>
                </a>
            </li>
            <li class="nav-item" *ngIf="!IsUserLoggedIn" (click)="loginClick()">
                <a class="nav-link text-color waves-light font-weight-bold" mdbWavesEffect>
                    <i class="fas fa-sign-in-alt tittle1"></i><span class="tittle3">Log In</span>
                </a>
            </li>
            <li class="nav-item" *ngIf="IsUserLoggedIn && (IsBuyer || IsSeller)" [routerLink]="['/offers']">
                <a class="nav-link text-color waves-light font-weight-bold" mdbWavesEffect><i
                        class="fas fa-file-signature tittle1"></i>
                    <span class="tittle3">My Offers</span>
                </a>
            </li>
            <li class="nav-item" *ngIf="IsUserLoggedIn && IsBuyer" [routerLink]="['/shopping-cart']">
                <a class="nav-link text-color waves-light font-weight-bold" mdbWavesEffect>
                    <i class="fas fa-shopping-cart tittle1">
                        <sup class="nav-cart tittle3"
                            *ngIf="cartTotalItems != undefined && cartTotalItems">{{cartTotalItems}}</sup>
                    </i>
                    <span class="clearfix  d-sm-inline-block"></span>
                </a>
            </li>
            <li class="nav-item" *ngIf="IsUserLoggedIn && (IsBuyer || IsSeller)">
                <a class="nav-link text-color waves-light font-weight-bold" mdbWavesEffect>
                    <feedback-fish projectid="0f6e60891b5f31" userid={{loggedInUserName}}>
                        <i class="fas fa-comment-alt tittle1"></i>
                        <span class="tittle3">Feedback</span>
                    </feedback-fish>
                </a>
            </li>
            <!-- Dropdown -->
            <li class="nav-item dropdown" dropdown *ngIf="IsUserLoggedIn">
                <a dropdownToggle mdbWavesEffect type="button"
                    class="nav-link dropdown-toggle waves-light text-color font-weight-bold" mdbWavesEffect>
                    <i class="fas fa-user tittle1"></i>
                    <span class="clearfix  d-sm-inline-block tittle3">
                        {{ loggedInUserName }}
                    </span>
                    <span class="caret d-sm-inline-block"></span>
                    <sup class="nav-cart"></sup>
                </a>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">

                    <a class="dropdown-item waves-light tittle3 font-weight-bold" [routerLink]="['/maintenance']"
                        *ngIf="IsMFSystemAdmin" mdbWavesEffect>Maintenance Screens
                    </a>
                    <a class="dropdown-item waves-light tittle3 font-weight-bold" [routerLink]="['/maintenance/no-seller-magazines']"
                        *ngIf="IsMFSystemAdmin" mdbWavesEffect>No Seller Magazines
                    </a>
                    <a class="dropdown-item waves-light tittle3 font-weight-bold" [routerLink]="['/seller-onboarding']"
                        *ngIf="IsMFSystemAdmin" mdbWavesEffect>Seller Onboarding
                    </a>
                    <a class="dropdown-item waves-light tittle3 font-weight-bold" [routerLink]="['/buyer-onboarding']"
                        *ngIf="IsMFSystemAdmin" mdbWavesEffect>Buyer Onboarding
                    </a>
                    <a class="dropdown-item waves-light tittle3 font-weight-bold"
                        [routerLink]="['/buyer-account-acknowledgement']" *ngIf="IsMFAccountAdmin" mdbWavesEffect>Buyer
                        Account Acknowledgement
                    </a>
                    <a class="dropdown-item waves-light tittle3 font-weight-bold"
                        [routerLink]="['/offer-account-acknowledgement']" *ngIf="IsMFAccountAdmin" mdbWavesEffect>Offer
                        Account Acknowledgement
                    </a>
                    <a class="dropdown-item waves-light tittle3 font-weight-bold"
                        [routerLink]="['/download-amf-batch-file']" *ngIf="IsMFAccountAdmin" mdbWavesEffect>Download AMF
                        Batch File
                    </a>
                    <a class="dropdown-item waves-light tittle3 font-weight-bold"
                        [routerLink]="['/download-offers-batch-file']" *ngIf="IsMFAccountAdmin" mdbWavesEffect>Download
                        Offers Batch File
                    </a>
                    <a class="dropdown-item waves-light font-weight-bold" *ngIf="IsUserLoggedIn && IsBuyer"
                        href="{{paymentSetuplink}}" target="_blank">
                        <i class="fas fa-id-card tittle1"></i>&nbsp;
                        <span class="tittle3">Payment Portal</span>
                    </a>
                    <a class="dropdown-item waves-light tittle3 font-weight-bold" [routerLink]="['/buyer-activity']"
                        *ngIf="IsMFMarketingAdmin" mdbWavesEffect>Buyer Activity
                    </a>
                    <!-- <a class="dropdown-item waves-light" *ngIf="IsUserLoggedIn && IsBuyer" href="{{makePaymentlink}}"
                        target="_blank">
                        <i class="fas fa-file-invoice-dollar"></i>  Make Payment
                    </a> -->
                    <a class="dropdown-item waves-light font-weight-bold" (click)="logoutClick()">
                        <i class="fas fa-sign-out-alt tittle1"></i>&nbsp;
                        <span class="tittle3">Logout</span>
                    </a>

                </div>
            </li>
        </ul>
        <!-- Links -->
    </links>
    <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->