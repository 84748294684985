import { Injectable } from "@angular/core";
import { environment } from '../../../../environments/environment';
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class BreadCrumbsService {

    baseUrl: string = `${environment.mfServerUrl}`;

    constructor(private http: HttpClient) { }

    getBreadCrumbs(sellerId?: any, magazineId?: any, mediaKitId?: any) {
        let url = `${this.baseUrl}/api/BreadCrumbs`;
        
        if (typeof(sellerId) != typeof (undefined)) {
            url = url + `?sellerId=${sellerId}`;
        }

        if (typeof(magazineId) != typeof (undefined)) {
            url = url + `&magazineId=${magazineId}`;
        }

        if (typeof(mediaKitId) != typeof (undefined)) {
            url = url + `&mediaKitId=${mediaKitId}`;
        }

        return this.http.get(url);
    }

}