import { Component, OnInit, Renderer2 } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { AddAudienceComponent } from '../../../shared/add-audience/add-audience.component'
import { AudienceService } from 'src/app/core/api/services/audience.service';
import { Audience } from '../../../core/api/defs/Audience';
import { DeleteConfirmComponent } from 'src/app/shared/delete-confirm/delete-confirm.component';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss']
})
export class AudienceComponent implements OnInit {

  selectedMagazineId: any;

  modalRef: MDBModalRef;

  selectedMediakitId: number;

  audience: Audience;
  showAudience: boolean = false;
  constructor(private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private audienceService: AudienceService) { }
  optionsSelect: Array<any>;

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.selectedMediakitId = params.mediaKitId;
    });
    this.getAudience(this.selectedMediakitId);
  }

  getAudience(mediakitId) {
    this.audienceService.read(mediakitId).subscribe((data: Audience) => {
      this.audience = data;
      this.showAudience = true;
    })
  }

  addAudienceClick() {
    this.modalRef = this.modalService.show(AddAudienceComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.selectedMediakitId,
        isEdit: false
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
        this.getAudience(this.selectedMediakitId);
      }
    });
  }

  editAudienceClick(audience) {
    this.modalRef = this.modalService.show(AddAudienceComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.selectedMediakitId,
        isEdit: true,
        audience: audience
      }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
        this.getAudience(this.selectedMediakitId);
      }
    });
  }

  deleteAudi(audienceId) {
    this.modalRef = this.modalService.show(DeleteConfirmComponent, {
      class: 'modal-sm modal-notify modal-warning',
      ignoreBackdropClick: true,
      data: {
        deleteName : 'Audience'
      }
    }); 
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result.status == 'delete') {
        this.audienceService.delete(audienceId).subscribe((data: Audience) => {
          this.alertService.success("Audience is Deleted Successfully")
          this.getAudience(this.selectedMediakitId);
        }, (errMsg) => {
          this.alertService.error(errMsg);
        });
      }
    });
  }

}


