<div id="top-section">

  <div class="row">
    <!--Filter Panel-->
    <!-- TO DO: setting the height is temp fix. the dropdown needs to be opened on top based on the resolution -->
    <app-sidenav class="col-sm-3" id="app-sidenav" style="min-height: 1100px;"></app-sidenav>
    <app-displayresults class="col-sm-9" id="app-displayresults"></app-displayresults>
  </div>

  <div class="fixed-action-btn smooth-scroll" style="bottom: 45px; right: 24px;">
    <a mdbPageScroll mdbWavesEffect [pageScrollDuration]="750" href="#top-section1"
      class="btn-floating btn-large waves-effect waves-light">
      <mdb-icon fas icon="arrow-up"></mdb-icon>
    </a>
    <p class="tittle3">
      <strong>Back to Top</strong>
    </p>
  </div>

</div>