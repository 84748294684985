<!--Seller's Dashboard -->
<div class="row justify-content-center">
  <div class="col-sm-10">
    <div class="font-weight-bolder py-3">
      <div class="row col-sm-12">
        <div class="font-bold py-3">
          <i class="fas fa-arrow-left pr-2 tc-2" aria-hidden="true"></i>
          <a class="tc-2" [routerLink]="['/browse']">Back to Search & Browse</a>
        </div>
      </div>
      <!--Dashboard Header-->

      <!--Tab's Dashboard -->
      <div class="row">
        <div class="col-sm-12">

          <!--Upper Tabs-->
          <div id="myTabContent">
            <!--Activity Tab-->
            <div class="tab-pane fade show active" id="activity" role="tabpanel" aria-labelledby="activity-tab">
              <app-offeractivity></app-offeractivity>
            </div>
            <!--/.Activity Tab-->

            <!--Messages Tab-->
            <div *ngIf="false" class="tab-pane fade" id="messages" role="tabpanel" aria-labelledby="messages-tab">Food
              truck
              fixie
              locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit,
              blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui
              photo
              booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo
              nostrud
              organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr, vero magna
              velit
              sapiente
              labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit, sustainable jean shorts beard ut DIY
              ethical
              culpa terry richardson biodiesel. Art party scenester stumptown, tumblr butcher vero sint qui sapiente
              accusamus
              tattooed echo park.
            </div>
            <!--/.Messages Tab-->

            <!--Account Tab-->
            <div *ngIf="false" class="tab-pane fade" id="account" role="tabpanel" aria-labelledby="account-tab">Etsy
              mixtape
              wayfarers,
              ethical wes anderson tofu before they sold out mcsweeney's organic lomo retro fanny pack
              lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft beer, iphone skateboard
              locavore
              carles etsy salvia banksy hoodie helvetica. DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred
              pitchfork.
              Williamsburg banh mi whatever gluten-free, carles pitchfork biodiesel fixie etsy retro mlkshk vice blog.
              Scenester
              cred you probably haven't heard of them, vinyl craft beer blog stumptown. Pitchfork sustainable tofu synth
              chambray
              yr.
            </div>
            <!--/.Account Tab-->
          </div>
          <!--/.Upper Tabs-->
        </div>

      </div>
      <!--Tab's Dashboard -->
    </div>
    <!--/Seller's Dashboard -->




    <!--Dashboard Modals-->



    
    <!--Cancel Offer-->

    <!--Deline Offer-->
  </div>
</div>
<!--/.Deline Offer-->