import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../core/core.module';
import { MaintenancRoutingModule } from './maintenance-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';
import { MaintenanceComponent } from './pages/maintenance.component';
import { MediakitComponent } from './pages/mediakit/mediakit.component';
import { DemocricularratesComponent } from './pages/RateOptions/democricularrates.component';
import { DemographicComponent } from './pages/demographic/demographic.component';
import { CirculationComponent } from './pages/circulation/circulation.component';
import { AudienceComponent } from './pages/audience/audience.component';
import { RatesComponent } from './pages/rates/rates.component';
import { MediakituploadsComponent } from './pages/mediakituploads/mediakituploads.component';
import { UploadComponent } from './pages/mediakituploads/upload/upload.component';
import { MagazinesComponent } from './pages/magazines/magazines.component';
import { SellerInfoComponent } from './pages/sellerInfo/sellerInfo.component';
import { MagazineInfoComponent } from './pages/magazineInfo/magazineInfo.component';
import { MediakitInfoComponent } from './pages/mediakitInfo/mediakitInfo.component';
import { MigrateMediaKitComponent } from './pages/migrate-media-kit/migrate-media-kit.component';
import { AssignSellerToMagazineComponent } from './pages/assign-seller-to-magazine/assign-seller-to-magazine.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    MaintenancRoutingModule,
    FormsModule,
    CoreModule,
    SharedModule
  ],
  declarations: [
    MaintenanceComponent,
    MediakitComponent,
    DemocricularratesComponent,
    DemographicComponent,
    CirculationComponent,
    AudienceComponent,
    RatesComponent,
    MediakituploadsComponent,
    UploadComponent,
    MagazinesComponent,
    SellerInfoComponent,
    MagazineInfoComponent,
    MediakitInfoComponent,
    MigrateMediaKitComponent,
    AssignSellerToMagazineComponent
  ],
  entryComponents: [UploadComponent, MigrateMediaKitComponent]
})
export class MaintenanceModule { }
