import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Magazineissue } from '../defs/Magazineissue';
import { environment } from '../../../../environments/environment';
import { MagazineissueData } from '../model';
import { ResetPassword } from '../defs/ResetPassword';

@Injectable({
  providedIn: 'root'
})
export class PasswordresetService {

  constructor(private http: HttpClient) { }

  read(Email) {
    return this.http.get<any>(`${environment.loginUrl}ForgotPassword`, {
      params: {
        email: Email
      }
    });
  }

  create(resetPassword: ResetPassword): Observable<ResetPassword> {
    return this.http.post<ResetPassword>(`${environment.loginUrl}ResetPassword`, resetPassword);
  }
}
