import { Component, OnInit } from '@angular/core';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private mdbSpinningPreloader: MDBSpinningPreloader, private router : Router) {
   
    // Show and Hide Loader during routing between pages.
    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.mdbSpinningPreloader.start();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationError:
        case event instanceof NavigationCancel: {

          this.mdbSpinningPreloader.stop();
          break;
        }

        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
   
  }

  
 
}
