<form [formGroup]="counterOfferForm">
  <div class="modal-content" class="justify-content-center">
    <!--Header-->
    <div class="modal-header">
      <h4 class="modal-title" id="myacceptofferModalLabel">
        <strong class="font-weight-bold tittle3">Counter Offer</strong>
      </h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <!--Body-->
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12 px-4">

          <div class="mt-3">
            <strong class="tittle3">Rate Card:&nbsp;</strong>
            <span>
              <strong class="font-weight-bold">${{offer?.adPrice | number: '1.2-2'}}</strong>
            </span>
          </div>

          <div class="mt-3">
            <strong class="tittle3">Your Offer:&nbsp;</strong>
            <span>
              <strong class="font-weight-bold">${{offer?.offerredRate | number: '1.2-2'}}</strong>
            </span>
          </div>

          <div class="mt-3">
            <div class="md-form input-group mb-3">
              <strong style="margin-top: 5px;">Counter Offer</strong>
              <div class="input-group-prepend">
                <span class="input-group-text md-addon">$</span>
              </div>
              <input tabindex="1" mdbInput mdbValidate formControlName="offeredRate" required type="number"
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                class="w-25 form-control pl-0 rounded-0" id="offeredRate" name="offeredRate" required />

              <mdb-error style="margin-left: 130px;"
                *ngIf="offeredRate.invalid && (offeredRate.dirty || offeredRate.touched)">
                Required
              </mdb-error>
              <strong style="margin-top: 5px;">
                per insertions
              </strong>
            </div>
          </div>

          <div class="mt-3">
            <mdb-checkbox mdbValidate formControlName="isFinalOffer">
              Is Best and Final
            </mdb-checkbox>
          </div>

          <div class="mt-3">
            <div class="md-form">
              <mdb-select label="Counter Reason" mdbValidate formControlName="reasonId" [options]="reasonTypeOptions">
              </mdb-select>
              <mdb-error *ngIf="reasonId.invalid && (reasonId.dirty || reasonId.touched)">
                Required
              </mdb-error>
            </div>
          </div>

          <div class="mt-3">
            <div class="md-form">
              <textarea tabindex="2" formControlName="instructions" type="text" name="instructions" id="instructions"
                class="md-textarea form-control" mdbInput></textarea>
              <label for="instructions">Add Comments</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Footer-->
    <div class="modal-footer justify-content-center">
      <button type="button" mdbBtn mdbWavesEffect data-dismiss="modal"
        class="btn-secondary1 secondary relative waves-light mr-4" (click)="counterOfferFormOnSubmit();">
        Submit Counter Offer
      </button>
      <button type="button" mdbBtn color="primary" outline="true"
      class="relative waves-light btn-outline-primary tc-1 blueborder" mdbWavesEffect
        mdbWavesEffect (click)="close()">Close</button>
    </div>
  </div>
</form>
<!--/.Content-->
