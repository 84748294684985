<div class="row">
    <div class="col-sm-10 mx-auto">
        <div class="tab-pane fade show active">
            <div class="container-fluid">
                <div class="tab-pane fade in show active " id="panelSummary" role="tabpanel">
                    <div class="my-2 mt-3 mfl-o mf3-center mf-tittle">
                        <span class="ml-2">Download Account Master File Batch</span>
                    </div>
                    <div class="jumbotron" style="padding:1.5rem">
                        <form [formGroup]="addDownloadBatchForm" (ngSubmit)="addDownloadBatchFormOnSubmit()">
                            <div class="row">
                                <div class="col-xs-12 col-sm-6">
                                    <div class="md-form mt-0">
                                        <input mdbInput mdbValidate type="text" formControlName="mainPartnerKey"
                                            class="form-control" id="mainPartnerKey" />
                                        <label for="mainPartnerKey">Main Partner Key</label>
                                        <mdb-error
                                            *ngIf="mainPartnerKey.invalid && (mainPartnerKey.dirty || mainPartnerKey.touched)">
                                            <span *ngIf="mainPartnerKey.errors.required">Required</span>
                                            <span *ngIf="mainPartnerKey.errors.maxlength">Exceeds max length</span>
                                        </mdb-error>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                    <div class="md-form mt-0">
                                        <input type="text" mdbValidate formControlName="buyerName" class="completer-input form-control mdb-autocomplete mb-0"
                                            [(ngModel)]="searchText" (ngModelChange)="onChange()"
                                            [mdbAutoCompleter]="auto" placeholder="Enter Buyer Name" />
                                        <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="No results found">
                                            <mdb-option *ngFor="let option of buyerSearchResultData"
                                                [value]="option.BuyerName">
                                                <div class="d-flex flex-column">
                                                    <strong>{{ option.BuyerName }}</strong>
                                                </div>
                                            </mdb-option>
                                        </mdb-auto-completer>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-sm-12 justify-content-center">
                                <div>
                                    <button type="submit" class="btn-secondary1 border-radius btn-md waves-light btn"
                                        mdbWavesEffect>Download</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>