export class BuyerSearch {
    BuyerId: number;
    BuyerName: string;
    IsActive : boolean;
    BuyerCompanyDescription: string;
    BuyerDBA: string;
    BuyerDnB: string;
    BuyerFEID: string;
    TransactionFeePercent: string;
    PartyId: number;
}

export class BuyerSearchResult {
    value: Array<BuyerSearch>;
}
