<div class="breadCrumps">
    <mdb-breadcrumb customClass="blue-text lighten-4">
        <mdb-breadcrumb-item class="tittle3" [routerLink]="['/buyer-activity']">
            <mdb-icon fas class="mr-1"></mdb-icon> Back to Buyer Search
        </mdb-breadcrumb-item>
    </mdb-breadcrumb>
</div>
<div class="tab-pane fade in show active" id="activityListTabPane" role="tabpanel">
    <div class="my-2 mfl-o text-center mf-tittle">
        <span class="ml-2">Activity List</span>
    </div>
    <div class="jumbotron py-3">
        <p *ngIf="buyer"><b class="tittle1">Buyer Name</b> : <span *ngIf="buyer.entityName">{{buyer.entityName}}</span></p>
        <p *ngIf="buyer"><b class="tittle1">Buyer Email</b> : <span *ngIf="buyer.userEmailId">{{buyer.userEmailId}}</span></p>
        <div class="mb-5 table-responsive" *ngIf=" activitys && activitys.length >0; else noActivity">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Activity</th>
                        <th scope="col">Notes</th>
                        <th scope="col">Action Date and Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let activity of activitys">
                        <td scope="col">{{activity.userActionName}}</td>
                        <td scope="col">{{activity.notes}}</td>
                        <td scope="col">{{activity.activityActionDateTime}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #noActivity>
          <p class="text-center"> No Activity Found</p> 
        </ng-template>
        <div class="mb-3" *ngIf="canShowPagination">
            <app-mf-pagination [TotalCount]="totalCount" [CurrentPageIndex]="currentPageIndex"
                (OnPageChanged)="mfPaginationOnPageChanged($event)"></app-mf-pagination>
        </div>
    </div>
</div>