import { AfterViewInit, Component, ViewChildren, Input } from '@angular/core';
import { CollapseComponent as Collapse, MDBModalRef, MDBModalService, ModalDirective } from 'ng-uikit-pro-standard';
import { ViewrateService } from '../../../core/api/services/viewrate.service';
import { AlertService } from '../../../core/api/services/alertservice/alert.service';
import { ViewRate, AdMediaKit, shoppingCart, AdRate, wishlistLine, Assets } from 'src/app/core/api/model';
import { Observable, Subscription } from 'rxjs';
import { CartService } from 'src/app/core/api/services/cart.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserRole } from 'src/app/enums/UserRole';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})

export class CollapseComponent implements AfterViewInit {

  isCollapsed: any;

  errormessage: any;

  selectedMagazine: any;

  shoppingCart: shoppingCart;

  @ViewChildren(Collapse)
  collapses: Collapse[];

  @Input()
  magazineId: any;

  @Input()
  magazineName: string;

  @Input()
  publisherName: string;

  @Input()
  coverUrl: string;

  viewrateData: any;

  shoppingCartObject: any;

  results: any[];

  assetTypeId: number[];

  MediaKitURL: string;

  CoverUrl: string;

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(private _ViewrateService: ViewrateService,
    private _cartService: CartService,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService) {

  }

  ngOnInit() {
    this.assetTypeId = [4, 6];

    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

  }


  showModelwithRates(frameRef: ModalDirective) {
    var _self = this;
    this._ViewrateService.getAdRates(this.magazineId).subscribe((response: ViewRate) => {
      _self.viewrateData = response;
      frameRef.show();
      this.collapses.forEach((collapse: Collapse, index: number) => {
        // if (index === 1) {
        collapse.hide();
        // }
      });
    })
    this._ViewrateService.assetsList.subscribe((response: any) => {
      this.results = response;
    });
  }

  get IsBuyer() {
    if (this.isAuthenticated) {
      return this.authService.getUserRoles().indexOf(UserRole.Buyer) != -1;
    }

    return false;
  }

  // adding item to cart
  addToCart(ad: AdRate) {
    this._ViewrateService.addToCart(ad.adRateId);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.collapses.forEach((collapse: Collapse, index: number) => {
        // if (index === 1) {
        collapse.hide();
        // }
      });
    }, 0);
  }

  hasRates() {
    if (this.results) {
      return this.results.find((result) => (this.assetTypeId.indexOf(result.assetTypeId) != -1) ? true : false);
    }
  }

  onMedialinkClick(MediaKitURL) {
    window.close();
    let w = window.open(MediaKitURL, "MediaKitPopup", "height=600,width=800");
    w.focus();
  }
}