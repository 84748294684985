import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService, ModalDirective } from 'ng-uikit-pro-standard';
import { Buyer } from 'src/app/core/api/defs/Buyer';
import { BuyerContact, TempAuthRequest } from 'src/app/core/api/model';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { BuyersearchService } from 'src/app/core/api/services/buyersearch.service';
import { AddContactComponent } from 'src/app/shared/add-contact/add-contact.component';

@Component({
  selector: 'app-buyercontact',
  templateUrl: './buyercontact.component.html'
})
export class BuyercontactComponent implements OnInit {
  @ViewChild('generatedCodeModal', /* TODO: add static flag */ {static:true})
  public generatedCodeModal: ModalDirective;
  canShowBuyerContactCard: boolean = false;
  buyercontact: BuyerContact;
  buyerPrimaryContactAny: boolean = false;
  generatedTempAuthResponse: TempAuthRequest;
  datacard: any;
  buyerid:any;
  hasRegisteredPrimaryContact: boolean = false;
  modalRef: MDBModalRef;
  constructor(private buyersearchService: BuyersearchService,
    private alertService: AlertService,
    private modalService: MDBModalService,
    private router: Router,
    private renderer: Renderer2,
    public route: ActivatedRoute) {
    route.params.subscribe(params => {
      this.buyerid = params.id;
      this.getContact(this.buyerid);
    })
  }


  ngOnInit(): void {
  }

  getContact(buyerId) {
    this.buyersearchService.getBuyerDetails(buyerId).subscribe((data: Buyer) => {
      if (data != null) {
          this.datacard = data;
          this.hasRegisteredPrimaryContact = data.hasRegisteredPrimaryContact;
      }
  }, (errMsg)=>{
      this.alertService.error(errMsg);
  });
    this.buyersearchService.getBuyerContact(buyerId).subscribe((data: any) => {
      this.canShowBuyerContactCard = (data) ? true : false;
      this.buyercontact = data;
      this.buyerPrimaryContactAny = data.some((c) => c.contactTypeId === 3);
    }, (errMsg) => {
      this.alertService.error(errMsg);
    });
  }

  generateCode() {
    if(this.buyerPrimaryContactAny){
        this.buyersearchService.generateCode(this.datacard).subscribe((data: TempAuthRequest) => {
            this.generatedTempAuthResponse = data;
            this.generatedCodeModal.show();
        }, (errMsg) => {
            this.alertService.error(errMsg);
        });
    }
    else{
        this.alertService.error("Please select primary contact before generating code");
    }
}
addContactOnClick() {
  this.modalRef = this.modalService.show(AddContactComponent, {
      class: 'modal-lg',
      data: {
          id: this.datacard.buyerId,
          type: "buyer",
          isEdit: false
      }
  });
  //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
  this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
  // Once the modal is closed trigger the API to get the fresh results
  this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
          if (result.type == "buyer") {
              // Invoke API to get the data
              this.getContact(result.id);
          }
      }
  });
}

editContactOnClick(contact) {
  this.modalRef = this.modalService.show(AddContactComponent, {
      class: 'modal-lg',
      data: {
          id: this.datacard.buyerId,
          type: "buyer",
          data: contact,
          isEdit: true
      }
  });
  //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
  this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
  // Once the modal is closed trigger the API to get the fresh results
  this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
          if (result.type == "buyer") {
              // Invoke API to get the data
              this.getContact(result.id);
          }
      }
  });
}

buyerPrimaryContactOnChange(buyerContact: BuyerContact, buyerid) {
  buyerContact.buyerId = buyerid;
  this.buyersearchService.anyRegisteredPrimaryContact(buyerid).subscribe((data: boolean) => {
      if (!data) {
          this.buyersearchService.updateBuyerPrimaryContact(buyerContact, buyerid).subscribe((data: any) => {
              this.alertService.success(data.message);
              this.getContact(buyerContact.buyerId);
          }, (errMsg) => {
              this.alertService.error(errMsg);
          });
      }
      else {
          this.getContact(buyerContact.buyerId);
      }
  }, (error) => {
      console.log(error);
  })
}

buyerSecondaryContactOnChange(buyerContact: BuyerContact, buyerid) {
  buyerContact.buyerId = buyerid;
  this.buyersearchService.updateBuyerSecondaryContact(buyerContact, buyerid).subscribe((data: any) => {
      this.alertService.success(data.message);
      this.getContact(buyerContact.buyerId);
  }, (errMsg) => {
      this.alertService.error(errMsg);
  });
}

}
