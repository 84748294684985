import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShoppingcartComponent } from './pages/shoppingcart/shoppingcart.component';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { AuthGuardService } from '../auth/service/auth-guard.service';

const routes: Routes = [
  {
    path: 'shopping-cart',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: ShoppingcartComponent,
        canActivate: [AuthGuardService]
      }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CartRoutingModule { }
