import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AdRates } from '../defs/AdRates';

@Injectable({
  providedIn: 'root'
})
export class RatesService {

  constructor(private http: HttpClient) { }

  getAdColor() {
    let url = `${environment.mfServerUrl}` + "/api/AdColor";
    return this.http.get(url);
  }

  getAdOrientation() {
    let url = `${environment.mfServerUrl}` + `/api/AdOrientation`;
    return this.http.get(url);
  }

  getAdRates() {
    let url = `${environment.mfServerUrl}` + `/api/AdRate/{mediaKitId}/AdRates`;
    return this.http.get(url);
  }

  getAdPlacement() {
    let url = `${environment.mfServerUrl}` + "/api/AdPlacement";
    return this.http.get(url);
  }

  getAdPrintSource() {
    let url = `${environment.mfServerUrl}` + "/api/AdPrintSource";
    return this.http.get(url);
  }

  getAdType() {
    let url = `${environment.mfServerUrl}` + "/api/AdType";
    return this.http.get(url);
  }
  getAdUnit() {
    let url = `${environment.mfServerUrl}` + "/api/AdUnit";
    return this.http.get(url);
  }

  getAdUnitType() {
    let url = `${environment.mfServerUrl}` + "/api/AdUnitType";
    return this.http.get(url);
  }

  read(mediakitId: number): Observable<AdRates> {
    return this.http
      .get<any>(`${environment.mfServerUrl}/api/AdRate/${mediakitId}/AdRates`);   ///api/AdRate/{mediaKitId}/AdRates
  }

  create(rates: AdRates): Observable<AdRates> {
    return this.http.post<AdRates>(`${environment.mfServerUrl}/api/AdRate`, rates);
  }

  update(rates: AdRates): Observable<AdRates> {
    return this.http.put<AdRates>(`${environment.mfServerUrl}/api/AdRate`, rates);
  }
  delete(adRateId: number) {
    return this.http.delete<any>(`${environment.mfServerUrl}/api/AdRate?adRateId=${adRateId}`);
  }

  getQuantity() {
    return this.http.get<any>(`${environment.mfServerUrl}/api/PageFraction`);
  }

}
