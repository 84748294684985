import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Demographic } from '../defs/Demographic';
import { DemoStaticGroup } from '../defs/DemoStaticGroup';

@Injectable({
  providedIn: 'root'
})
export class DemographicsService {


  constructor(private http: HttpClient) { }


  getDemoStatistic() {
    let url = `${environment.mfServerUrl}` + "/api/DemoStatistic";
    return this.http.get(url);
  }
  read(mediaKitId: number): Observable<Demographic> {
    return this.http
      .get<Demographic>(`${environment.mfServerUrl}/api/Demo/${mediaKitId}`);

  }

  create(demographic: Demographic): Observable<Demographic> {
    return this.http.post<Demographic>(`${environment.mfServerUrl}/api/Demo`, demographic);
  }

  update(demographic: Demographic): Observable<Demographic> {
    return this.http.put<Demographic>(`${environment.mfServerUrl}/api/Demo`, demographic);
  }
  delete(demoId: number) {
    return this.http.delete<any>(`${environment.mfServerUrl}/api/Demo?demoId=${demoId}`);
  }
}