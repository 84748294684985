import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Magazine } from '../defs/Magazine';
import { environment } from '../../../../environments/environment';
import { Magazines } from '../defs/Magazines';

@Injectable({
  providedIn: 'root'
})

export class MagazineService {
  serachMagazine = [];
  HouseholdIncome = [];
  netWorth = [];
  Circulation = [];
  Age = [];
  Education = [];

  selectedMagazine: any;
  constructor(private http: HttpClient) { }

  read(magazineId: number): Observable<Magazines> {
    return this.http
      .get<Magazines>(`${environment.mfServerUrl}/api/Magazine/${magazineId}/Magazines`);

  }

  create(magazine: Magazine): Observable<Magazine> {
    return this.http.post<Magazine>(`${environment.mfServerUrl}/api/Magazine`, magazine);
  }

  update(magazineid, magazine: Magazine): Observable<Magazine> {
    return this.http.put<Magazine>(`${environment.mfServerUrl}/api/Magazine`, magazine);
  }



  getFrequency() {
    return this.http
      .get<any>(`${environment.mfServerUrl}/api/FrequencyType`);

  }

  getMediaClassification() {
    return this.http
      .get<any>(`${environment.mfServerUrl}/api/MediaClassification`);

  }

  getMarket() {
    return this.http
      .get<any>(`${environment.mfServerUrl}/api/MarketCategory`);
  }

  getMagazine(sellerId: number, magazineId: number) {
    return this.http.get<Magazines>(`${environment.mfServerUrl}/api/Magazine/${sellerId}/Magazine/${magazineId}`);
  }

  changeSeller(magazine) {
    return this.http.post<any>(`${environment.mfServerUrl}/api/Magazine/ChangeSeller`, magazine);
  }

  noSellerMagazines(magazineName){
    return this.http.get<Magazines[]>(`${environment.mfServerUrl}/api/Magazine/NoSellerMagazines/${magazineName}`);
  }
}
