import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { OfferService } from 'src/app/core/api/services/offer.service';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { Subject, from } from 'rxjs';
import { OfferlineComponent } from '../offerline/offerline.component';

@Component({
  selector: 'app-viewofferline',
  templateUrl: './viewofferline.component.html'
})
export class ViewofferlineComponent implements OnInit {

  @Input() offerid: number;
  @Input() showdetails;
  @Input() offerlines;
  @Input() offerstatus;
  @Input () offerNumber;
  @Input () offerLineCount;
  offerline: any;
  modalRef: MDBModalRef;
  constructor(private offerService: OfferService,
    private modalService: MDBModalService,
     private alertService: AlertService,
    private renderer: Renderer2,
  ) { }
 

  deleteLine(linenumber) {
    this.offerService.deleteLine(linenumber).subscribe((response) => {
      this.alertService.success("Line Item Deleted Successfully");
      //this.modalRef.hide();
      this.offerService.emitOfferLineConfig("offerline");
      this.offerService.emitOfferConfig("offer");
    },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }
  showOfferLineModal() {
    this.modalRef = this.modalService.show(OfferlineComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      data: {
        offerId: this.offerid,
        offerstatus: this.offerstatus,
        offerNumber: this.offerNumber,
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
      // this.invokeOfferService(1);
    });
  }

  ngOnInit() {
    if (this.showdetails) {
      this.offerline = this.offerlines;
    } else {
      this.offerService.getOfferLines(this.offerid).subscribe(data => {
        this.offerline = data;
      })
    }
  }

}
