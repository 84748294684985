<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myHistory1ModalLabel">
      <span class="tittle3 font-weight-bold">Offer History</span>
    </h4>
  </div>
  <div class="modal-body">
    <div class="chat-message" *ngIf="offerhistoryData">
      <h5 class="tittle1">({{offerhistoryData.offerNumber}})</h5>
      <ul class="list-unstyled chat" *ngFor="let history of offerhistoryData.offerHistory; let $index = index">
        <li class="d-flex justify-content-between mb-4 pb-3" *ngIf="history.offerBy == 'Buyer'">
          <div class="text-center">
            <i class="fa fa-user-o fa-5x" aria-hidden="true"></i>
            <div class="col-3" style="max-width: 130px">
              <div class="text-center"><i class="svg-inline--fa fa-user fa-w-14 fa-5x far fa-user"></i></div>
              <span class="tittle3 font-weight-bold">{{history.partyName}}</span>
            </div>
          </div>
          <div class="chat-body white p-3 z-depth-1 w-40">
            <div class="header row">
              <strong class="primary-font font-weight-bold tc-2 col-4">Offer Status</strong>
              <strong class="primary-font font-weight-bold tc-2 col-4">Price Per Insertion</strong>
                <strong class="primary-font font-weight-bold tc-2 col-4">Comments</strong>
            </div>
            <div class="header row">
            <small class="primary-font tc-2 col-4">{{history.offerStatus}}</small>
            <small class="text-muted tc-2 col-4">{{history.totalPrice| number: '1.2-2'}}</small>
            <small class="text-muted tc-2 col-4">{{history.instructions}}</small>
          </div>
          <hr class="w-100">
          <div class="mb-0">
            <div class="header row">
              <!-- <small class="text-muted  col-1 td-u text-left">Line</small> -->

              <small class="text-muted col-3  td-u text-left">Line Number</small>
              <small class="text-muted col-3 td-u text-left">Price Per Insertion</small>
              <small class="text-muted  col-3 td-u text-left">Cover Date</small>
            </div>
            <div class="header row" *ngFor="let history of history.offerLines ; let $index = index">
              <!-- <small class="text-muted  col-1 text-left">{{historyline.lineNumber}}</small> -->
              <small class="text-muted  col-3 text-left">{{history.lineNumber}}</small>
              <small class="text-muted col-3 text-left">{{history.rate| number: '1.2-2'}}</small>
              <small class="text-muted col-3 text-left">{{history.coverDate}}</small>
            </div>
          </div>
    </div>
    </li>
    <li class="d-flex justify-content-between mb-4" *ngIf="history.offerBy == 'Seller'">
      <div class="chat-body white p-3 z-depth-1 w-40">
        <div class="header row">
          <strong class="primary-font font-weight-bold tc-2 col-4">Offer Status</strong>
          <strong class="primary-font font-weight-bold tc-2 col-4">Price</strong>
            <strong class="primary-font font-weight-bold tc-2 col-4">Comments</strong>
        </div>
        <div class="header row">
        <small class="primary-font tc-2 col-4">{{history.offerStatus}}</small>
        <small class="text-muted col-4 tc-2">{{history.totalPrice| number: '1.2-2'}}</small>
        <small class="text-muted col-4 tc-2">{{history.instructions}}</small>
      </div>
        <hr class="w-100">
        <div class="mb-0">
          <div class="header row">
            <!-- <small class="text-muted  col-1 td-u text-left">Line</small> -->

            <small class="text-muted col-3  td-u text-left">Line Number</small>
            <small class="text-muted col-3 td-u text-left">Price</small>
            <small class="text-muted  col-3 td-u text-left">Cover Date</small>
          </div>
          <div class="header row" *ngFor="let history of history.offerLines ; let $index = index">
            <!-- <small class="text-muted  col-1 text-left">{{historyline.lineNumber}}</small> -->
            <small class="text-muted  col-3 text-left">{{history.lineNumber}}</small>
            <small class="text-muted col-3 text-left">{{history.rate| number: '1.2-2'}}</small>
            <small class="text-muted col-3 text-left">{{history.coverDate}}</small>
          </div>
        </div>
      </div>
      <div class="text-center">
        <i class="fa fa-user-o fa-5x" aria-hidden="true"></i>
        <div class="col-3" style="max-width: 130px">
          <div class="text-center"><i class="svg-inline--fa fa-user fa-w-14 fa-5x far fa-user"></i></div>
          <span class="tittle3 font-weight-bold">{{history.partyName}}</span>
        </div>
      </div>
    </li>
    </ul>
    <button type="button" mdbBtn class="btn-secondary1 secondary relative waves-light mr-4 float-right"
      aria-label="Close" (click)="close()">Close</button>
  </div>
</div>
</div>