<div class="row">
  <div class="col-sm-12 mx-auto">
    <div class="tab-pane fade show active">
      <div class="container-fluid">
        <div class="tab-pane fade in show active" id="issueTabPane" role="tabpanel">
          <div class="my-2 mt-5 mfl-o mf3-center mf-tittle">
            <span class="ml-2">Issue List</span>
          </div>
          <div class="jumbotron py-3">
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th>MediaKit Id</th>
                  <th>Issue Name</th>
                  <th>Cover Date</th>
                  <th>Space Close Date</th>
                  <th>InHome Date</th>
                  <th>Insert Due Date</th>
                  <th>OnSale Date</th>
                  <th>Specs Due Date</th>
                  <th>Materials Due Date</th>
                  <th>Is Active</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let iss of issues; let i = index">
                  <td>{{iss.mediaKitId}}</td>
                  <td>{{iss.issueName}}</td>
                  <td>{{iss.coverDate}}</td>
                  <td>{{iss.spaceCloseDate}}</td>
                  <td>{{iss.inHomeDate}}</td>
                  <td>{{iss.insertDueDate}}</td>
                  <td>{{iss.onSaleDate}}</td>
                  <td>{{iss.specsDueDate}}</td>
                  <td>{{iss.materialsDueDate}}</td>
                  <td>{{iss.isActive}}</td>
                  <td class="tittle1">
                    <a (click)="editIssueClick(iss)">
                      <mdb-icon fas icon="edit"></mdb-icon>
                    </a>
                  </td>
                  <td class="tittle1">
                    <a (click)="deleteIssueClick(iss.issueId)">
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row pb-3">
            <div class="col-sm-12 text-center">
              <button type="button" mdbBtn size="md" class="btn btn-secondary1 secondary relative waves-light mr-4"
                (click)="addIssueClick()" mdbWavesEffect>Add Issue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>