import { Store, select } from '@ngrx/store';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { map, debounceTime, tap, switchAll, distinctUntilChanged, first } from 'rxjs/operators';
import { MagazineSearch } from '../../../core/search/models/magazine-search.model';
import * as MagazineSearchActions from '../../../core/search/actions/magazine-search.action';
import * as fromRoot from '../../../core/search/reducers/searchstate.reducer';
import { MagazineService } from 'src/app/core/api/services/magazine.service';
import { OnInit, ElementRef, ViewChild, Component } from '@angular/core';
import { AuthService } from 'src/app/auth/service/auth.service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})

export class SidenavComponent implements OnInit {
  @ViewChild('input', /* TODO: add static flag */ { static: true }) 
  input: ElementRef;
  
  mediaClassValue: string;
  singleSelectDropdownSettings: any;
  count$: Observable<number>;
  searchState$: Observable<fromRoot.SearchState>;
  index$: Observable<string>;
  results$: Observable<MagazineSearch.SearchResults>;
  adTypesValues$: Observable<(string | number)[]>;
  mediaClassValue$: Observable<(string | number)[]>;
  isUsNationalValue$: Observable<(string | number)[]>;
  IsRegionalValue$: Observable<(string | number)[]>;
  marketCategoryValue$: Observable<(string | number)[]>;
  marketCategoriesValue$: Observable<(string | number)[]>;
  publicationFrequencyValues$: Observable<(string | number)[]>;
  readerShipValues$: Observable<(string | number)[]>;
  issueDateValues$: Observable<(string | number)[]>;
  averageAgeValues$: Observable<(string | number)[]>;
  readerIncomeValues$: Observable<(string | number)[]>;
  averageHouseholdIncomeValues$: Observable<(string | number)[]>;
  averageNetWorthValues$: Observable<(string | number)[]>;
  someCollegeValues$: Observable<(string | number)[]>;

  searchText: string;
  nationalPublication: any;
  IsRegionalCheck: any;
  sortByMap: { [value: string]: string; } = {};

  constructor(private store: Store<fromRoot.State>,
    private magazineService: MagazineService,
    private authService: AuthService) {
    this.searchState$ = store.pipe(select('search'));
    this.index$ = store.pipe(select(fromRoot.getIndex));
    this.adTypesValues$ = store.pipe(select(fromRoot.getMultiSelectValues, { key: "AdTypes" }));
    this.mediaClassValue$ = store.pipe(select(fromRoot.getMultiSelectValues, { key: "MediaClassificationName" }));
    this.isUsNationalValue$ = store.pipe(select(fromRoot.getMultiSelectValues, { key: "IsUsNational" }));
    this.marketCategoryValue$ = store.pipe(select(fromRoot.getMultiSelectValues, { key: "MarketCategories" }));
    this.IsRegionalValue$ = store.pipe(select(fromRoot.getMultiSelectValues, { key: "IsRegional" }));
    // this.marketCategoriesValue$ = store.pipe(select(fromRoot.getMultiSelectValues, { key: "MarketCategories" }));
    this.publicationFrequencyValues$ = store.pipe(select(fromRoot.getMultiSelectValues, { key: "PublicationFrequency" }));
    this.readerShipValues$ = store.pipe(select(fromRoot.getMultiSelectValues, { key: "Circulation" }));
    this.issueDateValues$ = store.pipe(select(fromRoot.getMultiSelectValues, { key: "Issues" }));
    this.sortByMap["Magazine Name - A-Z"] = "MagazineName asc";
    this.sortByMap["Magazine Name - Z-A"] = "MagazineName desc";
    this.sortByMap["Circulation - low-high"] = "Circulation asc";
    this.sortByMap["Circulation - high-low"] = "Circulation desc";
    this.sortByMap["Publisher Name - A-Z"] = "PublisherName asc";
    this.sortByMap["Publisher Name - Z-A"] = "PublisherName desc";
  }
  sortByItems = ['Magazine Name - A-Z', 'Magazine Name - Z-A', 'Circulation - low-high', 'Circulation - high-low', 'Publisher Name - A-Z', 'Publisher Name - Z-A'];

  sortBySelected = null;

  sortBySelectedHHI = null;

  sortBySelectedHHNWI = null;

  sortByCirculation = null;

  sortBySelectedCirculation = null;

  sortByAge = null;

  sortByEducation = null;

  sortByHHI = [{ id: "0-50000", value: "Less than $50K" }, { id: "50000-75000", value: "$50K-$75K" }, { id: "75000-100000", value: "$75K-$100K" }, { id: "100000-150000", value: "$100-$150K" }, { id: "150000-200000", value: "$150-$200K" }, { id: "200000", value: "$200K+" }]

  sortByHHNW = [{ id: "0-100000", value: "Less than $100K" }, { id: "100000-250000", value: "$100K-$250K" }, { id: "250000-500000", value: "$250-$500K" }, { id: "500000-1000000", value: "$500K-$1MM" }, { id: "1000000", value: "$1MM+" },]

  circulation = [{ id: "0-100000", value: "Less than 100,000" }, { id: "100000-500000", value: "100,000-500,000" }, { id: "50000-100000", value: "500,000-1,000,000" }, { id: "1000000-5000000", value: "1,000,000-5,000,000" }, { id: "5000000-10000000", value: "5,000,000-10,000,000" }, { id: "10000000", value: "10,000,000+" }]

  age = [{ id: "0-18", value: "Less than 18" }, { id: "18-34", value: " 18-34" }, { id: "35-44", value: " 35-44" }, { id: "45-54", value: " 45-54" }, { id: "55", value: "55+" }]

  education = [{ id: "High_School", value: "High School Graduate" }, { id: "Some_College", value: "Some College" }, { id: "Bachelors_Degree", value: "Bachelor " }, { id: "Postgraduate", value: "Masters" }];

  sortarray = ['Choose Sort By', 'Closing Date', 'Issue', 'A to Z', 'Percentage'];
  /*Less than $100K

$100K-$250K

$250-$500K
  
$500K-$1MM

$1MM+*/
  marketCategorySelected = null;

  publicationFrequencySelected = null;

  issueDateValueSelected = null;

  chooseAdSelected = null;

  mediaClassSelected = null;

  isUsNationalSelected = null;

  IsRegionalSelected = null;

  public show: boolean = false;

  getUser: any;

  public buttonName: any = 'Show Filter(s)';

  isAuthenticated: boolean;

  subscription: Subscription;

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    if (this.isAuthenticated) {
      this.getUser = this.authService.name.indexOf('mediafiche.com') != -1 ? true : false;
    }

    this.setConfig();
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    fromEvent(this.input.nativeElement, 'keyup').pipe(
      debounceTime(300),
      map((e: any) => e.target.value as string),
      distinctUntilChanged<string>(),
      tap((query: string) => this.search()),
      switchAll())
      .subscribe()
    this.setSearchfacets();

  }

  toggleFilters() {
    this.show = !this.show;
    if (this.show)
      this.buttonName = "Hide Filter(s)";
    else
      this.buttonName = "Show Filter(s)";
  }

  setSearchfacets() {
    this.searchState$.pipe(first()).subscribe(x => {
      this.searchText = x.input.input;
      this.sortBySelected = [this.mapByValue(x.parameters.orderby)];
      this.marketCategorySelected = this.getSelectedValues(x, "MarketCategories");
      this.publicationFrequencySelected = this.getSelectedValues(x, "PublicationFrequency");
      this.chooseAdSelected = this.getSelectedValues(x, "AdTypes");
      this.issueDateValueSelected = this.getSelectedValues(x, "Issues");
      this.mediaClassSelected = this.getSelectedValues(x, "MediaClassificationName");
      this.isUsNationalSelected = this.getSelectedValues(x, "IsUsNational");
      this.IsRegionalSelected = this.getSelectedValues(x, "IsRegional");
      this.nationalPublication = this.isUsNationalSelected[0];
      this.IsRegionalCheck = this.IsRegionalSelected[0];
      this.sortBySelectedHHI = this.getSelectedValues(x, "Household_Income");
      this.sortBySelectedHHNWI = this.getSelectedValues(x, "Net_Worth");
      this.sortByCirculation = this.getSelectedValues(x, "Circulation");
      this.sortByAge = this.getSelectedValues(x, "Age");
      //this.setMultiSelectionOptions(x);	
      this.setselecteditems();
    });
  }

  readersage: MagazineSearch.ageNumericSlider = new MagazineSearch.ageNumericSlider();

  readerIncome: MagazineSearch.currencyRangeSlider = new MagazineSearch.currencyRangeSlider();

  someCollege: MagazineSearch.percentRangeSlider = new MagazineSearch.percentRangeSlider();

  householdnet: MagazineSearch.currencyRangeSlider = new MagazineSearch.currencyRangeSlider();

  homehousehold: MagazineSearch.currencyRangeSlider = new MagazineSearch.currencyRangeSlider();

  readerShip: MagazineSearch.numberslider = new MagazineSearch.numberslider();

  search() {
    this.store.dispatch(new MagazineSearchActions.SearchInput({ input: this.searchText }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
  }

  rangeSlidersKeyMap: object = {
    "Age": "readersage",
    "Reader_Income": "readerIncome",
    "Household_Income": "homehousehold",
    "Net_Worth": "householdnet",
    "Circulation": "readerShip",
    "Some_College": "someCollege",
  }

  mapByValue(value: string) {
    // todo find a better syntax
    let values = Object.values(this.sortByMap);
    let keys = Object.keys(this.sortByMap);
    let index = values.indexOf(value);
    return keys[index];
  }

  getSelectedValues(state: fromRoot.SearchState, key: string) {
    // todo find a better syntax
    if (state.searchFacets.facets[key]) {
      var f = (<MagazineSearch.CheckboxFacet>state.searchFacets.facets[key]);
      return Object.keys(f.values).filter(x => f.values[x].selected).map(x => {
        // let item: MagazineSearch.CheckboxFacetItem = {
        //   value: f.values[x].value, count: f.values[x].count, selected: f.values[x].selected
        // };
        // return item;
        return f.values[x].value;
      })
    }
  }

  removeItems() {
    this.magazineService.HouseholdIncome = [];
    this.magazineService.netWorth = [];
    this.magazineService.Circulation = [];
    this.magazineService.Age = [];
    this.magazineService.Education = [];
    this.sortBySelectedHHI = null;
    this.sortBySelectedHHNWI = null;
    this.sortByCirculation = null;
    this.sortBySelectedCirculation = null;
    this.sortByAge = null;
    this.sortByEducation = null;
  }

  saveItem(key, item, select) {
    if (key == 'Household_Income') {
      if (select) {
        this.magazineService.HouseholdIncome.push(item);
      } else {
        this.deselectitems(this.magazineService.HouseholdIncome, item)
      }
    } else if (key == 'Net_Worth') {
      if (select) {
        this.magazineService.netWorth.push(item);
      } else {
        this.deselectitems(this.magazineService.netWorth, item)
      }
    } else if (key == 'Circulation') {
      if (select) {
        this.magazineService.Circulation.push(item);
      } else {
        this.deselectitems(this.magazineService.Circulation, item)
      }
    } else if (key == 'Age') {
      if (select) {
        this.magazineService.Age.push(item);
      } else {
        this.deselectitems(this.magazineService.Age, item)
      }
    } else if (key == 'Education') {
      if (select) {
        this.magazineService.Education.push(item);
      } else {
        this.deselectitems(this.magazineService.Education, item)
      }
    }
  }

  deselectitems(slecectedArray, item) {
    slecectedArray.forEach((el, index) => {
      if (el.id == item.id) {
        slecectedArray.splice(index, 1);
      }
    })
  }

  setselecteditems() {
    this.sortBySelectedHHI = (this.magazineService.HouseholdIncome.length > 0) ? this.magazineService.HouseholdIncome : [];
    this.sortBySelectedHHNWI = (this.magazineService.netWorth.length > 0) ? this.magazineService.netWorth : []
    this.sortBySelectedCirculation = (this.magazineService.Circulation.length > 0) ? this.magazineService.Circulation : []
    this.sortByAge = (this.magazineService.Age.length > 0) ? this.magazineService.Age : [];
    this.sortByEducation = (this.magazineService.Education.length > 0) ? this.magazineService.Education : [];
  }

  isusnationalCheck(key: string, e) {
    let status = e.target.checked;
    if (status == true) {
      this.singleSelect(key, status);
    } else {
      this.singleDeSelect(key, "")
    }

  }

  isRegionalCheck(key: string, e) {
    let status = e.target.checked;
    if (status == true) {
      this.singleSelect(key, status);
    } else {
      this.singleDeSelect(key, "")
    }

  }

  goToTop() {
    window.scrollTo(0, 0);
    //  $('html, body').animate({scrollTop: 0}, 1000);
  }

  sortByDeSelect(item) {
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop()
  }

  singleStaticDeSelect(key, item) {
    let select = false;
    this.saveItem(key, item, select);
    this.store.dispatch(new MagazineSearchActions.SearchFacetsSingleToggleCheckbox({
      "key": key,
      "value": [item.id]
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
  }

  singleEducationStaticSelect(key, item) {
    let select = true
    this.saveItem(key, item, select);
    this.store.dispatch(new MagazineSearchActions.SearchFacetsSingleToggleCheckbox({
      "key": item.id,
      "value": 0
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
  }

  singleEducationStaticDeSelect(key, item) {
    let select = false;
    this.saveItem(key, item, select);
    this.store.dispatch(new MagazineSearchActions.SearchFacetsSingleToggleCheckbox({
      "key": item.id,
      "value": 0
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
  }

  sortBySelect(item) {
    const orderbyString = this.sortByMap[item];
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ orderby: orderbyString, skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop()
  }

  singleDeSelect(key, item) {
    this.store.dispatch(new MagazineSearchActions.SearchFacetsSingleToggleCheckbox({
      "key": key,
      "value": item
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop()
  }

  singleSelect(key, item) {
    this.store.dispatch(new MagazineSearchActions.SearchFacetsSingleToggleCheckbox({
      "key": key,
      "value": item
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop()
  }

  multiSelect(key: string, item: any) {
    this.store.dispatch(new MagazineSearchActions.SearchFacetsToggleCheckbox({
      "key": key,
      "value": item
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop();
  }

  multiDeselect(key: string, item: string | number) {
    // todo
    this.store.dispatch(new MagazineSearchActions.SearchFacetsToggleCheckbox({
      "key": key,
      "value": item
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop();
  }

  multiSelectAll(key: string, items: string[] | number[]) {
    // items = [...items.map(x => x.id)]
    this.store.dispatch(new MagazineSearchActions.SearchFacetsToggleAllCheckbox({
      "key": key,
      "values": items,
      boolType: true
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop();
  }

  multiDeselectAll(key: string, items: string[] | number[]) {
    this.store.dispatch(new MagazineSearchActions.SearchFacetsToggleAllCheckbox({
      "key": key,
      "values": items,
      boolType: false
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop();
  }

  multiStaticSelect(key, item) {
    let select = true
    this.saveItem(key, item, select);
    this.store.dispatch(new MagazineSearchActions.SearchFacetsToggleCheckbox({
      "key": key,
      "value": item.id
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop();
  };

  multiStaticDeselect(key: string, item) {
    let select = false
    this.saveItem(key, item, select);
    this.store.dispatch(new MagazineSearchActions.SearchFacetsToggleCheckbox({
      "key": key,
      "value": item.id
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop();
  }

  multiStaticSelectAll(key: string, items: any) {
    let select = true
    this.saveItem(key, items, select);
    this.store.dispatch(new MagazineSearchActions.SearchFacetsToggleAllCheckbox({
      "key": key,
      "values": items = [...items.map(x => x.id)],
      boolType: true
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop();
  }

  multiStaticDeselectAll(key: string, items: any) {
    let select = false
    this.saveItem(key, items, select);
    this.store.dispatch(new MagazineSearchActions.SearchFacetsToggleAllCheckbox({
      "key": key,
      "values": items = [...items.map(x => x.id)],
      boolType: false
    }));
    this.store.dispatch(new MagazineSearchActions.SearchParametersUpdate({ skip: 0 }));
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.goToTop();
  }

  reload() {
    this.searchText = '';
    this.store.dispatch(new MagazineSearchActions.SearchFacetsClear());
    this.store.dispatch(new MagazineSearchActions.SearchInput({ input: this.searchText }));
    this.store.dispatch(new MagazineSearchActions.SearchReset());
    this.setConfig();
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
    this.setSearchfacets();
    this.nationalPublication = false;
    this.IsRegionalCheck = false;
    this.removeItems();
    this.setselecteditems();
  }

  setConfig() {
    //dropdowns
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 1,
        dataType: "boolean",
        key: "IsUsNational",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 1,
        dataType: "boolean",
        key: "IsRegional",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "string",
        key: "MediaClassificationName",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 900,
        dataType: "collection",
        key: "MarketCategories",
        sort: "value"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "string",
        key: "PublicationFrequency",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "collection",
        key: "AdTypes",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "collection",
        key: "Issues",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "array",
        key: "Net_Worth",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "array",
        key: "Circulation",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "array",
        key: "Age",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "array",
        key: "Household_Income",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "education",
        key: "Some_College",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "education",
        key: "Bachelors_Degree",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "education",
        key: "Postgraduate",
        sort: "count"
      }
    ));
    this.store.dispatch(new MagazineSearchActions.SearchFacetsAddCheckbox(
      {
        count: 15,
        dataType: "education",
        key: "High_School",
        sort: "count"
      }
    ));
  }

  nextPage() {
    this.store.dispatch(new MagazineSearchActions.SearchPaginationIncrementSkip());
    this.store.dispatch(new MagazineSearchActions.SearchBegin());
  }

  clearAllFacets() {
    location.reload();
    //this.store.dispatch(new MagazineSearchActions.SearchFacetsClear());
  }

  sliderChange(value) {

  }
}