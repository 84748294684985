<div class="row justify-content-center">
  <div class="col-sm-10">
    <div class="font-weight-bolder py-3">
      <div class="row col-sm-12">
        <div class="font-bold py-3">
          <i class="fas fa-arrow-left pr-2 tc-2" aria-hidden="true"></i>
          <a class="tc-2" [routerLink]="['/browse']">Back to Search & Browse</a>
        </div>
      </div>
    </div>


    <div class="">
      <div class="row" style="color:black">
        <div class="col-xs-12 col-sm-4 col-md-4 result_img">
          <img class="d-block w-100" src="{{magazineDetails?.coverPageUrl}}" alt="First slide">
        </div>

        <!-- Thumbnails carousel -->

        <!--Title Details-->
        <div class="col-xs-12 col-sm-7 col-md-7">
          <div class="row">
            <div class="col-sm">
              <h4>
                <span class="mainTittle">{{magazineDetails?.magazineName}}</span> &nbsp;
              </h4>
              <hr>
              <div class="resultdescription">
                <span class="tittle1">Publisher Name: </span>
                <span>{{magazineDetails?.sellerName}}</span>
              </div>
              <div class="resultdescription">
                <span class="tittle1">Category(s): </span>
                <span>{{magazineDetails?.category}}</span>
              </div>
              <div class="resultproperties">
                <div class="resultproperties_frequency">
                  <span class=" tittle1">Frequency: </span>
                  <span>{{magazineDetails?.publicationFrequency}}</span>
                </div>
              </div>
              <div class="resultproperties o-display-inline" style="padding-right:8px;"
                *ngIf="magazineDetails?.circulation">
                <div>
                  <span class="tittle1">Circulation: </span>
                  <span>{{ magazineDetails?.circulation | number}}</span>
                </div>
              </div>
              <div *ngIf="magazineDetails?.circulation" class="fa fa-info-circle" aria-hidden="true">
                <span class="info bordered"> Circulation shown may be either Reported Per Issue or Audited.</span>
              </div>
              <div class="resultdescription">
                <span class="o-display-inline" style="margin-bottom:2%">
                  <span class=" tittle1">Description: </span>{{magazineDetails?.publisherStatement}}</span>
              </div>
              <button *ngIf="magazineDetails?.mediaKitUrl" type="button" mdbBtn
                class="btn-secondary1 border-radius btn-md waves-light"
                (click)="onMedialinkClick(magazineDetails?.mediaKitUrl)" mdbWavesEffect>View Media Kit <i
                  class="fas fa-eye ml-2"></i></button>
              <span *ngIf="!magazineDetails?.mediaKitUrl" style="font-size: 12px">No Media Kit Available for this
                Publication.</span>
              <span class="ml-3" *ngIf="hasAdRates; else noAdRates">
                <app-collapse [magazineId]="selectedMagazineId" [magazineName]="magazineDetails?.magazineName"
                  [coverUrl]="magazineDetails?.coverPageUrl" [publisherName]="PublisherName">
                </app-collapse>
                <hr>
                <app-createoffer [magazineId]="selectedMagazineId" [magazineName]="magazineDetails?.magazineName">
                </app-createoffer>
              </span>
              <ng-template #noAdRates>
                <p>No Rates Available</p>
                <p>Call for latest available rates (+1 844-516-3342) or Email us at support@mediafiche.com</p>
              </ng-template>

            </div>
          </div>
        </div>
      </div>
      <!--Carousel Row-->


      <div class="mt-3">
        <nav (click)="scroll(target)">
          <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist"
            style="background-color: #f5f5f5; font-size:18px">
            <a class="nav-item nav-link active font-weight tittle3" [routerLink]="['demographics']"
              id="nav-demographics-tab" data-toggle="tab" href="# nav-demographics" role="tab"
              aria-controls="nav-demographics" aria-selected="true">Demographics</a>
            <a class="nav-item nav-link font-weight tittle3" [routerLink]="['audience']" id="nav-audience-tab"
              data-toggle="tab" href="#nav-audience" role="tab" aria-controls="nav-audience"
              aria-selected="false">Audience</a>
            <a class="nav-item nav-link font-weight tittle3" [routerLink]="['circulation']" id="nav-circulation-tab"
              data-toggle="tab" href="#nav-circulation" role="tab" aria-controls="nav-circulation font-weight"
              aria-selected="false">Circulation</a>
            <a class="nav-item nav-link font-weight tittle3" [routerLink]="['dates']" id="nav-dates-tab"
              data-toggle="tab" href="#nav-dates" role="tab" aria-controls="nav-dates" aria-selected="false">Dates</a>
            <a class="nav-item nav-link font-weight tittle3" [routerLink]="['rates']" id="nav-rates-tab"
              data-toggle="tab" href="#nav-rates" role="tab" aria-controls="nav-rates" aria-selected="false">Rates</a>
          </div>
        </nav>
        <div #target>
          <router-outlet></router-outlet>
        </div>

      </div>

    </div>
  </div>
</div>