import { Component, OnInit, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { OfferService } from '../../../core/api/services/offer.service';
import { AlertService } from '../../../core/api/services/alertservice/alert.service';
import { Offer, OfferData } from '../../../core/api/model';
import { ViewrateService } from '../../../core/api/services/viewrate.service';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { AcceptofferComponent } from '../acceptoffer/acceptoffer.component';
import { OfferhistoryComponent } from '../offerhistory/offerhistory.component';
import { OffercounterComponent } from '../../../shared/offercounter/offercounter.component';
import { UserRole } from '../../../enums/UserRole';
import { SubmitofferComponent } from 'src/app/shared/submitoffer/submitoffer.component';
import { DeclineOfferConfirmationComponent } from '../decline-offer-confirmation/decline-offer-confirmation.component';
import { OfferlineComponent } from 'src/app/shared/offerline/offerline.component';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { AuthService } from 'src/app/auth/service/auth.service';
import { DeleteConfirmComponent } from 'src/app/shared/delete-confirm/delete-confirm.component';

@Component({
  selector: 'app-offeractivity',
  templateUrl: './offeractivity.component.html',
  styleUrls: ['./offeractivity.component.scss']
})
export class OfferactivityComponent implements OnInit, AfterViewInit {
  offer: Offer;

  // TO DO: this needs to moved to service ( creat a pagination service )
  totalOfferCount: number;

  currentPageIndex: number;

  modalRef: MDBModalRef;

  canShowPagination: boolean = false;

  isAuthenticated: boolean;

  subscription: Subscription;

  constructor(private offerService: OfferService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private alertService: AlertService,
    private authService: AuthService) {
    this.offerService.offerObservable.subscribe(value => {
      if (value) {
        this.invokeOfferService(1);
      }
    });
  }

  goToTop() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.subscription = this.authService.loginChanged.subscribe(status => this.isAuthenticated = status);

    this.currentPageIndex = 1;

    this.invokeOfferService(1);
  }


  // Hiding fields

  deleteOffer(offerid) {
    this.offerService.delete(offerid).subscribe((data) => {
      this.alertService.success("Offer Deleted Successfully");
      this.invokeOfferService(1);
    },
      (errMsg) => {
        this.alertService.error(errMsg);
      });
  }

  get IsBuyer() {
    if (this.isAuthenticated) {
      return this.authService.getUserRoles().indexOf(UserRole.Buyer) != -1;
    }

    return false;
  }

  get IsSeller() {
    if (this.isAuthenticated) {
      return this.authService.getUserRoles().indexOf(UserRole.Seller) != -1;
    }

    return false;
  }

  // Accept model
  showAcceptModal(offer, offerId) {
    this.modalRef = this.modalService.show(AcceptofferComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      data: {
        offer: offer,
        offerid: offerId
      }
    });
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.invokeOfferService(1);
    });
  }

  // Offer History
  showOfferHistoryModal(offers, offerId) {
    this.modalRef = this.modalService.show(OfferhistoryComponent, {
      class: 'modal-dialog modal-lg',
      ignoreBackdropClick: true,
      data: {
        offer: offers,
        offerId: offerId,
      }
    });
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.invokeOfferService(1);
    });
  }

  // Counter Offer
  counterModel(offer, offerId) {
    this.modalRef = this.modalService.show(OffercounterComponent, {
      class: 'modal-dialog modal-md',
      ignoreBackdropClick: true,
      data: {
        offer: offer,
        offerid: offerId,
      }
    });
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');
    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.invokeOfferService(1);
    });
  }

  // Submit Offer
  callToSubmitOffferPopUp(offerId: number) {
    this.modalRef = this.modalService.show(SubmitofferComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      data: {
        offerId: offerId,
        coverDate: "",
        page: "offer"
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
      this.invokeOfferService(1);
    });
  }

  // Decline Offer
  showDeclineModal(offerId) {
    this.modalRef = this.modalService.show(DeclineOfferConfirmationComponent, {
      class: 'modal-dialog modal-md',
      ignoreBackdropClick: true,
      data: {
        offerId: offerId,
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      this.modalRef.hide();
      // this.invokeOfferService(1);
    });
  }

  showDeleteModal(offerid, maagazinename) {
    this.modalRef = this.modalService.show(DeleteConfirmComponent, {
      class: 'modal-sm modal-notify modal-warning',
      ignoreBackdropClick: true,
      data: {
        deleteName: "Offer"
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      if (result.status) {
        this.offerService.delete(offerid).subscribe((response: any) => {
          this.invokeOfferService(1);
        },
          (errMsg) => {
            this.alertService.error(errMsg);
          });
      }
      this.modalRef.hide();
      // this.invokeOfferService(1);
    });
  }

  ngAfterViewInit() {
  }

  linedelete(event) {
    this.invokeOfferService(1);
  }

  canShowBuyNowText(offer: OfferData): boolean {

    if (offer != null) {
      return offer.offerState != "Accepted";
    }
    return true;
  }


  // This method is invoked as a callback from pagination. 
  mfPaginationOnPageChanged(currentPageNumber: any) {
    this.invokeOfferService(currentPageNumber);
  }

  invokeOfferService(currentPageNumber: number) {
    this.offerService.readByPage(currentPageNumber - 1).subscribe((response: Offer) => {

      this.totalOfferCount = response.count;

      // TO DO: this needs to be moved to pagination service.
      this.currentPageIndex = currentPageNumber;
      this.offer = response;
      this.goToTop();

      setTimeout(() => {
        this.canShowPagination = true;
      });

    });
  }
}