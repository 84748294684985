<div class="row justify-content-center p-3 no-gutters">
  <div class="col-sm-12 col-lg-8 col-md-9">
    <div class="card">
      <div class="card-body">
        <form id="login-form" [formGroup]="forgotPasswordForm" action="" (ngSubmit)="onSubmit()" method="post"
          role="form">
          
          <h2 class="text-center">
            Forgot Password
          </h2>

          <div class="md-form">
            <input type="email" formControlName="email" id="email" class="form-control"
               mdbInput mdbValidate tabindex="1" required />
            <label for="materialRegisterFormPassword">Email</label>
            <mdb-error *ngIf="email.invalid && (email.dirty || email.touched)">
              Required
            </mdb-error>
          </div>

          <div class="md-form">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-lg-6 col-md-6">
                <input type="submit" name="login-submit" id="login-submit" class="form-control btn-register btn"
                  value="Submit" tabindex="2" />
              </div>
            </div>
          </div>
          
          <div class="text-left">Return to
            <a [routerLink]="['/login']" id="register-form-link" style="font-weight:bold; text-decoration: underline">
              Login</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>