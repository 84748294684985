<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h2>
        {{title}}
      </h2>
      <hr>
      <div class="row">
        <div class="col-md-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">Time Picker</h3>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <app-time-picker [(ngModel)]="timePickerTime"></app-time-picker>
              </div>
              <div class="form-group">
                <label>Current Setting</label>
                <p class="form-control-static">
                  {{ timePickerTime }}
                </p>
              </div>
              <button class="btn btn-default" (click)="timePickerTime = 9*1000*60*60">Set Time to 9 AM</button>
              <button class="btn btn-default" (click)="timePickerTime = 10*1000*60*60">Set Time to 10 AM</button>
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="dayPickerDay">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">Day Picker</h3>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <app-day-picker [date]="dayPickerDay"></app-day-picker>
              </div>
              <div class="form-group">
                <label>Current Setting</label>
                <p class="form-control-static">
                  {{ dayPickerDay.toLocaleDateString() }}
                </p>
              </div>
              <button class="btn btn-default" (click)="setToJanuary1()">Change to January 1</button>
              <button class="btn btn-default" (click)="setToDecember31()">Change to December 31</button>
              <hr>
              <div class="form-group">
                <label>Day Picker for copy <br> <small>should stay in sync....</small></label>
                <app-day-picker [date]="dayPickerCopy"></app-day-picker>
              </div>
              <div class="form-group">
                <label>Current Setting</label>
                <p class="form-control-static">
                  {{ dayPickerCopy.toLocaleDateString() }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">Date Picker</h3>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <app-date-picker [date]="datePickerDate"></app-date-picker>
              </div>
              <div class="form-group">
                <label>Current Setting</label>
                <p class="form-control-static">
                  {{ datePickerDate.toLocaleString() }}
                </p>
              </div>
              <button class="btn btn-default" (click)="setToJanuary1NineAm()">Change to January 1 @ 9 AM</button>
              <button class="btn btn-default" (click)="setToDecember31TenPm()">Change to December 31 @ 10 PM</button>
              <hr />
              <div class="form-group">
                <label>Mirrored Date Picker <br> <small>***should*** stay in sync with the previous one</small></label>
                <app-date-picker [date]="datePickerCopy"></app-date-picker>
              </div>
              <div class="form-group">
                <label>Current Setting</label>
                <p class="form-control-static">
                  {{ datePickerCopy.toLocaleString() }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
