import { Component, OnInit, Renderer2 } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/core/api/services/alertservice/alert.service';
import { CirculationService } from 'src/app/core/api/services/circulation.service';
import { Circulation } from 'src/app/core/api/model';
import { FormBuilder, Validators } from '@angular/forms';
import { AddCirculationComponent } from 'src/app/shared/add-circulation/add-circulation.component';
import { DeleteConfirmComponent } from 'src/app/shared/delete-confirm/delete-confirm.component';

@Component({
  selector: 'app-circulation',
  templateUrl: './circulation.component.html',
  styleUrls: ['./circulation.component.scss']
})
export class CirculationComponent implements OnInit {


  selectedMediakitId: any;

  modalRef: MDBModalRef;

  circulations: Circulation

  constructor(private alertService: AlertService,
    private modalService: MDBModalService,
    private renderer: Renderer2,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private circulationService: CirculationService) { }

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.selectedMediakitId = params.mediaKitId;
    });

    this.getCirculation(this.selectedMediakitId);
  }

  getCirculation(mediakitid: any) {
    this.circulationService.read(mediakitid).subscribe((data: any) => {
      this.circulations = data;
      console.log(data)
    });
  }

  addCirculationClick() {
    this.modalRef = this.modalService.show(AddCirculationComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.selectedMediakitId,
        isEdit: false
      }
    });
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
        this.getCirculation(this.selectedMediakitId);
      }
    });
  }

  editCirculationClick(circulation) {
    this.modalRef = this.modalService.show(AddCirculationComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      data: {
        id: this.selectedMediakitId,
        isEdit: true,
        circulation: circulation
      }
    });

    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result != null) {
        this.getCirculation(this.selectedMediakitId);
      }
    });
  }

  delteCirculationClick(circulationId) {
    this.modalRef = this.modalService.show(DeleteConfirmComponent, {
      class: 'modal-sm modal-notify modal-warning',
      ignoreBackdropClick: true,
      data: {
        deleteName : 'Circulation'
      }
    }); 
    //https://mdbootstrap.com/support/angular/scroll-long-content-on-a-dynamic-modal/
    this.renderer.addClass(document.querySelector('mdb-modal-container'), 'overflow-y-auto');

    // Once the modal is closed trigger the API to get the fresh results
    this.modalRef.content.action.subscribe((result: any) => {
      // The values are sent in json format
      if (result.status == 'delete') {
        this.circulationService.delete(circulationId).subscribe((data) => {
          this.alertService.success("Circulation is Deleted Successfully")
          this.getCirculation(this.selectedMediakitId);
        }, (errMsg) => {
          this.alertService.error(errMsg);
        });
      }
    });
  }

}