<ng-multiselect-dropdown name="mediaType" 
                        style="display: block;background-color: white; color:black" 
                        class="omargin-left" 
                        [placeholder]="'select'"
                        [settings]="dropdownSettings"
                        [data]="items" 
                        [(ngModel)]="magazine"  
                        (onSelect)="onMediaClassSelect($event)" 
                        (onSelectAll)="onMediaClassSelectAll($event)" 
                        (onDeSelect) ="onMediaClassDeSelect($event)"
                        (onDeSelectAll)="onMediaClassDeSelectAll($event)"
                        required #mediatype="ngModel">
  </ng-multiselect-dropdown>