import { Component, OnInit } from '@angular/core';
import { OfferService } from '../../../core/api/services/offer.service';
import { Offers, AcceptDeclineOffer, AcceptOffer } from '../../../core/api/model';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { AlertService } from '../../../core/api/services/alertservice/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-acceptoffer',
  templateUrl: './acceptoffer.component.html',
  styleUrls: ['./acceptoffer.component.scss']
})
export class AcceptofferComponent implements OnInit {

  constructor(public modalRef: MDBModalRef, private offerService: OfferService, private alertService: AlertService) { }
  offer?: Offers;
  offerid: number;
  acceptOffer: AcceptOffer;
  offerlines: any
  action: Subject<any> = new Subject();

  id4TermsAndConditionsUrl: string = `${environment.identityProviderRootUrl}` + "/Account/TermsAndConditions";

  ngOnInit() {
    this.offerService.getOfferLines(this.offerid).subscribe(data => {
      this.offerlines = data;
    })
  }

  //Accept offer
  AcceptOffer() {
    this.offerService.accept(this.offerid).subscribe((response: any) => {
      this.alertService.success('Offer Accepted successfully');
      this.action.next(response);
      this.modalRef.hide();
    },
      (errMsg) => {
        this.alertService.error(errMsg);
      });

  }

  close() {
    this.modalRef.hide()
  }

}
