import * as __model from '../model';

export class Contact {
  data: __model.ContactData;
  _id?: string;
  /**
   * The date this resource was created.
   * format: date
   */
  created?: string;
  /**
   * The date this resource was modified.
   * format: date
   */
  modified?: string;
}
