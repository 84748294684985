import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Magazineissue } from '../defs/Magazineissue';
import { environment } from '../../../../environments/environment';
import { MagazineissueData } from '../model';
import { EmailConformation } from '../../../core/api/defs/EmailConformation';


@Injectable({
  providedIn: 'root'
})
export class EmailconformationService {

  constructor(private http: HttpClient) { }



  read(userId: string, confirmationCode: string) {
    return this.http.post<any>(`${environment.loginUrl}ConfirmEmail`, { userId: userId, confirmationCode: confirmationCode });
  }


}
