<div class="row justify-content-center p-3 no-gutters">
  <div class="col-xs-12 col-lg-8 col-md-9">
    <div class="card mb-4">
      <div class="card-body">
        <form id="register-form" [formGroup]="registerForm" action="" (ngSubmit)="onSubmit()" method="post" role="form">
          <h2 class="text-center">
            Register Now
          </h2>
          <div class="md-form">
            <input mdbInput mdbValidate type="text" formControlName="tempAuthToken" class="form-control" required
              id="tempAuthToken" aria-describedby="materialRegisterFormTempHelpBlock" tabindex="3" />
            <label for="tempAuthToken">Temporary Authorization Code</label>
            <mdb-error *ngIf="tempAuthToken.invalid && (tempAuthToken.dirty || tempAuthToken.touched)">
              Required
            </mdb-error>
            <small id="materialRegisterFormTempHelpBlock" class="form-text text-muted pt-2">
              Enter provided Temporary Authorization Code
            </small>
          </div>
          <div class="row">
            <div class="col-sm-12 col-lg-6 col-md-6">
              <div class="md-form">
                <input mdbInput mdbValidate type="text" formControlName="firstName" class="form-control" required
                  id="firstName" tabindex="4" />
                <label for="firstName">First Name</label>
                <mdb-error *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6">
              <div class="md-form">
                <input mdbInput mdbValidate type="text" formControlName="lastName" class="form-control" required
                  id="lastName" tabindex="5" />
                <label for="lastName">Last Name</label>
                <mdb-error *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                  Required
                </mdb-error>
              </div>
            </div>
          </div>

          <div class="md-form">
            <input mdbInput mdbValidate type="text" formControlName="email" class="form-control" required id="email"
              aria-describedby="materialRegisterFormEmailHelpBlock" tabindex="6" />
            <label for="email">Email</label>
            <mdb-error *ngIf="email.invalid && (email.dirty || email.touched)">
              <span *ngIf="email.errors.required">Required</span>
              <span *ngIf="email.errors.email">Enter Valid Email Address</span>
            </mdb-error>
            <small id="materialRegisterFormEmailHelpBlock" class="form-text text-muted mb-4 pt-2">
              Confirmation email will be sent to this address
            </small>
          </div>

          <div class="md-form">
            <input type="password" formControlName="password" id="password" class="form-control"
              aria-describedby="materialRegisterFormPasswordHelpBlock" mdbInput mdbValidate tabindex="7" required />
            <label for="password">Password</label>
            <mdb-error *ngIf="password.invalid && (password.dirty || password.touched)">
              Required
            </mdb-error>
            <small id="materialRegisterFormPasswordHelpBlock" class="form-text text-muted mb-4 pt-2">
              At least 8 characters, including 1 uppercase, 1 lowercase, 1 digit and 1 special character
            </small>
          </div>


          <div class="md-form">
            <input mdbInput mdbValidate type="password" formControlName="confirmpassword" class="form-control" required
              validateequal="password" id="confirmpassword" tabindex="8" />
            <label for="confirmpassword">Confirm Password</label>
            <mdb-error *ngIf="confirmpassword.invalid && (confirmpassword.dirty || confirmpassword.touched)">
              {{getConfirmPasswordValidationErrorMessage()}}
            </mdb-error>
          </div>

          <div class="form-group">
            <div style="height:400px; text-align: left;border:2px solid #ced4da; overflow-y:scroll">
              <div style="color:black" [innerHTML]="'<span>'+viewContent+'</span>'"></div>
            </div>
          </div>
          <div class="md-form">
              <mdb-checkbox
                [ngClass]="{ 'is-invalid' : agreementAccepted.invalid && (agreementAccepted.dirty || agreementAccepted.touched) }"
                formControlName="agreementAccepted">I Agree</mdb-checkbox>
              <mdb-error *ngIf="agreementAccepted.invalid && (agreementAccepted.dirty || agreementAccepted.touched)">
                Please Accept Agreement
              </mdb-error>
          </div>

          <div class="form-group">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-lg-6 col-md-6">
                <input type="submit" name="register-submit" id="register-submit" tabindex="10"
                  class="form-control btn-register btn" value="Register Now">
              </div>
            </div>
          </div>

          <div class="text-left">
            Not ready?
            <a [routerLink]="['/login']" id="login-form-link2" style="font-weight:bold; text-decoration: underline">
              Cancel
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>