import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRole } from 'src/app/enums/UserRole';

import { OnboardingbuyerComponent } from './onboardingbuyer.component';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { BuyeraddressComponent } from './buyeraddress/buyeraddress.component';
import { BuyerinfoComponent } from './buyerinfo/buyerinfo.component';
import { BuyercontactComponent } from './buyercontact/buyercontact.component';
import { AuthGuardService } from '../auth/service/auth-guard.service';

const appRoutes: Routes = [
    {
        path: 'buyer-onboarding',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                component: OnboardingbuyerComponent,
                canActivate: [AuthGuardService],
                data: {
                    roles: [
                        UserRole.MFSystemAdmin
                    ]
                }
            }
        ]
    },
    {
        path: 'buyer-onboarding/:id',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                component: BuyerinfoComponent,
                canActivate: [AuthGuardService],
                data: {
                    roles: [
                        UserRole.MFSystemAdmin
                    ]
                }
            }
        ]
    },
    {
        path: 'buyer-onboarding/:id/buyer-address',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                component: BuyeraddressComponent,
                canActivate: [AuthGuardService],
                data: {
                    roles: [
                        UserRole.MFSystemAdmin
                    ]
                }
            }
        ]
    },
    {
        path: 'buyer-onboarding/:id/buyer-contact',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                component: BuyercontactComponent,
                canActivate: [AuthGuardService],
                data: {
                    roles: [
                        UserRole.MFSystemAdmin
                    ]
                }
            }
        ]
    }

];


@NgModule({
    imports: [
        RouterModule.forChild(appRoutes)
    ],
    exports: [RouterModule]
})
export class OnboardingbuyerRoutingModule { }