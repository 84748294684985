import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BuyeraccountacknowledgementComponent} from './pages/buyeraccountacknowledgement/buyeraccountacknowledgement.component';
import { MainLayoutComponent } from '../layout/mainLayout/main-layout/main-layout.component';
import { UserRole } from 'src/app/enums/UserRole';
import { BuyerdetailComponent } from './pages/buyerdetail/buyerdetail.component';
import { AuthGuardService } from '../auth/service/auth-guard.service';

const routes: Routes = [
  {
    path: 'buyer-account-acknowledgement',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: BuyeraccountacknowledgementComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFAccountingAdmin
          ]
        }
      }
    ]
  },
  {
    path: 'buyer-account-acknowledgement/buyer/:buyerId',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: BuyerdetailComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [
            UserRole.MFAccountingAdmin
          ]
        }
      }
    ]
  }

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule { }
